import React from 'react'
import styled from 'styled-components'

const BackdropDiv = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
`

const backDrop = (props) => {
  return <BackdropDiv onClick={props.onClose}></BackdropDiv>
}

export default backDrop
