import React from 'react'
// Constants
import { k_rowid, k_columnid } from '../../../constants'
import styled from 'styled-components'

const RowDiv = styled.div`
  position: relative;

  &:after {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-left-color: #cbcbcb;
  }

  &:before {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-right-color: #cbcbcb;
  }
`

const ColumnDiv = styled.div`
  position: relative;

  &:before {
    position: absolute;
    content: '';
    border-style: solid;
    border: 5px solid transparent;
    display: inline-block;
    border-bottom-color: #cbcbcb;
    top: 0;
  }

  &:after {
    position: absolute;
    content: '';
    border-style: solid;
    border: 5px solid transparent;
    display: inline-block;
    bottom: 0;
    border-top-color: #cbcbcb;
  }
`

const SelectedDiv = styled.div`
  border-color: var(--itk-red-color);
  border-radius: 0.3em;
  border-width: 1px;
  border-style: dotted;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

export const advancedComponent = (props) => {
  let personalStyle = {}

  personalStyle = {}
  if (typeof props.properties[Object.keys(props.properties)[0]] === 'object') {
    Object.keys(props.properties).map((propertyName) => {
      personalStyle[propertyName] = props.properties[propertyName].value
      if (props.type === k_rowid || props.type === k_columnid) {
        if (
          (propertyName === 'borderStyle' &&
            props.properties[propertyName].value === 'none') ||
          (propertyName === 'borderWidth' &&
            props.properties[propertyName].value === '0px') ||
          (propertyName === 'borderColor' &&
            props.properties[propertyName].value === 'none')
        ) {
          personalStyle['borderStyle'] = 'dashed'
          personalStyle['borderWidth'] = '1px'
          personalStyle['borderColor'] = 'lightgray'
        }
      }
      return null
    })
    Object.keys(props.styles).map((styleName) => {
      personalStyle[styleName] = props.styles[styleName]
      return null
    })
  } else {
    personalStyle = props.properties
  }

  if (props.selected) {
    switch (props.type) {
      case k_rowid:
        return (
          <RowDiv
            data-id={props.dataId}
            data-parent={props.dataParent}
            data-comptype="advanced"
            style={personalStyle}
            draggable
            onDragStart={props.dragStart}
            onDragOver={props.dragOver}
            onDragLeave={props.dragLeave}
            onDrop={props.drop}
          >
            <SelectedDiv
              data-id={props.dataId}
              data-parent={props.dataParent}
              data-comptype="advanced"
            ></SelectedDiv>
            {props.children}
          </RowDiv>
        )
      case k_columnid:
        return (
          <ColumnDiv
            data-id={props.dataId}
            data-parent={props.dataParent}
            data-comptype="advanced"
            draggable
            style={personalStyle}
            onDragStart={props.dragStart}
            onDragOver={props.dragOver}
            onDragLeave={props.dragLeave}
            onDrop={props.drop}
          >
            <SelectedDiv
              data-id={props.dataId}
              data-parent={props.dataParent}
              data-comptype="advanced"
            ></SelectedDiv>
            {props.children}
          </ColumnDiv>
        )
      default:
        return null
    }
  } else {
    switch (props.type) {
      case k_rowid:
        return (
          <RowDiv
            style={personalStyle}
            data-id={props.dataId}
            data-parent={props.dataParent}
            data-comptype="advanced"
            draggable
            onDragStart={props.dragStart}
            onDragOver={props.dragOver}
            onDragLeave={props.dragLeave}
            onDrop={props.drop}
            onClickCapture={() => props.onSelect(props.dataId)}
          >
            {props.children}
          </RowDiv>
        )
      case k_columnid:
        return (
          <ColumnDiv
            style={personalStyle}
            data-id={props.dataId}
            data-parent={props.dataParent}
            data-comptype="advanced"
            draggable
            onDragStart={props.dragStart}
            onDragOver={props.dragOver}
            onDragLeave={props.dragLeave}
            onDrop={props.drop}
            onClickCapture={() => props.onSelect(props.dataId)}
          >
            {props.children}
          </ColumnDiv>
        )
      default:
        return null
    }
  }
}
