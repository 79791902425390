import React, { Component, Fragment, createRef } from 'react'
import Blockly from 'blockly/core'
import BlocklyJS from 'blockly/javascript'
import locale from 'blockly/msg/en'
import 'blockly/blocks'
// Custom blocks imports
import { Block, Category, Sep } from './index'
import '../../blocks/customBlocks'
import '../../blocks/generator'
import { Maze as maze } from '../../contexts/maze'

Blockly.setLocale(locale)

class MazeBlocklyComponent extends Component {
  constructor(props) {
    super(props)
    this.myRef = createRef()
    // TODO: add functions blocks category if functions exists in blocks. Functions: []
    // let propCategories = {
    //   Loops: ['controls_repeat_ext', 'math_number'],
    //   Movement: ['when_run', 'move_forward', 'turn_left', 'turn_right'],
    // }
    // const block = props?.blocks || {}
    // let initialToolbar = [
    //   <Category name="General" key="generalCategory" colour="210">
    //     <Block type="set_color"></Block>
    //     <Block type="set_text_input"></Block>
    //     <Block type="al_numerical_input"></Block>
    //     <Block type="al_boolean"></Block>
    //     <Block type="al_keyboard_type_input"></Block>
    //   </Category>,
    //   <Sep key="generalSeparator" />,
    // ]
    // console.log('blocks', block)
    // Object.keys(block).forEach((category, idx) => {
    //   initialToolbar.push(
    //     <Category
    //       name={category}
    //       key={`control${category}`}
    //       colour={((idx + 1) * 40 + 10).toString()}
    //     >
    //       {block[category].map((blockName) => (
    //         <Block type={blockName}></Block>
    //       ))}
    //     </Category>
    //   )
    // })
    // this.state = {
    //   initialToolbar: initialToolbar,
    // }
  }
  componentDidMount() {
    const { initialXml, children, ...rest } = this.props
    this.primaryWorkspace = Blockly.inject(this.blocklyDiv, {
      toolbox: this.toolbox,
      zoom: {
        controls: true,
        wheel: true,
        startScale: 1.0,
        maxScale: 3,
        minScale: 0.3,
        scaleSpeed: 1.2,
      },
      ...rest,
    })

    if (initialXml) {
      Blockly.Xml.domToWorkspace(
        Blockly.Xml.textToDom(initialXml),
        this.primaryWorkspace
      )
    }
    new maze(
      this.myRef,
      this.props.matrix,
      this.props.assets,
      this.get_charcter_info(this.props.matrix, this.props.assets),
      this.props.random,
      this.props.level,
      this.props.images_folder,
      this.props.background
    )
    this.primaryWorkspace.addChangeListener(this.onEveryChange)
  }

  componentWillUnmount() {
    // this.onEveryChange()
  }

  onEveryChange = () => {
    this.saveCode()
    this.saveXml()
  }

  saveCode = () => {
    var code = BlocklyJS.workspaceToCode(this.primaryWorkspace)
    console.log('code', code)
  }

  saveXml = () => {
    var xml = Blockly.Xml.workspaceToDom(this.primaryWorkspace)
    var xml_text = Blockly.Xml.domToText(xml)
    console.log('xml', xml_text)
  }

  get workspace() {
    return this.primaryWorkspace
  }

  setXml(xml) {
    Blockly.Xml.domToWorkspace(
      Blockly.Xml.textToDom(xml),
      this.primaryWorkspace
    )
  }

  get_charcter_info(level, assets) {
    console.log({ level, assets })
    var character_info = {}

    //where is the character - getting the character x and y
    for (var i = 0; i < level.length; i++) {
      for (var j = 0; j < level[0].length; j++) {
        if (assets[level[i][j]] !== undefined) {
          if (assets[level[i][j]].type === 'character') {
            //which number is the character
            character_info['asset_number'] = level[i][j]
            //x and y position of the character
            character_info['x'] = j * 50
            character_info['y'] = i * 50
            character_info['angle'] = assets[level[i][j]].angle
          }
        }
      }
    }
    return character_info
  }

  render() {
    // console.log('rendering blockly component', this.props, this.state)
    return (
      <Fragment>
        <div>
          <div
            ref={(e) => (this.blocklyDiv = e)}
            className="h-[70%] w-6/12 absolute"
          />
          <xml
            xmlns="https://developers.google.com/blockly/xml"
            is="blockly"
            ref={(toolbox) => {
              this.toolbox = toolbox
            }}
          >
            <Category name="General" key="generalCategory" colour="210">
              <Block type="set_color"></Block>
              <Block type="set_text_input"></Block>
              <Block type="al_numerical_input"></Block>
              <Block type="al_boolean"></Block>
              <Block type="al_keyboard_type_input"></Block>
            </Category>
            <Sep key="generalSeparator" />
            {Object.keys(this.props.blocks).map((category, idx) => {
              return (
                <Category
                  name={category}
                  key={`control${category}`}
                  colour={((idx + 1) * 40 + 10).toString()}
                >
                  {this.props.blocks[category].map((blockName) => (
                    <Block key={blockName} type={blockName}></Block>
                  ))}
                </Category>
              )
            })}
          </xml>
          <canvas ref={this.myRef} width="120" height="120">
            An alternative text describing what your canvas displays.
          </canvas>
        </div>
      </Fragment>
    )
  }
}

// const mapStateToProps = (state) => ({
//   components: state.component.components,
// })

// export default connect(mapStateToProps, null)(GameBlocklyComponent)
export default MazeBlocklyComponent
