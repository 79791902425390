import * as Blockly from 'blockly/core'
import {
  k_textInputid,
  k_imageid,
  k_labelid,
  k_buttonid,
  k_screenid,
  k_dataid,
  k_customContainer,
  k_radioButtonid,
  k_iconid,
  k_dateTimeid,
  k_switchid,
} from '../constants'

var whenDoScreen = {
  type: 'when_do_screen',
  message0: 'when %1 opens do %2',
  args0: [
    {
      type: 'input_dummy',
      name: 'current_screen_input',
    },
    {
      type: 'input_statement',
      name: 'do',
    },
  ],
  style: {
    hat: 'caps',
  },
  nextStatement: null,
  colour: 180,
  tooltip: 'Choose what should happen when the screen loads',
  helpUrl: '',
  extensions: ['get_current_screen'],
}

var getterScreenBlock = {
  type: 'al_screen_getter',
  message0: '%1 %2 get %3',
  args0: [
    {
      type: 'field_label_serializable',
      name: 'from',
      text: 'from',
    },
    {
      type: 'input_dummy',
      name: 'current_screen_input',
    },
    {
      type: 'field_dropdown',
      name: 'screen_properties',
      options: [
        ['Background color', 'backgroundColor'],
        ['Align items vertically', 'justifyContent'],
        ['Align items horizontally', 'alignItems'],
      ],
    },
  ],
  extensions: ['get_current_screen'],
  inputsInline: false,
  output: null,
  colour: 60,
  tooltip: 'Get Screen properties',
  helpUrl: '',
}

var setterScreenBlock = {
  type: 'al_screen_setter',
  message0: 'for %1 set %2 to %3',
  args0: [
    {
      type: 'input_dummy',
      name: 'current_screen_input',
    },
    {
      type: 'field_dropdown',
      name: 'screen_properties',
      options: [
        ['Background color', 'backgroundColor'],
        ['Align items vertically', 'justifyContent'],
        ['Align items horizontally', 'alignItems'],
      ],
    },
    {
      type: 'input_value',
      name: 'set screen',
      align: 'RIGHT',
    },
  ],
  extensions: ['get_current_screen'],
  inputsInline: false,
  previousStatement: null,
  nextStatement: null,
  colour: 60,
  tooltip: 'Set Screen properties',
  helpUrl: '',
}

var navigateToAnotherScreen = {
  type: 'al_move_screen',
  message0: 'move to %1',
  args0: [
    {
      type: 'input_dummy',
      name: 'screen_input',
    },
  ],
  previousStatement: null,
  nextStatement: null,
  extensions: ['get_screens'],
  colour: 225,
  tooltip: 'Use to move to a particular screen on an event',
  helpUrl: '',
}

var whenDoButtons = {
  type: 'when_do_buttons',
  message0: 'when %1 click do %2',
  args0: [
    {
      type: 'input_dummy',
      name: 'button_input',
    },
    {
      type: 'input_statement',
      name: 'do',
    },
  ],
  extensions: ['get_buttons'],
  style: {
    hat: 'cap',
  },
  nextStatement: null,
  colour: 150,
  tooltip: 'Choose the button and its actions',
  helpUrl: '',
}

var getterBlockButton = {
  type: 'al_button_getter',
  message0: 'from %1 get %2',
  args0: [
    {
      type: 'input_dummy',
      name: 'button_input',
    },
    {
      type: 'field_dropdown',
      name: 'button_properties',
      options: [
        ['Background Color', 'backgroundColor'],
        ['Text', 'textContent'],
        ['Text Color', 'color'],
        ['Boldness', 'fontWeight'],
        ['Font Style', 'fontStyle'],
        ['Height', 'height'],
        ['Width', 'width'],
        ['Visible', 'display'],
      ],
    },
  ],
  extensions: ['get_buttons'],
  inputsInline: false,
  output: null,
  colour: 150,
  tooltip: 'Get button properties',
  helpUrl: '',
}

var setterBlockButton = {
  type: 'al_button_setter',
  message0: 'for %1 set %2 to %3',
  args0: [
    {
      type: 'input_dummy',
      name: 'button_input',
    },
    {
      type: 'field_dropdown',
      name: 'button_properties',
      options: [
        ['Background Color', 'backgroundColor'],
        ['Text', 'textContent'],
        ['Text Color', 'color'],
        ['Boldness', 'fontWeight'],
        ['Font Style', 'fontStyle'],
        ['Height', 'height'],
        ['Width', 'width'],
        ['Visible', 'display'],
      ],
    },
    {
      type: 'input_value',
      name: 'set button',
      align: 'RIGHT',
    },
  ],
  inputsInline: false,
  extensions: ['get_buttons'],
  previousStatement: null,
  nextStatement: null,
  colour: 150,
  tooltip: 'Set button properties',
  helpUrl: '',
}

var labelGetterBlock = {
  type: 'al_label_getter',
  message0: 'from %1 get %2',
  args0: [
    {
      type: 'input_dummy',
      name: 'label_input',
    },
    {
      type: 'field_dropdown',
      name: 'label_properties',
      options: [
        ['Background Color', 'backgroundColor'],
        ['Text', 'textContent'],
        ['Text Color', 'color'],
        ['Boldness', 'fontWeight'],
        ['Font Style', 'fontStyle'],
        ['Height', 'height'],
        ['Width', 'width'],
        ['Visible', 'display'],
      ],
    },
  ],
  inputsInline: false,
  output: null,
  extensions: ['get_label'],
  colour: 30,
  tooltip: 'Get label properties',
  helpUrl: '',
}

var labelSetterBlock = {
  type: 'al_label_setter',
  message0: 'for %1 set %2 to %3',
  args0: [
    {
      type: 'input_dummy',
      name: 'label_input',
    },
    {
      type: 'field_dropdown',
      name: 'label_properties',
      options: [
        ['Background Color', 'backgroundColor'],
        ['Text', 'textContent'],
        ['Text Color', 'color'],
        ['Boldness', 'fontWeight'],
        ['Font Style', 'fontStyle'],
        ['Height', 'height'],
        ['Width', 'width'],
        ['Visible', 'display'],
      ],
    },
    {
      type: 'input_value',
      name: 'set label',
      align: 'RIGHT',
    },
  ],
  inputsInline: false,
  previousStatement: null,
  nextStatement: null,
  extensions: ['get_label'],
  colour: 30,
  tooltip: 'Set label properties',
  helpUrl: '',
}

var textInputChangeBlock = {
  type: 'al_text_input_change',
  message0: 'when %1 %2 do %3',
  args0: [
    {
      type: 'input_dummy',
      name: 'textInput_input',
    },
    {
      type: 'field_dropdown',
      name: 'event_type',
      options: [
        ['changes', 'change'],
        ['instant change', 'keyup'],
      ],
    },
    {
      type: 'input_statement',
      name: 'action',
    },
  ],
  nextStatement: null,
  extensions: ['get_textInput'],
  colour: 0,
  tooltip: 'When text input changes do these blocks',
  helpUrl: '',
}

var textInputGetterBlock = {
  type: 'al_text_input_getter',
  message0: 'from %1 get %2',
  args0: [
    {
      type: 'input_dummy',
      name: 'textInput_input',
    },
    {
      type: 'field_dropdown',
      name: 'text_input_properties',
      options: [
        ['Value', 'value'],
        ['Name', 'name'],
        ['Hint text', 'placeholder'],
        ['Height', 'height'],
        ['Width', 'width'],
        ['Visible', 'display'],
        ['keyboard type', 'type'],
      ],
    },
  ],
  inputsInline: false,
  output: null,
  extensions: ['get_textInput'],
  colour: 0,
  tooltip: 'Get text input properties',
  helpUrl: '',
}

var textInputSetterBlock = {
  type: 'al_text_input_setter',
  message0: 'for %1 set %2 to %3',
  args0: [
    {
      type: 'input_dummy',
      name: 'textInput_input',
    },
    {
      type: 'field_dropdown',
      name: 'text_input_properties',
      options: [
        ['Value', 'value'],
        ['Hint text', 'placeholder'],
        ['Height', 'height'],
        ['Width', 'width'],
        ['Visible', 'display'],
        ['keyboard type', 'type'],
      ],
    },
    {
      type: 'input_value',
      name: 'set text input',
      align: 'RIGHT',
    },
  ],
  inputsInline: false,
  previousStatement: null,
  nextStatement: null,
  extensions: ['get_textInput'],
  colour: 0,
  tooltip: 'Set text input properties',
  helpUrl: '',
}

var imageGetterBlock = {
  type: 'al_image_getter',
  message0: 'from %1 get %2',
  args0: [
    {
      type: 'input_dummy',
      name: 'image_input',
    },
    {
      type: 'field_dropdown',
      name: 'image_properties',
      options: [
        ['Height', 'height'],
        ['Width', 'width'],
        ['Resize', 'objectFit'],
        ['Visible', 'display'],
      ],
    },
  ],
  inputsInline: false,
  output: null,
  extensions: ['get_images'],
  colour: 270,
  tooltip: 'Get image input properties',
  helpUrl: '',
}

var imageSetterBlock = {
  type: 'al_image_setter',
  message0: 'for %1 set %2 to %3',
  args0: [
    {
      type: 'input_dummy',
      name: 'image_input',
    },
    {
      type: 'field_dropdown',
      name: 'image_properties',
      options: [
        ['Height', 'height'],
        ['Width', 'width'],
        ['Resize', 'objectFit'],
        ['Visible', 'display'],
      ],
    },
    {
      type: 'input_value',
      name: 'set image',
      align: 'RIGHT',
    },
  ],
  inputsInline: false,
  previousStatement: null,
  nextStatement: null,
  extensions: ['get_images'],
  colour: 270,
  tooltip: 'Set image input properties',
  helpUrl: '',
}

var radioButtonGetterBlock = {
  type: 'al_radio_button_getter',
  message0: 'from %1 get %2',
  args0: [
    {
      type: 'input_dummy',
      name: 'radio_button_input',
    },
    {
      type: 'field_dropdown',
      name: 'radio_button_properties',
      options: [
        ['Selected', 'checked'],
        ['Label', 'textContent'],
        ['Label position', 'flexDirection'],
        ['Visible', 'display'],
      ],
    },
  ],
  inputsInline: false,
  output: null,
  extensions: ['get_radio_buttons'],
  colour: 300,
  tooltip: 'Get radio button input properties',
  helpUrl: '',
}

var radioButtonSetterBlock = {
  type: 'al_radio_button_setter',
  message0: 'for %1 set %2 to %3',
  args0: [
    {
      type: 'input_dummy',
      name: 'radio_button_input',
    },
    {
      type: 'field_dropdown',
      name: 'radio_button_properties',
      options: [
        ['Selected', 'checked'],
        ['Label', 'textContent'],
        ['Visible', 'display'],
      ],
    },
    {
      type: 'input_value',
      name: 'set radio button',
      align: 'RIGHT',
    },
  ],
  inputsInline: false,
  previousStatement: null,
  nextStatement: null,
  extensions: ['get_radio_buttons'],
  colour: 300,
  tooltip: 'Set radio button input properties',
  helpUrl: '',
}

var radioButtongetSelectedGroupValue = {
  type: 'al_radio_button_get_selected',
  message0: "get selected value of %1's group",
  args0: [
    {
      type: 'input_dummy',
      name: 'radio_button_input',
    },
  ],
  inputsInline: true,
  output: null,
  extensions: ['get_radio_buttons_groups'],
  colour: 300,
  tooltip: "Get selected value of this radio button's group",
  helpUrl: '',
}

var radioButtonSetGroupValue = {
  type: 'al_radio_button_group_set',
  message0: 'set group value for %1 to %2',
  args0: [
    {
      type: 'input_dummy',
      name: 'radio_button_input',
    },
    {
      type: 'input_value',
      name: 'set group value',
      align: 'RIGHT',
    },
  ],
  inputsInline: false,
  previousStatement: null,
  nextStatement: null,
  extensions: ['get_radio_buttons_groups'],
  colour: 300,
  tooltip: 'Set group value for radio button',
  helpUrl: '',
}

var iconEvent = {
  type: 'al_icon_event',
  message0: 'when %1 %2 do %3',
  args0: [
    {
      type: 'input_dummy',
      name: 'icon_input',
    },
    {
      type: 'field_dropdown',
      name: 'event_type',
      options: [['clicked', 'click']],
    },
    {
      type: 'input_statement',
      name: 'action',
    },
  ],
  nextStatement: null,
  extensions: ['get_icon'],
  colour: 300,
  tooltip: 'When icon clicked do these blocks',
  helpUrl: '',
}

// var iconGetterBlock = {
//   type: "al_icon_getter",
//   message0: "from %1 get %2",
//   args0: [
//     {
//       type: "input_dummy",
//       name: "icon_input",
//     },
//     {
//       type: "field_dropdown",
//       name: "icon_properties",
//       options: [["helper text", "title"]],
//     },
//   ],
//   inputsInline: false,
//   output: null,
//   extensions: ["get_icon"],
//   colour: 300,
//   tooltip: "Get icon properties",
//   helpUrl: "",
// };

var datetimeGetterBlock = {
  type: 'al_datetime_input_getter',
  message0: 'from %1 get %2',
  args0: [
    {
      type: 'input_dummy',
      name: 'datetime_input',
    },
    {
      type: 'field_dropdown',
      name: 'datetime_properties',
      options: [
        ['mode', 'type'],
        ['value', 'value'],
      ],
    },
  ],
  inputsInline: false,
  output: null,
  extensions: ['get_datetime'],
  colour: 120,
  tooltip: 'Get date time properties',
  helpUrl: '',
}

var datetimeSetterBlock = {
  type: 'al_datetime_input_setter',
  message0: 'from %1 set %2 to %3',
  args0: [
    {
      type: 'input_dummy',
      name: 'datetime_input',
    },
    {
      type: 'field_dropdown',
      name: 'datetime_properties',
      options: [['value', 'value']],
    },
    {
      type: 'input_value',
      name: 'set value',
    },
  ],
  inputsInline: false,
  previousStatement: null,
  nextStatement: null,
  extensions: ['get_datetime'],
  colour: 120,
  tooltip: 'Set date time properties',
  helpUrl: '',
}

var datetimeEventBlock = {
  type: 'al_datetime_event',
  message0: 'when %1 is %2 do %3',
  args0: [
    {
      type: 'input_dummy',
      name: 'datetime_input',
    },
    {
      type: 'field_dropdown',
      name: 'event_type',
      options: [['changed', 'change']],
    },
    {
      type: 'input_statement',
      name: 'action',
    },
  ],
  nextStatement: null,
  extensions: ['get_datetime'],
  colour: 120,
  tooltip: 'When date time input changes do these blocks',
  helpUrl: '',
}

var switchGetterBlock = {
  type: 'al_switch_input_getter',
  message0: 'from %1 get %2',
  args0: [
    {
      type: 'input_dummy',
      name: 'switch_input',
    },
    {
      type: 'field_dropdown',
      name: 'switch_properties',
      options: [['selected', 'checked']],
    },
  ],
  inputsInline: false,
  output: null,
  extensions: ['get_switch'],
  colour: 240,
  tooltip: 'Get switch properties',
  helpUrl: '',
}

var switchSetterBlock = {
  type: 'al_switch_input_setter',
  message0: 'from %1 set %2 to %3',
  args0: [
    {
      type: 'input_dummy',
      name: 'switch_input',
    },
    {
      type: 'field_dropdown',
      name: 'switch_properties',
      options: [['selected', 'checked']],
    },
    {
      type: 'input_value',
      name: 'set value',
    },
  ],
  inputsInline: false,
  previousStatement: null,
  nextStatement: null,
  extensions: ['get_switch'],
  colour: 240,
  tooltip: 'Set switch properties',
  helpUrl: '',
}

var switchEventBlock = {
  type: 'al_switch_event',
  message0: 'when %1 is %2 do %3',
  args0: [
    {
      type: 'input_dummy',
      name: 'switch_input',
    },
    {
      type: 'field_dropdown',
      name: 'event_type',
      options: [
        ['changed', 'change'],
        ['clicked', 'click'],
      ],
    },
    {
      type: 'input_statement',
      name: 'action',
    },
  ],
  nextStatement: null,
  extensions: ['get_switch'],
  colour: 240,
  tooltip: 'When switch changes do these blocks',
  helpUrl: '',
}

var setColor = {
  type: 'set_color',
  message0: '%1',
  args0: [
    {
      type: 'field_colour',
      name: 'COLOR',
      colour: '#ff0000',
    },
  ],
  output: 'Colour',
  colour: 210,
  tooltip: '',
  helpUrl: '',
}

var setTextInput = {
  type: 'set_text_input',
  message0: '" %1 "',
  args0: [
    {
      type: 'field_input',
      name: 'NAME',
      text: 'type here',
    },
  ],
  output: 'String',
  colour: 210,
  tooltip: '',
  helpUrl: '',
}

var setNumberInput = {
  type: 'al_numerical_input',
  message0: '%1',
  args0: [
    {
      type: 'field_number',
      name: 'number_input',
      value: 0,
    },
  ],
  output: 'Number',
  colour: 210,
  tooltip: '',
  helpUrl: '',
}

var setBooleanInput = {
  type: 'al_boolean',
  message0: '%1',
  args0: [
    {
      type: 'field_dropdown',
      name: 'boolean',
      options: [
        ['true', 'true'],
        ['false', 'false'],
      ],
    },
  ],
  output: 'Boolean',
  colour: 210,
  tooltip: '',
  helpUrl: '',
}

var setKeyboardType = {
  type: 'al_keyboard_type_input',
  message0: '%1 %2',
  args0: [
    {
      type: 'field_label_serializable',
      name: 'keyboard_type',
      text: 'keyboard type',
    },
    {
      type: 'field_dropdown',
      name: 'keyboard_type_options',
      options: [
        ['default', 'text'],
        ['email address', 'email'],
        ['numerical', 'number'],
        ['*masked*', 'password'],
      ],
    },
  ],
  output: 'String',
  colour: 0,
  tooltip: '',
  helpUrl: '',
}

var setScreenJustification = {
  type: 'al_screen_justification',
  message0: 'justification %1',
  args0: [
    {
      type: 'field_dropdown',
      name: 'justification_options',
      options: [
        ['top', 'flex-start'],
        ['bottom', 'flex-end'],
        ['center', 'center'],
      ],
    },
  ],
  output: 'String',
  colour: 60,
  tooltip: 'Used to set justification',
  helpUrl: '',
}

var setScreenAlignment = {
  type: 'al_screen_alignment',
  message0: 'alignment %1',
  args0: [
    {
      type: 'field_dropdown',
      name: 'alignment_options',
      options: [
        ['left', 'flex-start'],
        ['right', 'flex-end'],
        ['center', 'center'],
      ],
    },
  ],
  output: 'String',
  colour: 60,
  tooltip: 'Used to set alignment',
  helpUrl: '',
}

var setImageSize = {
  type: 'al_image_size',
  message0: 'resize %1',
  args0: [
    {
      type: 'field_dropdown',
      name: 'image_resize',
      options: [
        ['cover', 'cover'],
        ['contain', 'contain'],
        ['fill', 'fill'],
        ['none', 'none'],
      ],
    },
  ],
  output: 'String',
  colour: 270,
  tooltip: 'Used to set image resize',
  helpUrl: '',
}

var insertTableRow = {
  type: 'al_insert_table_row',
  message0:
    'in table %1 set value for column %2 for row number %3 get value from %4',
  args0: [
    {
      type: 'input_dummy',
      name: 'table_input',
    },
    {
      type: 'input_dummy',
      name: 'column_input',
    },
    {
      type: 'input_value',
      name: 'row_number',
    },
    {
      type: 'input_value',
      name: 'get_value_from',
    },
  ],
  inputsInline: false,
  previousStatement: null,
  nextStatement: null,
  colour: 90,
  tooltip: 'Inserts value in the given column in a table',
  helpUrl: '',
  extensions: ['get_tables', 'get_column'],
  mutator: 'insert_value_table_mutator_mixin',
}

var insertTableRowEnd = {
  type: 'al_insert_table_row_end',
  message0: 'Add a row in the end of the table %1 %2',
  args0: [
    {
      // for the purpose of breaking line
      type: 'input_dummy',
    },
    {
      type: 'input_dummy',
      name: 'table_input',
      align: 'RIGHT',
    },
  ],
  inputsInline: false,
  previousStatement: null,
  nextStatement: null,
  colour: 90,
  tooltip: 'Inserts new row at the end of the table',
  helpUrl: '',
  extensions: ['get_tables'],
  mutator: 'update_column_value_fields',
}

var updateTableData = {
  type: 'al_update_table_data',
  message0:
    'Update in table %1 where column %2 is %3 set value for column %4 to %5',
  args0: [
    {
      type: 'input_dummy',
      name: 'table_input',
    },
    {
      type: 'input_dummy',
      name: 'column_input',
    },
    {
      type: 'input_value',
      name: 'get_value',
    },
    {
      type: 'input_dummy',
      name: 'column_input1',
    },
    {
      type: 'input_value',
      name: 'set_value',
    },
  ],
  inputsInline: false,
  previousStatement: null,
  nextStatement: null,
  fieldRow: false,
  colour: 120,
  tooltip: 'Update value in a table',
  helpUrl: '',
  extensions: ['get_tables', 'get_column', 'get_column1'],
  mutator: 'update_table_mutator_mixin',
}

var updateTableAllData = {
  type: 'al_update_table_all_data',
  message0:
    'Update all in table %1 where column %2 is %3 set value for column %4 to %5',
  args0: [
    {
      type: 'input_dummy',
      name: 'table_input',
    },
    {
      type: 'input_dummy',
      name: 'column_input',
    },
    {
      type: 'input_value',
      name: 'get_value',
    },
    {
      type: 'input_dummy',
      name: 'column_input1',
    },
    {
      type: 'input_value',
      name: 'set_value',
    },
  ],
  inputsInline: false,
  previousStatement: null,
  nextStatement: null,
  fieldRow: false,
  colour: 120,
  tooltip: 'Update all value in a table',
  helpUrl: '',
  extensions: ['get_tables', 'get_column', 'get_column1'],
  mutator: 'update_table_mutator_mixin',
}

var deleteOneRow = {
  type: 'al_delete_one_row',
  message0: 'Delete first row from %1 where %2 is %3',
  args0: [
    {
      type: 'input_dummy',
      name: 'table_input',
    },
    {
      type: 'input_dummy',
      name: 'column_input',
    },
    {
      type: 'input_value',
      name: 'condition_value',
      align: 'RIGHT',
    },
  ],
  inputsInline: false,
  previousStatement: null,
  nextStatement: null,
  fieldRow: false,
  colour: 130,
  tooltip: 'Delete first row from table where condition is met',
  helpUrl: '',
  extensions: ['get_tables', 'get_column'],
  mutator: 'delete_row_mutator_mixin',
}

var deleteOneRowConditional = {
  type: 'al_delete_one_row_conditional',
  message0: 'Delete first row from %1 where %2',
  args0: [
    {
      type: 'input_dummy',
      name: 'table_input',
    },
    {
      type: 'input_value',
      name: 'condition_value',
      align: 'RIGHT',
    },
  ],
  inputsInline: false,
  previousStatement: null,
  nextStatement: null,
  fieldRow: false,
  colour: 130,
  tooltip: 'Delete first row from table where multiple conditions are met',
  helpUrl: '',
  extensions: ['get_tables'],
}

var deleteMultipleRow = {
  type: 'al_delete_multiple_row',
  message0: 'Delete all rows from %1 where %2 is %3',
  args0: [
    {
      type: 'input_dummy',
      name: 'table_input',
    },
    {
      type: 'input_dummy',
      name: 'column_input',
    },
    {
      type: 'input_value',
      name: 'condition_value',
      align: 'RIGHT',
    },
  ],
  inputsInline: false,
  previousStatement: null,
  nextStatement: null,
  fieldRow: false,
  colour: 130,
  tooltip: 'Delete all rows from table where condition is met',
  helpUrl: '',
  extensions: ['get_tables', 'get_column'],
  mutator: 'delete_row_mutator_mixin',
}

var deleteMultipleRowConditional = {
  type: 'al_delete_multiple_row_conditional',
  message0: 'Delete all rows from %1 where %2',
  args0: [
    {
      type: 'input_dummy',
      name: 'table_input',
    },
    {
      type: 'input_value',
      name: 'condition_value',
      align: 'RIGHT',
    },
  ],
  inputsInline: false,
  previousStatement: null,
  nextStatement: null,
  fieldRow: false,
  colour: 130,
  tooltip: 'Delete all rows from table where multiple conditions are met',
  helpUrl: '',
  extensions: ['get_tables'],
}

var getTableRow = {
  type: 'al_get_table_row',
  message0: 'from table %1 get value from column %2 for row number %3',
  args0: [
    {
      type: 'input_dummy',
      name: 'table_input',
    },
    {
      type: 'input_dummy',
      name: 'column_input',
    },
    {
      type: 'input_value',
      name: 'row_number',
    },
  ],
  inputsInline: false,
  output: null,
  colour: 140,
  tooltip: 'Get value from the given column in a table',
  helpUrl: '',
  extensions: ['get_tables', 'get_column'],
  mutator: 'insert_value_table_mutator_mixin',
}

var getTableRowConditional = {
  type: 'al_get_table_row_conditional',
  message0: 'from table %1 get first row where column %2 is %3',
  args0: [
    {
      type: 'input_dummy',
      name: 'table_input',
    },
    {
      type: 'input_dummy',
      name: 'column_input',
    },
    {
      type: 'input_value',
      name: 'where_value',
    },
  ],
  inputsInline: false,
  output: null,
  colour: 140,
  tooltip: 'Get the first row from table where condition is true',
  helpUrl: '',
  extensions: ['get_tables', 'get_column'],
  mutator: 'insert_value_table_mutator_mixin',
}

var getTableRowMultipleConditional = {
  type: 'al_get_table_row_multiple_conditional',
  message0: 'from table %1 get first row where %2',
  args0: [
    {
      type: 'input_dummy',
      name: 'table_input',
    },
    {
      type: 'input_value',
      name: 'where_value',
      align: 'RIGHT',
    },
  ],
  inputsInline: false,
  output: null,
  colour: 140,
  tooltip:
    'Returns the first row from table where multiple conditions are true',
  helpUrl: '',
  extensions: ['get_tables'],
}

var getTableRowMultipleConditionalMale = {
  type: 'al_get_table_row_multiple_conditional_male',
  message0: 'from table %1 get row where %2',
  args0: [
    {
      type: 'input_dummy',
      name: 'table_input',
    },
    {
      type: 'input_value',
      name: 'where_value',
      align: 'RIGHT',
    },
  ],
  inputsInline: false,
  previousStatement: null,
  nextStatement: null,
  colour: 140,
  tooltip: 'Get the first row from table where multiple conditions are true',
  helpUrl: '',
  extensions: ['get_tables'],
}

var getTableRows = {
  type: 'al_get_table_rows',
  message0: 'from table %1 get all rows',
  args0: [
    {
      type: 'input_dummy',
      name: 'table_input',
    },
  ],
  inputsInline: false,
  output: null,
  colour: 140,
  tooltip: 'Get all rows from a table',
  helpUrl: '',
  extensions: ['get_tables'],
}

var getTableRowsConditional = {
  type: 'al_get_table_rows_conditional',
  message0: 'from table %1 get all rows where %2 is %3',
  args0: [
    {
      type: 'input_dummy',
      name: 'table_input',
    },
    {
      type: 'input_dummy',
      name: 'column_input',
    },
    {
      type: 'input_value',
      name: 'where_value',
    },
  ],
  inputsInline: false,
  output: null,
  colour: 140,
  tooltip: 'Get all rows from a table where condition is true',
  helpUrl: '',
  extensions: ['get_tables', 'get_column'],
  mutator: 'insert_value_table_mutator_mixin',
}

var getTableRowsMultipleConditional = {
  type: 'al_get_table_rows_multiple_conditional',
  message0: 'from table %1 get all rows where %2',
  args0: [
    {
      type: 'input_dummy',
      name: 'table_input',
    },
    {
      type: 'input_value',
      name: 'where_value',
      align: 'RIGHT',
    },
  ],
  inputsInline: false,
  output: null,
  colour: 140,
  tooltip: 'Get all rows from a table where multiple conditions are true',
  helpUrl: '',
  extensions: ['get_tables'],
}

var whereRowColumn = {
  type: 'al_where_row_column',
  message0: 'where column name %1 and column value %2',
  args0: [
    {
      type: 'input_value',
      name: 'column_name',
    },
    {
      type: 'input_value',
      name: 'column_value',
    },
  ],
  inputsInline: false,
  previousStatement: null,
  colour: 45,
  tooltip: 'where clause for a column and row value',
  helpUrl: '',
}

var columnCondition = {
  type: 'al_column_condition',
  message0: '%1 %2 %3',
  args0: [
    {
      type: 'input_dummy',
      name: 'column_input',
    },
    {
      type: 'field_dropdown',
      name: 'comparison_operator',
      options: [
        ['is equal to', '=='],
        ['is not equal to', '!='],
        ['is greater than', '>'],
        ['is greater than or equal to', '>='],
        ['is less than', '<'],
        ['is less than or equal to', '<='],
      ],
    },
    {
      type: 'input_value',
      name: 'condition_value',
    },
  ],
  inputsInline: false,
  output: 'Boolean',
  colour: 45,
  tooltip: 'where clause for a column',
  helpUrl: '',
  extensions: ['get_column_from_parent_block'],
  mutator: 'column_condition_mutator_mixin',
}

var nextRow = {
  type: 'al_next_row',
  message0: 'next row',
  inputsInline: false,
  output: null,
  colour: 45,
  tooltip: 'where clause for a column and row value',
  helpUrl: '',
}

var setupBlock = {
  type: 'al_game_setup',
  message0: 'setup %1',
  args0: [
    {
      type: 'input_statement',
      name: 'do',
    },
  ],
  style: {
    hat: 'caps',
  },
  colour: 10,
  tooltip: 'Choose what should happen when the canvas sets up',
  helpUrl: '',
}

var drawBlock = {
  type: 'al_game_draw',
  message0: 'draw %1',
  args0: [
    {
      type: 'input_statement',
      name: 'do',
    },
  ],
  style: {
    hat: 'caps',
  },
  colour: 10,
  tooltip: 'Choose what should happens regularly',
  helpUrl: '',
}

var fillShape = {
  type: 'al_game_fill_shape',
  message0: 'Fill %1',
  args0: [
    {
      type: 'input_value',
      name: 'set_color',
      align: 'RIGHT',
    },
  ],
  colour: 50,
  tooltip: 'Fill next shapes with colour',
  previousStatement: null,
  nextStatement: null,
}

var createRectangle = {
  type: 'al_game_create_rect',
  message0: 'create a rectangle at x %1 y %2 with width %3 & height %4',
  args0: [
    {
      type: 'input_value',
      name: 'x_input',
      align: 'RIGHT',
    },
    {
      type: 'input_value',
      name: 'y_input',
      align: 'RIGHT',
    },
    {
      type: 'input_value',
      name: 'width_input',
      align: 'RIGHT',
    },
    {
      type: 'input_value',
      name: 'height_input',
      align: 'RIGHT',
    },
  ],
  inputsInline: false,
  previousStatement: null,
  nextStatement: null,
  colour: 50,
  tooltip: 'create rectangle',
  helpUrl: '',
}

// var customContainerParent_getAllRows = {
//     "type": "al_custome_container_selector",
//     "message0": "In container %1, get all rows from table %2",
//     "args0": [
//         {
//             "type": "input_dummy",
//             "name": "container_input"
//         },
//         {
//             "type": "input_dummy",
//             "name": "table_input"
//         }
//     ],
//     "nextStatement": null,
//     "colour": 45,
//     "tooltip": "Choose the button and its actions",
//     "helpUrl": "",
//     "extensions": ["get_tables", "get_custom_container"],
//     "mutator": "update_column_value_fields"
// }

// var createNewComponent = {
//     "type": "al_create_new_component",
//     "message0": "create a new component of type %1",
//     "args0": [
//         {
//             "type": "field_dropdown",
//             "name": "component_type",
//             "options": [
//                 [
//                     "label",
//                     "label"
//                 ]
//             ]
//         }
//     ],
//     "inputsInline": false,
//     "previousStatement": null,
//     "colour": 45,
//     "tooltip": "Create a new component inside the container component",
//     "helpUrl": ""
// }

// Extensions

Blockly.Extensions.register('get_current_screen', function () {
  this.getInput('current_screen_input').appendField(
    new Blockly.FieldDropdown(function () {
      let options = []
      let components = JSON.parse(localStorage.getItem('applab_componentList'))
      components
        .filter(
          (comp) => comp._id === localStorage.getItem('applab_currentScreen')
        )
        .map((comp) => options.push([comp.name, comp._id]))
      return options
    }),
    'current_screen_input'
  )
})

Blockly.Extensions.register('get_screens', function () {
  this.getInput('screen_input').appendField(
    new Blockly.FieldDropdown(function () {
      let options = []
      let components = JSON.parse(localStorage.getItem('applab_componentList'))
      components
        .filter((comp) => comp.c_type === k_screenid)
        .map((comp) => options.push([comp.name, comp._id]))
      return options
    }),
    'screen_input'
  )
})

Blockly.Extensions.register('get_buttons', function () {
  this.getInput('button_input').appendField(
    new Blockly.FieldDropdown(function () {
      let options = []
      let components = JSON.parse(localStorage.getItem('applab_componentList'))
      components
        .filter((comp) => comp.c_type === k_buttonid)
        .map((comp) => options.push([comp.name, comp._id]))
      return options
    }),
    'button_input'
  )
})

Blockly.Extensions.register('get_label', function () {
  this.getInput('label_input').appendField(
    new Blockly.FieldDropdown(function () {
      let options = []
      let components = JSON.parse(localStorage.getItem('applab_componentList'))
      components
        .filter((comp) => comp.c_type === k_labelid)
        .map((comp) => options.push([comp.name, comp._id]))
      return options
    }),
    'label_input'
  )
})

Blockly.Extensions.register('get_textInput', function () {
  this.getInput('textInput_input').appendField(
    new Blockly.FieldDropdown(function () {
      let options = []
      let components = JSON.parse(localStorage.getItem('applab_componentList'))
      components
        .filter((comp) => comp.c_type === k_textInputid)
        .map((comp) => options.push([comp.name, comp._id]))
      return options
    }),
    'textInput_input'
  )
})

Blockly.Extensions.register('get_images', function () {
  this.getInput('image_input').appendField(
    new Blockly.FieldDropdown(function () {
      let options = []
      let components = JSON.parse(localStorage.getItem('applab_componentList'))
      components
        .filter((comp) => comp.c_type === k_imageid)
        .map((comp) => options.push([comp.name, comp._id]))
      return options
    }),
    'image_input'
  )
})

Blockly.Extensions.register('get_radio_buttons', function () {
  this.getInput('radio_button_input').appendField(
    new Blockly.FieldDropdown(function () {
      let options = []
      let components = JSON.parse(localStorage.getItem('applab_componentList'))
      components
        .filter((comp) => comp.c_type === k_radioButtonid)
        .map((comp) => options.push([comp.name, comp._id]))
      return options
    }),
    'radio_button_input'
  )
})

Blockly.Extensions.register('get_radio_buttons_groups', function () {
  this.getInput('radio_button_input').appendField(
    new Blockly.FieldDropdown(function () {
      let options = []
      let components = JSON.parse(localStorage.getItem('applab_componentList'))
      components
        .filter((comp) => comp.c_type === k_radioButtonid)
        .map((comp) => options.push([comp.name, comp.properties.group.value]))
      return options
    }),
    'radio_button_input'
  )
})

Blockly.Extensions.register('get_icon', function () {
  this.getInput('icon_input').appendField(
    new Blockly.FieldDropdown(function () {
      let options = []
      let components = JSON.parse(localStorage.getItem('applab_componentList'))
      components
        .filter((comp) => comp.c_type === k_iconid)
        .map((comp) => options.push([comp.name, comp._id]))
      return options
    }),
    'icon_input'
  )
})

Blockly.Extensions.register('get_datetime', function () {
  this.getInput('datetime_input').appendField(
    new Blockly.FieldDropdown(function () {
      let options = []
      let components = JSON.parse(localStorage.getItem('applab_componentList'))
      components
        .filter((comp) => comp.c_type === k_dateTimeid)
        .map((comp) => options.push([comp.name, comp._id]))
      return options
    }),
    'datetime_input'
  )
})

Blockly.Extensions.register('get_switch', function () {
  this.getInput('switch_input').appendField(
    new Blockly.FieldDropdown(function () {
      let options = []
      let components = JSON.parse(localStorage.getItem('applab_componentList'))
      components
        .filter((comp) => comp.c_type === k_switchid)
        .map((comp) => options.push([comp.name, comp._id]))
      return options
    }),
    'switch_input'
  )
})

Blockly.Extensions.register('get_custom_container', function () {
  this.getInput('container_input').appendField(
    new Blockly.FieldDropdown(function () {
      let options = []
      let components = JSON.parse(localStorage.getItem('applab_componentList'))
      components
        .filter((comp) => comp.c_type === k_customContainer)
        .map((comp) => options.push([comp.name, comp._id]))
      return options
    }),
    'custom_container_field'
  )
})

Blockly.Extensions.register('get_data_components', function () {
  this.getInput('dataC_input').appendField(
    new Blockly.FieldDropdown(function () {
      let options = []
      let components = JSON.parse(localStorage.getItem('applab_componentList'))
      components
        .filter((comp) => comp.c_type === k_dataid)
        .map((comp) => options.push([comp.name, comp._id]))
      return options
    }),
    'dataC_input'
  )
})

Blockly.Extensions.register('get_tables', function () {
  this.getInput('table_input').appendField(
    new Blockly.FieldDropdown(function () {
      let options = []
      let tables = JSON.parse(localStorage.getItem('applab_myTables'))
      tables.map((t) => options.push([t.name, t.id]))
      return options
    }),
    'table_input_field'
  )
})

Blockly.Extensions.register('get_column', function () {
  var selectedTable = this.getFieldValue('table_input_field')
  this.getInput('column_input').appendField(
    new Blockly.FieldDropdown(function () {
      let options = []
      let table = JSON.parse(localStorage.getItem('applab_myTables')).find(
        (table) => table.id === selectedTable
      )
      Object.keys(table['columnData'])
        .filter((cId) => table['columnOrder'].includes(cId))
        .map((cId) => options.push([table['columnData'][cId]['name'], cId]))
      return options
    }),
    'column_input_field'
  )
})

Blockly.Extensions.register('get_column_from_parent_block', function () {
  var selectedTable
  if (this.getSurroundParent()) {
    if (this.getSurroundParent().getFieldValue('table_input_field')) {
      // console.log('parent block is a data block. table id is ', this.getSurroundParent().getFieldValue("table_input_field"))
      selectedTable =
        this.getSurroundParent().getFieldValue('table_input_field')
    } else if (this.getSurroundParent().getSurroundParent()) {
      if (
        this.getSurroundParent()
          .getSurroundParent()
          .getFieldValue('table_input_field')
      ) {
        // console.log('parent block is a logic block. table id is ', this.getSurroundParent().getSurroundParent().getFieldValue("table_input_field"))
        selectedTable = this.getSurroundParent()
          .getSurroundParent()
          .getFieldValue('table_input_field')
      }
    }
  }
  this.getInput('column_input').appendField(
    new Blockly.FieldDropdown(function () {
      let options = []
      if (selectedTable) {
        let table = JSON.parse(localStorage.getItem('applab_myTables')).find(
          (table) => table.id === selectedTable
        )
        Object.keys(table['columnData'])
          .filter((cId) => table['columnOrder'].includes(cId))
          .map((cId) => options.push([table['columnData'][cId]['name'], cId]))
      } else {
        options.push(['please select a table', 'invalid'])
      }
      return options
    }),
    'column_input_field'
  )
})

Blockly.Extensions.register('get_column1', function () {
  var selectedTable = this.getFieldValue('table_input_field')
  var selectedColumn = this.getFieldValue('column_input_field')
  this.getInput('column_input1').appendField(
    new Blockly.FieldDropdown(function () {
      let options = []
      let table = JSON.parse(localStorage.getItem('applab_myTables')).find(
        (table) => table.id === selectedTable
      )
      Object.keys(table['columnData'])
        .filter(
          (cId) => table['columnOrder'].includes(cId) && cId !== selectedColumn
        )
        .map((cId) => options.push([table['columnData'][cId]['name'], cId]))
      return options
    }),
    'column_input1_field'
  )
})

Blockly.Extensions.registerMixin('insert_value_table_mutator_mixin', {
  mutationToDom: function () {
    const container = Blockly.utils.xml.createElement('mutation')
    container.setAttribute(
      'update_value_column_tableId',
      this.getFieldValue('table_input_field')
    )
    container.setAttribute(
      'update_value_column_columnId',
      this.getFieldValue('column_input_field')
    )
    return container
  },
  domToMutation: function (xmlElement) {
    // set field value of selected table upon serializing and call getOptions on column input fields to generate columns from corresponding table
    // update table drop down
    this.setFieldValue(
      xmlElement.getAttribute('update_value_column_tableId'),
      'table_input_field'
    )
    // update column drop down
    this.getInput('column_input').removeField('column_input_field')
    this.getInput('column_input').appendField(
      new Blockly.FieldDropdown(function () {
        let options = []
        let table = JSON.parse(localStorage.getItem('applab_myTables')).find(
          (table) =>
            table.id === xmlElement.getAttribute('update_value_column_tableId')
        )
        Object.keys(table['columnData'])
          .filter((cId) => table['columnOrder'].includes(cId))
          .map((cId) => options.push([table['columnData'][cId]['name'], cId]))
        return options
      }),
      'column_input_field'
    )
    // set value for column drop down
    this.setFieldValue(
      xmlElement.getAttribute('update_value_column_columnId'),
      'column_input_field'
    )
  },
})

Blockly.Extensions.registerMixin('update_column_value_fields', {
  mutationToDom: function () {
    const container = Blockly.utils.xml.createElement('mutation')
    container.setAttribute(
      'add_new_row_end_tableId',
      this.getFieldValue('table_input_field')
    )
    return container
  },
  domToMutation: function (xmlElement) {
    this.setFieldValue(
      xmlElement.getAttribute('add_new_row_end_tableId'),
      'table_input_field'
    )
    // this.removeInput('column_input')
    let table = JSON.parse(localStorage.getItem('applab_myTables')).find(
      (t) => t.id === xmlElement.getAttribute('add_new_row_end_tableId')
    )
    table['columnOrder'].map((cId) => {
      let columnname = table['columnData'][cId].name
      this.appendValueInput('column_input' + cId)
        .setAlign(Blockly.ALIGN_RIGHT)
        .appendField(columnname.toString() + ' value as')
      return null
    })
  },
})

Blockly.Extensions.registerMixin('update_table_mutator_mixin', {
  mutationToDom: function () {
    // store table id in mutator when serializing
    const container = Blockly.utils.xml.createElement('mutation')
    container.setAttribute('tableId', this.getFieldValue('table_input_field'))
    container.setAttribute('columnId', this.getFieldValue('column_input_field'))
    container.setAttribute(
      'column1Id',
      this.getFieldValue('column_input1_field')
    )
    return container
  },
  domToMutation: function (xmlElement) {
    // set field value of selected table upon serializing and call getOptions on column input fields to generate columns from corresponding table
    // update table drop down
    this.setFieldValue(xmlElement.getAttribute('tableId'), 'table_input_field')
    // update column drop down
    this.getInput('column_input').removeField('column_input_field')
    this.getInput('column_input').appendField(
      new Blockly.FieldDropdown(function () {
        let options = []
        let table = JSON.parse(localStorage.getItem('applab_myTables')).find(
          (table) => table.id === xmlElement.getAttribute('tableId')
        )
        Object.keys(table['columnData'])
          .filter((cId) => table['columnOrder'].includes(cId))
          .map((cId) => options.push([table['columnData'][cId]['name'], cId]))
        return options
      }),
      'column_input_field'
    )
    // set value for column drop down
    this.setFieldValue(
      xmlElement.getAttribute('columnId'),
      'column_input_field'
    )
    // update column1 drop down
    this.getInput('column_input1').removeField('column_input1_field')
    this.getInput('column_input1').appendField(
      new Blockly.FieldDropdown(function () {
        let options = []
        let table = JSON.parse(localStorage.getItem('applab_myTables')).find(
          (table) => table.id === xmlElement.getAttribute('tableId')
        )
        Object.keys(table['columnData'])
          .filter(
            (cId) =>
              table['columnOrder'].includes(cId) &&
              cId !== xmlElement.getAttribute('columnId')
          )
          .map((cId) => options.push([table['columnData'][cId]['name'], cId]))
        return options
      }),
      'column_input1_field'
    )
    // set value for column1 drop down
    this.setFieldValue(
      xmlElement.getAttribute('column1Id'),
      'column_input1_field'
    )
  },
})

Blockly.Extensions.registerMixin('column_condition_mutator_mixin', {
  mutationToDom: function () {
    const container = Blockly.utils.xml.createElement('mutation')
    if (this.getSurroundParent()) {
      if (this.getSurroundParent().getFieldValue('table_input_field')) {
        // console.log('parent block is a data block. table id is ', this.getSurroundParent().getFieldValue("table_input_field"))
        container.setAttribute(
          'condition_column_parent_tableId',
          this.getSurroundParent().getFieldValue('table_input_field')
        )
      } else if (this.getSurroundParent().getSurroundParent()) {
        if (
          this.getSurroundParent()
            .getSurroundParent()
            .getFieldValue('table_input_field')
        ) {
          // console.log('parent block is a logic block. table id is ', this.getSurroundParent().getSurroundParent().getFieldValue("table_input_field"))
          container.setAttribute(
            'condition_column_parent_tableId',
            this.getSurroundParent()
              .getSurroundParent()
              .getFieldValue('table_input_field')
          )
        }
      }
      // console.log(this.getFieldValue('column_input_field'));
      // container.setAttribute('condition_column_parent_tableId', this.getSurroundParent().getFieldValue('table_input_field'));
      container.setAttribute(
        'condition_column_columnId',
        this.getFieldValue('column_input_field')
      )
    }
    return container
  },
  domToMutation: function (xmlElement) {
    // set field value of selected table upon serializing and call getOptions on column input fields to generate columns from corresponding table
    // update table drop down
    let tableId = xmlElement.getAttribute('condition_column_parent_tableId')
    // update column drop down
    this.getInput('column_input').removeField('column_input_field')
    if (tableId) {
      this.getInput('column_input').appendField(
        new Blockly.FieldDropdown(function () {
          let options = []
          let table = JSON.parse(localStorage.getItem('applab_myTables')).find(
            (table) => table.id === tableId
          )
          Object.keys(table['columnData'])
            .filter((cId) => table['columnOrder'].includes(cId))
            .map((cId) => options.push([table['columnData'][cId]['name'], cId]))
          return options
        }),
        'column_input_field'
      )
    } else {
      this.getInput('column_input').appendField(
        new Blockly.FieldDropdown(function () {
          return [['please select a table', 'invalid']]
        }),
        'column_input_field'
      )
    }
    // set value for column drop down
    this.setFieldValue(
      xmlElement.getAttribute('condition_column_columnId'),
      'column_input_field'
    )
  },
})

Blockly.Extensions.registerMixin('delete_row_mutator_mixin', {
  mutationToDom: function () {
    const container = Blockly.utils.xml.createElement('mutation')
    container.setAttribute(
      'delete_row_tableId',
      this.getFieldValue('table_input_field')
    )
    container.setAttribute(
      'delete_row_columnId',
      this.getFieldValue('column_input_field')
    )
    return container
  },
  domToMutation: function (xmlElement) {
    this.setFieldValue(
      xmlElement.getAttribute('delete_row_tableId'),
      'table_input_field'
    )
    this.getInput('column_input').removeField('column_input_field')
    this.getInput('column_input').appendField(
      new Blockly.FieldDropdown(function () {
        let options = []
        let table = JSON.parse(localStorage.getItem('applab_myTables')).find(
          (table) => table.id === xmlElement.getAttribute('delete_row_tableId')
        )
        Object.keys(table['columnData'])
          .filter((cId) => table['columnOrder'].includes(cId))
          .map((cId) => options.push([table['columnData'][cId]['name'], cId]))
        return options
      }),
      'column_input_field'
    )
    this.setFieldValue(
      xmlElement.getAttribute('delete_row_columnId'),
      'column_input_field'
    )
  },
})

// Init blocks

Blockly.Blocks['when_do_screen'] = {
  init: function () {
    this.jsonInit(whenDoScreen)
  },
}

Blockly.Blocks['al_screen_getter'] = {
  init: function () {
    this.jsonInit(getterScreenBlock)
  },
}

Blockly.Blocks['al_screen_setter'] = {
  init: function () {
    this.jsonInit(setterScreenBlock)
  },
}

Blockly.Blocks['al_move_screen'] = {
  init: function () {
    this.jsonInit(navigateToAnotherScreen)
  },
}

Blockly.Blocks['when_do_buttons'] = {
  init: function () {
    this.jsonInit(whenDoButtons)
  },
}

Blockly.Blocks['al_button_getter'] = {
  init: function () {
    this.jsonInit(getterBlockButton)
  },
}

Blockly.Blocks['al_button_setter'] = {
  init: function () {
    this.jsonInit(setterBlockButton)
  },
}

Blockly.Blocks['al_label_getter'] = {
  init: function () {
    this.jsonInit(labelGetterBlock)
  },
}

Blockly.Blocks['al_label_setter'] = {
  init: function () {
    this.jsonInit(labelSetterBlock)
  },
}

Blockly.Blocks['al_text_input_change'] = {
  init: function () {
    this.jsonInit(textInputChangeBlock)
  },
}

Blockly.Blocks['al_text_input_getter'] = {
  init: function () {
    this.jsonInit(textInputGetterBlock)
  },
}

Blockly.Blocks['al_text_input_setter'] = {
  init: function () {
    this.jsonInit(textInputSetterBlock)
  },
}

Blockly.Blocks['al_image_getter'] = {
  init: function () {
    this.jsonInit(imageGetterBlock)
  },
}

Blockly.Blocks['al_image_setter'] = {
  init: function () {
    this.jsonInit(imageSetterBlock)
  },
}

Blockly.Blocks['al_radio_button_getter'] = {
  init: function () {
    this.jsonInit(radioButtonGetterBlock)
  },
}

Blockly.Blocks['al_radio_button_setter'] = {
  init: function () {
    this.jsonInit(radioButtonSetterBlock)
  },
}

Blockly.Blocks['al_radio_button_get_selected'] = {
  init: function () {
    this.jsonInit(radioButtongetSelectedGroupValue)
  },
}

Blockly.Blocks['al_radio_button_group_set'] = {
  init: function () {
    this.jsonInit(radioButtonSetGroupValue)
  },
}

Blockly.Blocks['al_icon_event'] = {
  init: function () {
    this.jsonInit(iconEvent)
  },
}

// Blockly.Blocks["al_icon_getter"] = {
//   init: function () {
//     this.jsonInit(iconGetterBlock);
//   },
// };

Blockly.Blocks['al_datetime_input_getter'] = {
  init: function () {
    this.jsonInit(datetimeGetterBlock)
  },
}

Blockly.Blocks['al_datetime_input_setter'] = {
  init: function () {
    this.jsonInit(datetimeSetterBlock)
  },
}

Blockly.Blocks['al_datetime_event'] = {
  init: function () {
    this.jsonInit(datetimeEventBlock)
  },
}

Blockly.Blocks['al_switch_input_getter'] = {
  init: function () {
    this.jsonInit(switchGetterBlock)
  },
}

Blockly.Blocks['al_switch_input_setter'] = {
  init: function () {
    this.jsonInit(switchSetterBlock)
  },
}

Blockly.Blocks['al_switch_event'] = {
  init: function () {
    this.jsonInit(switchEventBlock)
  },
}

Blockly.Blocks['set_color'] = {
  init: function () {
    this.jsonInit(setColor)
  },
}

Blockly.Blocks['set_text_input'] = {
  init: function () {
    this.jsonInit(setTextInput)
  },
}

Blockly.Blocks['al_numerical_input'] = {
  init: function () {
    this.jsonInit(setNumberInput)
  },
}

Blockly.Blocks['al_boolean'] = {
  init: function () {
    this.jsonInit(setBooleanInput)
  },
}

Blockly.Blocks['al_keyboard_type_input'] = {
  init: function () {
    this.jsonInit(setKeyboardType)
  },
}

Blockly.Blocks['al_screen_justification'] = {
  init: function () {
    this.jsonInit(setScreenJustification)
  },
}

Blockly.Blocks['al_screen_alignment'] = {
  init: function () {
    this.jsonInit(setScreenAlignment)
  },
}

Blockly.Blocks['al_image_size'] = {
  init: function () {
    this.jsonInit(setImageSize)
  },
}

Blockly.Blocks['al_insert_table_row'] = {
  init: function () {
    this.jsonInit(insertTableRow)
  },
  onchange: function (event) {
    if (event.blockId === this.id) {
      // something happened on this block
      if (event.type === Blockly.Events.BLOCK_CHANGE) {
        // change detected
        if (event.name === 'table_input_field') {
          // change in table, update column_input and column_input1
          this.getInput('column_input').removeField('column_input_field')
          this.getInput('column_input').appendField(
            new Blockly.FieldDropdown(function () {
              let options = []
              let table = JSON.parse(
                localStorage.getItem('applab_myTables')
              ).find((table) => table.id === event.newValue)
              Object.keys(table['columnData'])
                .filter((cId) => table['columnOrder'].includes(cId))
                .map((cId) =>
                  options.push([table['columnData'][cId]['name'], cId])
                )
              return options
            }),
            'column_input_field'
          )
        }
      }
    }
  },
}

Blockly.Blocks['al_insert_table_row_end'] = {
  init: function () {
    this.jsonInit(insertTableRowEnd)
  },
  onchange: function (event) {
    if (event.blockId === this.id) {
      // something happened on this block
      if (event.type === Blockly.Events.BLOCK_CHANGE) {
        // a change event happened
        if (event.name === 'table_input_field') {
          let oldTable = JSON.parse(
            localStorage.getItem('applab_myTables')
          ).find((t) => t.id === event.oldValue)
          oldTable['columnOrder'].map((cId) =>
            this.removeInput('column_input' + cId)
          )
          let table = JSON.parse(localStorage.getItem('applab_myTables')).find(
            (t) => t.id === event.newValue
          )
          table['columnOrder'].map((cId) => {
            let columnname = table['columnData'][cId].name
            this.appendValueInput('column_input' + cId)
              .setAlign(Blockly.ALIGN_RIGHT)
              .appendField(columnname.toString() + ' value as')
            return null
          })
        }
      }
    }
  },
}

Blockly.Blocks['al_update_table_data'] = {
  init: function () {
    this.jsonInit(updateTableData)
  },
  onchange: function (event) {
    if (event.blockId === this.id) {
      // something happened on this block
      if (event.type === Blockly.Events.BLOCK_CHANGE) {
        // change detected
        if (event.name === 'table_input_field') {
          // change in table, update column_input and column_input1
          this.getInput('column_input').removeField('column_input_field')
          this.getInput('column_input').appendField(
            new Blockly.FieldDropdown(function () {
              let options = []
              let table = JSON.parse(
                localStorage.getItem('applab_myTables')
              ).find((table) => table.id === event.newValue)
              Object.keys(table['columnData'])
                .filter((cId) => table['columnOrder'].includes(cId))
                .map((cId) =>
                  options.push([table['columnData'][cId]['name'], cId])
                )
              return options
            }),
            'column_input_field'
          )
          var selectedColumn = this.getFieldValue('column_input_field')
          this.getInput('column_input1').removeField('column_input1_field')
          this.getInput('column_input1').appendField(
            new Blockly.FieldDropdown(function () {
              let options = []
              let table = JSON.parse(
                localStorage.getItem('applab_myTables')
              ).find((table) => table.id === event.newValue)
              Object.keys(table['columnData'])
                .filter((cId) => table['columnOrder'].includes(cId))
                .filter((cId) => cId !== selectedColumn)
                .map((cId) =>
                  options.push([table['columnData'][cId]['name'], cId])
                )
              return options
            }),
            'column_input1_field'
          )
        } else if (event.name === 'column_input_field') {
          // change in selected column, update column_input1
          var table_id = this.getFieldValue('table_input_field')
          this.getInput('column_input1').removeField('column_input1_field')
          this.getInput('column_input1').appendField(
            new Blockly.FieldDropdown(function () {
              let options = []
              let table = JSON.parse(
                localStorage.getItem('applab_myTables')
              ).find((table) => table.id === table_id)
              Object.keys(table['columnData'])
                .filter((cId) => table['columnOrder'].includes(cId))
                .filter((cId) => cId !== event.newValue)
                .map((cId) =>
                  options.push([table['columnData'][cId]['name'], cId])
                )
              return options
            }),
            'column_input1_field'
          )
        }
      }
    }
  },
}

Blockly.Blocks['al_update_table_all_data'] = {
  init: function () {
    this.jsonInit(updateTableAllData)
  },
  onchange: function (event) {
    if (event.blockId === this.id) {
      // something happened on this block
      if (event.type === Blockly.Events.BLOCK_CHANGE) {
        // change detected
        if (event.name === 'table_input_field') {
          // change in table, update column_input and column_input1
          this.getInput('column_input').removeField('column_input_field')
          this.getInput('column_input').appendField(
            new Blockly.FieldDropdown(function () {
              let options = []
              let table = JSON.parse(
                localStorage.getItem('applab_myTables')
              ).find((table) => table.id === event.newValue)
              Object.keys(table['columnData'])
                .filter((cId) => table['columnOrder'].includes(cId))
                .map((cId) =>
                  options.push([table['columnData'][cId]['name'], cId])
                )
              return options
            }),
            'column_input_field'
          )
          var selectedColumn = this.getFieldValue('column_input_field')
          this.getInput('column_input1').removeField('column_input1_field')
          this.getInput('column_input1').appendField(
            new Blockly.FieldDropdown(function () {
              let options = []
              let table = JSON.parse(
                localStorage.getItem('applab_myTables')
              ).find((table) => table.id === event.newValue)
              Object.keys(table['columnData'])
                .filter((cId) => table['columnOrder'].includes(cId))
                .filter((cId) => cId !== selectedColumn)
                .map((cId) =>
                  options.push([table['columnData'][cId]['name'], cId])
                )
              return options
            }),
            'column_input1_field'
          )
        } else if (event.name === 'column_input_field') {
          // change in selected column, update column_input1
          var table_id = this.getFieldValue('table_input_field')
          this.getInput('column_input1').removeField('column_input1_field')
          this.getInput('column_input1').appendField(
            new Blockly.FieldDropdown(function () {
              let options = []
              let table = JSON.parse(
                localStorage.getItem('applab_myTables')
              ).find((table) => table.id === table_id)
              Object.keys(table['columnData'])
                .filter((cId) => table['columnOrder'].includes(cId))
                .filter((cId) => cId !== event.newValue)
                .map((cId) =>
                  options.push([table['columnData'][cId]['name'], cId])
                )
              return options
            }),
            'column_input1_field'
          )
        }
      }
    }
  },
}

Blockly.Blocks['al_delete_one_row'] = {
  init: function () {
    this.jsonInit(deleteOneRow)
  },
  onchange: function (event) {
    if (event.blockId === this.id) {
      // something happened on this block
      if (event.type === Blockly.Events.BLOCK_CHANGE) {
        // a change event happened
        if (event.name === 'table_input_field') {
          // change in table, update column_input and column_input1
          this.getInput('column_input').removeField('column_input_field')
          this.getInput('column_input').appendField(
            new Blockly.FieldDropdown(function () {
              let options = []
              let table = JSON.parse(
                localStorage.getItem('applab_myTables')
              ).find((table) => table.id === event.newValue)
              Object.keys(table['columnData'])
                .filter((cId) => table['columnOrder'].includes(cId))
                .map((cId) =>
                  options.push([table['columnData'][cId]['name'], cId])
                )
              return options
            }),
            'column_input_field'
          )
        }
      }
    }
  },
}

Blockly.Blocks['al_delete_one_row_conditional'] = {
  init: function () {
    this.jsonInit(deleteOneRowConditional)
  },
}

Blockly.Blocks['al_delete_multiple_row'] = {
  init: function () {
    this.jsonInit(deleteMultipleRow)
  },
  onchange: function (event) {
    if (event.blockId === this.id) {
      // something happened on this block
      if (event.type === Blockly.Events.BLOCK_CHANGE) {
        // a change event happened
        if (event.name === 'table_input_field') {
          // change in table, update column_input and column_input1
          this.getInput('column_input').removeField('column_input_field')
          this.getInput('column_input').appendField(
            new Blockly.FieldDropdown(function () {
              let options = []
              let table = JSON.parse(
                localStorage.getItem('applab_myTables')
              ).find((table) => table.id === event.newValue)
              Object.keys(table['columnData'])
                .filter((cId) => table['columnOrder'].includes(cId))
                .map((cId) =>
                  options.push([table['columnData'][cId]['name'], cId])
                )
              return options
            }),
            'column_input_field'
          )
        }
      }
    }
  },
}

Blockly.Blocks['al_delete_multiple_row_conditional'] = {
  init: function () {
    this.jsonInit(deleteMultipleRowConditional)
  },
}

Blockly.Blocks['al_get_table_row'] = {
  init: function () {
    this.jsonInit(getTableRow)
  },
  onchange: function (event) {
    if (event.blockId === this.id) {
      // something happened on this block
      if (event.type === Blockly.Events.BLOCK_CHANGE) {
        // change detected
        if (event.name === 'table_input_field') {
          // change in table, update column_input and column_input1
          this.getInput('column_input').removeField('column_input_field')
          this.getInput('column_input').appendField(
            new Blockly.FieldDropdown(function () {
              let options = []
              let table = JSON.parse(
                localStorage.getItem('applab_myTables')
              ).find((table) => table.id === event.newValue)
              Object.keys(table['columnData'])
                .filter((cId) => table['columnOrder'].includes(cId))
                .map((cId) =>
                  options.push([table['columnData'][cId]['name'], cId])
                )
              return options
            }),
            'column_input_field'
          )
        }
      }
    }
  },
}

Blockly.Blocks['al_get_table_row_conditional'] = {
  init: function () {
    this.jsonInit(getTableRowConditional)
  },
  onchange: function (event) {
    if (event.blockId === this.id) {
      // something happened on this block
      if (event.type === Blockly.Events.BLOCK_CHANGE) {
        // change detected
        if (event.name === 'table_input_field') {
          // change in table, update column_input and column_input1
          this.getInput('column_input').removeField('column_input_field')
          this.getInput('column_input').appendField(
            new Blockly.FieldDropdown(function () {
              let options = []
              let table = JSON.parse(
                localStorage.getItem('applab_myTables')
              ).find((table) => table.id === event.newValue)
              Object.keys(table['columnData'])
                .filter((cId) => table['columnOrder'].includes(cId))
                .map((cId) =>
                  options.push([table['columnData'][cId]['name'], cId])
                )
              return options
            }),
            'column_input_field'
          )
        }
      }
    }
  },
}

Blockly.Blocks['al_get_table_row_multiple_conditional'] = {
  init: function () {
    this.jsonInit(getTableRowMultipleConditional)
  },
}

Blockly.Blocks['al_get_table_row_multiple_conditional_male'] = {
  init: function () {
    this.jsonInit(getTableRowMultipleConditionalMale)
  },
}

Blockly.Blocks['al_get_table_rows'] = {
  init: function () {
    this.jsonInit(getTableRows)
  },
}

Blockly.Blocks['al_get_table_rows_conditional'] = {
  init: function () {
    this.jsonInit(getTableRowsConditional)
  },
  onchange: function (event) {
    if (event.blockId === this.id) {
      // something happened on this block
      if (event.type === Blockly.Events.BLOCK_CHANGE) {
        // change detected
        if (event.name === 'table_input_field') {
          // change in table, update column_input and column_input1
          this.getInput('column_input').removeField('column_input_field')
          this.getInput('column_input').appendField(
            new Blockly.FieldDropdown(function () {
              let options = []
              let table = JSON.parse(
                localStorage.getItem('applab_myTables')
              ).find((table) => table.id === event.newValue)
              Object.keys(table['columnData'])
                .filter((cId) => table['columnOrder'].includes(cId))
                .map((cId) =>
                  options.push([table['columnData'][cId]['name'], cId])
                )
              return options
            }),
            'column_input_field'
          )
        }
      }
    }
  },
}

Blockly.Blocks['al_get_table_rows_multiple_conditional'] = {
  init: function () {
    this.jsonInit(getTableRowsMultipleConditional)
  },
}

Blockly.Blocks['al_where_row_column'] = {
  init: function () {
    this.jsonInit(whereRowColumn)
  },
}

Blockly.Blocks['al_column_condition'] = {
  init: function () {
    this.jsonInit(columnCondition)
  },
  onchange: function (event) {
    // check if change happened to a surroundParent with table_input_field
    let tableBlockId = undefined,
      tableId = undefined,
      parentBlockId = undefined
    if (
      this.getSurroundParent() &&
      this.getSurroundParent().getFieldValue('table_input_field')
    ) {
      tableId = this.getSurroundParent().getFieldValue('table_input_field')
      tableBlockId = this.getSurroundParent().id
    } else if (
      this.getSurroundParent() &&
      this.getSurroundParent().getSurroundParent() &&
      this.getSurroundParent()
        .getSurroundParent()
        .getFieldValue('table_input_field')
    ) {
      tableId = this.getSurroundParent()
        .getSurroundParent()
        .getFieldValue('table_input_field')
      parentBlockId = this.getSurroundParent().id
      tableBlockId = this.getSurroundParent().getSurroundParent().id
    }
    if (
      event.blockId === tableBlockId &&
      event.type === Blockly.Events.BLOCK_CHANGE
    ) {
      // change taken place to table block
      // event type is change
      if (event.name === 'table_input_field') {
        // change happened to table drop down
        this.getInput('column_input').removeField('column_input_field')
        this.getInput('column_input').appendField(
          new Blockly.FieldDropdown(function () {
            let options = []
            let table = JSON.parse(
              localStorage.getItem('applab_myTables')
            ).find((table) => table.id === event.newValue)
            Object.keys(table['columnData'])
              .filter((cId) => table['columnOrder'].includes(cId))
              .map((cId) =>
                options.push([table['columnData'][cId]['name'], cId])
              )
            return options
          }),
          'column_input_field'
        )
      }
    }
    if (
      event.blockId === parentBlockId &&
      event.type === Blockly.Events.BLOCK_MOVE
    ) {
      // move change taken place to parent block
      // console.log("parent block moved");
      this.getInput('column_input').removeField('column_input_field')
      if (
        tableId &&
        event.newParentId &&
        event.oldParentId !== event.newParentId
      ) {
        this.getInput('column_input').appendField(
          new Blockly.FieldDropdown(function () {
            let options = []
            let table = JSON.parse(
              localStorage.getItem('applab_myTables')
            ).find((table) => table.id === tableId)
            Object.keys(table['columnData'])
              .filter((cId) => table['columnOrder'].includes(cId))
              .map((cId) =>
                options.push([table['columnData'][cId]['name'], cId])
              )
            return options
          }),
          'column_input_field'
        )
      } else {
        this.getInput('column_input').appendField(
          new Blockly.FieldDropdown(function () {
            return [['please select a table', 'invalid']]
          }),
          'column_input_field'
        )
      }
    }
    if (event.blockId === this.id && event.type === Blockly.Events.BLOCK_MOVE) {
      // move change happened to this block
      this.getInput('column_input').removeField('column_input_field')
      if (
        tableId &&
        event.newParentId &&
        event.oldParentId !== event.newParentId
      ) {
        this.getInput('column_input').appendField(
          new Blockly.FieldDropdown(function () {
            let options = []
            let table = JSON.parse(
              localStorage.getItem('applab_myTables')
            ).find((table) => table.id === tableId)
            Object.keys(table['columnData'])
              .filter((cId) => table['columnOrder'].includes(cId))
              .map((cId) =>
                options.push([table['columnData'][cId]['name'], cId])
              )
            return options
          }),
          'column_input_field'
        )
      } else {
        this.getInput('column_input').appendField(
          new Blockly.FieldDropdown(function () {
            return [['please select a table', 'invalid']]
          }),
          'column_input_field'
        )
      }
    }
  },
}

Blockly.Blocks['al_next_row'] = {
  init: function () {
    this.jsonInit(nextRow)
  },
}

/**
 *
 *
 * MAZE LAB
 *
 *
 */

Blockly.Blocks['color_select'] = {
  init: function () {
    this.appendDummyInput().appendField(
      new Blockly.FieldColour('#ff0000'),
      'color'
    )
    this.setOutput(true, null)
    this.setColour(230)
    this.setTooltip('')
    this.setHelpUrl('')
  },
}

var when_run = {
  type: 'when_run',
  message0: '%1 %2 %3 %4',
  args0: [
    {
      type: 'field_label_serializable',
      name: 'when_run',
      text: 'when run',
    },
    {
      type: 'input_dummy',
    },
    {
      type: 'field_label_serializable',
      name: 'NAME',
      text: '',
    },
    {
      type: 'input_statement',
      name: 'all_maze_statements',
    },
  ],

  nextStatement: null,
  colour: 230,
  tooltip: '',
  helpUrl: '',
}

Blockly.Blocks['when_run'] = {
  init: function () {
    this.jsonInit(when_run)
    this.setStyle('loop_blocks')
  },
}

var move_forward = {
  type: 'move_forward',
  message0: '%1',
  args0: [
    {
      type: 'field_label_serializable',
      name: 'move Forward',
      text: 'move Forward',
    },
  ],
  previousStatement: null,
  nextStatement: null,
  colour: 180,
  tooltip: 'Block that helps character to move forward',
  helpUrl: '',
}

Blockly.Blocks['move_forward'] = {
  init: function () {
    this.jsonInit(move_forward)
    this.setStyle('loop_blocks')
  },
}

var turn_right = {
  type: 'turn_right',
  message0: '%1',
  args0: [
    {
      type: 'field_label_serializable',
      name: 'turn right',
      text: 'turn right',
    },
  ],
  previousStatement: null,
  nextStatement: null,
  colour: 180,
  tooltip: 'Helps your sprite turn right',
  helpUrl: '',
}

Blockly.Blocks['turn_right'] = {
  init: function () {
    this.jsonInit(turn_right)
    this.setStyle('loop_blocks')
  },
}

var turn_left = {
  type: 'turn_left',
  message0: '%1',
  args0: [
    {
      type: 'field_label_serializable',
      name: 'turn Left',
      text: 'turn Left',
    },
  ],
  previousStatement: null,
  nextStatement: null,
  colour: 180,
  tooltip: 'Helps your sprite move left',
  helpUrl: '',
}

Blockly.Blocks['turn_left'] = {
  init: function () {
    this.jsonInit(turn_left)
    this.setStyle('loop_blocks')
  },
}

var add_to_basket = {
  type: 'add_to_basket',
  message0: '%1 %2 %3 %4 %5',
  args0: [
    {
      type: 'field_label_serializable',
      name: 'add',
      text: 'add',
    },
    {
      type: 'input_dummy',
    },
    {
      type: 'input_value',
      name: 'number',
    },
    {
      type: 'input_value',
      name: 'collectibles',
    },
    {
      type: 'field_label_serializable',
      name: 'to the basket',
      text: 'to the basket',
    },
  ],
  previousStatement: null,
  nextStatement: null,
  inputsInline: true,
  colour: 90,
  tooltip: 'Add points to the basket based on apple or oranges',
  helpUrl: '',
}

Blockly.Blocks['add_to_basket'] = {
  init: function () {
    this.jsonInit(add_to_basket)
    this.setStyle('loop_blocks')
  },
}

var numerical_input_value = {
  type: 'numerical_input_value',
  message0: '%1',
  args0: [
    {
      type: 'field_number',
      name: 'numerical input value',
      value: 0,
    },
  ],
  output: null,
  colour: 230,
  tooltip: '',
  helpUrl: '',
}

Blockly.Blocks['numerical_input_value'] = {
  init: function () {
    this.jsonInit(numerical_input_value)
    this.setStyle('loop_blocks')
  },
}

var collect = {
  type: 'collect',
  message0: '%1 %2',
  args0: [
    {
      type: 'field_label_serializable',
      name: 'collect',
      text: 'collect',
    },
    {
      type: 'input_value',
      name: 'NAME',
    },
  ],
  previousStatement: null,
  nextStatement: null,
  colour: 90,
  tooltip: 'Helps you collect objects/sprites',
  helpUrl: '',
}

Blockly.Blocks['collect'] = {
  init: function () {
    this.jsonInit(collect)
    this.setStyle('loop_blocks')
  },
}

var deposit = {
  type: 'deposit',
  message0: 'deposit %1',
  args0: [
    {
      type: 'input_value',
      name: 'NAME',
    },
  ],
  previousStatement: null,
  nextStatement: null,
  colour: 230,
  tooltip: null,
  helpUrl: null,
}

Blockly.Blocks['deposit'] = {
  init: function () {
    this.jsonInit(deposit)
    this.setStyle('loop_blocks')
  },
}

var orange = {
  type: 'orange',
  message0: '%1',
  args0: [
    {
      type: 'field_label_serializable',
      name: 'orange',
      text: 'orange',
    },
  ],
  output: null,
  colour: 30,
  tooltip: '',
  helpUrl: '',
}

Blockly.Blocks['orange'] = {
  init: function () {
    this.jsonInit(orange)
    this.setStyle('loop_blocks')
  },
}

var apple = {
  type: 'apple',
  message0: '%1',
  args0: [
    {
      type: 'field_label_serializable',
      name: 'apple',
      text: 'apple',
    },
  ],
  output: null,
  colour: 0,
  tooltip: '',
  helpUrl: '',
}

Blockly.Blocks['apple'] = {
  init: function () {
    this.jsonInit(apple)
    this.setStyle('loop_blocks')
  },
}

var bone = {
  type: 'bone',
  message0: '%1',
  args0: [
    {
      type: 'field_label_serializable',
      name: 'bone',
      text: 'bone',
    },
  ],
  output: null,
  colour: 0,
  tooltip: '',
  helpUrl: '',
}

Blockly.Blocks['bone'] = {
  init: function () {
    this.jsonInit(bone)
    this.setStyle('loop_blocks')
  },
}

var pear = {
  type: 'pear',
  message0: '%1',
  args0: [
    {
      type: 'field_label_serializable',
      name: 'pear',
      text: 'pear',
    },
  ],
  output: null,
  colour: 0,
  tooltip: '',
  helpUrl: '',
}

Blockly.Blocks['pear'] = {
  init: function () {
    this.jsonInit(pear)
    this.setStyle('loop_blocks')
  },
}

var cherries = {
  type: 'cherries',
  message0: '%1',
  args0: [
    {
      type: 'field_label_serializable',
      name: 'cherries',
      text: 'cherries',
    },
  ],
  output: null,
  colour: 0,
  tooltip: '',
  helpUrl: '',
}

Blockly.Blocks['cherries'] = {
  init: function () {
    this.jsonInit(cherries)
    this.setStyle('loop_blocks')
  },
}

var kiwi = {
  type: 'kiwi',
  message0: '%1',
  args0: [
    {
      type: 'field_label_serializable',
      name: 'kiwi',
      text: 'kiwi',
    },
  ],
  output: null,
  colour: 0,
  tooltip: '',
  helpUrl: '',
}

Blockly.Blocks['kiwi'] = {
  init: function () {
    this.jsonInit(kiwi)
    this.setStyle('loop_blocks')
  },
}

var strawberry = {
  type: 'strawberry',
  message0: '%1',
  args0: [
    {
      type: 'field_label_serializable',
      name: 'strawberry',
      text: 'strawberry',
    },
  ],
  output: null,
  colour: 0,
  tooltip: '',
  helpUrl: '',
}

Blockly.Blocks['strawberry'] = {
  init: function () {
    this.jsonInit(strawberry)
    this.setStyle('loop_blocks')
  },
}

var pomegranate = {
  type: 'pomegranate',
  message0: '%1',
  args0: [
    {
      type: 'field_label_serializable',
      name: 'pomegranate',
      text: 'pomegranate',
    },
  ],
  output: null,
  colour: 0,
  tooltip: '',
  helpUrl: '',
}

Blockly.Blocks['pomegranate'] = {
  init: function () {
    this.jsonInit(pomegranate)
    this.setStyle('loop_blocks')
  },
}

var bug = {
  type: 'bug',
  message0: '%1',
  args0: [
    {
      type: 'field_label_serializable',
      name: 'bug',
      text: 'bug',
    },
  ],
  output: null,
  colour: 0,
  tooltip: '',
  helpUrl: '',
}

Blockly.Blocks['bug'] = {
  init: function () {
    this.jsonInit(bug)
    this.setStyle('loop_blocks')
  },
}

var bug2 = {
  type: 'bug2',
  message0: '%1',
  args0: [
    {
      type: 'field_label_serializable',
      name: 'bug2',
      text: 'big bug',
    },
  ],
  output: null,
  colour: 0,
  tooltip: '',
  helpUrl: '',
}

Blockly.Blocks['bug2'] = {
  init: function () {
    this.jsonInit(bug2)
    this.setStyle('loop_blocks')
  },
}

var scorpio = {
  type: 'scorpio',
  message0: '%1',
  args0: [
    {
      type: 'field_label_serializable',
      name: 'scorpio',
      text: 'scorpio',
    },
  ],
  output: null,
  colour: 0,
  tooltip: '',
  helpUrl: '',
}

Blockly.Blocks['scorpio'] = {
  init: function () {
    this.jsonInit(scorpio)
    this.setStyle('loop_blocks')
  },
}

var caterpillar = {
  type: 'caterpillar',
  message0: '%1',
  args0: [
    {
      type: 'field_label_serializable',
      name: 'caterpillar',
      text: 'caterpillar',
    },
  ],
  output: null,
  colour: 0,
  tooltip: '',
  helpUrl: '',
}

Blockly.Blocks['caterpillar'] = {
  init: function () {
    this.jsonInit(caterpillar)
    this.setStyle('loop_blocks')
  },
}

var berries = {
  type: 'berries',
  message0: '%1',
  args0: [
    {
      type: 'field_label_serializable',
      name: 'berries',
      text: 'berries',
    },
  ],
  output: null,
  colour: 0,
  tooltip: '',
  helpUrl: '',
}

Blockly.Blocks['berries'] = {
  init: function () {
    this.jsonInit(berries)
    this.setStyle('loop_blocks')
  },
}

var strawberries = {
  type: 'strawberries',
  message0: '%1',
  args0: [
    {
      type: 'field_label_serializable',
      name: 'strawberries',
      text: 'strawberries',
    },
  ],
  output: null,
  colour: 0,
  tooltip: '',
  helpUrl: '',
}

Blockly.Blocks['strawberries'] = {
  init: function () {
    this.jsonInit(strawberries)
    this.setStyle('loop_blocks')
  },
}

var corns = {
  type: 'corns',
  message0: '%1',
  args0: [
    {
      type: 'field_label_serializable',
      name: 'corns',
      text: 'corns',
    },
  ],
  output: null,
  colour: 0,
  tooltip: '',
  helpUrl: '',
}

Blockly.Blocks['corns'] = {
  init: function () {
    this.jsonInit(corns)
    this.setStyle('loop_blocks')
  },
}

var carrot = {
  type: 'carrot',
  message0: '%1',
  args0: [
    {
      type: 'field_label_serializable',
      name: 'carrot',
      text: 'carrot',
    },
  ],
  output: null,
  colour: 0,
  tooltip: '',
  helpUrl: '',
}

Blockly.Blocks['carrot'] = {
  init: function () {
    this.jsonInit(carrot)
    this.setStyle('loop_blocks')
  },
}

var pink_flower = {
  type: 'pink_flower',
  message0: '%1',
  args0: [
    {
      type: 'field_label_serializable',
      name: 'pink flower',
      text: 'pink flower',
    },
  ],
  output: null,
  colour: 330,
  tooltip: '',
  helpUrl: '',
}

Blockly.Blocks['pink_flower'] = {
  init: function () {
    this.jsonInit(pink_flower)
    this.setStyle('loop_blocks')
  },
}

var cream_flower = {
  type: 'cream_flower',
  message0: '%1',
  args0: [
    {
      type: 'field_label_serializable',
      name: 'cream flower',
      text: 'cream flower',
    },
  ],
  output: null,
  colour: 330,
  tooltip: '',
  helpUrl: '',
}

Blockly.Blocks['cream_flower'] = {
  init: function () {
    this.jsonInit(cream_flower)
    this.setStyle('loop_blocks')
  },
}

var honey = {
  type: 'honey',
  message0: '%1',
  args0: [
    {
      type: 'field_label_serializable',
      name: 'honey',
      text: 'honey',
    },
  ],
  output: null,
  colour: 330,
  tooltip: '',
  helpUrl: '',
}

Blockly.Blocks['honey'] = {
  init: function () {
    this.jsonInit(honey)
    this.setStyle('loop_blocks')
  },
}

var yellow_question = {
  type: 'mz_yellow_question_input',
  message0: 'yellow question',
  output: null,
  colour: 330,
  tooltip: null,
  helpUrl: null,
}

Blockly.Blocks['mz_yellow_question_input'] = {
  init: function () {
    this.jsonInit(yellow_question)
    this.setStyle('loop_blocks')
  },
}

var orange_question = {
  type: 'mz_orange_question_input',
  message0: 'orange question',
  output: null,
  colour: 330,
  tooltip: null,
  helpUrl: null,
}

Blockly.Blocks['mz_orange_question_input'] = {
  init: function () {
    this.jsonInit(orange_question)
    this.setStyle('loop_blocks')
  },
}

var bee = {
  type: 'bee',
  message0: '%1',
  args0: [
    {
      type: 'field_label_serializable',
      name: 'bee',
      text: 'Bee',
    },
  ],
  output: null,
  colour: 330,
  tooltip: '',
  helpUrl: '',
}

Blockly.Blocks['bee'] = {
  init: function () {
    this.jsonInit(bee)
    this.setStyle('loop_blocks')
  },
}

var rabbit = {
  type: 'rabbit',
  message0: '%1',
  args0: [
    {
      type: 'field_label_serializable',
      name: 'rabbit',
      text: 'Rabbit',
    },
  ],
  output: null,
  colour: 330,
  tooltip: '',
  helpUrl: '',
}

Blockly.Blocks['rabbit'] = {
  init: function () {
    this.jsonInit(rabbit)
    this.setStyle('loop_blocks')
  },
}

var yellow_flower = {
  type: 'yellow_flower',
  message0: '%1',
  args0: [
    {
      type: 'field_label_serializable',
      name: 'yellow flower',
      text: 'yellow flower',
    },
  ],
  output: null,
  colour: 45,
  tooltip: '',
  helpUrl: '',
}

Blockly.Blocks['yellow_flower'] = {
  init: function () {
    this.jsonInit(yellow_flower)
    this.setStyle('loop_blocks')
  },
}

var lotus = {
  type: 'lotus',
  message0: '%1',
  args0: [
    {
      type: 'field_label_serializable',
      name: 'lotus',
      text: 'lotus',
    },
  ],
  output: null,
  colour: 45,
  tooltip: '',
  helpUrl: '',
}

Blockly.Blocks['lotus'] = {
  init: function () {
    this.jsonInit(lotus)
    this.setStyle('loop_blocks')
  },
}

var rock = {
  type: 'rock',
  message0: '%1',
  args0: [
    {
      type: 'field_label_serializable',
      name: 'rock',
      text: 'rock',
    },
  ],
  output: null,
  colour: 45,
  tooltip: '',
  helpUrl: '',
}

Blockly.Blocks['rock'] = {
  init: function () {
    this.jsonInit(rock)
    this.setStyle('loop_blocks')
  },
}

var duck = {
  type: 'duck',
  message0: '%1',
  args0: [
    {
      type: 'field_label_serializable',
      name: 'duck',
      text: 'duck',
    },
  ],
  output: null,
  colour: 45,
  tooltip: '',
  helpUrl: '',
}

Blockly.Blocks['duck'] = {
  init: function () {
    this.jsonInit(duck)
    this.setStyle('loop_blocks')
  },
}

var blue_fish = {
  type: 'blue_fish',
  message0: '%1',
  args0: [
    {
      type: 'field_label_serializable',
      name: 'blue fish',
      text: 'blue fish',
    },
  ],
  output: null,
  colour: 45,
  tooltip: '',
  helpUrl: '',
}

Blockly.Blocks['blue_fish'] = {
  init: function () {
    this.jsonInit(blue_fish)
    this.setStyle('loop_blocks')
  },
}

var yellow_qt = {
  type: 'yellow_qt',
  message0: '%1',
  args0: [
    {
      type: 'field_label_serializable',
      name: 'yellow question',
      text: 'yellow question',
    },
  ],
  output: null,
  colour: 45,
  tooltip: '',
  helpUrl: '',
}

Blockly.Blocks['yellow_qt'] = {
  init: function () {
    this.jsonInit(yellow_qt)
    this.setStyle('loop_blocks')
  },
}

var purple_qt = {
  type: 'purple_qt',
  message0: '%1',
  args0: [
    {
      type: 'field_label_serializable',
      name: 'purple question',
      text: 'purple question',
    },
  ],
  output: null,
  colour: 45,
  tooltip: '',
  helpUrl: '',
}

Blockly.Blocks['purple_qt'] = {
  init: function () {
    this.jsonInit(purple_qt)
    this.setStyle('loop_blocks')
  },
}

var gold_fish = {
  type: 'gold_fish',
  message0: '%1',
  args0: [
    {
      type: 'field_label_serializable',
      name: 'gold_fish',
      text: 'gold fish',
    },
  ],
  output: null,
  colour: 45,
  tooltip: '',
  helpUrl: '',
}

Blockly.Blocks['gold_fish'] = {
  init: function () {
    this.jsonInit(gold_fish)
    this.setStyle('loop_blocks')
  },
}

var yellow_fish = {
  type: 'yellow_fish',
  message0: '%1',
  args0: [
    {
      type: 'field_label_serializable',
      name: 'yellow fish',
      text: 'yellow fish',
    },
  ],
  output: null,
  colour: 45,
  tooltip: '',
  helpUrl: '',
}

Blockly.Blocks['yellow_fish'] = {
  init: function () {
    this.jsonInit(yellow_fish)
    this.setStyle('loop_blocks')
  },
}

var red_fish = {
  type: 'red_fish',
  message0: '%1',
  args0: [
    {
      type: 'field_label_serializable',
      name: 'red_fish',
      text: 'red_fish',
    },
  ],
  output: null,
  colour: 45,
  tooltip: '',
  helpUrl: '',
}

Blockly.Blocks['red_fish'] = {
  init: function () {
    this.jsonInit(red_fish)
    this.setStyle('loop_blocks')
  },
}

var football = {
  type: 'football',
  message0: '%1',
  args0: [
    {
      type: 'field_label_serializable',
      name: 'football',
      text: 'football',
    },
  ],
  output: null,
  colour: 15,
  tooltip: '',
  helpUrl: '',
}

Blockly.Blocks['football'] = {
  init: function () {
    this.jsonInit(football)
    this.setStyle('loop_blocks')
  },
}

var red_ball = {
  type: 'red_ball',
  message0: '%1',
  args0: [
    {
      type: 'field_label_serializable',
      name: 'red_ball',
      text: 'red ball',
    },
  ],
  output: null,
  colour: 15,
  tooltip: '',
  helpUrl: '',
}

Blockly.Blocks['red_ball'] = {
  init: function () {
    this.jsonInit(red_ball)
    this.setStyle('loop_blocks')
  },
}

var volleyball = {
  type: 'volleyball',
  message0: '%1',
  args0: [
    {
      type: 'field_label_serializable',
      name: 'volleyball',
      text: 'volleyball',
    },
  ],
  output: null,
  colour: 15,
  tooltip: '',
  helpUrl: '',
}

Blockly.Blocks['volleyball'] = {
  init: function () {
    this.jsonInit(volleyball)
    this.setStyle('loop_blocks')
  },
}

var white_ball = {
  type: 'white_ball',
  message0: '%1',
  args0: [
    {
      type: 'field_label_serializable',
      name: 'white_ball',
      text: 'white ball',
    },
  ],
  output: null,
  colour: 15,
  tooltip: '',
  helpUrl: '',
}

Blockly.Blocks['white_ball'] = {
  init: function () {
    this.jsonInit(white_ball)
    this.setStyle('loop_blocks')
  },
}

var pink_ball = {
  type: 'pink_ball',
  message0: '%1',
  args0: [
    {
      type: 'field_label_serializable',
      name: 'pink_ball',
      text: 'pink ball',
    },
  ],
  output: null,
  colour: 15,
  tooltip: '',
  helpUrl: '',
}

Blockly.Blocks['pink_ball'] = {
  init: function () {
    this.jsonInit(pink_ball)
    this.setStyle('loop_blocks')
  },
}

var purple_ball = {
  type: 'purple_ball',
  message0: '%1',
  args0: [
    {
      type: 'field_label_serializable',
      name: 'purple_ball',
      text: 'purple ball',
    },
  ],
  output: null,
  colour: 15,
  tooltip: '',
  helpUrl: '',
}

Blockly.Blocks['purple_ball'] = {
  init: function () {
    this.jsonInit(purple_ball)
    this.setStyle('loop_blocks')
  },
}

var blue_ball = {
  type: 'blue_ball',
  message0: '%1',
  args0: [
    {
      type: 'field_label_serializable',
      name: 'blue_ball',
      text: 'blue ball',
    },
  ],
  output: null,
  colour: 15,
  tooltip: '',
  helpUrl: '',
}

Blockly.Blocks['blue_ball'] = {
  init: function () {
    this.jsonInit(blue_ball)
    this.setStyle('loop_blocks')
  },
}

var red_flower = {
  type: 'red_flower',
  message0: '%1',
  args0: [
    {
      type: 'field_label_serializable',
      name: 'red_flower',
      text: 'red_flower',
    },
  ],
  output: null,
  colour: 15,
  tooltip: '',
  helpUrl: '',
}

Blockly.Blocks['red_flower'] = {
  init: function () {
    this.jsonInit(red_flower)
    this.setStyle('loop_blocks')
  },
}

var green_balloon = {
  type: 'green_balloon',
  message0: '%1',
  args0: [
    {
      type: 'field_label_serializable',
      name: 'green_balloon',
      text: 'green_balloon',
    },
  ],
  output: null,
  colour: 15,
  tooltip: '',
  helpUrl: '',
}

Blockly.Blocks['green_balloon'] = {
  init: function () {
    this.jsonInit(green_balloon)
    this.setStyle('loop_blocks')
  },
}

var maroon_balloon = {
  type: 'maroon_balloon',
  message0: '%1',
  args0: [
    {
      type: 'field_label_serializable',
      name: 'maroon_balloon',
      text: 'maroon_balloon',
    },
  ],
  output: null,
  colour: 15,
  tooltip: '',
  helpUrl: '',
}

Blockly.Blocks['maroon_balloon'] = {
  init: function () {
    this.jsonInit(maroon_balloon)
    this.setStyle('loop_blocks')
  },
}

var gold_gemstone = {
  type: 'gold_gemstone',
  message0: '%1',
  args0: [
    {
      type: 'field_label_serializable',
      name: 'gold_gemstone',
      text: 'gold_gemstone',
    },
  ],
  output: null,
  colour: 15,
  tooltip: '',
  helpUrl: '',
}

Blockly.Blocks['gold_gemstone'] = {
  init: function () {
    this.jsonInit(gold_gemstone)
    this.setStyle('loop_blocks')
  },
}

var blue_gemstone = {
  type: 'blue_gemstone',
  message0: '%1',
  args0: [
    {
      type: 'field_label_serializable',
      name: 'blue_gemstone',
      text: 'blue_gemstone',
    },
  ],
  output: null,
  colour: 15,
  tooltip: '',
  helpUrl: '',
}

Blockly.Blocks['blue_gemstone'] = {
  init: function () {
    this.jsonInit(blue_gemstone)
    this.setStyle('loop_blocks')
  },
}

var blue_balloon = {
  type: 'blue_balloon',
  message0: '%1',
  args0: [
    {
      type: 'field_label_serializable',
      name: 'blue_balloon',
      text: 'blue_balloon',
    },
  ],
  output: null,
  colour: 15,
  tooltip: '',
  helpUrl: '',
}

Blockly.Blocks['blue_balloon'] = {
  init: function () {
    this.jsonInit(blue_balloon)
    this.setStyle('loop_blocks')
  },
}

var red_balloon = {
  type: 'red_balloon',
  message0: '%1',
  args0: [
    {
      type: 'field_label_serializable',
      name: 'red_balloon',
      text: 'red_balloon',
    },
  ],
  output: null,
  colour: 15,
  tooltip: '',
  helpUrl: '',
}

Blockly.Blocks['red_balloon'] = {
  init: function () {
    this.jsonInit(red_balloon)
    this.setStyle('loop_blocks')
  },
}

var purple_baloon = {
  type: 'purple_baloon',
  message0: '%1',
  args0: [
    {
      type: 'field_label_serializable',
      name: 'purple_baloon',
      text: 'purple_baloon',
    },
  ],
  output: null,
  colour: 15,
  tooltip: '',
  helpUrl: '',
}

Blockly.Blocks['purple_baloon'] = {
  init: function () {
    this.jsonInit(purple_baloon)
    this.setStyle('loop_blocks')
  },
}

var snowflake = {
  type: 'snowflake',
  message0: '%1',
  args0: [
    {
      type: 'field_label_serializable',
      name: 'snowflake',
      text: 'snowflake',
    },
  ],
  output: null,
  colour: 15,
  tooltip: '',
  helpUrl: '',
}

Blockly.Blocks['snowflake'] = {
  init: function () {
    this.jsonInit(snowflake)
    this.setStyle('loop_blocks')
  },
}

var pumpkin = {
  type: 'pumpkin',
  message0: '%1',
  args0: [
    {
      type: 'field_label_serializable',
      name: 'pumpkin',
      text: 'pumpkin',
    },
  ],
  output: null,
  colour: 15,
  tooltip: '',
  helpUrl: '',
}

Blockly.Blocks['pumpkin'] = {
  init: function () {
    this.jsonInit(pumpkin)
    this.setStyle('loop_blocks')
  },
}

var pot = {
  type: 'pot',
  message0: '%1',
  args0: [
    {
      type: 'field_label_serializable',
      name: 'pot',
      text: 'pot',
    },
  ],
  output: null,
  colour: 15,
  tooltip: '',
  helpUrl: '',
}

Blockly.Blocks['pot'] = {
  init: function () {
    this.jsonInit(pot)
    this.setStyle('loop_blocks')
  },
}

var beetroot = {
  type: 'beetroot',
  message0: '%1',
  args0: [
    {
      type: 'field_label_serializable',
      name: 'beetroot',
      text: 'beetroot',
    },
  ],
  output: null,
  colour: 315,
  tooltip: '',
  helpUrl: '',
}

Blockly.Blocks['beetroot'] = {
  init: function () {
    this.jsonInit(beetroot)
    this.setStyle('loop_blocks')
  },
}

var check_question_block = {
  type: 'check_question_block',
  message0: '%1 %2',
  args0: [
    {
      type: 'field_label_serializable',
      name: 'check question',
      text: 'check question block',
    },
    {
      type: 'input_value',
      name: 'NAME',
    },
  ],
  output: null,
  colour: 135,
  tooltip: 'Check the block with the question mark',
  helpUrl: '',
}

Blockly.Blocks['check_question_block'] = {
  init: function () {
    this.jsonInit(check_question_block)
    this.setStyle('loop_blocks')
  },
}

//--------------------------------------------------------------//
// STORY LAB BLOCKS
//-------------------------------------------------------------//
// var levelOfExercise = localStorage.getItem("ExerciseLevel");
// var nameOfExercise = localStorage.getItem("ExerciseName");
// var assetsList = [];

// if (localStorage.getItem("ExerciseName") && Assets[nameOfExercise.replace(/ /g, "")])
//   assetsList = Assets[nameOfExercise.replace(/ /g, "")].default[levelOfExercise].assets;

Blockly.Blocks['create_sprite_lab1'] = {
  init: function () {
    this.appendDummyInput()
      .appendField('create sprite')
      .appendField(
        new Blockly.FieldDropdown([
          [
            {
              src: '/images/StoryLab/astrostory_0png.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'astrostory_0png.png',
          ],
          [
            {
              src: '/images/StoryLab/astrostory_90.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'astrostory_90.png',
          ],
          [
            {
              src: '/images/StoryLab/astrostory_180.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'astrostory_180.png',
          ],
          [
            {
              src: '/images/StoryLab/astrostory_270.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'astrostory_270.png',
          ],
          [
            {
              src: '/images/StoryLab/purplegem_story.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'purplegem_story.png',
          ],
          [
            {
              src: '/images/StoryLab/bluegem_story.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'bluegem_story.png',
          ],
          [
            {
              src: '/images/StoryLab/graygem_story.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'graygem_story.png',
          ],
          [
            {
              src: '/images/StoryLab/pinkgem_story.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'pinkgem_story.png',
          ],
          [
            {
              src: '/images/StoryLab/rocket_story.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'rocket_story.png',
          ],
          [
            {
              src: '/images/StoryLab/purplealien_story_0.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'purplealien_story_0.png',
          ],
          [
            {
              src: '/images/StoryLab/purplealien_story_90.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'purplealien_story_90.png',
          ],
          [
            {
              src: '/images/StoryLab/purplealien_story_180.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'purplealien_story_180.png',
          ],
          [
            {
              src: '/images/StoryLab/purplealien_story_270.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'purplealien_story_270.png',
          ],
          [
            {
              src: '/images/StoryLab/asteroid_story.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'asteroid_story.png',
          ],
        ]),
        'image'
      )
    this.appendDummyInput()
      .appendField('with name as')
      .appendField(new Blockly.FieldTextInput('sprite name'), 'sprite_name')
    this.appendValueInput('X location')
      .setCheck(null)
      .appendField('at X location')
    this.appendValueInput('Y location')
      .setCheck(null)
      .appendField('at Y location')
    this.setPreviousStatement(true, null)
    this.setNextStatement(true, null)
    this.setColour(0)
    this.setTooltip('')
    this.setHelpUrl('')
  },
}

Blockly.Blocks['create_sprite_lab2'] = {
  init: function () {
    this.appendDummyInput()
      .appendField('create sprite')
      .appendField(
        new Blockly.FieldDropdown([
          [
            {
              src: '/images/StoryLab/fairy_90.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'fairy_90.png',
          ],
          [
            {
              src: '/images/StoryLab/fairy_270.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'fairy_270.png',
          ],
          [
            {
              src: '/images/StoryLab/sphinx.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'sphinx.png',
          ],
          [
            {
              src: '/images/StoryLab/pumpkin_story.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'pumpkin_story.png',
          ],
          [
            {
              src: '/images/StoryLab/sadunicorn.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'sadunicorn.png',
          ],
          [
            {
              src: '/images/StoryLab/redstar.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'redstar.png',
          ],
          [
            {
              src: '/images/StoryLab/bluestar.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'bluestar.png',
          ],
          [
            {
              src: '/images/StoryLab/cauldron.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'cauldron.png',
          ],
          [
            {
              src: '/images/StoryLab/red_mushroom1.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'red_mushroom1.png',
          ],
          [
            {
              src: '/images/StoryLab/red_mushroom2.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'red_mushroom2.png',
          ],
          [
            {
              src: '/images/StoryLab/red_mushroom3.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'red_mushroom3.png',
          ],
          [
            {
              src: '/images/StoryLab/rabbit.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'rabbit.png',
          ],
          [
            {
              src: '/images/StoryLab/colorunicorn.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'colorunicorn.png',
          ],
          [
            {
              src: '/images/StoryLab/fish.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'fish.png',
          ],
        ]),
        'image'
      )
    this.appendDummyInput()
      .appendField('with name as')
      .appendField(new Blockly.FieldTextInput('sprite name'), 'sprite_name')
    this.appendValueInput('X location')
      .setCheck(null)
      .appendField('at X location')
    this.appendValueInput('Y location')
      .setCheck(null)
      .appendField('at Y location')
    this.setPreviousStatement(true, null)
    this.setNextStatement(true, null)
    this.setColour(0)
    this.setTooltip('')
    this.setHelpUrl('')
  },
}

Blockly.Blocks['create_sprite_lab3'] = {
  init: function () {
    this.appendDummyInput()
      .appendField('create sprite')
      .appendField(
        new Blockly.FieldDropdown([
          [
            {
              src: '/images/StoryLab/boygenius_0-01.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'boygenius_0-01.png',
          ],
          [
            {
              src: '/images/StoryLab/boygenius_90-01.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'boygenius_90-01.png',
          ],
          [
            {
              src: '/images/StoryLab/boygenius_180.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'boygenius_180.png',
          ],
          [
            {
              src: '/images/StoryLab/boygenius_270.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'boygenius_270.png',
          ],
          [
            {
              src: '/images/StoryLab/girlgenius_0-01.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'girlgenius_0-01.png',
          ],
          [
            {
              src: '/images/StoryLab/girlgenius_90-01.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'girlgenius_90-01.png',
          ],
          [
            {
              src: '/images/StoryLab/girlgenius_180-01.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'girlgenius_180-01.png',
          ],
          [
            {
              src: '/images/StoryLab/girlgenius_270-01.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'girlgenius_270-01.png',
          ],
          [
            {
              src: '/images/StoryLab/bigflame-01.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'bigflame-01.png',
          ],
          [
            {
              src: '/images/StoryLab/smallflame.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'smallflame.png',
          ],
          [
            {
              src: '/images/StoryLab/testtubered.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'testtubered.png',
          ],
          [
            {
              src: '/images/StoryLab/testtubeyellow.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'testtubeyellow.png',
          ],
          [
            {
              src: '/images/StoryLab/purpletesttube.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'purpletesttube.png',
          ],
          [
            {
              src: '/images/StoryLab/greenbeaker.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'greenbeaker.png',
          ],
          [
            {
              src: '/images/StoryLab/redbeaker1.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'redbeaker1.png',
          ],
          [
            {
              src: '/images/StoryLab/redbeaker2.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'redbeaker2.png',
          ],
          [
            {
              src: '/images/StoryLab/empty_testtube.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'empty_testtube.png',
          ],
          [
            {
              src: '/images/StoryLab/livingplant.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'livingplant.png',
          ],
          [
            {
              src: '/images/StoryLab/steam.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'steam.png',
          ],
          [
            {
              src: '/images/StoryLab/spider small.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'spider small.png',
          ],
          [
            {
              src: '/images/StoryLab/yellowbeaker.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'yellowbeaker.png',
          ],
          [
            {
              src: '/images/StoryLab/deadplant.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'deadplant.png',
          ],
        ]),
        'image'
      )
    this.appendDummyInput()
      .appendField('with name as')
      .appendField(new Blockly.FieldTextInput('sprite name'), 'sprite_name')
    this.appendValueInput('X location')
      .setCheck(null)
      .appendField('at X location')
    this.appendValueInput('Y location')
      .setCheck(null)
      .appendField('at Y location')
    this.setPreviousStatement(true, null)
    this.setNextStatement(true, null)
    this.setColour(0)
    this.setTooltip('')
    this.setHelpUrl('')
  },
}

Blockly.Blocks['create_sprite_lab4'] = {
  init: function () {
    this.appendDummyInput()
      .appendField('create sprite')
      .appendField(
        new Blockly.FieldDropdown([
          [
            {
              src: '/images/StoryLab/schoolboy_0-01.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'schoolboy_0-01.png',
          ],
          [
            {
              src: '/images/StoryLab/schoolboy_90-01.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'schoolboy_90-01.png',
          ],
          [
            {
              src: '/images/StoryLab/schoolboy_180-01.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'schoolboy_180-01.png',
          ],
          [
            {
              src: '/images/StoryLab/schoolboy_270-01.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'schoolboy_270-01.png',
          ],
          [
            {
              src: '/images/StoryLab/schoolgirl_0-01.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'schoolgirl_0-01.png',
          ],
          [
            {
              src: '/images/StoryLab/schoolgirl_90-01.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'schoolgirl_90-01.png',
          ],
          [
            {
              src: '/images/StoryLab/schoolgirl_180-01.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'schoolgirl_180-01.png',
          ],
          [
            {
              src: '/images/StoryLab/schoolgirl_270-01.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'schoolgirl_270-01.png',
          ],
          [
            {
              src: '/images/StoryLab/mother_0-01.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'mother_0-01.png',
          ],
          [
            {
              src: '/images/StoryLab/mother_180-01.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'mother_180-01.png',
          ],
          [
            {
              src: '/images/StoryLab/green_door.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'green_door.png',
          ],
          [
            {
              src: '/images/StoryLab/blue_door.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'blue_door.png',
          ],
          [
            {
              src: '/images/StoryLab/blue_door_bottom.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'blue_door_bottom.png',
          ],
          [
            {
              src: '/images/StoryLab/red_door.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'red_door.png',
          ],
          [
            {
              src: '/images/StoryLab/red_door_right.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'red_door_right.png',
          ],
          [
            {
              src: '/images/StoryLab/yellow_door.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'yellow_door.png',
          ],
          [
            {
              src: '/images/StoryLab/yellow_door_left.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'yellow_door_left.png',
          ],
          [
            {
              src: '/images/StoryLab/books.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'books.png',
          ],
          [
            {
              src: '/images/StoryLab/racquet.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'racquet.png',
          ],
          [
            {
              src: '/images/StoryLab/school_bag.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'school_bag.png',
          ],
          [
            {
              src: '/images/StoryLab/stationery.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'stationery.png',
          ],
          [
            {
              src: '/images/StoryLab/bottom_door.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'bottom_door.png',
          ],
          [
            {
              src: '/images/StoryLab/left_door.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'left_door.png',
          ],
          [
            {
              src: '/images/StoryLab/right_door.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'right_door.png',
          ],
        ]),
        'image'
      )
    this.appendDummyInput()
      .appendField('with name as')
      .appendField(new Blockly.FieldTextInput('sprite name'), 'sprite_name')
    this.appendValueInput('X location')
      .setCheck(null)
      .appendField('at X location')
    this.appendValueInput('Y location')
      .setCheck(null)
      .appendField('at Y location')
    this.setPreviousStatement(true, null)
    this.setNextStatement(true, null)
    this.setColour(0)
    this.setTooltip('')
    this.setHelpUrl('')
  },
}

Blockly.Blocks['change_sprite_lab1'] = {
  init: function () {
    this.appendDummyInput()
      .appendField('change sprite with name')
      .appendField(new Blockly.FieldTextInput('sprite name'), 'sprite_name')
      .appendField('to')
      .appendField(
        new Blockly.FieldDropdown([
          [
            {
              src: '/images/StoryLab/astrostory_0png.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'astrostory_0png.png',
          ],
          [
            {
              src: '/images/StoryLab/astrostory_90.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'astrostory_90.png',
          ],
          [
            {
              src: '/images/StoryLab/astrostory_180.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'astrostory_180.png',
          ],
          [
            {
              src: '/images/StoryLab/astrostory_270.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'astrostory_270.png',
          ],
          [
            {
              src: '/images/StoryLab/purplegem_story.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'purplegem_story.png',
          ],
          [
            {
              src: '/images/StoryLab/bluegem_story.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'bluegem_story.png',
          ],
          [
            {
              src: '/images/StoryLab/graygem_story.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'graygem_story.png',
          ],
          [
            {
              src: '/images/StoryLab/pinkgem_story.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'pinkgem_story.png',
          ],
          [
            {
              src: '/images/StoryLab/rocket_story.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'rocket_story.png',
          ],
          [
            {
              src: '/images/StoryLab/purplealien_story_0.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'purplealien_story_0.png',
          ],
          [
            {
              src: '/images/StoryLab/purplealien_story_90.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'purplealien_story_90.png',
          ],
          [
            {
              src: '/images/StoryLab/purplealien_story_180.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'purplealien_story_180.png',
          ],
          [
            {
              src: '/images/StoryLab/purplealien_story_270.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'purplealien_story_270.png',
          ],
          [
            {
              src: '/images/StoryLab/asteroid_story.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'asteroid_story.png',
          ],
        ]),
        'image'
      )
    this.appendValueInput('X location')
      .setCheck(null)
      .appendField('at X location')
    this.appendValueInput('Y location')
      .setCheck(null)
      .appendField('at Y location')
    this.setPreviousStatement(true, null)
    this.setNextStatement(true, null)
    this.setColour(0)
    this.setTooltip('Used to change existing sprite to new sprite')
    this.setHelpUrl('')
  },
}

Blockly.Blocks['change_sprite_lab2'] = {
  init: function () {
    this.appendDummyInput()
      .appendField('change sprite with name')
      .appendField(new Blockly.FieldTextInput('sprite name'), 'sprite_name')
      .appendField('to')
      .appendField(
        new Blockly.FieldDropdown([
          [
            {
              src: '/images/StoryLab/fairy_90.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'fairy_90.png',
          ],
          [
            {
              src: '/images/StoryLab/fairy_270.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'fairy_270.png',
          ],
          [
            {
              src: '/images/StoryLab/sphinx.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'sphinx.png',
          ],
          [
            {
              src: '/images/StoryLab/pumpkin_story.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'pumpkin_story.png',
          ],
          [
            {
              src: '/images/StoryLab/sadunicorn.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'sadunicorn.png',
          ],
          [
            {
              src: '/images/StoryLab/redstar.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'redstar.png',
          ],
          [
            {
              src: '/images/StoryLab/bluestar.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'bluestar.png',
          ],
          [
            {
              src: '/images/StoryLab/cauldron.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'cauldron.png',
          ],
          [
            {
              src: '/images/StoryLab/red_mushroom1.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'red_mushroom1.png',
          ],
          [
            {
              src: '/images/StoryLab/red_mushroom2.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'red_mushroom2.png',
          ],
          [
            {
              src: '/images/StoryLab/red_mushroom3.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'red_mushroom3.png',
          ],
          [
            {
              src: '/images/StoryLab/rabbit.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'rabbit.png',
          ],
          [
            {
              src: '/images/StoryLab/colorunicorn.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'colorunicorn.png',
          ],
          [
            {
              src: '/images/StoryLab/fish.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'fish.png',
          ],
        ]),
        'image'
      )
    this.appendValueInput('X location')
      .setCheck(null)
      .appendField('at X location')
    this.appendValueInput('Y location')
      .setCheck(null)
      .appendField('at Y location')
    this.setPreviousStatement(true, null)
    this.setNextStatement(true, null)
    this.setColour(0)
    this.setTooltip('Used to change existing sprite to new sprite')
    this.setHelpUrl('')
  },
}

Blockly.Blocks['change_sprite_lab3'] = {
  init: function () {
    this.appendDummyInput()
      .appendField('change sprite with name')
      .appendField(new Blockly.FieldTextInput('sprite name'), 'sprite_name')
      .appendField('to')
      .appendField(
        new Blockly.FieldDropdown([
          [
            {
              src: '/images/StoryLab/boygenius_0-01.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'boygenius_0-01.png',
          ],
          [
            {
              src: '/images/StoryLab/boygenius_90-01.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'boygenius_90-01.png',
          ],
          [
            {
              src: '/images/StoryLab/boygenius_180.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'boygenius_180.png',
          ],
          [
            {
              src: '/images/StoryLab/boygenius_270.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'boygenius_270.png',
          ],
          [
            {
              src: '/images/StoryLab/girlgenius_0-01.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'girlgenius_0-01.png',
          ],
          [
            {
              src: '/images/StoryLab/girlgenius_90-01.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'girlgenius_90-01.png',
          ],
          [
            {
              src: '/images/StoryLab/girlgenius_180-01.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'girlgenius_180-01.png',
          ],
          [
            {
              src: '/images/StoryLab/girlgenius_270-01.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'girlgenius_270-01.png',
          ],
          [
            {
              src: '/images/StoryLab/bigflame-01.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'bigflame-01.png',
          ],
          [
            {
              src: '/images/StoryLab/smallflame.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'smallflame.png',
          ],
          [
            {
              src: '/images/StoryLab/testtubered.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'testtubered.png',
          ],
          [
            {
              src: '/images/StoryLab/testtubeyellow.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'testtubeyellow.png',
          ],
          [
            {
              src: '/images/StoryLab/purpletesttube.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'purpletesttube.png',
          ],
          [
            {
              src: '/images/StoryLab/greenbeaker.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'greenbeaker.png',
          ],
          [
            {
              src: '/images/StoryLab/redbeaker1.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'redbeaker1.png',
          ],
          [
            {
              src: '/images/StoryLab/redbeaker2.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'redbeaker2.png',
          ],
          [
            {
              src: '/images/StoryLab/empty_testtube.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'empty_testtube.png',
          ],
          [
            {
              src: '/images/StoryLab/livingplant.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'livingplant.png',
          ],
          [
            {
              src: '/images/StoryLab/steam.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'steam.png',
          ],
          [
            {
              src: '/images/StoryLab/spider small.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'spider small.png',
          ],
          [
            {
              src: '/images/StoryLab/yellowbeaker.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'yellowbeaker.png',
          ],
          [
            {
              src: '/images/StoryLab/deadplant.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'deadplant.png',
          ],
        ]),
        'image'
      )
    this.appendValueInput('X location')
      .setCheck(null)
      .appendField('at X location')
    this.appendValueInput('Y location')
      .setCheck(null)
      .appendField('at Y location')
    this.setPreviousStatement(true, null)
    this.setNextStatement(true, null)
    this.setColour(0)
    this.setTooltip('Used to change existing sprite to new sprite')
    this.setHelpUrl('')
  },
}

Blockly.Blocks['change_sprite_lab4'] = {
  init: function () {
    this.appendDummyInput()
      .appendField('change sprite with name')
      .appendField(new Blockly.FieldTextInput('sprite name'), 'sprite_name')
      .appendField('to')
      .appendField(
        new Blockly.FieldDropdown([
          [
            {
              src: '/images/StoryLab/schoolboy_0-01.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'schoolboy_0-01.png',
          ],
          [
            {
              src: '/images/StoryLab/schoolboy_90-01.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'schoolboy_90-01.png',
          ],
          [
            {
              src: '/images/StoryLab/schoolboy_180-01.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'schoolboy_180-01.png',
          ],
          [
            {
              src: '/images/StoryLab/schoolboy_270-01.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'schoolboy_270-01.png',
          ],
          [
            {
              src: '/images/StoryLab/schoolgirl_0-01.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'schoolgirl_0-01.png',
          ],
          [
            {
              src: '/images/StoryLab/schoolgirl_90-01.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'schoolgirl-90-01.png',
          ],
          [
            {
              src: '/images/StoryLab/schoolgirl-180-01.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'schoolgirl-180-01.png',
          ],
          [
            {
              src: '/images/StoryLab/schoolgirl_270-01.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'schoolgirl_270-01.png',
          ],
          [
            {
              src: '/images/StoryLab/mother_0-01.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'mother_0-01.png',
          ],
          [
            {
              src: '/images/StoryLab/mother_180-01.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'mother_180-01.png',
          ],
          [
            {
              src: '/images/StoryLab/green_door.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'green_door.png',
          ],
          [
            {
              src: '/images/StoryLab/blue_door.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'blue_door.png',
          ],
          [
            {
              src: '/images/StoryLab/blue_door_bottom.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'blue_door_bottom.png',
          ],
          [
            {
              src: '/images/StoryLab/red_door.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'red_door.png',
          ],
          [
            {
              src: '/images/StoryLab/red_door_right.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'red_door_right.png',
          ],
          [
            {
              src: '/images/StoryLab/yellow_door.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'yellow_door.png',
          ],
          [
            {
              src: '/images/StoryLab/yellow_door_left.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'yellow_door_left.png',
          ],
          [
            {
              src: '/images/StoryLab/books.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'books.png',
          ],
          [
            {
              src: '/images/StoryLab/racquet.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'racquet.png',
          ],
          [
            {
              src: '/images/StoryLab/school_bag.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'school_bag.png',
          ],
          [
            {
              src: '/images/StoryLab/stationery.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'stationery.png',
          ],
          [
            {
              src: '/images/StoryLab/bottom_door.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'bottom_door.png',
          ],
          [
            {
              src: '/images/StoryLab/left_door.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'left_door.png',
          ],
          [
            {
              src: '/images/StoryLab/right_door.png',
              width: 35,
              height: 35,
              alt: '*',
            },
            'right_door.png',
          ],
        ]),
        'image'
      )
    this.appendValueInput('X location')
      .setCheck(null)
      .appendField('at X location')
    this.appendValueInput('Y location')
      .setCheck(null)
      .appendField('at Y location')
    this.setPreviousStatement(true, null)
    this.setNextStatement(true, null)
    this.setColour(0)
    this.setTooltip('Used to change existing sprite to new sprite')
    this.setHelpUrl('')
  },
}

Blockly.Blocks['set_background'] = {
  init: function () {
    this.appendDummyInput()
      .appendField('set background to')
      .appendField(
        new Blockly.FieldDropdown([
          [
            {
              src: '/images/StoryLab/space_story_background.png',
              width: 15,
              height: 15,
              alt: '*',
            },
            'space_story_background.png',
          ],
          [
            {
              src: '/images/StoryLab/space_story_background2.png',
              width: 15,
              height: 15,
              alt: '*',
            },
            'space_story_background2.png',
          ],
          [
            {
              src: '/images/StoryLab/space_story_background3.png',
              width: 15,
              height: 15,
              alt: '*',
            },
            'space_story_background3.png',
          ],
          [
            {
              src: '/images/StoryLab/space_story_background4.png',
              width: 15,
              height: 15,
              alt: '*',
            },
            'space_story_background4.png',
          ],
          [
            {
              src: '/images/StoryLab/unicorn_story_background1.png',
              width: 15,
              height: 15,
              alt: '*',
            },
            'unicorn_story_background1.png',
          ],
          [
            {
              src: '/images/StoryLab/unicorn_story_background2.png',
              width: 15,
              height: 15,
              alt: '*',
            },
            'unicorn_story_background2.png',
          ],
          [
            {
              src: '/images/StoryLab/unicorn_story_background3.png',
              width: 15,
              height: 15,
              alt: '*',
            },
            'unicorn_story_background3.png',
          ],
          [
            {
              src: '/images/StoryLab/unicorn_story_background4.png',
              width: 15,
              height: 15,
              alt: '*',
            },
            'unicorn_story_background4.png',
          ],
          [
            {
              src: '/images/StoryLab/laboratorystory_background1.png',
              width: 15,
              height: 15,
              alt: '*',
            },
            'laboratorystory_background1.png',
          ],
          [
            {
              src: '/images/StoryLab/laboratorystory_background2.png',
              width: 15,
              height: 15,
              alt: '*',
            },
            'laboratorystory_background2.png',
          ],
          [
            {
              src: '/images/StoryLab/laboratorystory_background3.png',
              width: 15,
              height: 15,
              alt: '*',
            },
            'laboratorystory_background3.png',
          ],
          [
            {
              src: '/images/StoryLab/laboratorystory_background4.png',
              width: 15,
              height: 15,
              alt: '*',
            },
            'laboratorystory_background4.png',
          ],
          [
            {
              src: '/images/StoryLab/schoolstory_bottom_background.png',
              width: 15,
              height: 15,
              alt: '*',
            },
            'schoolstory_bottom_background.png',
          ],
          [
            {
              src: '/images/StoryLab/schoolstory_top_background.png',
              width: 15,
              height: 15,
              alt: '*',
            },
            'schoolstory_top_background.png',
          ],
          [
            {
              src: '/images/StoryLab/schoolstory_centre_background.png',
              width: 15,
              height: 15,
              alt: '*',
            },
            'schoolstory_centre_background.png',
          ],
          [
            {
              src: '/images/StoryLab/schoolstory_left_background.png',
              width: 15,
              height: 15,
              alt: '*',
            },
            'schoolstory_left_background.png',
          ],
          [
            {
              src: '/images/StoryLab/schoolstory_right_background.png',
              width: 15,
              height: 15,
              alt: '*',
            },
            'schoolstory_right_background.png',
          ],
        ]),
        'set_background'
      )
    this.setPreviousStatement(true, null)
    this.setNextStatement(true, null)
    this.setColour(230)
    this.setTooltip('Set background to represent chosen image')
    this.setHelpUrl('')
  },
}

Blockly.Blocks['move_around'] = {
  init: function () {
    this.appendDummyInput()
      .appendField('move')
      .appendField(
        new Blockly.FieldDropdown([
          ['left', 'left 1'],
          ['right', 'right 1'],
          ['up', 'up 1'],
          ['down', 'down 1'],
        ]),
        'direction 1'
      )
      .appendField('to')
      .appendField(
        new Blockly.FieldDropdown([
          ['right', 'right 2'],
          ['left', 'left 2'],
          ['up', 'up 2'],
          ['down ', 'down 2'],
        ]),
        'direction 2'
      )
    this.appendDummyInput()
      .appendField(
        new Blockly.FieldLabelSerializable('with speed'),
        'with speed'
      )
      .appendField(new Blockly.FieldNumber(0, 0, 10), 'speed input')
    this.setOutput(true, null)
    this.setColour(230)
    this.setTooltip('')
    this.setHelpUrl('')
  },
}

Blockly.Blocks['set_text_color'] = {
  init: function () {
    this.appendValueInput('NAME')
      .setCheck(null)
      .appendField(
        new Blockly.FieldLabelSerializable('set colour for'),
        'set colour'
      )
      .appendField(
        new Blockly.FieldTextInput('text variable name'),
        'text variable name'
      )
    this.setPreviousStatement(true, null)
    this.setNextStatement(true, null)
    this.setColour(15)
    this.setTooltip('Set text colour to')
    this.setHelpUrl('')
  },
}

Blockly.Blocks['move_randomly_with_speed'] = {
  init: function () {
    this.appendDummyInput()
      .appendField(
        new Blockly.FieldLabelSerializable('move randomly with speed'),
        'move random'
      )
      .appendField(new Blockly.FieldNumber(0, 0, 10), 'NAME')
    this.setOutput(true, null)
    this.setColour(230)
    this.setTooltip('')
    this.setHelpUrl('')
  },
}

Blockly.Blocks['grow_sprite'] = {
  init: function () {
    this.appendDummyInput().appendField(
      new Blockly.FieldLabelSerializable('growing by'),
      'grow sprite value'
    )
    this.appendValueInput('NAME').setCheck(null)
    this.setInputsInline(true)
    this.setOutput(true, null)
    this.setColour(135)
    this.setTooltip('Grow sprite by a factor ')
    this.setHelpUrl('')
  },
}

Blockly.Blocks['if_right_edge'] = {
  init: function () {
    this.appendDummyInput().appendField(
      new Blockly.FieldLabelSerializable('if right edge is touched'),
      'if_right'
    )
    this.setInputsInline(true)
    this.setOutput(true, null)
    this.setColour(135)
    this.setTooltip('If right sprite is touched')
    this.setHelpUrl('')
  },
}

Blockly.Blocks['if_left_edge'] = {
  init: function () {
    this.appendDummyInput().appendField(
      new Blockly.FieldLabelSerializable('if left edge is touched'),
      'if_left'
    )
    this.setInputsInline(true)
    this.setOutput(true, null)
    this.setColour(135)
    this.setTooltip('If left sprite is touched ')
    this.setHelpUrl('')
  },
}

Blockly.Blocks['shrink_sprite'] = {
  init: function () {
    this.appendDummyInput().appendField(
      new Blockly.FieldLabelSerializable('shrinking by'),
      'shrink sprite value'
    )
    this.appendValueInput('NAME').setCheck(null)
    this.setInputsInline(true)
    this.setOutput(true, null)
    this.setColour(135)
    this.setTooltip('Shrink sprite by a factor')
    this.setHelpUrl('')
  },
}

Blockly.Blocks['when_run'] = {
  init: function () {
    this.appendDummyInput().appendField(
      new Blockly.FieldLabelSerializable('when run'),
      'when_run'
    )
    this.appendStatementInput('all_maze_statements')
      .setCheck(null)
      .appendField(new Blockly.FieldLabelSerializable(''), 'NAME')
    this.setPreviousStatement(true, null)
    this.setNextStatement(true, null)
    this.setColour(230)
    this.setTooltip('')
    this.setHelpUrl('')
  },
}

Blockly.Blocks['when_button_pressed'] = {
  init: function () {
    this.appendDummyInput()
      .appendField(new Blockly.FieldLabelSerializable('when'), 'when')
      .appendField(
        new Blockly.FieldDropdown([
          ['left', 'left'],
          ['right', 'right'],
          ['up', 'up'],
          ['down', 'down'],
        ]),
        'button_direction'
      )
      .appendField(new Blockly.FieldLabelSerializable('button pressed'), 'NAME')
    this.appendStatementInput('NAME').setCheck(null)
    this.setPreviousStatement(true, null)
    this.setNextStatement(true, null)
    this.setColour(270)
    this.setTooltip('when button pressed do what')
    this.setHelpUrl('')
  },
}

Blockly.Blocks['sprite_begins'] = {
  init: function () {
    this.appendDummyInput()
      .appendField(new Blockly.FieldLabelSerializable('sprite'), 'sprite')
      .appendField(new Blockly.FieldTextInput('sprite name'), 'sprite_name')
    this.appendValueInput('begins')
      .setCheck(null)
      .appendField(new Blockly.FieldLabelSerializable('begins'), 'begins')
    this.setPreviousStatement(true, null)
    this.setNextStatement(true, null)
    this.setColour(180)
    this.setTooltip('Helps make the Sprite perform chosen movements')
    this.setHelpUrl('')
  },
}

Blockly.Blocks['when_sprite_touches'] = {
  init: function () {
    this.appendDummyInput()
      .appendField(new Blockly.FieldLabelSerializable('when'), 'when')
      .appendField(new Blockly.FieldTextInput('sprite name'), 'sprite 1')
      .appendField(new Blockly.FieldLabelSerializable('touches'), 'touches')
      .appendField(new Blockly.FieldTextInput('sprite name'), 'sprite 2')
    this.appendStatementInput('NAME').setCheck(null)
    this.setPreviousStatement(true, null)
    this.setNextStatement(true, null)
    this.setColour(270)
    this.setTooltip('when sprite interacts with another')
    this.setHelpUrl('')
  },
}

Blockly.Blocks['when_sprite_touches_surface'] = {
  init: function () {
    this.appendDummyInput()
      .appendField(new Blockly.FieldLabelSerializable('when'), 'when')
      .appendField(new Blockly.FieldTextInput('sprite name'), 'sprite_name')
      .appendField(new Blockly.FieldLabelSerializable('touches'), 'touches')
      .appendField(
        new Blockly.FieldDropdown([
          ['top', 'top'],
          ['bottom', 'bottom'],
          ['left', 'left'],
          ['right', 'right'],
        ]),
        'surface'
      )
      .appendField(new Blockly.FieldLabelSerializable('edge'), 'edge')
    this.appendStatementInput('NAME').setCheck(null)
    this.setPreviousStatement(true, null)
    this.setNextStatement(true, null)
    this.setColour(270)
    this.setTooltip('')
    this.setHelpUrl('')
  },
}

Blockly.Blocks['set_text'] = {
  init: function () {
    this.appendDummyInput()
      .appendField(new Blockly.FieldLabelSerializable('set text for'), 'text')
      .appendField(
        new Blockly.FieldTextInput('text variable name'),
        'text variable'
      )
    this.appendDummyInput()
      .appendField(new Blockly.FieldLabelSerializable('as'), 'NAME')
      .appendField(new Blockly.FieldTextInput('text input'), 'text input')
    this.appendValueInput('x location')
      .setCheck(null)
      .appendField(
        new Blockly.FieldLabelSerializable('at X location'),
        'x location'
      )
    this.appendValueInput('y location')
      .setCheck(null)
      .appendField(
        new Blockly.FieldLabelSerializable('at Y location'),
        'y location'
      )
    this.setPreviousStatement(true, null)
    this.setNextStatement(true, null)
    this.setColour(230)
    this.setTooltip('')
    this.setHelpUrl('')
  },
}

Blockly.Blocks['hide_sprite'] = {
  init: function () {
    this.appendDummyInput()
      .appendField(new Blockly.FieldLabelSerializable('hide '), 'hide')
      .appendField(new Blockly.FieldTextInput('sprite name'), 'sprite_name')
    this.setPreviousStatement(true, null)
    this.setNextStatement(true, null)
    this.setColour(0)
    this.setTooltip('')
    this.setHelpUrl('')
  },
}

Blockly.Blocks['show_sprite'] = {
  init: function () {
    this.appendDummyInput()
      .appendField(new Blockly.FieldLabelSerializable('show '), 'show')
      .appendField(new Blockly.FieldTextInput('sprite name'), 'sprite_name')
    this.setPreviousStatement(true, null)
    this.setNextStatement(true, null)
    this.setColour(0)
    this.setTooltip('')
    this.setHelpUrl('')
  },
}

Blockly.Blocks['numerical_input_value'] = {
  init: function () {
    this.appendDummyInput().appendField(
      new Blockly.FieldNumber(0),
      'numerical_input_value'
    )
    this.setOutput(true, null)
    this.setColour(230)
    this.setTooltip('')
    this.setHelpUrl('')
  },
}

Blockly.Blocks['default_x'] = {
  init: function () {
    this.appendDummyInput().appendField(
      new Blockly.FieldTextInput('default_x'),
      'default_x'
    )
    this.setOutput(true, null)
    this.setColour(230)
    this.setTooltip('')
    this.setHelpUrl('')
  },
}

Blockly.Blocks['default_y'] = {
  init: function () {
    this.appendDummyInput().appendField(
      new Blockly.FieldTextInput('default_y'),
      'default_y'
    )
    this.setOutput(true, null)
    this.setColour(230)
    this.setTooltip('')
    this.setHelpUrl('')
  },
}

Blockly.Blocks['sprite_stops'] = {
  init: function () {
    this.appendDummyInput()
      .appendField(new Blockly.FieldLabelSerializable('sprite'), 'sprite')
      .appendField(new Blockly.FieldTextInput('sprite name'), 'sprite name')
    this.appendValueInput('stops movements')
      .setCheck(null)
      .appendField(new Blockly.FieldLabelSerializable('stops'), 'stops')
    this.setPreviousStatement(true, null)
    this.setNextStatement(true, null)
    this.setColour(180)
    this.setTooltip('Makes sprites stop on going movements')
    this.setHelpUrl('')
  },
}

Blockly.Blocks['move_left'] = {
  init: function () {
    this.appendValueInput('left distance')
      .setCheck(null)
      .appendField(new Blockly.FieldLabelSerializable('moving'), 'moving by')
      .appendField(
        new Blockly.FieldDropdown([
          ['left', '180'],
          ['right', '0'],
          ['up', '270'],
          ['down', '90'],
        ]),
        'direction'
      )
      // .appendField(new Blockly.FieldLabelSerializable("by"), "NAME");
      // this.appendValueInput("speed")
      .setCheck(null)
      .appendField(new Blockly.FieldLabelSerializable('with speed'), 'speed')
    this.setInputsInline(true)
    this.setOutput(true, null)
    this.setColour(135)
    this.setTooltip('Move sprite to the left')
    this.setHelpUrl('')
  },
}

Blockly.Blocks['every_movement'] = {
  init: function () {
    this.appendDummyInput().appendField(
      new Blockly.FieldLabelSerializable('stops every movement'),
      'stops every movement'
    )
    this.setOutput(true, null)
    this.setColour(135)
    this.setTooltip('')
    this.setHelpUrl('')
  },
}

Blockly.Blocks['stops_every_movement'] = {
  init: function () {
    this.appendDummyInput()
      .appendField(new Blockly.FieldLabelSerializable('sprite'), 'sprite')
      .appendField(new Blockly.FieldTextInput('sprite name'), 'sprite name')
    this.appendDummyInput().appendField(
      new Blockly.FieldLabelSerializable('stops all movement'),
      'stops all movement'
    )
    this.setPreviousStatement(true, null)
    this.setNextStatement(true, null)
    this.setColour(180)
    this.setTooltip('')
    this.setHelpUrl('')
  },
}

Blockly.Blocks['when_clicked'] = {
  init: function () {
    this.appendDummyInput()
      .appendField(new Blockly.FieldLabelSerializable('when'), 'when')
      .appendField(new Blockly.FieldTextInput('name'), 'entity name')
      .appendField(new Blockly.FieldLabelSerializable('is clicked'), 'NAME')
    this.appendStatementInput('NAME').setCheck(null)
    this.setPreviousStatement(true, null)
    this.setNextStatement(true, null)
    this.setColour(270)
    this.setTooltip('when entity is clicked do what')
    this.setHelpUrl('')
  },
}

Blockly.Blocks['text_color'] = {
  init: function () {
    this.appendDummyInput().appendField(
      new Blockly.FieldColour('#ff0000'),
      'NAME'
    )
    this.setOutput(true, null)
    this.setColour(230)
    this.setTooltip('')
    this.setHelpUrl('')
  },
}

Blockly.Blocks['create_text_input'] = {
  init: function () {
    this.appendDummyInput()
      .appendField(
        new Blockly.FieldLabelSerializable('create text box'),
        'text'
      )
      .appendField(
        new Blockly.FieldTextInput('text variable name'),
        'text_variable'
      )
    this.appendDummyInput()
      .appendField(new Blockly.FieldLabelSerializable('as'), 'as')
      .appendField(
        new Blockly.FieldDropdown([
          [
            {
              src: '/images/StoryLab/left_speech_bubble.png',
              width: 15,
              height: 15,
              alt: '*',
            },
            'left_speech_bubble.png',
          ],
          [
            {
              src: '/images/StoryLab/right_speech_bubble.png',
              width: 15,
              height: 15,
              alt: '*',
            },
            'right_speech_bubble.png',
          ],
        ]),
        'speech_bubble'
      )
      .appendField(
        new Blockly.FieldLabelSerializable('with text input'),
        'NAME'
      )
    this.appendDummyInput().appendField(
      new Blockly.FieldTextInput('Please Click on the Mashroom you think'),
      'text_value'
    )
    this.appendValueInput('x location')
      .setCheck(null)
      .appendField(
        new Blockly.FieldLabelSerializable('at X location'),
        'x location'
      )
    this.appendValueInput('y location')
      .setCheck(null)
      .appendField(
        new Blockly.FieldLabelSerializable('at Y location'),
        'y location'
      )
    this.setPreviousStatement(true, null)
    this.setNextStatement(true, null)
    this.setColour(15)
    this.setTooltip('Create text box with given text input')
    this.setHelpUrl('')
  },
}

Blockly.Blocks['set_text_size'] = {
  init: function () {
    this.appendValueInput('NAME')
      .setCheck(null)
      .appendField(
        new Blockly.FieldLabelSerializable('set size for'),
        'set size'
      )
      .appendField(
        new Blockly.FieldTextInput('text variable name'),
        'text variable'
      )
      .appendField(new Blockly.FieldLabelSerializable('to'), 'to')
    this.setInputsInline(true)
    this.setPreviousStatement(true, null)
    this.setNextStatement(true, null)
    this.setColour(15)
    this.setTooltip('Set text size')
    this.setHelpUrl('')
  },
}

Blockly.Blocks['create_button'] = {
  init: function () {
    this.appendDummyInput()
      .appendField(
        new Blockly.FieldLabelSerializable('create button'),
        'create button'
      )
      .appendField(new Blockly.FieldTextInput('button name'), 'button name')
    this.appendDummyInput()
      .appendField(
        new Blockly.FieldLabelSerializable('with width'),
        'with width'
      )
      .appendField(new Blockly.FieldNumber(0, 0), 'width')
      .appendField(
        new Blockly.FieldLabelSerializable('with height'),
        'with height'
      )
      .appendField(new Blockly.FieldNumber(0, 0), 'height')
    this.appendValueInput('X position')
      .setCheck(null)
      .appendField(
        new Blockly.FieldLabelSerializable('at X positon'),
        'at X positon'
      )
    this.appendValueInput('Y position')
      .setCheck(null)
      .appendField(
        new Blockly.FieldLabelSerializable('at Y position'),
        'at Y position'
      )
    this.setPreviousStatement(true, null)
    this.setNextStatement(true, null)
    this.setColour(30)
    this.setTooltip('Create button with given width and height')
    this.setHelpUrl('')
  },
}

Blockly.Blocks['set_button_colour'] = {
  init: function () {
    this.appendValueInput('NAME')
      .setCheck(null)
      .appendField(
        new Blockly.FieldLabelSerializable('set colour for'),
        'set button colour'
      )
      .appendField(new Blockly.FieldTextInput('button name'), 'button name')
      .appendField(new Blockly.FieldLabelSerializable('to'), 'to')
    this.setPreviousStatement(true, null)
    this.setNextStatement(true, null)
    this.setColour(30)
    this.setTooltip('Set button colour')
    this.setHelpUrl('')
  },
}

Blockly.Blocks['set_button_text'] = {
  init: function () {
    this.appendDummyInput()
      .appendField(
        new Blockly.FieldLabelSerializable('set text for'),
        'set text for'
      )
      .appendField(new Blockly.FieldTextInput('button name'), 'button name')
      .appendField(new Blockly.FieldLabelSerializable('as'), 'as')
      .appendField(new Blockly.FieldTextInput('text input'), 'text input')
    this.setPreviousStatement(true, null)
    this.setNextStatement(true, null)
    this.setColour(30)
    this.setTooltip('Set button text')
    this.setHelpUrl('')
  },
}

Blockly.Blocks['set_button_text_colour'] = {
  init: function () {
    this.appendValueInput('NAME')
      .setCheck(null)
      .appendField(
        new Blockly.FieldLabelSerializable('set text colour of'),
        'set text colour'
      )
      .appendField(new Blockly.FieldTextInput('button name'), 'button name')
    this.setPreviousStatement(true, null)
    this.setNextStatement(true, null)
    this.setColour(30)
    this.setTooltip('Set button text colour')
    this.setHelpUrl('')
  },
}

Blockly.Blocks['set_button_text_size'] = {
  init: function () {
    this.appendValueInput('NAME')
      .setCheck(null)
      .appendField(
        new Blockly.FieldLabelSerializable('set text size of'),
        'set text size'
      )
      .appendField(new Blockly.FieldTextInput('button name'), 'button name')
      .appendField(new Blockly.FieldLabelSerializable('to'), 'to')
    this.setInputsInline(true)
    this.setPreviousStatement(true, null)
    this.setNextStatement(true, null)
    this.setColour(30)
    this.setTooltip('Set button text size')
    this.setHelpUrl('')
  },
}

Blockly.Blocks['text_value'] = {
  init: function () {
    this.appendDummyInput().appendField(
      new Blockly.FieldTextInput('text input value'),
      'NAME'
    )
    this.setOutput(true, null)
    this.setColour(230)
    this.setTooltip('text input')
    this.setHelpUrl('')
  },
}

Blockly.Blocks['create_screen'] = {
  init: function () {
    this.appendDummyInput()
      .appendField(
        new Blockly.FieldLabelSerializable('create screen'),
        'create screen'
      )
      .appendField(new Blockly.FieldTextInput('screen name'), 'screen name')
    this.setPreviousStatement(true, null)
    this.setNextStatement(true, null)
    this.setColour(90)
    this.setTooltip('Create different game screens')
    this.setHelpUrl('')
  },
}

Blockly.Blocks['change_screens'] = {
  init: function () {
    this.appendDummyInput()
      .appendField(new Blockly.FieldLabelSerializable('change'), 'change')
      .appendField(
        new Blockly.FieldTextInput('current screen name'),
        'screen name 1'
      )
      .appendField(new Blockly.FieldLabelSerializable('to'), 'to')
      .appendField(
        new Blockly.FieldTextInput('next screen name'),
        'screen name 2'
      )
    this.setPreviousStatement(true, null)
    this.setNextStatement(true, null)
    this.setColour(90)
    this.setTooltip('change between different screens')
    this.setHelpUrl('')
  },
}

Blockly.Blocks['move_left_right'] = {
  init: function () {
    this.appendDummyInput().appendField(
      new Blockly.FieldLabelSerializable('Move left and right'),
      'grow sprite value'
    )
    this.appendValueInput('NAME').setCheck(null)
    this.setInputsInline(true)
    this.setOutput(true, null)
    this.setColour(135)
    this.setTooltip('Move sprite by a factor ')
    this.setHelpUrl('')
  },
}

Blockly.Blocks['move_up_down'] = {
  init: function () {
    this.appendDummyInput().appendField(
      new Blockly.FieldLabelSerializable('Move up and down'),
      'grow sprite value'
    )
    this.appendValueInput('NAME').setCheck(null)
    this.setInputsInline(true)
    this.setOutput(true, null)
    this.setColour(135)
    this.setTooltip('Move sprite by a factor ')
    this.setHelpUrl('')
  },
}

Blockly.Blocks['move_down'] = {
  init: function () {
    this.appendDummyInput().appendField(
      new Blockly.FieldLabelSerializable('Move down'),
      'grow sprite value'
    )
    this.appendValueInput('NAME').setCheck(null)
    this.setInputsInline(true)
    this.setOutput(true, null)
    this.setColour(135)
    this.setTooltip('Move sprite by a factor ')
    this.setHelpUrl('')
  },
}

Blockly.Blocks['move_up'] = {
  init: function () {
    this.appendDummyInput().appendField(
      new Blockly.FieldLabelSerializable('Move up'),
      'grow sprite value'
    )
    this.appendValueInput('NAME').setCheck(null)
    this.setInputsInline(true)
    this.setOutput(true, null)
    this.setColour(135)
    this.setTooltip('Move sprite by a factor ')
    this.setHelpUrl('')
  },
}

Blockly.Blocks['random_x'] = {
  init: function () {
    this.appendDummyInput().appendField(
      new Blockly.FieldLabelSerializable('random x'),
      'X'
    )
    this.setOutput(true, null)
    this.setColour(230)
    this.setTooltip('')
    this.setHelpUrl('')
  },
}

Blockly.Blocks['random_y'] = {
  init: function () {
    this.appendDummyInput().appendField(
      new Blockly.FieldLabelSerializable('random y'),
      'Y'
    )
    this.setOutput(true, null)
    this.setColour(230)
    this.setTooltip('')
    this.setHelpUrl('')
  },
}

Blockly.Blocks['sl_create_text'] = {
  init: function () {
    this.appendDummyInput().appendField('create text input')
    this.appendValueInput('text_input')
      .setCheck(null)
      .appendField('with text input as')
    this.appendValueInput('X_position')
      .setCheck(null)
      .appendField('with X position')
    this.appendValueInput('Y_position')
      .setCheck(null)
      .appendField('with Y positon')
    this.appendValueInput('time_interval')
      .setCheck(null)
      .appendField('with time in seconds')
    this.setInputsInline(false)
    this.setPreviousStatement(true, null)
    this.setNextStatement(true, null)
    this.setColour(30)
    this.setTooltip(
      'Creates a dialog box or text input with a set time interval'
    )
    this.setHelpUrl('')
  },
}

Blockly.Blocks['al_game_setup'] = {
  init: function () {
    this.jsonInit(setupBlock)
  },
}

Blockly.Blocks['al_game_draw'] = {
  init: function () {
    this.jsonInit(drawBlock)
  },
}

Blockly.Blocks['al_game_create_rect'] = {
  init: function () {
    this.jsonInit(createRectangle)
  },
}

Blockly.Blocks['al_game_fill_shape'] = {
  init: function () {
    this.jsonInit(fillShape)
  },
}
