import { useState, useEffect, useCallback, useMemo } from 'react'
import Modal from '../components/modals'
import HalfGeneral from '../components/half-general-slideover'
import Button from '../components/button'
import app_api from '../config/api'
import Table from '../components/tables/table'
import { user_column } from '../components/tables/tableheader'
import { Sidebar } from '../components/navigation/sidebar'
import ConfirmDialog from '../components/dialog/confirmation_dialog'
import { Formik } from 'formik'
import { userSchema, adminSchema } from '../schema'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Switch } from '@headlessui/react'
import { classNames } from '../helpers/classname'
import Select from 'react-select'

const initialModalState = {
  type: '',
  state: false,
  index: null,
  edit_id: '',
  data: {
    firstName: '',
    lastName: '',
    email: '',
    role: '',
    status: '',
  },
}

const roleValue = [{
    id: 'ADMIN',
    name: 'Admin'
},
{
    id: 'HEAD_PRINCIPAL',
    name: 'Head Principal'
}]

const passwordTypeOption = [
  {
    id: 'auto',
    name: 'AUTO'
  },
  {
    id: 'manual',
    name: 'MANUAL'
  }
]

const UserPage = () => {
  const [modal, setModal] = useState(initialModalState)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [category, setCategory] = useState([])
  const [schoolData, setSchoolData] = useState([])
  const [confirmationDialog, setConfirmationDialog] = useState(false)
  const [roleName, setRoleName] = useState('')
  const [TabData, setTabData] = useState([])
  const [enabled, setEnabled] = useState(true)
  const [inactiveStatusConfirmationDialog, setInactiveStatusConfirmationDialog] = useState(false)
  const [activeStatusConfirmationDialog, setActiveStatusConfirmationDialog] = useState(false)
  // Pagination Start
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(15)
  const [totalPages, setTotalPages] = useState(1)
  const [count, setCount] = useState(0)
  const [searchFilter, setSearchFilter] = useState('')
  // Pagination End

  const goPrev = () => {
    if (page > 0) setPage((prev) => --prev)
  }

  const goNext = () => {
    if (page < totalPages - 1) setPage((prev) => ++prev)
  }

  const renderTable = useCallback(() => {
    return (
      <>
       <Table
          progPage={page}
          totalCount={count}
          columns={user_column({ onEditOpen, onDeleteOpen })}
          data={TabData}
          onEditClose={cleanModalData}
          setSearchFilter={setSearchFilter}
        />
      </>
    )
  }, [TabData])

  useEffect(() => {
    getCategoryData()
  }, [page, limit, searchFilter])

  const onDeleteOpen = (id, index) => {
    setModal((prev) => ({ ...prev, id: id, index: index }))
    setConfirmationDialog(true)
  }

  const cleanModalData = () => {
    setModal(initialModalState)
  }

  const onEditOpen = (id, index) => {
    setModal((prev) => ({
      ...prev,
      type: 'edit',
      edit_id: id,
      index: index,
      state: true,
      data: TabData[index],
    }))
    setEnabled(TabData[index].status)
  }

  const onDeleteCategory = () => {
    const { id, index } = modal
    app_api
      .delete(`/user/${id}`)
      .then((res) => {
        toast.success('Deleted Successfully')
        getCategoryData()
        setConfirmationDialog(false)
      })
      .catch((err) => {
        setError(err.toString())
        toast.error(err.response.data.message)
      })
  }

  const getCategoryData = () => {
    let url = `user/admin?page=${page}&size=${limit}&searchFilter=${searchFilter}`
    app_api
      .get(url)
      .then((res) => res.data)
      .then((res) => {
        setError(null)
        setCategory(res.data.data)
        setTabData(res.data.data)
        setCount(res.data.count)
        setTotalPages(res.data.count / limit)
        setLoading(false)
      })
      .catch((err) => {
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const getSchoolData = () => {
    let url = `school-master/many`
    app_api
      .get(url)
      .then((res) => res.data)
      .then((res) => {
        setError(null)
        setSchoolData(res.data.data)
        setLoading(false)
      })
      .catch((err) => {
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  useEffect(() => {
    getCategoryData()
    getSchoolData()
  }, [])

  const renderModal = () => {
    const { type, state, edit_id, data } = modal
    data.schoolIdArray = data.Schoolusers?.map((e) => e.school.id)
    return (
      <Formik
        initialValues={data}
        validationSchema={roleName != 'ADMIN' ? userSchema : adminSchema}
        enableReinitialize
        onSubmit={(values, { setSubmitting, resetForm }) =>
        {
          if (type === 'add') {
            app_api
              .post('user/register', {...values, status: enabled})
              .then((res) => {
                cleanModalData()
                getCategoryData()
                setSubmitting(false)
                resetForm()
                toast.success('Created Successfully')
              })
              .catch((err) => {
                if (err.response.status == 424) {
                  toast.error('Duplicate Entry')
                } else {
                  toast.error('Something Went Wrong')
                }
                cleanModalData()
                setSubmitting(false)
              })
          } else {
            delete values.Schoolusers
            app_api
              .patch(`/user/${edit_id}`, {...values, status: enabled})
              .then((res) => {
                getCategoryData()
                cleanModalData()
                setSubmitting(false)
                resetForm()
                toast.success('Updated Successfully')
              })
              .catch((err) => {
                toast.error(err.response.data.message)
              })
          }
        }}
      >
        {({
          handleBlur,
          Formik,
          handleChange,
          handleSubmit,
          setFieldTouched,
          setValues,
          values,
          touched,
          isValid,
          isSubmitting,
          errors,
        }) => (
          <HalfGeneral
            title={type === 'add' ? 'Add User' : 'Edit User'}
            open={state}
            setOpen={() => cleanModalData()}
          >
            <form onSubmit={handleSubmit} noValidate>
              <div className="mt-4 text-left">
                <div className="mt-4"></div>
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >First Name</label>
                  <span className='text-red-700 ml-1'>*</span>
                </div>
                <input
                  name="firstName"
                  label="Enter Name"
                  value={values.firstName}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="Enter First Name..."
                />
                {touched.firstName && (
                  <p className="text-red-700 error_msg">
                    {errors.firstName}
                  </p>
                )}
                <div className="mt-4"></div>
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >Last Name</label>
                  <span className='text-red-700 ml-1'>*</span>
                </div>
                <input
                  name="lastName"
                  label="lastName"
                  value={values.lastName}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Enter Last Name..."
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                />
                {touched.lastName && (
                  <p className="text-red-700 error_msg">{errors.lastName}</p>
                )}
                <div className="mt-4"></div>
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >Email</label>
                  <span className='text-red-700 ml-1'>*</span>
                </div>
                <input
                  name="email"
                  label="email"
                  value={values.email}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Enter Email Address..."
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                />
                {touched.email && (
                  <p className="text-red-700 error_msg">{errors.email}</p>
                )}
                <div className="mt-4"></div>
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >Role</label>
                  <span className='text-red-700 ml-1'>*</span>
                </div>

                <Select
                  className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="role"
                  id="role"
                  isSearchable
                  loadingMessage="Getting Role..."
                  placeholder="Select a Role"
                  value={
                    values.role
                      ? roleValue?.map((l) => ({
                          value: roleValue?.find((e) => e.id == values.role)
                            ?.id,
                          label: roleValue?.find((e) => e.id == values.role)
                            ?.name,
                        }))
                      :null 
                  }
                  options={
                    roleValue?.map((l) => ({
                    ...l,
                    label: l.name,
                    value: l.id,
                  }))
                }
                  onChange={(option) => {
                    setValues({
                      ...values,
                      role: option.value,
                    })
                    setRoleName(option.id)
                  }}
                  onBlur={handleBlur}
                />
                {
                  values.role != 'ADMIN' && values.role != '' && (
                    <>
                    <div className="mt-4"></div>
                <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >School</label>
                  <span className='text-red-700 ml-1'>*</span>
                </div>
                <Select
                  className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="schoolIdArray"
                  id="schoolIdArray"
                  isSearchable
                  loadingMessage="Getting School..."
                  placeholder="Select a school"
                  isMulti={true}
                  value={
                    values.schoolIdArray
                      ? values?.schoolIdArray?.map(l => ({
                          value: l,
                          label: schoolData.find((e) => e.id == l)
                            ?.name,
                        })): null
                  }
                  options={schoolData?.map((l) => ({
                    ...l,
                    label: l.name,
                    value: l.id,
                  }))}
                  onChange={(option) => {
                    setValues({
                      ...values,
                      schoolIdArray: option.map((l) => l.value),
                    })
                  }}
                  onBlur={handleBlur}
                />
                {touched.schoolIdArray && (
                  <p className="text-red-700 mb-2 error_msg">{errors.schoolIdArray}</p>
                )}
                    </>
                  )
                }
                {touched.role && (
                  <p className="text-red-700 mb-2 error_msg">{errors.role}</p>
                )}
                <div className="mt-4"></div>
                <div className='grid grid-cols-4 gap-4 flex flex-wrap'>
                  <div className='col-span-2'>
                  <div className='flex'>
                  <label className='block text-sm font-medium text-gray-700' >Username</label>
                  <span className='text-red-700 ml-1'>*</span>
                </div>
                <input
                  name="username"
                  label="Enter Name"
                  value={values.username}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="Enter Username..."
                />
                {touched.username && (
                  <p className="text-red-700 error_msg">
                    {errors.username}
                  </p>
                )}
                  </div>
                  <div className='col-span-2'>
                  <div className='flex'>
                    <label className='block text-sm font-medium text-gray-700' >Password Type</label>
                    <span className='text-red-700 ml-1'>*</span>
                  </div>
                  <Select
                  className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="passwordType"
                  id="passwordType"
                  isSearchable
                  loadingMessage="Getting type..."
                  placeholder="Select a type"
                  value={
                    values.passwordType
                      ? passwordTypeOption?.map((l) => ({
                          value: passwordTypeOption?.find((e) => e.id == values.passwordType)
                            ?.id,
                          label: passwordTypeOption?.find((e) => e.id == values.passwordType)
                            ?.name,
                        }))
                      :null 
                  }
                  options={
                    passwordTypeOption?.map((l) => ({
                    ...l,
                    label: l.name,
                    value: l.id,
                  }))
                }
                  onChange={(option) => {
                    let random_pass
                    if(!values.passwordType){
                      if(option.value == 'auto') random_pass = values.firstName.toLowerCase()+Math.floor(Math.random() * 90 + 10);
                      else random_pass = ''
                      setValues({
                        ...values,
                        passwordType: option.value,
                        password: random_pass
                      })
                    }
                    else
                    {
                      setValues({
                        ...values,
                        passwordType: option.value,
                      })
                    }
                  }}
                  onBlur={handleBlur}
                />
                    {touched.size_range && (
                      <p className="text-red-700 mb-1 error_msg">{errors.size_range}</p>
                    )}
                  </div>
                </div>
                <div className="mt-4"></div>
                {values.passwordType != null && (
                  <>
                    <div className="grid grid-cols-4 gap-4 flex flex-wrap">
                  <div className="col-span-2">
                  </div>
                  <div className="col-span-2">
                    <input
                      name="password"
                      type="text"
                      value={values.password}
                      min={4}
                      disabled={values.passwordType == 'auto'}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter Password..."
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                    {touched.password && (
                      <p className="text-red-700 mb-1 error_msg">
                        {errors.password}
                      </p>
                    )}
                  </div>
                </div>
                  </>
                )
                }
                <div className='mt-4'>
                <Switch.Group
                  as="div"
                  className="flex items-center justify-between"
                >
                  <span className="flex flex-grow flex-col">
                    <Switch.Label
                      as="span"
                      className="text-sm font-medium text-gray-900"
                      passive
                    >
                      User Status
                    </Switch.Label>
                  </span>
                  <Switch
                    checked={enabled}
                    onChange={setEnabled}
                    className={classNames(
                      enabled ? 'bg-indigo-600' : 'bg-gray-200',
                      'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 toggleButton'
                    )}
                  >
                    <span
                      aria-hidden="true"
                      className={classNames(
                        enabled ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                      )}
                    />
                  </Switch>
                </Switch.Group>
              </div>
                <div className="mt-4 sm:mt-6">
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting
                      ? type === 'add'
                        ? 'Adding...'
                        : 'Saving...'
                      : type === 'add'
                      ? 'Add User'
                      : 'Update User'}
                  </Button>
                </div>
              </div>
            </form>
          </HalfGeneral>
        )}
      </Formik>
    )
  }

  return (
    <Sidebar>
      <ConfirmDialog
        setOpen={setConfirmationDialog}
        open={confirmationDialog}
        onDelete={onDeleteCategory}
      />
      {renderModal()}
      <div className="px-4 pt-2 sm:px-6 lg:px-8 ContainerUI sticky">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-3xl font-semibold text-gray-900">Users</h1>
          </div>
          <div className="sm:mt-0 sm:ml-16 sm:flex-none">
            <Button
              onClick={() =>{
                setEnabled(true)
                setModal((prev) => ({ ...prev, state: true, type: 'add' }))
              }}
              className="flex justify-center items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5 mt-0 mr-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 4.5v15m7.5-7.5h-15"
                />
              </svg>
              Add
            </Button>
          </div>
        </div>
        {loading ? (
          <div className="flex items-center justify-center">
            <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) :
          <>
        <div className="absolute my-5 w-8/12 flex justify-end items-center text-right right-0 mr-2">
      </div>
        {renderTable()}
          <nav
        className="flex flex-col md:flex-row md:items-center md:justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
        aria-label="Pagination"
      >
        <div>
          <p className="text-sm text-gray-700">
            Showing{' '}
            <select
              onChange={(e) => {
                setLimit(e.target.value)
                setPage(0)
              }}
              value={limit}
            >
              <option value={count}>{count}</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
            </select>{' '}
            of <span className="font-medium">{count}</span> results
          </p>
        </div>
        <div className="flex items-center md:justify-end">
          <span
            onClick={goPrev}
            className="relative mr-3 items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 border hover:bg-indigo-800 hover:text-white duration-500 cursor-pointer"
          >
            Previous
          </span>
          <span className="text-sm">
            Page{' '}{page + 1}
            <span className='ml-1'></span>/ {Math.ceil(totalPages)}
          </span>
          <span
            onClick={goNext}
            className="relative ml-3 items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 border hover:bg-indigo-800 hover:text-white duration-500 cursor-pointer"
          >
            Next
          </span>
        </div>
      </nav>
      </>}
      </div>
      <ToastContainer
        closeButton={false}
        closeOnClick={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false} 
        autoClose={2000}
        hideProgressBar={false}/>
    </Sidebar>
  )
}

export default UserPage
