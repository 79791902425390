import React, {useMemo, useState} from 'react'
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  useAsyncDebounce,
  usePagination,
} from 'react-table'

import { Button, PageButton } from './paginationButtons'
import { SortIcon, SortUpIcon, SortDownIcon } from '../../assets/icons/sorting'
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ArrowLongLeftIcon,
  ArrowLongRightIcon
} from '@heroicons/react/24/outline'
import Input from '../input'
import { classNames } from '../../helpers/classname'
import Pagination from "../Pagination/Pagination"

// Define a default UI for filtering

// This is a custom filter UI for selecting
// a unique option from a list
export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id, render },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach((row) => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  return (
    <label className="flex gap-x-2 items-baseline">
      <span className="text-gray-700">{render('Header')}: </span>
      <select
        className="rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        name={id}
        id={id}
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined)
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    </label>
  )
}

// Define a default UI for filtering
function GlobalFilter({
  // preGlobalFilteredRows,
  // globalFilter,
  // setGlobalFilter,
  totalCount,
  setSearchFilter,
}) {
  const [value, setValue] = useState('')
  // const count = preGlobalFilteredRows.length
  // const [value, setValue] = React.useState(globalFilter)
  const onFilterChange = useAsyncDebounce((value) => {
    setSearchFilter(value || '')
  }, 1000)
  return (
    <label className="relative flex searchUI">
      
      <Input
        type="text"
        className="rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 w-100"
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value)
          onFilterChange(e.target.value)
        }}
        placeholder={`${totalCount} records...`}
      >
      </Input>
      <span className="absolute iconAlign">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-6 ml-0 mr-5 iconColoor">
            <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
        </svg>
      </span>
    </label>
  )
}

function Table({ columns, data, totalCount, propPage, pagingData, setSearchFilter, onPaginationChange }) {
  const [currentPage, setCurrentPage] = useState(0)
  const {
    state,
    pageOptions,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    preGlobalFilteredRows,
    setGlobalFilter,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: propPage ? propPage : 0 },
      autoResetGlobalFilter: false,
      // initialState: { pageIndex: 1 },
    },
    // useFilters, // useFilters!
    useGlobalFilter,
    useSortBy,
    // usePagination // new
  )
  
  const pageArray = pageOptions

  // Render the UI for your table
  return (
    <>
      {/* SEARCH UI */}
      <div className="sm:flex sm:gap-x-2 mt-4 searchUI">
        <GlobalFilter
          // preGlobalFilteredRows={preGlobalFilteredRows}
          // globalFilter={state.globalFilter}
          // setGlobalFilter={setGlobalFilter}
          totalCount={totalCount}
          setSearchFilter={setSearchFilter}
        />
        {headerGroups.map((headerGroup) =>
          headerGroup.headers.map((column) =>
            column.Filter ? (
              <div className="mt-2 sm:mt-0" key={column.id}>
                {column.render('Filter')}
              </div>
            ) : null
          )
        )}
      </div>
      {/* TABLE UI */}
      <div className="mt-4 flex flex-col">
        <div className="-my-2 overflow-x-auto -mx-4 sm:-mx-4 lg:-mx-6">
          <div className="align-middle inline-block min-w-full sm:px-6 lg:px-8 tableUI">
            <div className="overflow-hidden border-b border-gray-200 sm:rounded-lg tableShadow">
              <table
                {...getTableProps()}
                className="min-w-full divide-y divide-gray-200"
              >
                <thead className="bg-gray-50">
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        // Add the sorting props to control sorting. For this example
                        // we can add them into the header props
                        <th
                          scope="col"
                          className="group px-4 py-3 text-left text-base font-medium text-gray-900 uppercase tracking-wider"
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          <div className="flex items-center justify-between">
                            {column.render('Header')}
                            {/* Add a sort direction indicator */}
                            <span>
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <SortDownIcon className="w-4 h-4 text-gray-400" />
                                ) : (
                                  <SortUpIcon className="w-4 h-4 text-gray-400" />
                                )
                              ) : (
                                <SortIcon className="w-4 h-4 text-gray-400 opacity-0 group-hover:opacity-100" />
                              )}
                            </span>
                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody
                  {...getTableBodyProps()}
                  className="bg-white divide-y divide-gray-200"
                >
                  {rows.map((row, i) => {
                    // new
                    prepareRow(row)
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td
                              {...cell.getCellProps()}
                              className="px-4 py-3 whitespace-nowrap"
                              role="cell"
                            >
                              {cell.column.Cell.name === 'defaultRenderer' ? (
                                <div className="text-base text-gray-800">
                                  {cell.render('Cell')}
                                </div>
                              ) : (
                                <div className="text-base text-gray-800">
                                {cell.render('Cell')}
                              </div>
                              )}
                            </td>
                          )
                        })}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {/* <nav className="flex items-center justify-between border-t border-gray-200 px-4 pb-6 sm:px-0 pagBackground mt-4">
        <div className="-mt-px flex w-0 flex-1">
          {pageArray.length >1 && 
          <a
            href="#"
            className="inline-flex items-center border-transparent pt-4 pr-1 text-sm font-medium"
          >
            <PageButton
              onClick={() => {
                setCurrentPage((prev) => prev - 1)
                previousPage()
              }}
              className="pagButton"
              disabled={!canPreviousPage}
            >
              <ArrowLongLeftIcon className="mr-3 h-5 w-5" aria-hidden="true" />
              Previous
            </PageButton>
          </a>}
        </div>
        <div className="hidden md:-mt-px pt-4 pl-1 md:flex flex justify-content:center">
          {pageArray.map((item, index) => {
            return (
              <p
                style={{
                  backgroundColor: currentPage === item ? '#3730a3' : '',
                  color: currentPage === item ? 'white' : '',
                  borderRadius: currentPage === item ? '50px' : '',
                }}
                className="inline-flex items-center border-transparent px-4 text-sm font-medium text-gray-500 pagNumber cursor-pointer"
                onClick={() => {
                  setCurrentPage((prev) => (prev = item))
                  gotoPage(item)
                  setPageIndex(item)
                }}
              >
                {item + 1}
              </p>
            )
          })}
        </div>
        <div className="-mt-px flex w-0 flex-1 justify-end">
          {pageArray.length > 1 && <a
            href="#"
            className="inline-flex items-center border-transparent pt-4 pl-1 text-sm font-medium"
          >
            <PageButton
              onClick={() => {
                setCurrentPage((prev) => prev + 1)
                nextPage()
              }}
              disabled={!canNextPage}
              className="pagButton"
            >
              Next
              <ArrowLongRightIcon className="ml-3 h-5 w-5" aria-hidden="true" />
            </PageButton>
          </a>}
        </div>
      </nav> */}
    </>
  )
}

export default Table
