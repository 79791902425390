import { useState, useEffect, useCallback } from 'react'
import HalfGeneral from '../components/half-general-slideover'
import Button from '../components/button'
import app_api from '../config/api'
import Table from '../components/tables/table'
import { student_master_column } from '../components/tables/tableheader'
import { Sidebar } from '../components/navigation/sidebar'
import ConfirmDialog from '../components/dialog/confirmation_dialog'
import { Formik } from 'formik'
import { studentMasterSchema } from '../schema'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Select from 'react-select'
import { Switch } from '@headlessui/react'
import { LuDownload, LuListFilter } from "react-icons/lu";
import * as excelJs from "exceljs";
import { PhotoIcon, XMarkIcon, CheckIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import Modal from '../components/modals'

const initialModalState = {
  type: '',
  state: false,
  index: null,
  edit_id: '',
  data: {
    firstName: '',
    lastName: '',
    classId: '',
    date_of_birth: '',
    status: '',
    username: '',
    passwordType: '',
    password: '',
  },
}

const initialBulkUploadModalState = {
  type: '',
  renderstate: false,
  index: null,
  edit_id: '',
  data: {
    schoolId: '',
    files: '',
  },
}

const passwordTypeOption = [
  {
    id: 'auto',
    name: 'AUTO',
  },
  {
    id: 'manual',
    name: 'MANUAL',
  },
]

const StudentMaster = () => {
  const [modal, setModal] = useState(initialModalState)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [confirmationDialog, setConfirmationDialog] = useState(false)
  const [responseModel, setResponseModal] = useState(false)
  const [responseData, setResponseData] = useState([])
  const [TabData, setTabData] = useState([])
  const [enabled, setEnabled] = useState(true)
  const [bulkUploadModal, setBulkUploadModal] = useState(initialBulkUploadModalState)

  // Options
  const [school, setSchool] = useState([])
  const [classMaster, setClassMaster] = useState([])
  const [grade, setGrade] = useState([])
  const [division, setDivision] = useState([])

  // Pagination Start
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(15)
  const [totalPages, setTotalPages] = useState(1)
  const [count, setCount] = useState(0)
  const [searchFilter, setSearchFilter] = useState('')

  // Filters
  const [isFilterSidebarOpen, setIsFilterSidebarOpen] = useState(false)
  const [filter, setFilter] = useState({
    schoolId: 0,
    gradeId: 0,
    divisionId: 0,
  })
  const [filterData, setFilterData] = useState({
    schoolName: '',
    grade: '',
    division: '',
  })
  const [downloadButtonLoading, setDownloadButtonLoading] = useState(false)
  const handleFilters = () => {
    setFilterData({
      ...filterData,
      loading: true,
    })
    setFilter({
      schoolId: [filterData?.schoolName?.id],
      gradeId: filterData?.grade?.id,
      divisionId: filterData?.division?.id
    })
    setIsFilterSidebarOpen(!isFilterSidebarOpen)
  }
  const handleClearFilters = () => {
    setIsFilterSidebarOpen(!isFilterSidebarOpen)
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const goPrev = () => {
    if (page > 0) setPage((prev) => --prev)
  }

  const goNext = () => {
    if (page < totalPages - 1) setPage((prev) => ++prev)
  }

  const renderTable = useCallback(() => {
    return (
      <>
        <Table
          progPage={page}
          totalCount={count}
          columns={student_master_column({ onEditOpen, onDeleteOpen })}
          data={TabData}
          onEditClose={cleanModalData}
          setSearchFilter={setSearchFilter}
        />
      </>
    )
  }, [TabData])

  useEffect(() => {
    getStudentData()
  }, [page, limit, searchFilter, filter])

  const onDeleteOpen = (id, index) => {
    setModal((prev) => ({ ...prev, id: id, index: index }))
    setConfirmationDialog(true)
  }

  const cleanModalData = () => {
    setModal(initialModalState)
  }

  const onEditOpen = (id, index) => {
    setModal((prev) => ({
      ...prev,
      type: 'edit',
      edit_id: id,
      index: index,
      state: true,
      data: TabData[index],
    }))
    getClassData(TabData[index].schoolId)
    setEnabled(TabData[index].user.status)
  }

  const onDeleteCategory = () => {
    const { id, index } = modal
    app_api
      .delete(`/student-master/${id}`)
      .then((res) => {
        toast.success('Deleted Successfully')
        getStudentData()
        setConfirmationDialog(false)
      })
      .catch((err) => {
        setError(err.toString())
        toast.error(err.response.data.message)
      })
  }

  const getStudentData = () => {
    let url = `student-master/all?page=${page}&size=${limit}&searchFilter=${searchFilter}`
    if (filter?.schoolId && filter.schoolId.length > 0) url = url + filter.schoolId.map(id => `&start=${id}`).join(',')
    if (filter?.gradeId) url = url + `&end=${filter?.gradeId}`
    if (filter?.divisionId) url = url + `&location=${filter?.divisionId}`
    app_api
      .get(url)
      .then((res) => res.data)
      .then((res) => {
        setError(null)
        setFilterData({
          ...filterData,
          loading: false,
        })
        setTabData(res.data.data)
        setCount(res.data.count)
        setTotalPages(res.data.count / limit)
        setLoading(false)
      })
      .catch((err) => {
        setError(err?.response?.data?.message || 'error getting data')
        setFilterData({
          ...filterData,
          loading: false,
        })
      })
  }

  const getSchoolData = () => {
    let url = `school-master/many`
    app_api
      .get(url)
      .then((res) => res.data)
      .then((res) => {
        setError(null)
        setSchool(res.data.data)
        setLoading(false)
      })
      .catch((err) => {
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const getClassData = (id) => {
    let url = `class-master/all/${id}`
    app_api
      .get(url)
      .then((res) => res.data)
      .then((res) => {
        setError(null)
        setClassMaster(res.data.data)
        setLoading(false)
      })
      .catch((err) => {
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  useEffect(() => {
    getSchoolData()
  }, [])


  useEffect(() => {
    JSON.parse(localStorage.getItem('itkKey')).role === 'TEACHER' ? getParticularTeacher() : JSON.parse(localStorage.getItem('itkKey')).role === 'HEAD_PRINCIPAL' && getParticularDataFromPrinciple()
  }, [])

  const getParticularTeacher = () => {
    const userID = JSON.parse(localStorage.getItem('itkKey')).id
    app_api
      .get(`/teacher-master/userid/${userID}`)
      .then((res) => res.data)
      .then((res) => {
        const data = res.data
        getClassData(data.schoolId)
        getDivisionData(data.schoolId)
        getGradeData(data.schoolId)
        setFilter({
          schoolId: [data.schoolId],
        })
      })
      .catch((err) => {
        setError(err.toString())
        toast.error(err.response.data.message)
      })
  }

  const getParticularDataFromPrinciple = () => {
    const userID = JSON.parse(localStorage.getItem('itkKey')).id
    app_api.get(`/school-master/principle/${userID}`)
      .then((res) => res.data)
      .then((res) => {
        const data = res.data.school
        const ids = data.map(e => e.id)
        setFilter({
          schoolId: ids,
        })
      })
      .catch((err) => {
        setError(err.toString())
        toast.error(err.response.data.message)
      })
  }
  const getGradeData = (id) => {
    app_api
      .get(`grade-master/school/${id}`)
      .then((res) => res.data).then((res) => {
        setGrade(res.data)
      }).catch((err) => {
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const downloadSample = async () => {
    const Grade = [classMaster.map((data) => data.grade.grade)]
    const Division = [classMaster.map((data) => data.division.division)]
    const schoolName = classMaster[0].school.name
    const wb = new excelJs.Workbook();
    const ws = wb.addWorksheet("Test Worksheet");
    ws.addRow([
      "FirstName",
      "LastName",
      "Grade",
      "Division",
      "Username",
      "Password",
      "DOB"
    ]);
    ws.columns.map((col, index) => (col.width = 18));
    ws.dataValidations.add("C2:C99999", {
      type: "list",
      allowBlank: false,
      formulae: [`"${Grade.join(",")}"`],
    });
    ws.dataValidations.add("D2:D99999", {
      type: "list",
      allowBlank: false,
      formulae: [`"${Division.join(",")}"`],
    });
    ws.dataValidations.add("G2:G99999", {
      type: 'date',
      operator: 'between',
      formula1: new Date(1900, 0, 1), // Minimum date value (e.g., January 1, 1900)
      formula2: new Date(9999, 11, 31) // Maximum date value (e.g., December 31, 9999)
    });
    const excelBlob = await wb.xlsx.writeBuffer();
    const excelUrl = URL.createObjectURL(
      new Blob([excelBlob], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
    );
    const link = document.createElement("a");
    link.href = excelUrl;
    link.download = "template.xlsx";
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(excelUrl);
    document.body.removeChild(link);
  }

  const getDivisionData = (id) => {
    app_api
      .get(`/division-master/school/${id}`)
      .then((res) => res.data)
      .then((res) => {
        setDivision(res.data)
      })
      .catch((err) => {
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const downloadData = () => {
    setDownloadButtonLoading(true)
    app_api
      .post('student-master/download', filter)
      .then((res) => {
        const link = document.createElement('a')
        link.href = process.env.REACT_APP_STATIC_URL + res.data.data.path
        link.setAttribute('download', "yourfilename.csv")
        document.body.appendChild(link)
        link.click()
        link.remove()
        setDownloadButtonLoading(false)
        toast.success('Downloaded Successfully')
      }).catch((err) => {
        setDownloadButtonLoading(false)
        toast.error('Failed to download')
      })
  }
  const renderModal = () => {
    const { type, state, edit_id, data } = modal
    data.username = data.user?.username
    data.passwordType = data.user?.passwordType
    data.password = data.user?.password
    return (
      <Formik
        initialValues={data}
        validationSchema={studentMasterSchema}
        enableReinitialize
        onSubmit={(values, { setSubmitting, resetForm }) => {
          if (type === 'add') {
            app_api
              .post('student-master', { ...values, status: enabled })
              .then((res) => {
                cleanModalData()
                getStudentData()
                setSubmitting(false)
                resetForm()
                toast.success('Created Successfully')
              })
              .catch((err) => {
                if (err.response.status == 424) {
                  toast.error('Duplicate Entry')
                } else {
                  toast.error('Something Went Wrong')
                }
                cleanModalData()
                setSubmitting(false)
              })
          } else {
            delete values.user
            delete values.class
            delete values.school
            app_api
              .patch(`/student-master/${edit_id}`, {
                ...values,
                status: enabled,
              })
              .then((res) => {
                getStudentData()
                cleanModalData()
                setSubmitting(false)
                resetForm()
                toast.success('Updated Successfully')
              })
              .catch((err) => {
                toast.error(err.response.data.message)
              })
          }
        }}
      >
        {({
          handleBlur,
          Formik,
          handleChange,
          handleSubmit,
          setFieldTouched,
          setValues,
          values,
          touched,
          isValid,
          isSubmitting,
          errors,
        }) => (
          <HalfGeneral
            title={type === 'add' ? 'Add Student' : 'Edit Student'}
            open={state}
            setOpen={() => cleanModalData()}
          >
            <form onSubmit={handleSubmit} noValidate>
              <div className="mt-4 text-left">
                <div className="mt-4"></div>
                <div className="flex">
                  <label className="block text-sm font-medium text-gray-700">
                    First Name
                  </label>
                  <span className="text-red-700 ml-1">*</span>
                </div>
                <input
                  name="firstName"
                  label="Enter Name"
                  value={values.firstName}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="Enter First Name..."
                />
                {touched.firstName && (
                  <p className="text-red-700 error_msg">{errors.firstName}</p>
                )}
                <div className="mt-4"></div>
                <div className="flex">
                  <label className="block text-sm font-medium text-gray-700">
                    Last Name
                  </label>
                  <span className="text-red-700 ml-1">*</span>
                </div>
                <input
                  name="lastName"
                  label="lastName"
                  value={values.lastName}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Enter Last Name..."
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                />
                {touched.lastName && (
                  <p className="text-red-700 error_msg">{errors.lastName}</p>
                )}
                <div className="mt-4"></div>
                <div className="flex">
                  <label className="block text-sm font-medium text-gray-700">
                    Date Of Birth
                  </label>
                  <span className="text-red-700 ml-1">*</span>
                </div>
                <input
                  name="date_of_birth"
                  label="date_of_birth"
                  type="date"
                  value={values.date_of_birth}
                  max={new Date().toISOString().split('T')[0]}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Enter date of birth..."
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                />
                {touched.date_of_birth && (
                  <p className="text-red-700 error_msg">
                    {errors.date_of_birth}
                  </p>
                )}
                <div className="mt-4"></div>
                <div className="grid grid-cols-4 gap-4 flex flex-wrap">
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        School
                      </label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <Select
                      className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="schoolId"
                      id="schoolId"
                      isSearchable
                      loadingMessage="Getting School..."
                      placeholder="Select a school"
                      value={
                        values.schoolId
                          ? school?.map((l) => ({
                            value: school?.find(
                              (e) => e.id == values.schoolId
                            )?.id,
                            label: school?.find(
                              (e) => e.id == values.schoolId
                            )?.name,
                          }))
                          : null
                      }
                      options={school?.map((l) => ({
                        ...l,
                        label: l.name,
                        value: l.id,
                      }))}
                      onChange={(option) => {
                        getClassData(option.id)
                        setValues({
                          ...values,
                          schoolId: option.value,
                        })
                      }}
                      onBlur={handleBlur}
                    />
                    {touched.schoolId && (
                      <p className="text-red-700 error_msg">
                        {errors.schoolId}
                      </p>
                    )}
                  </div>
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Class
                      </label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <Select
                      className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="classId"
                      id="classId"
                      isSearchable
                      isDisabled={values.schoolId == null}
                      loadingMessage="Getting Class..."
                      placeholder="Select a class"
                      value={
                        values.classId
                          ? classMaster?.map((l) => ({
                            value: classMaster?.find(
                              (e) => e.id == values.classId
                            )?.id,
                            label: classMaster?.find(
                              (e) => e.id == values.classId
                            )?.grade.grade + " " + classMaster?.find(
                              (e) => e.id == values.classId
                            )?.division.division,
                          }))
                          : null
                      }
                      options={classMaster?.map((l) => ({
                        ...l,
                        label: l.grade.grade + " " + l.division.division,
                        value: l.id,
                      }))}
                      onChange={(option) => {
                        setValues({
                          ...values,
                          classId: option.value,
                        })
                      }}
                      onBlur={handleBlur}
                    />
                    {touched.classId && (
                      <p className="text-red-700 mb-1 error_msg">
                        {errors.classId}
                      </p>
                    )}
                  </div>
                </div>
                <div className="mt-4"></div>
                <div className="grid grid-cols-4 gap-4 flex flex-wrap">
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Username
                      </label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <input
                      name="username"
                      label="Enter Name"
                      value={values.username}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      placeholder="Enter Username..."
                    />
                    {touched.username && (
                      <p className="text-red-700 error_msg">
                        {errors.username}
                      </p>
                    )}
                  </div>
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Password Type
                      </label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <Select
                      className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="passwordType"
                      id="passwordType"
                      isSearchable
                      loadingMessage="Getting type..."
                      placeholder="Select a type"
                      value={
                        values.passwordType
                          ? passwordTypeOption?.map((l) => ({
                            value: passwordTypeOption?.find(
                              (e) => e.id == values.passwordType
                            )?.id,
                            label: passwordTypeOption?.find(
                              (e) => e.id == values.passwordType
                            )?.name,
                          }))
                          : null
                      }
                      options={passwordTypeOption?.map((l) => ({
                        ...l,
                        label: l.name,
                        value: l.id,
                      }))}
                      onChange={(option) => {
                        let random_pass
                        if (!values.passwordType) {
                          if (option.value == 'auto') random_pass = values.firstName.toLowerCase() + Math.floor(Math.random() * 90 + 10);
                          else random_pass = ''
                          setValues({
                            ...values,
                            passwordType: option.value,
                            password: random_pass
                          })
                        }
                        else {
                          setValues({
                            ...values,
                            passwordType: option.value,
                          })
                        }
                      }}
                      onBlur={handleBlur}
                    />
                    {touched.passwordType && (
                      <p className="text-red-700 mb-1 error_msg">
                        {errors.passwordType}
                      </p>
                    )}
                  </div>
                </div>
                <div className="mt-4"></div>
                {values.passwordType != null && (
                  <>
                    <div className="grid grid-cols-4 gap-4 flex flex-wrap">
                      <div className="col-span-2">
                      </div>
                      <div className="col-span-2">
                        <input
                          name="password"
                          type="text"
                          value={values.password}
                          min={4}
                          disabled={values.passwordType == 'auto'}
                          autoComplete="off"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder="Enter Password..."
                          className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        />
                        {touched.password && (
                          <p className="text-red-700 mb-1 error_msg">
                            {errors.password}
                          </p>
                        )}
                      </div>
                    </div>
                  </>
                )
                }
                <div className="mt-4">
                  <Switch.Group
                    as="div"
                    className="flex items-center justify-"
                  >
                    <span className="flex flex-grow flex-col">
                      <Switch.Label
                        as="span"
                        className="text-sm font-medium text-gray-900"
                        passive
                      >
                        Student Status
                      </Switch.Label>
                    </span>
                    <Switch
                      checked={enabled}
                      onChange={setEnabled}
                      className={classNames(
                        enabled ? 'bg-indigo-600' : 'bg-gray-200',
                        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 toggleButton'
                      )}
                    >
                      <span
                        aria-hidden="true"
                        className={classNames(
                          enabled ? 'translate-x-5' : 'translate-x-0',
                          'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                        )}
                      />
                    </Switch>
                  </Switch.Group>
                </div>
                <div className="mt-4 sm:mt-6">
                  <Button type="submit" disabled={isSubmitting || JSON.parse(localStorage.getItem('itkKey')).role === 'HEAD_PRINCIPAL'}>
                    {isSubmitting
                      ? type === 'add'
                        ? 'Adding...'
                        : 'Saving...'
                      : type === 'add'
                        ? 'Add Student...'
                        : 'Update Student'}
                  </Button>
                </div>
              </div>
            </form>
          </HalfGeneral>
        )}
      </Formik>
    )
  }

  const renderBulkUploadModal = () => {
    const { type, renderstate, edit_id, data } = bulkUploadModal
    return (
      <Formik
        initialValues={data}
        validationSchema={null}
        enableReinitialize
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true)
          let fd = new FormData()
          fd.append('file', values.files)
          const headers = {
            'Content-Type': 'multipart/form-data',
          }
          fd.append('schoolId', values.schoolId ? values.schoolId : classMaster[0].schoolId)
          app_api
            .post('student-master/bulk-upload', fd, { headers })
            .then((res) => {
              if (res) {
                setSubmitting(false)
                setResponseData(res.data.data)
                setBulkUploadModal(initialBulkUploadModalState)
                setResponseModal(true)
                getStudentData()
              }
            })
            .catch((err) => {
              setSubmitting(false)
              toast.error("Failed to upload document")
            })
        }}
      >
        {({
          handleBlur,
          Formik,
          handleChange,
          handleSubmit,
          setFieldTouched,
          setValues,
          values,
          touched,
          isValid,
          isSubmitting,
          errors,
        }) => (
          <Modal
            title={'Bulk Upload'}
            open={renderstate}
            setOpen={() => setBulkUploadModal(initialBulkUploadModalState)}
          >
            <form onSubmit={handleSubmit} noValidate>

              <div className="flex flex-col text-left">
                {JSON.parse(localStorage.getItem('itkKey')).role === 'ADMIN' && (
                  <div className='mt-4 w-full'>
                    <div className="flex ">
                      <label className="block text-sm font-medium text-gray-700">
                        Select Class
                      </label>
                      <span className="text-red-500 mx-1">*</span>
                    </div>
                    <Select
                      className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="serviceId"
                      id="serviceId"
                      isSearchable
                      placeholder="Select service"
                      value={
                        values.schoolId
                          ? school?.map((l) => ({
                            value: school?.find(
                              (e) => e.id == values.schoolId
                            )?.id,
                            label: school?.find(
                              (e) => e.id == values.schoolId
                            )?.name,
                          }))
                          : null
                      }
                      options={school?.map((l) => ({
                        ...l,
                        label: l.name,
                        value: l.id,
                      }))}
                      onChange={(option) => {
                        getClassData(option.id)
                        setValues({
                          ...values,
                          schoolId: option.value,
                        })
                      }}
                      onBlur={handleBlur}
                    />
                  </div>
                )}
                {(JSON.parse(localStorage.getItem('itkKey')).role === 'ADMIN' && values.schoolId != '') && (
                  <>
                    <div className="flex justify-center mb-2 mt-4 w-full">
                      <div className="w-full bg-gray-50 p-6 rounded-md shadow-md">
                        <h2 className="text-2xl font-bold text-gray-900 flex justify-center mt-2">Uploads</h2>
                        <div
                          onDragOver={(e) => e.preventDefault()}
                          onDrop={(e) => {
                            e.preventDefault()
                            const data = e.target.files[0]
                            setValues({
                              ...values,
                              files: data,
                            })
                          }}
                          className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10"
                        >
                          <div className="text-center">
                            <PhotoIcon
                              className="mx-auto h-12 w-12 text-gray-300"
                              aria-hidden="true"
                            />
                            <div className="mt-4 flex text-sm leading-6 text-primary">
                              <label
                                htmlFor="fileName"
                                className="relative flex cursor-pointer rounded-md font-semibold text-focus:ring-primary focus-within:outline-none focus-within:ring-2 focus-within:ring-focus:ring-primary focus-within:ring-offset-2 hover:text-primary-300"
                              >
                                <span>Upload a file</span>
                                <input
                                  id="fileName"
                                  name="fileName"
                                  type="file"
                                  className="sr-only"
                                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                  onChange={(e) => {
                                    const data = e.target.files[0]
                                    setValues({
                                      ...values,
                                      files: data,
                                    })
                                  }}
                                />
                                <p className="pl-1">or drag and drop</p>
                              </label>
                            </div>
                            <p className="text-xs leading-5 flex w-full items-center justify-center gap-3 text-gray-600">

                              {values.files ?
                                <p><span className="flex gap-2"> <p>{values.files.name}</p>
                                  <button type='button'
                                    onClick={() => {
                                      setValues({
                                        ...values,
                                        files: ''
                                      })
                                    }}
                                    className="cursor-pointer text-red-500 px-1 py-1 hover:bg-red-800 hover:rounded-md hover:text-white disabled:text-gray-700 disabled:hover:bg-gray-300 hover:border-slate-300 hover:border-solid"
                                  ><XMarkIcon className="w-4" />
                                  </button>
                                </span></p>
                                : "Excel"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 sm:mt-6">
                      <Button type="submit" disabled={isSubmitting}>
                        Upload
                      </Button>
                      <p className='flex justify-end' > <span className='cursor-pointer text-[#04ada3] mt-1' onClick={() => downloadSample()}>Download Sample</span></p>
                    </div>
                  </>
                )}
                {JSON.parse(localStorage.getItem('itkKey')).role != 'ADMIN' && (
                  <>
                    <div className="flex justify-center mb-2 mt-4 w-full">
                      <div className="w-full bg-gray-50 p-6 rounded-md shadow-md">
                        <h2 className="text-2xl font-bold text-gray-900 flex justify-center mt-2">Uploads</h2>
                        <div
                          onDragOver={(e) => e.preventDefault()}
                          onDrop={(e) => {
                            e.preventDefault()
                            const data = e.target.files[0]
                            setValues({
                              ...values,
                              files: data,
                            })
                          }}
                          className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10"
                        >
                          <div className="text-center">
                            <PhotoIcon
                              className="mx-auto h-12 w-12 text-gray-300"
                              aria-hidden="true"
                            />
                            <div className="mt-4 flex text-sm leading-6 text-primary">
                              <label
                                htmlFor="fileName"
                                className="relative flex cursor-pointer rounded-md font-semibold text-focus:ring-primary focus-within:outline-none focus-within:ring-2 focus-within:ring-focus:ring-primary focus-within:ring-offset-2 hover:text-primary-300"
                              >
                                <span>Upload a file</span>
                                <input
                                  id="fileName"
                                  name="fileName"
                                  type="file"
                                  className="sr-only"
                                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                  onChange={(e) => {
                                    const data = e.target.files[0]
                                    setValues({
                                      ...values,
                                      files: data,
                                    })
                                  }}
                                />
                                <p className="pl-1">or drag and drop</p>
                              </label>
                            </div>
                            <p className="text-xs leading-5 flex w-full items-center justify-center gap-3 text-gray-600">

                              {values.files ?
                                <p><span className="flex gap-2"> <p>{values.files.name}</p>
                                  <button type='button'
                                    onClick={() => {
                                      setValues({
                                        ...values,
                                        files: ''
                                      })
                                    }}
                                    className="cursor-pointer text-red-500 px-1 py-1 hover:bg-red-800 hover:rounded-md hover:text-white disabled:text-gray-700 disabled:hover:bg-gray-300 hover:border-slate-300 hover:border-solid"
                                  ><XMarkIcon className="w-4" />
                                  </button>
                                </span></p>
                                : "Excel"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 sm:mt-6">
                      <Button type="submit" disabled={isSubmitting}>
                        Upload
                      </Button>
                      <p className='flex justify-end' > <span className='cursor-pointer text-[#04ada3] mt-1' onClick={() => downloadSample()}>Download Sample</span></p>
                    </div>
                  </>
                )}
              </div>
            </form>
          </Modal>
        )}
      </Formik>
    )
  }

  const renderResponseModal = () => {
    return (
      <Modal
        title={''}
        open={responseModel}
        setOpen={() => setResponseModal(false)}
      >

        <div className="flex flex-col">
          {responseData.notUploaded?.length > 0 ? (<>
            <div>
              <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
                <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
              </div>
              <div className="mt-3 text-center sm:mt-5">
                <h3 className="text-base font-semibold leading-6 text-gray-900">
                  List of Student which failed to enrol
                </h3>
                <div className="mt-2">
                  {responseData.notUploaded.map(e => {
                    return (
                      <>
                        <div className='flex flex-col text-left'>
                          <p><b>Name: </b>{e.FirstName} {e?.LastName}</p>
                          <p><b>Username: </b>{e.Username}</p>
                          <p><b>Reason: </b>{e.reason}</p>
                          <hr className='mb-2'></hr>
                        </div>
                      </>
                    )
                  })}
                </div>
              </div>
            </div>
          </>) : (<>
            <div>
              <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
              </div>
              <div className="mt-3 text-center sm:mt-5">
                <h3 className="text-base font-semibold leading-6 text-gray-900">
                  Bulk uploaded successful
                </h3>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur amet labore.
                  </p>
                </div>
              </div>
            </div>
          </>)}
          <div className="mt-5 sm:mt-6">
            <button
              type="button"
              className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={() => setResponseModal(false)}
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
    )
  }

  return (
    <Sidebar>
      <ConfirmDialog
        setOpen={setConfirmationDialog}
        open={confirmationDialog}
        onDelete={onDeleteCategory}
      />
      <>
        {renderModal()}
        {renderResponseModal()}
        {renderBulkUploadModal()}
        <div className="px-4 pt-2 sm:px-6 lg:px-8 ContainerUI sticky min-h-screen">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-3xl font-semibold text-gray-900">
                Students
              </h1>
            </div>
            {JSON.parse(localStorage.getItem('itkKey')).role !== 'HEAD_PRINCIPAL' &&
              <div className='flex gap-4'>
                <div className="sm:mt-0 sm:ml-16 sm:flex-none">
                  <Button
                    onClick={() => {
                      setEnabled(true)
                      setModal((prev) => ({ ...prev, state: true, type: 'add' }))
                    }}
                    className="flex justify-center items-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5 mt-0 mr-2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                    Add
                  </Button>
                </div>
                <div className="sm:mt-0 sm:flex-none">
                  <Button
                    onClick={() => {
                      setBulkUploadModal((prev) => ({ ...prev, renderstate: true, type: 'add' }))
                    }}
                    className="flex justify-center items-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5 mt-0 mr-2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                    Bulk Upload
                  </Button>
                </div>
              </div>
            }
          </div>
          {loading ? (
            <div className="flex items-center justify-center">
              <div
                className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <>
              <div className="relative mx-2">
                <div className="w-full flex md:mt-6 mt-16 absolute justify-between gap-2">
                  <div></div>
                  <div className="flex justify-between gap-4 ">
                    <div
                      onClick={() => setIsFilterSidebarOpen(!isFilterSidebarOpen)}
                      className="rounded-full py-2 px-2 cursor-pointer border shadow-sm bg-[#e42b35] text-white"
                    >
                      {filterData.loading ? (
                        <div className="flex items-center justify-center">
                          <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full" role="status">
                          </div>
                        </div>) : <LuListFilter className="w-6 h-6" />}
                    </div>
                    <div
                      className="rounded-full py-2 px-2 cursor-pointer border shadow-sm bg-[#e42b35] text-white"
                      onClick={() => {
                        downloadData();
                      }}
                    >
                      {downloadButtonLoading ? (
                        <div className="flex items-center justify-center">
                          <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full" role="status">
                          </div>
                        </div>) : <LuDownload className="w-6 h-6" />}
                    </div>
                  </div>
                </div>

                {/* Filter sidebar */}
                {isFilterSidebarOpen && (
                  <div className='h-full'>
                    <div className={`absolute bg-white border rounded-lg shadow-lg w-full  md:w-1/2 lg:w-1/3 md:top-24 md:right-0 top-28 z-20`}>
                      <div className='flex justify-between p-2'>
                        <span className=" text-sm text-gray-700 ">Filters</span>
                        {/* <span className='text-sm text-[#04ADA3] cursor-pointer' onClick={() => {
                          setFilterData({
                            schoolName: '',
                            grade: '',
                            division: '',
                            loading: false
                          })
                          handleClearFilters()
                        }}> Reset All </span> */}
                      </div>
                      {JSON.parse(localStorage.getItem('itkKey')).role !== 'TEACHER' && <>
                        <hr className='' />
                        <div className="w-full p-2">
                          <div className='flex justify-between mb-2'>
                            <span className=" text-sm text-gray-700 ">School</span>
                            <span className='text-sm text-[#04ADA3] cursor-pointer' onClick={() => {
                              setFilterData({
                                ...filterData,
                                schoolName: '',
                              })
                            }}> Reset </span>
                          </div>
                          <Select
                            className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            name="schoolName"
                            id="schoolName"
                            isSearchable
                            loadingMessage="Getting School Name..."
                            placeholder="School Name"
                            isClearable={true}
                            // isMulti
                            options={school?.map((l) => ({
                              ...l,
                              label: l.name,
                              value: l.id,
                            }))}
                            value={filterData.schoolName}
                            onChange={(option) => {
                              setFilterData({
                                ...filterData,
                                schoolName: option,
                              })
                              getDivisionData(option?.value)
                              getGradeData(option?.value)
                            }}
                          />
                        </div>
                      </>}
                      <hr className='' />
                      <div className="w-full p-2">
                        <div className='flex justify-between mb-2'>
                          <span className=" text-sm text-gray-700 ">Grade</span>
                          <span className='text-sm text-[#04ADA3] cursor-pointer' onClick={() => {
                            setFilterData({
                              ...filterData,
                              grade: '',
                            })
                          }}> Reset </span>
                        </div>
                        <Select
                          className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          name="grade"
                          id="grade"
                          isSearchable
                          loadingMessage="Getting Grade..."
                          placeholder="Grade"
                          isClearable={true}
                          options={grade?.map((l) => ({
                            ...l,
                            label: l.grade,
                            value: l.id,
                          }))}
                          onChange={(option) => {
                            setFilterData({
                              ...filterData,
                              grade: option,
                            })
                          }}
                          value={filterData.grade}
                        />
                      </div>
                      <hr className='' />
                      <div className="w-full p-2">
                        <div className='flex justify-between mb-2'>
                          <span className=" text-sm text-gray-700 ">Division</span>
                          <span className='text-sm text-[#04ADA3] cursor-pointer' onClick={() => {
                            setFilterData({
                              ...filterData,
                              division: '',
                            })
                          }}> Reset </span>
                        </div>
                        <Select
                          className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          name="division"
                          id="division"
                          isSearchable
                          loadingMessage="Getting Division..."
                          placeholder="Division"
                          isClearable={true}
                          options={division?.map((l) => ({
                            ...l,
                            label: l.division,
                            value: l.id,
                          }))}
                          onChange={(option) => {
                            setFilterData({
                              ...filterData,
                              division: option,
                            })
                          }}
                          value={filterData.division}
                        />
                      </div>
                      <hr className='' />
                      <div className="flex justify-between gap-2 p-2">
                        <div className="">
                          <Button
                            type="button"
                            className={'bg-red-500 hover:bg-red-700'}
                            onClick={() => {
                              setFilterData({
                                startDate: null,
                                endDate: null,
                                status: '',
                                grade: '',
                                location: '',
                                loading: false
                              })
                              handleClearFilters()
                            }}
                          >
                            Reset All
                          </Button>
                        </div>
                        <div className="">
                          <Button
                            type="button"
                            onClick={() => {
                              handleFilters()
                            }}
                          >
                            Apply Now
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {renderTable()}
              <nav
                className="flex flex-col md:flex-row md:items-center md:justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
                aria-label="Pagination"
              >
                <div>
                  <p className="text-sm text-gray-700">
                    Showing{' '}
                    <select
                      onChange={(e) => {
                        setLimit(e.target.value)
                        setPage(0)
                      }}
                      value={limit}
                    >
                      <option value="10">10</option>
                      <option value="15">15</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="200">200</option>
                    </select>{' '}
                    of <span className="font-medium">{count}</span> results
                  </p>
                </div>
                <div className="flex items-center md:justify-end">
                  <span
                    onClick={goPrev}
                    className="relative mr-3 items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 border hover:bg-indigo-800 hover:text-white duration-500 cursor-pointer"
                  >
                    Previous
                  </span>
                  <span className="text-sm">
                    Page {page + 1}
                    <span className="ml-1"></span>/ {Math.ceil(totalPages)}
                  </span>
                  <span
                    onClick={goNext}
                    className="relative ml-3 items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 border hover:bg-indigo-800 hover:text-white duration-500 cursor-pointer"
                  >
                    Next
                  </span>
                </div>
              </nav>
            </>
          )}
        </div>
      </>
      <ToastContainer
        closeButton={false}
        closeOnClick={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
        autoClose={2000}
        hideProgressBar={false}
      />
    </Sidebar>
  )
}

export default StudentMaster
