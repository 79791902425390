import Button from '../button'
import { Formik } from 'formik'
import { loginSchema } from '../../schema'
import logo from '../../assets/icons/logo.png'
import loginBg from '../../assets/icons/Dashboard.jpg'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { forgotPasswordSchema } from '../../schema'
import Modal from '../modals'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { EyeIcon } from '@heroicons/react/24/outline'
import { EyeSlashIcon } from '@heroicons/react/24/solid'

const initialModalState = {
  type: '',
  state: false,
  index: null,
  edit_id: '',
  data: {
    forgotEmail: '',
  },
}

const Login = ({
  loading,
  loader,
  statusCode,
  forgotError,
  error,
  login,
  forgotPasssword,
  success,
}) => {
  const navigate = useNavigate()
  const [key, setKey] = useState(localStorage.getItem('itkKey'))
  const [modal, setModal] = useState(initialModalState)
  const [showpass, setShowpass] = useState(false)

  useEffect(() => {
    localStorage.getItem('itkKey') && navigate('/')
  }, [key])

  const cleanModalData = () => {
    setModal(initialModalState)
  }

  const renderModal = () => {
    const { type, state, data } = modal
    const { forgotEmail } = data
    return (
      <Formik
        initialValues={{ forgotEmail: '' }}
        validationSchema={forgotPasswordSchema}
        enableReinitialize
      >
        {({
          handleBlur,
          handleChange,
          handleSubmit,
          setValues,
          values,
          touched,
          isValid,
          isSubmitting,
          errors,
        }) => (
          <Modal
            title="Forgot Password"
            open={state}
            setOpen={() => {
              setModal((prev) => ({ ...prev, state: false }))
              cleanModalData()
            }}
          >
            <form noValidate>
              <div className="text-left mt-4">
                <div className="mb-4">
                  {forgotError !== null && (
                    <p className="text-red-700 error_msg error_text">
                      {forgotError}
                    </p>
                  )}
                </div>
                <div className="mb-4">
                  {success !== null && <p className="success_msg">{success}</p>}
                </div>
                <div>
                  <label
                    htmlFor="forgotEmail"
                    className="block text-sm font-medium text-gray-700 loginFontSize"
                  >
                    Email
                  </label>
                  <div className="mt-1">
                    <input
                      id="forgotEmail"
                      placeholder="Enter your Email"
                      name="forgotEmail"
                      type="forgotEmail"
                      autoComplete
                      required={true}
                      value={values.forgotEmail}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm inputSize"
                    />
                    {touched.forgotEmail && (
                      <p className="text-red-700 error_msg">
                        {errors.forgotEmail}
                      </p>
                    )}
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <Button
                    disabled={isSubmitting}
                    loading={loader}
                    onClick={() => {
                      forgotPasssword(values)
                    }}
                  >
                    Request
                  </Button>
                </div>
              </div>
            </form>
          </Modal>
        )}
      </Formik>
    )
  }
  return (
    <>
      {renderModal()}
      <div className="relative flex min-h-screen text-left">
        <div className="flex flex-col justify-center flex-1 w-1/2 px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="absolute top-8 left-[28%] md:left-8">
            <img className="w-auto h-20" src={logo} alt="Your Company" />
          </div>
          <div className="w-full max-w-sm mx-auto">
            <div>
              <h2 className="text-3xl font-medium tracking-tight text-gray-900">
                Log in
              </h2>
              <p className="my-2 text-sm font-normal text-gray-500">
                Welcome back! Please enter your details
              </p>
            </div>

            <div className="mt-8">
              {error !== null && (
                <p className="text-md text-red-700 error_msg">{error}</p>
              )}
              <div className="mt-6">
                <Formik
                  initialValues={{ username: '', password: '' }}
                  validationSchema={loginSchema}
                  onSubmit={login}
                >
                  {({
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    values,
                    errors,
                    touched,
                    isValid,
                  }) => (
                    <form
                      className="space-y-5"
                      onSubmit={handleSubmit}
                      noValidate={true}
                    >
                      <div>
                        <label
                          htmlFor="username"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Username
                        </label>
                        <div className="mt-1">
                          <input
                            id="username"
                            placeholder="Username"
                            name="username"
                            type="username"
                            autoComplete
                            required={true}
                            value={values.username}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          />
                          {touched.username && (
                            <p className="text-xs text-red-700 ">
                              {errors.username}
                            </p>
                          )}
                        </div>
                      </div>
                      <div>
                        <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                          Password
                        </label>
                        <div className="relative mt-1">
                          <input
                            id="password"
                            placeholder="Password"
                            name="password"
                            type={!showpass? "password" : "text"}
                            autoComplete
                            required={true}
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          />
                          <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                            {!showpass && values.password? <EyeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" onClick={()=>{setShowpass(!showpass)}}/> :
                            <EyeSlashIcon className="h-5 w-5 text-gray-400" aria-hidden="true" onClick={()=>{setShowpass(!showpass)}}/>}
                          </div>
                        </div>
                      </div>

                      <div className="">
                        <Button
                          type="submit"
                          className=" bg-primary"
                          loading={loading}
                        >
                          Sign In
                        </Button>
                      </div>
                    </form>
                  )}
                </Formik>
                <div>
                  {/* <p className="text-xs font-semibold inline-flex mt-2 text-red-600 cursor-pointer hover:bg-red-600 hover:text-white duration-500 px-1 py-1 rounded-md">
                    Forgot Password
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="relative hidden w-1/2 text-center md:flex-1 lg:block bg-gray-100">
          <div className="absolute top-0 bottom-0 right-0 flex items-center">
            <img className="w-full h-full py-10" src={loginBg} alt="" />
          </div>
        </div>
      </div>
      <ToastContainer
        closeButton={false}
        closeOnClick={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
        autoClose={2000}
        hideProgressBar={false} />
    </>
  )
}

export default Login
