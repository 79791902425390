import React, { useEffect, useState } from 'react'
import app_api from '../config/api'
import Login from '../components/auth/login'
import { useNavigate } from 'react-router-dom'

const Auth = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [loader, setLoader] = useState(false)
  const [forgotError, setForgotError] = useState(null)
  const [error, setError] = useState(null)
  const [statusCode, setStatusCode] = useState(0)
  const [mfaMesage, setMFAMesage] = useState(null)
  const [authDataURL, setAuthDataURL] = useState()
  const [success, setSuccess] = useState(null)

  const login = (values) => {
    setLoading(true)
    app_api
      .post('/auth/login', values)
      .then((res) => {
        setLoading(false)
        setError(null)
        if (res.status == 202 || res.status == 201) {
          localStorage.setItem('itkKey', JSON.stringify(res.data.data))
          if (res.data.data.role == 'ADMIN') {
            navigate('/users')
          } else if (res.data.data.role == 'TEACHER') {
            navigate('/student-master')
          } else if (res.data.data.role == 'HEAD_PRINCIPAL') {
            navigate('/student-master')
          } else {
            setError('Students cannot login just yet')
          }
        }
      })
      .catch((err) => {
        setLoading(false)
        setError(
          err?.response?.data?.message || 'Please enter your correct password'
        )
      })
  }

  const forgotPasssword = (email) => {
    setLoader(true)
    app_api
      .post('/forgot-password', { email: email.forgotEmail })
      .then((res) => {
        setLoader(false)
        setForgotError(null)
        setSuccess('An email has been sent to the registered email address')
      })
      .catch((err) => {
        setLoader(false)
        setForgotError(
          err?.response?.data?.message || 'Please check your email address'
        )
      })
  }

  const genORCode = (values) => {
    console.log(values)
  }

  return (
    <div>
      <Login
        loading={loading}
        loader={loader}
        authDataURL={authDataURL}
        mfaMesage={mfaMesage}
        statusCode={statusCode}
        forgotError={forgotError}
        error={error}
        login={login}
        genORCode={genORCode}
        forgotPasssword={forgotPasssword}
        success={success}
      />
    </div>
  )
}

export default Auth
