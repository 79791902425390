import { useState, useEffect, useCallback } from 'react'
import HalfGeneral from '../components/half-general-slideover'
import Button from '../components/button'
import app_api from '../config/api'
import Table from '../components/tables/table'
import {
  grade_column
} from '../components/tables/tableheader'
import { Sidebar } from '../components/navigation/sidebar'
import ConfirmDialog from '../components/dialog/confirmation_dialog'
import { Formik } from 'formik'
import {
    gradeSchema
} from '../schema'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Select from 'react-select'

const initialModalState = {
  type: '',
  state: false,
  index: null,
  edit_id: '',
  data: {
    grade: '',
  },
}

const GradeMaster = () => {
  const [modal, setModal] = useState(initialModalState)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [school, setSchool] = useState([])
  const [confirmationDialog, setConfirmationDialog] = useState(false)
  const [TabData, setTabData] = useState([])
  const [enabled, setEnabled] = useState(true)
  const [itkKey, setItkKey] = useState(JSON.parse(localStorage.getItem('itkKey')))

  // Pagination Start
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(15)
  const [totalPages, setTotalPages] = useState(1)
  const [count, setCount] = useState(0)
  const [searchFilter, setSearchFilter] = useState('')
  const [schoolId, setSchoolId] = useState()

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const goPrev = () => {
    if (page > 0) setPage((prev) => --prev)
  }

  const goNext = () => {
    if (page < totalPages - 1) setPage((prev) => ++prev)
  }

  const renderTable = useCallback(() => {
    return (
      <>
        <Table
          progPage={page}
          totalCount={count}
          columns={grade_column({ onEditOpen, onDeleteOpen})}
          data={TabData}
          onEditClose={cleanModalData}
          setSearchFilter={setSearchFilter}
        />
      </>
    )
  }, [TabData])

  useEffect(() => {
    getGradeData()
  }, [page, limit, searchFilter])

  const onDeleteOpen = (id, index) => {
    setModal((prev) => ({ ...prev, id: id, index: index }))
    setConfirmationDialog(true)
  }

  const cleanModalData = () => {
    setModal(initialModalState)
  }

  const onEditOpen = (id, index) => {
    setModal((prev) => ({
      ...prev,
      type: 'edit',
      edit_id: id,
      index: index,
      state: true,
      data: TabData[index],
    }))
    setEnabled(TabData[index].status)
  }

  const onDeleteCategory = () => {
    const { id, index } = modal
    app_api
      .delete(`/grade-master/${id}`)
      .then((res) => {
        toast.success('Deleted Successfully')
        getGradeData()
        setConfirmationDialog(false)
      })
      .catch((err) => {
        setError(err.toString())
        toast.error(err.response.data.message)
      })
  }

  const getGradeData = () => {
    let url
    if(itkKey.role == 'TEACHER')
    url = `grade-master/all?page=${page}&size=${limit}&searchFilter=${searchFilter}&schoolId=${itkKey.schoolId}`
    else
    url = `grade-master/all?page=${page}&size=${limit}&searchFilter=${searchFilter}`
    app_api
      .get(url)
      .then((res) => res.data)
      .then((res) => {
        setError(null)
        setTabData(res.data.data)
        setCount(res.data.count)
        setTotalPages(res.data.count / limit)
        setLoading(false)
      })
      .catch((err) => {
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const getSchoolData = () => {
    let url = `school-master/many`
    app_api
      .get(url)
      .then((res) => res.data)
      .then((res) => {
        if(itkKey.role == 'TEACHER')
        {
          let data = res.data.data.filter(e => e.id == itkKey.schoolId)
          setSchool(data)
          setLoading(false)
        }
        else
        {
          setSchool(res.data.data)
          setLoading(false)
        }
        setError(null)
      })
      .catch((err) => {
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  useEffect(() => {
    getGradeData()
    getSchoolData()
  }, [])

  const renderModal = () => {
    const { type, state, edit_id, data } = modal
    return (
      <Formik
        initialValues={data}
        validationSchema={gradeSchema}
        enableReinitialize
        onSubmit={(values, { setSubmitting, resetForm }) => {
          if (type === 'add') {
            app_api
              .post('grade-master', { ...values })
              .then((res) => {
                cleanModalData()
                getGradeData()
                setSubmitting(false)
                resetForm()
                toast.success('Created Successfully')
              })
              .catch((err) => {
                if (err.response.status == 424) {
                  toast.error('Duplicate Entry')
                } else {
                  toast.error('Something Went Wrong')
                }
                cleanModalData()
                setSubmitting(false)
              })
          } else {
            delete values.Schoolusers
            delete values.school
            app_api
              .patch(`/grade-master/${edit_id}`, {
                ...values,
                status: enabled,
              })
              .then((res) => {
                getGradeData()
                cleanModalData()
                setSubmitting(false)
                resetForm()
                toast.success('Updated Successfully')
              })
              .catch((err) => {
                toast.error(err.response.data.message)
              })
          }
        }}
      >
        {({
          handleBlur,
          Formik,
          handleChange,
          handleSubmit,
          setFieldTouched,
          setValues,
          values,
          touched,
          isValid,
          isSubmitting,
          errors,
        }) => (
          <HalfGeneral
            title={type === 'add' ? 'Add Grade' : 'Edit Grade'}
            open={state}
            setOpen={() => cleanModalData()}
          >
            <form onSubmit={handleSubmit} noValidate>
              <div className="mt-4 text-left">
                <div className="mt-4"></div>
                <div className="flex">
                  <label className="block text-sm font-medium text-gray-700">
                    Grade
                  </label>
                  <span className="text-red-700 ml-1">*</span>
                </div>
                <input
                  name="grade"
                  label="Enter Grade"
                  value={values.grade}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="Enter Grade Name..."
                />
                {touched.grade && (
                  <p className="text-red-700 error_msg">{errors.grade}</p>
                )}
                <div className="col-span-2 mt-4">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        School
                      </label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <Select
                      className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="schoolId"
                      id="schoolId"
                      isSearchable
                      loadingMessage="Getting School..."
                      placeholder="Select School"
                      value={
                        values.schoolId
                          ? {
                              value: school?.find(
                                (e) => e.id == values.schoolId
                              )?.id,
                              label: school?.find(
                                (e) => e.id == values.schoolId
                              )?.name,
                            }
                          : null
                      }
                      options={school?.map((l) => ({
                        ...l,
                        label: l.name,
                        value: l.id,
                      }))}
                      onChange={(option) => {
                        setValues({
                          ...values,
                          schoolId: option.value,
                        })
                      }}
                      onBlur={handleBlur}
                    />
                    {touched.schoolId && (
                      <p className="text-red-700 error_msg">{errors.schoolId}</p>
                    )}
                  </div>
                <div className="mt-4 sm:mt-6">
                  <Button type="submit" disabled={isSubmitting}>
                    {isSubmitting
                      ? type === 'add'
                        ? 'Adding...'
                        : 'Saving...'
                      : type === 'add'
                      ? 'Add Grade'
                      : 'Update Grade'}
                  </Button>
                </div>
              </div>
            </form>
          </HalfGeneral>
        )}
      </Formik>
    )
  }

  return (
    <Sidebar>
      <ConfirmDialog
        setOpen={setConfirmationDialog}
        open={confirmationDialog}
        onDelete={onDeleteCategory}
      />
        <>
          {renderModal()}
          <div className="px-4 pt-2 sm:px-6 lg:px-8 ContainerUI sticky">
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto">
                <h1 className="text-3xl font-semibold text-gray-900">
                  Grade
                </h1>
              </div>
              <div className="sm:mt-0 sm:ml-16 sm:flex-none">
                <Button
                  onClick={() => {
                    setEnabled(true)
                    setModal((prev) => ({ ...prev, state: true, type: 'add' }))
                  }}
                  className="flex justify-center items-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-5 h-5 mt-0 mr-2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 4.5v15m7.5-7.5h-15"
                    />
                  </svg>
                  Add
                </Button>
              </div>
            </div>
            {loading ? (
              <div className="flex items-center justify-center">
                <div
                  className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              <>
                <div className="absolute my-5 w-8/12 flex justify-end items-center text-right right-0 mr-2"></div>
                {renderTable()}
                <nav
                  className="flex flex-col md:flex-row md:items-center md:justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
                  aria-label="Pagination"
                >
                  <div>
                    <p className="text-sm text-gray-700">
                      Showing{' '}
                      <select
                        onChange={(e) => {
                          setLimit(e.target.value)
                          setPage(0)
                        }}
                        value={limit}
                      >
                        <option value={count}>{count}</option>
                        <option value="10">10</option>
                        <option value="15">15</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="200">200</option>
                      </select>{' '}
                      of <span className="font-medium">{count}</span> results
                    </p>
                  </div>
                  <div className="flex items-center md:justify-end">
                    <span
                      onClick={goPrev}
                      className="relative mr-3 items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 border hover:bg-indigo-800 hover:text-white duration-500 cursor-pointer"
                    >
                      Previous
                    </span>
                    <span className="text-sm">
                      Page {page + 1}
                      <span className="ml-1"></span>/ {Math.ceil(totalPages)}
                    </span>
                    <span
                      onClick={goNext}
                      className="relative ml-3 items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 border hover:bg-indigo-800 hover:text-white duration-500 cursor-pointer"
                    >
                      Next
                    </span>
                  </div>
                </nav>
              </>
            )}
          </div>
        </>
      <ToastContainer
        closeButton={false}
        closeOnClick={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
        autoClose={2000}
        hideProgressBar={false}
      />
    </Sidebar>
  )
}

export default GradeMaster
