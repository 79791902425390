import { useState, useEffect, useCallback } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import app_api from '../../config/api'
import Button from '../../components/button'
import { Sidebar } from '../../components/navigation/sidebar'
import ConfirmationDialog from '../../components/dialog/confirmation_dialog'
import { Formik } from 'formik'
import Modal from '../../components/modals'
import { useAsyncDebounce } from 'react-table'
import { Link } from 'react-router-dom'
import { CalendarIcon } from '@heroicons/react/24/outline'

const initialModalState = {
  type: '',
  state: false,
  index: null,
  edit_id: '',
  data: {
    name: '',
    description: '',
    userId: '',
  },
}

const ProjectList = () => {
  const [modal, setModal] = useState(initialModalState)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [confirmationDialog, setConfirmationDialog] = useState(false)
  const [TabData, setTabData] = useState([])
  const [value, setValue] = useState(null)
  // Pagination Start
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(15)
  const [totalPages, setTotalPages] = useState(1)
  const [count, setCount] = useState(0)
  const [searchFilter, setSearchFilter] = useState('')
  const [show, setShow] = useState(false)
  const [currentTab, setCurrentTab] = useState('Project')
  const [tabs, setTabs] = useState([
    { name: 'Project', current: true },
    { name: 'Table', current: false },
  ])

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  useEffect(() => {
    GetProjectData()
  }, [])

  const onDeleteOpen = (id, index) => {
    setModal((prev) => ({ ...prev, edit_id: id, index: index }))
    setConfirmationDialog(true)
  }

  const cleanModalData = () => {
    setModal(initialModalState)
  }

  const onEditOpen = (id, index) => {
    setModal((prev) => ({
      ...prev,
      type: 'edit',
      edit_id: id,
      index: index,
      state: true,
      data: TabData[index],
    }))
  }

  const onDeleteCategory = () => {
    const { edit_id, index } = modal
    app_api
      .delete(`/app-lab-exercise/${edit_id}`)
      .then((res) => {
        toast.success('Deleted Successfully')
        GetProjectData()
        setConfirmationDialog(false)
      })
      .catch((err) => {
        setError(err.toString())
        toast.error(err.response.data.message)
      })
  }

  const GetProjectData = () => {
    const userId = JSON.parse(localStorage.getItem('itkKey')).id
    let url = `app-lab-exercise/user/${userId}`
    app_api
      .get(url)
      .then((res) => res.data)
      .then((res) => {
        setError(null)
        setTabData(res.data)
        setLoading(false)
      })
      .catch((err) => {
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const onSearch = useAsyncDebounce((value) => {
    setSearchFilter(value)
  }, 1000)

  useEffect(() => {
    onSearch(value)
  }, [value])

  const tabChangeHandler = (data, index) => {
    if (data) {
      let tabd_data = tabs
      tabd_data = tabd_data.map((e) => {
        if (e.current == true) {
          return {
            name: e.name,
            current: false,
          }
        } else {
          return {
            name: e.name,
            current: e.current,
          }
        }
      })
      tabd_data[index].current = true
      setTabs(tabd_data)
      setCurrentTab(tabd_data[index].name)
    }
  }

  const renderModal = () => {
    const { type, state, edit_id, data } = modal
    return (
      <Formik
        initialValues={data}
        validationSchema={null}
        enableReinitialize
        onSubmit={(values, { setSubmitting, resetForm }) => {
          values.userId = JSON.parse(localStorage.getItem('itkKey')).id
          if (type === 'add') {
            app_api
              .post('app-lab-exercise', values)
              .then((res) => {
                cleanModalData()
                GetProjectData()
                setSubmitting(false)
                resetForm()
                toast.success('Created Successfully')
              })
              .catch((err) => {
                if (err.response.status == 424) {
                  toast.error('Duplicate Entry')
                } else {
                  toast.error('Something Went Wrong')
                }
                cleanModalData()
                setSubmitting(false)
              })
          } else {
            values.pid = edit_id
            app_api
              .put(`/app-lab-exercise/edit`, values)
              .then((res) => {
                GetProjectData()
                cleanModalData()
                setSubmitting(false)
                resetForm()
                toast.success('Updated Successfully')
              })
              .catch((err) => {
                toast.error(err.response.data.message)
              })
          }
        }}
      >
        {({
          handleBlur,
          Formik,
          handleChange,
          handleSubmit,
          setFieldTouched,
          setValues,
          values,
          touched,
          isValid,
          isSubmitting,
          errors,
        }) => (
          <Modal
            title={type === 'add' ? 'Add Project' : 'Edit Project'}
            open={state}
            setOpen={() => cleanModalData()}
          >
            <form onSubmit={handleSubmit} noValidate>
              <div className="mt-4 text-left">
                <div className="mt-4">
                  <div className="flex">
                    <label className="block text-sm font-medium text-gray-700">
                      Name
                    </label>
                    <span className="text-red-700 ml-1">*</span>
                  </div>
                  <input
                    name="name"
                    label="Enter Name"
                    value={values.name}
                    autoComplete="off"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    placeholder="Enter Name..."
                  />
                  {touched.name && (
                    <p className="text-red-700 error_msg">{errors.name}</p>
                  )}
                </div>
                <div className="mt-4">
                  <div className="flex">
                    <label className="block text-sm font-medium text-gray-700">
                      Description
                    </label>
                    <span className="text-red-700 ml-1">*</span>
                  </div>
                  <input
                    name="description"
                    label="Enter description"
                    value={values.description}
                    autoComplete="off"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    placeholder="Enter Description..."
                  />
                  {touched.description && (
                    <p className="text-red-700 error_msg">
                      {errors.description}
                    </p>
                  )}
                </div>
                <div className="mt-4 sm:mt-6">
                  <Button
                    type="submit"
                    disabled={
                      isSubmitting ||
                      JSON.parse(localStorage.getItem('itkKey')).role ===
                        'HEAD_PRINCIPAL'
                    }
                  >
                    {isSubmitting
                      ? type === 'add'
                        ? 'Adding...'
                        : 'Saving...'
                      : type === 'add'
                      ? 'Add Project...'
                      : 'Update Project'}
                  </Button>
                </div>
              </div>
            </form>
          </Modal>
        )}
      </Formik>
    )
  }
  return (
    <Sidebar isCollapsed={false}>
      <ConfirmationDialog
        setOpen={setConfirmationDialog}
        open={confirmationDialog}
        onDelete={onDeleteCategory}
      />
      {renderModal()}
      <>
        <div className="px-4 pt-2 sm:px-6 lg:px-8 ContainerUI sticky min-h-screen">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-3xl font-semibold text-gray-900">Projects</h1>
            </div>
          </div>
          {loading ? (
            <div className="flex items-center justify-center">
              <div
                className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <>
              <div className="mt-4 px-4 ">
                <input
                  type="text"
                  value={value}
                  placeholder="Search"
                  onChange={(e) => {
                    setValue(e.target.value)
                  }}
                  className="w-1/4 p-2 rounded-md shadow-md hover:shadow-xl hover:shadow-gray-200"
                />
              </div>
              <div className="gap-4 min-h-screen mt-4 p-4 ">
                <div className="flex flex-wrap gap-4">
                  <div
                    className="w-1/6 flex flex-col justify-center items-center bg-[#06a199] p-2 rounded-md text-white shadow-md hover:shadow-xl hover:shadow-gray-200 cursor-pointer "
                    onClick={() => {
                      setModal((prev) => ({
                        ...prev,
                        state: true,
                        type: 'add',
                      }))
                    }}
                  >
                    <div className="rounded-full p-2 bg-[#06635E] text-[#06a199 flex justify-center items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 4.5v15m7.5-7.5h-15"
                        />
                      </svg>
                    </div>
                    <div className="text-lg font-bold text-nowrap overflow-hidden text-ellipsis">
                      Add {currentTab}
                    </div>
                  </div>
                  {TabData.map((item, index) => (
                    <div className="w-1/6 flex flex-col justify-center bg-[#06a199] p-4 rounded-md text-white shadow-md hover:shadow-xl hover:shadow-gray-200">
                      <div className="flex justify-between">
                        <div className=""></div>
                        <div className="cursor-pointer flex gap-2">
                          <div
                            className="text-sm"
                            onClick={() => onEditOpen(item._id, index)}
                          >
                            <span>Edit</span>
                          </div>
                          <div
                            className="text-sm"
                            onClick={() => onDeleteOpen(item._id, index)}
                          >
                            <span className="">Delete</span>
                          </div>
                        </div>
                      </div>
                      <div className="text-lg font-bold text-nowrap overflow-hidden text-ellipsis">
                        <Link to={item._id}>{item.name}</Link>
                      </div>
                      <div>
                        <p
                          className={
                            'w-full h-10 text-nowrap overflow-hidden text-ellipsis font-light text-sm'
                          }
                        >
                          {item.description}
                        </p>
                      </div>
                      <div
                        className={
                          'flex flex-start items-center gap-2 w-full text-xs'
                        }
                      >
                        <CalendarIcon className="w-4 h-4" />
                        Created on
                        <span>{new Date(item.createdAt).toDateString()}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
      </>
      <ToastContainer
        closeButton={false}
        closeOnClick={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
        autoClose={2000}
        hideProgressBar={false}
      />
    </Sidebar>
  )
}

export default ProjectList
{
  /* <div className="relative mx-2">
                <div className="w-full flex md:mt-6 mt-16 absolute justify-between gap-2">
                  <div></div>
                  <div className="flex justify-between gap-4 ">
                    <div
                      onClick={() => setIsFilterSidebarOpen(!isFilterSidebarOpen)}
                      className="rounded-full py-2 px-2 cursor-pointer border shadow-sm bg-[#e42b35] text-white"
                    >
                      {filterData.loading ? (
                        <div className="flex items-center justify-center">
                          <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full" role="status">
                          </div>
                        </div>) : <LuListFilter className="w-6 h-6" />}
                    </div>
                    <div
                      className="rounded-full py-2 px-2 cursor-pointer border shadow-sm bg-[#e42b35] text-white"
                      onClick={() => {
                        downloadData();
                      }}
                    >
                      {downloadButtonLoading ? (
                        <div className="flex items-center justify-center">
                          <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full" role="status">
                          </div>
                        </div>) : <LuDownload className="w-6 h-6" />}
                    </div>
                  </div>
                </div>
              </div> */
}

{
  /* <nav
                className="flex flex-col md:flex-row md:items-center md:justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
                aria-label="Pagination"
              >
                <div>
                  <p className="text-sm text-gray-700">
                    Showing{' '}
                    <select
                      onChange={(e) => {
                        setLimit(e.target.value)
                        setPage(0)
                      }}
                      value={limit}
                    >
                      <option value="10">10</option>
                      <option value="15">15</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="200">200</option>
                    </select>{' '}
                    of <span className="font-medium">{count}</span> results
                  </p>
                </div>
                <div className="flex items-center md:justify-end">
                  <span
                    onClick={goPrev}
                    className="relative mr-3 items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 border hover:bg-indigo-800 hover:text-white duration-500 cursor-pointer"
                  >
                    Previous
                  </span>
                  <span className="text-sm">
                    Page {page + 1}
                    <span className="ml-1"></span>/ {Math.ceil(totalPages)}
                  </span>
                  <span
                    onClick={goNext}
                    className="relative ml-3 items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 border hover:bg-indigo-800 hover:text-white duration-500 cursor-pointer"
                  >
                    Next
                  </span>
                </div>
              </nav> */
}
