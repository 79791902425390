// import applab from "../../apis/applab";
import applab from '../../config/api'
import * as apiRoutes from '../../apis/constants'
import * as actionTypes from '../actionTypes'

// Project actions

// Create

const addProject_Request = () => ({
  type: actionTypes.ADD_PROJECT,
})

const addProject_Success = (response) => ({
  type: actionTypes.ADD_PROJECT_SUCCESS,
  payload: response,
})

const addProject_Failure = (error) => ({
  type: actionTypes.ADD_PROJECT_FAILURE,
  error: error?.message,
})

export const addProject = (project) => (dispatch) => {
  dispatch(addProject_Request())
  applab
    .post(apiRoutes.addProject, { ...project, eventType: 'project_added' })
    .then((response) => {
      if (response.status === 201) {
        dispatch(addProject_Success(response.data))
      } else {
        dispatch(addProject_Failure(response.data))
      }
    })
    .catch((error) => {
      if (error?.response?.status === 409) {
        dispatch(addProject_Failure({ message: error?.response.data.error }))
      } else {
        dispatch(addProject_Failure(error))
      }
    })
}

// Read

//// Read all
const fetchProjects_Request = () => ({
  type: actionTypes.FETCH_PROJECTS,
})

const fetchProjects_Success = (response) => ({
  type: actionTypes.FETCH_PROJECTS_SUCCESS,
  payload: response,
})

const fetchProjects_Failure = (error) => ({
  type: actionTypes.FETCH_PROJECTS_FAILURE,
  error: error?.message,
})

export const fetchProjects = (userid) => (dispatch) => {
  dispatch(fetchProjects_Request())
  // TODO: /project/:userid
  applab
    .get(apiRoutes.getProjects +'user/'+ userid)
    .then((response) => {
      dispatch(fetchProjects_Success(response.data))
    })
    .catch((error) => {
      dispatch(fetchProjects_Failure(error))})
}

//// Read one

const fetchOneProject_Request = () => ({
  type: actionTypes.FETCH_ONE_PROJECT,
})

const fetchOneProject_Success = (response) => ({
  type: actionTypes.FETCH_ONE_PROJECT_SUCCESS,
  payload: response,
})

const fetchOneProject_Failure = (error) => ({
  type: actionTypes.FETCH_ONE_PROJECT_FAILURE,
  error: error?.message,
})

export const fetchOneProject = (projectId) => (dispatch) => {
  dispatch(fetchOneProject_Request())
  applab
    .get(apiRoutes.getOneProject + projectId)
    .then((response) => {
      if (response.status === 204) {
        dispatch(
          fetchOneProject_Failure({ message: 'No project found with this ID' })
        )
      } else dispatch(fetchOneProject_Success(response.data.data))
    })
    .catch((error) => dispatch(fetchOneProject_Failure(error)))
}

// Update

// const editProject_onTheGo_Request = () => ({
//   type: actionTypes.EDIT_PROJECT
// })

// const editProject_onTheGo_Success = (response) => ({
//   type: actionTypes.EDIT_PROJECT_SUCCESS,
//   payload: response.data
// })

// export const editProject_onTheGo = (project) => dispatch => {
//   dispatch(editProject_onTheGo_Request())
//   dispatch(editProject_onTheGo_Success({ "data": project }))
// }

const editProject_Request = () => ({
  type: actionTypes.EDIT_PROJECT,
})

const editProject_Success = (response) => ({
  type: actionTypes.EDIT_PROJECT_SUCCESS,
  payload: response,
})

const editProject_Failure = (error) => ({
  type: actionTypes.EDIT_PROJECT_FAILURE,
  error: error?.message,
})

export const editProject = (project) => (dispatch) => {
  // let project = {
  //   "pid": this.state.projectId,
  //   [anything]
  // }
  dispatch(editProject_Request())
  applab
    .put(apiRoutes.editProject, { ...project, eventType: 'project_edited' })
    .then((response) => {
      dispatch(editProject_Success(response.data.data))
    })
    .catch((error) => dispatch(editProject_Failure(error)))
}

// Delete

const deleteProject_Request = () => ({
  type: actionTypes.DELETE_PROJECT,
})

const deleteProject_Success = (projectId) => ({
  type: actionTypes.DELETE_PROJECT_SUCCESS,
  pid: projectId,
})

const deleteProject_Failure = (error) => ({
  type: actionTypes.DELETE_PROJECT_FAILURE,
  error: error?.message,
})

export const deleteProject = (projectId) => (dispatch) => {
  dispatch(deleteProject_Request())
  applab
    .delete(apiRoutes.deleteProject + projectId, {
      data: { eventType: 'project_deleted' },
    })
    .then((response) => {
      dispatch(deleteProject_Success(projectId))
    })
    .catch((error) => dispatch(deleteProject_Failure(error)))
}

const deleteScreen_Request = () => ({
  type: actionTypes.DELETE_SCREEN,
})

const deleteScreen_Success = (projectId) => ({
  type: actionTypes.DELETE_SCREEN_SUCCESS,
  pid: projectId,
})

export const deleteScreen = (projectId, screenId, childIds) => (dispatch) => {
  dispatch(deleteScreen_Request())
  dispatch(deleteScreen_Success(projectId))
  dispatch(deleteChildren(null, screenId, childIds))
}

// Components

// Create

const addComponent_Request = () => ({
  type: actionTypes.ADD_COMPONENT,
})

const addComponent_Success = (response) => ({
  type: actionTypes.ADD_COMPONENT_SUCCESS,
  payload: response,
})

const addComponent_Failure = (error) => ({
  type: actionTypes.ADD_COMPONENT_FAILURE,
  error: error?.message,
})

export const addComponent =
  (projectId, componentType, parentId, pos) => (dispatch) => {
    dispatch(addComponent_Request())
    let requestBody = {
      project_id: projectId,
      c_type: componentType,
      parent_id: parentId,
      position: pos,
      eventType: 'component_added',
    }
    applab
      .post(apiRoutes.addComponent, requestBody)
      .then((response) => {
        dispatch(addComponent_Success(response.data))
        if (parentId !== null) {
          dispatch(addChild(parentId, response.data.data._id, pos))
        }
        if (response.data.data.c_type_name === 'screen') {
          dispatch(
            editProject({
              pid: response.data.data.project_id,
              current_screen: response.data.data._id,
            })
          )
        }
      })
      .catch((error) => {
        console.log(error)
        dispatch(addComponent_Failure(error))
      })
  }

// Read

const fetchComponents_Request = () => ({
  type: actionTypes.FETCH_COMPONENTS,
})

const fetchComponents_Success = (response) => ({
  type: actionTypes.FETCH_COMPONENTS_SUCCESS,
  payload: response,
})

const fetchComponents_Failure = (error) => ({
  type: actionTypes.FETCH_COMPONENTS_FAILURE,
  error: error?.message,
})

export const fetchComponents = (projectId) => (dispatch) => {
  dispatch(fetchComponents_Request())
  applab
    .get(apiRoutes.getComponents + projectId)
    .then((response) => {
      if (response.status === 204) {
        dispatch(fetchComponents_Failure({ message: 'No components exist' }))
      } else dispatch(fetchComponents_Success(response.data.data))
    })
    .catch((error) => dispatch(fetchComponents_Failure(error)))
}

// Update

const renameComponent_Request = () => ({
  type: actionTypes.RENAME_COMPONENT,
})

const renameComponent_Success = (response) => ({
  type: actionTypes.RENAME_COMPONENT_SUCCESS,
  payload: response,
})

const renameComponent_Failure = (error) => ({
  type: actionTypes.RENAME_COMPONENT_FAILURE,
  error: error?.message,
})

export const renameComponent = (component) => (dispatch) => {
  dispatch(renameComponent_Request())
  applab
    .put(apiRoutes.rename, { ...component, eventType: 'component_renamed' })
    .then((response) => {
      dispatch(renameComponent_Success(response.data))
    })
    .catch((error) => dispatch(renameComponent_Failure(error)))
}

export const changeProperty_OnTheGo =
  (cid, property_name, property_value) => (dispatch) => {
    let change_property = {
      cid,
      property_name,
      property_value,
    }
    dispatch(changeProperty_Success(change_property))
  }

const changeProperty_Request = () => ({
  type: actionTypes.CHANGE_PROPERTY,
})

const changeProperty_Success = (response) => ({
  type: actionTypes.CHANGE_PROPERTY_SUCCESS,
  payload: response,
})

const changeProperty_Failure = (error) => ({
  type: actionTypes.CHANGE_PROPERTY_FAILURE,
  error: error?.message,
})

export const changeProperty = (changeProperty) => (dispatch) => {
  dispatch(changeProperty_Request())
  applab
    .put(apiRoutes.changeProperty, {
      ...changeProperty,
      eventType: 'component_property_changed',
    })
    .then((response) => {
      dispatch(changeProperty_Success(response.data))
    })
    .catch((error) => dispatch(changeProperty_Failure(error)))
}

const changePosition_Request = () => ({
  type: actionTypes.CHANGE_POSITION,
})

const changePosition_Success = (response) => ({
  type: actionTypes.CHANGE_POSITION_SUCCESS,
  payload: response,
})

const changePosition_Failure = (error) => ({
  type: actionTypes.CHANGE_POSITION_FAILURE,
  error: error?.message,
})

export const changePosition = (cid, new_position) => (dispatch) => {
  dispatch(changePosition_Request())
  let changePositionRequest = {
    cid: cid,
    new_position: new_position,
    eventType: 'component_position_changed',
  }
  applab
    .put(apiRoutes.changePosition, changePositionRequest)
    .then((response) => {
      dispatch(changePosition_Success(response.data))
    })
    .catch((error) => dispatch(changePosition_Failure(error)))
}

const changeParent_Request = () => ({
  type: actionTypes.CHANGE_PARENT,
})

const changeParent_Success = (response) => ({
  type: actionTypes.CHANGE_PARENT_SUCCESS,
  childId: response.cid,
  new_parent: response.new_parent,
  old_parent: response.old_parent,
  position: response.new_position,
})

const changeParent_Failure = (error) => ({
  type: actionTypes.CHANGE_PARENT_FAILURE,
  error: error?.message,
})

export const changeParent =
  (oldParentId, newParentId, childId, pos) => (dispatch) => {
    dispatch(changeParent_Request())
    let change_parent = {
      cid: childId,
      new_parent: newParentId,
      old_parent: oldParentId,
      new_position: pos,
      eventType: 'component_parent_changed',
    }
    applab
      .put(apiRoutes.changeParent, change_parent)
      .then((response) => {
        dispatch(changeParent_Success(response.data))
      })
      .catch((error) => dispatch(changeParent_Failure(error)))
  }

const addChild_Request = () => ({
  type: actionTypes.ADD_CHILD,
})

const addChild_Success = (parent, childId, pos) => ({
  type: actionTypes.ADD_CHILD_SUCCESS,
  new_parent: parent,
  childId: childId,
  pos: pos,
})

const addChild = (parent, childId, pos) => (dispatch) => {
  dispatch(addChild_Request())
  dispatch(addChild_Success(parent, childId, pos))
}

const dropChild_Request = () => ({
  type: actionTypes.DROP_CHILD,
})

const dropChild_Success = (parent, childId) => ({
  type: actionTypes.DROP_CHILD_SUCCESS,
  old_parent: parent,
  childId: childId,
})

const dropChild = (parent, childId) => (dispatch) => {
  dispatch(dropChild_Request())
  dispatch(dropChild_Success(parent, childId))
}

const updateXml_Request_OnTheGo = () => ({
  type: actionTypes.UPDATE_SCREEN_XML,
})

const updateXml_Success_OnTheGo = (updatedComponent) => ({
  type: actionTypes.UPDATE_SCREEN_XML_SUCCESS,
  cid: updatedComponent.cid,
  xml: updatedComponent.xml,
})

export const updateXml_OnTheGo = (updatedComponent) => (dispatch) => {
  // updatedComponent = {
  //   'cid': 'component_id',
  //   'xml': '<xml></xml>'
  // }
  dispatch(updateXml_Request_OnTheGo())
  dispatch(updateXml_Success_OnTheGo(updatedComponent))
}

const updateXml_Request = () => ({
  type: actionTypes.UPDATE_SCREEN_XML,
})

const updateXml_Success = (response) => ({
  type: actionTypes.UPDATE_SCREEN_XML_SUCCESS,
  cid: response.cid,
  xml: response.xml,
})

const updateXml_Failure = (error) => ({
  type: actionTypes.UPDATE_SCREEN_XML_FAILURE,
  error: error?.message,
})

export const updateXml = (updatedComponent) => (dispatch) => {
  // updatedComponent = {
  //   'cid': 'component_id',
  //   'xml': '<xml></xml>'
  // }
  dispatch(updateXml_Request())
  applab
    .put(apiRoutes.updateXml, {
      ...updatedComponent,
      eventType: 'screen_xml_updated',
    })
    .then((response) => {
      // if (response.status === 200) {
      dispatch(updateXml_Success(response.data))
      // } else {
      //   dispatch(updateXml_Failure(response.data))
      // }
    })
    .catch((error) => {
      if (error?.response?.status === 400) {
        dispatch(
          updateXml_Failure({
            message:
              'Not a screen component. Only screens can have blocks data to them.',
          })
        )
      } else if (error?.response?.status === 404) {
        dispatch(updateXml_Failure({ message: 'No such component exists.' }))
      } else {
        dispatch(updateXml_Failure(error))
      }
    })
}

const updateCode_Request_OnTheGo = () => ({
  type: actionTypes.UPDATE_SCREEN_CODE,
})

const updateCode_Success_OnTheGo = (updatedComponent) => ({
  type: actionTypes.UPDATE_SCREEN_CODE_SUCCESS,
  cid: updatedComponent.cid,
  code: updatedComponent.code,
})

export const updateCode_OnTheGo = (updatedComponent) => (dispatch) => {
  // updatedComponent = {
  //   'cid': 'component_id',
  //   'code': 'js code'
  // }
  dispatch(updateCode_Request_OnTheGo())
  dispatch(updateCode_Success_OnTheGo(updatedComponent))
}

const updateCode_Request = () => ({
  type: actionTypes.UPDATE_SCREEN_CODE,
})

const updateCode_Success = (response) => ({
  type: actionTypes.UPDATE_SCREEN_CODE_SUCCESS,
  cid: response.cid,
  code: response.code,
})

const updateCode_Failure = (error) => ({
  type: actionTypes.UPDATE_SCREEN_CODE_FAILURE,
  error: error?.message,
})

export const updateCode = (updatedComponent) => (dispatch) => {
  // updatedComponent = {
  //   'cid': 'component_id',
  //   'code': 'js code'
  // }
  dispatch(updateCode_Request())
  applab
    .put(apiRoutes.updateCode, {
      ...updatedComponent,
      eventType: 'screen_code_updated',
    })
    .then((response) => {
      // if (response.status === 200) {
      // console.log(response.data)
      dispatch(updateCode_Success(response.data.data))
      // } else {
      //   dispatch(updateCode_Failure(response.data))
      // }
    })
    .catch((error) => {
      if (error?.response?.status === 400) {
        dispatch(
          updateXml_Failure({
            message:
              'Not a screen component. Only screens can have blocks data to them.',
          })
        )
      } else if (error?.response?.status === 404) {
        dispatch(updateXml_Failure({ message: 'No such component exists.' }))
      } else {
        dispatch(updateXml_Failure(error))
      }
    })
}

const uploadImage_Request = () => ({
  type: actionTypes.UPLOAD_IMAGE,
})

const uploadImage_Success = (response) => ({
  type: actionTypes.UPLOAD_IMAGE_SUCCESS,
  cid: response.cid,
  name: response.name,
  location: response.location,
})

const uploadImage_Failure = (error) => ({
  type: actionTypes.UPLOAD_IMAGE_FAILURE,
  error: error?.message,
})

export const uploadImage = (newImage) => (dispatch) => {
  // let newImage = new FormData()
  // newImage.append('cid', this.props.id)
  // newImage.append('image', e.target.files[0])

  dispatch(uploadImage_Request())
  applab
    .post(apiRoutes.uploadImage, newImage)
    .then((response) => {
      // if (response.status === 200) {
      dispatch(uploadImage_Success(response.data))
      // } else {
      //   dispatch(uploadImage_Failure(response.data))
      // }
    })
    .catch((error) => dispatch(uploadImage_Failure(error)))
}

const changeImage_Request = () => ({
  type: actionTypes.CHANGE_IMAGE,
})

const changeImage_Success = (cid, location) => ({
  type: actionTypes.CHANGE_IMAGE_SUCCESS,
  cid,
  location,
})

const changeImage_Failure = (error) => ({
  type: actionTypes.CHANGE_IMAGE_FAILURE,
  error: error?.message,
})

export const changeImage = (cid, location) => (dispatch) => {
  dispatch(changeImage_Request())
  let changeImage = {
    cid: cid,
    location: location,
    eventType: 'selected_image_changed',
  }
  applab
    .put(apiRoutes.changeSelectedImage, changeImage)
    .then((response) => {
      // if (response.status === 200) {
      dispatch(changeImage_Success(cid, location))
      // } else {
      //   dispatch(changeImage_Failure(response.data))
      // }
    })
    .catch((error) => dispatch(changeImage_Failure(error)))
}

// const duplicateComponent_Request = () => ({
//   type: actionTypes.DUPLICATE_COMPONENT
// })

// const duplicateComponent_Success = newComponent => { }

// const duplicateComponent_Failure = error => { }

// export const duplicateComponent = cid => dispatch => {
//   dispatch(duplicateComponent_Request())
//   applab
//     .put(apiRoutes.duplicateComponent + cid)
//     .then(response => true)
//     .catch(error => false)
// }

// Delete

const deleteComponent_Request = () => ({
  type: actionTypes.DELETE_COMPONENT,
})

const deleteComponent_Success = (componentId) => ({
  type: actionTypes.DELETE_COMPONENT_SUCCESS,
  cid: componentId,
})

const deleteComponent_Failure = (error) => ({
  type: actionTypes.DELETE_COMPONENT_FAILURE,
  error: error?.message,
})

export const deleteComponent = (componentId, parent) => (dispatch) => {
  dispatch(deleteComponent_Request())
  applab
    .delete(apiRoutes.deleteComponent + componentId, {
      data: { eventType: 'component_deleted' },
    })
    .then((response) => {
      if (response.status >= 200 && response.status <= 299) {
        if (parent !== null) {
          dispatch(dropChild(parent, componentId))
        }
        dispatch(deleteComponent_Success(componentId))
      } else {
        dispatch(deleteComponent_Failure(response.data))
      }
    })
    .catch((error) => dispatch(deleteComponent_Failure(error)))
}

const deleteChildren_Success = (childids) => ({
  type: actionTypes.DELETE_CHILDREN_SUCCESS,
  childids,
})

const deleteChildren_Failure = (error) => ({
  type: actionTypes.DELETE_CHILDREN_FAILURE,
  error: error?.message,
})

export const deleteChildren = (parent, componentId, childIds) => (dispatch) => {
  dispatch(deleteComponent_Request())
  let deleteComponent = {
    cid: componentId,
    childids: childIds,
    eventType: 'children_deleted',
  }
  applab
    .delete(apiRoutes.deleteChildren, { data: deleteComponent })
    .then((response) => {
      // if (response.status === 200) {
      if (!!parent) {
        dispatch(dropChild(parent, componentId))
      }
      dispatch(deleteChildren_Success(childIds))
      // } else {
      //   dispatch(deleteChildren_Failure(response.data))
      // }
    })
    .catch((error) => dispatch(deleteChildren_Failure(error)))
}
