import React from 'react'
import { Canvas } from '../../theme/html_render'

const EditorCanvas = ({ content }) => {
  return (
    <>
      <h2 className="text-lg font-medium mb-2 border-b">Canvas</h2>
      <Canvas
        dangerouslySetInnerHTML={{ __html: content }}
      ></Canvas>
    </>
  )
}

export default EditorCanvas
