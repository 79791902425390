import moment from 'moment'
import Button from '../button'
import { Link } from 'react-router-dom'

export const user_column = ({ onEditOpen, onDeleteOpen }) => [
  {
    Header: 'Name',
    accessor: (d) => d.firstName + ' ' + (d.lastName ? ' ' + d.lastName : ''),
  },
  {
    Header: 'Username',
    accessor: 'username',
  },
  {
    Header: 'Role',
    id: 'role',
    accessor: (d) => (d.role == 'HEAD_PRINCIPAL' ? 'HEAD PRINCIPAL' : d.role),
  },
  {
    Header: 'Status',
    id: 'status',
    accessor: (d) =>
      d.status == true ? (
        <>
          <span className="px-2 text-green-500 rounded-full text-sm font-semibold">
            ACTIVE
          </span>
        </>
      ) : (
        <>
          <span className="px-2 text-red-600 text-sm font-semibold rounded-full">
            INACTIVE
          </span>
        </>
      ),
  },
  {
    Header: 'Action',
    Cell: (cell) => {
      return (
        <>
          <span className="inline-flex px-2 text-sm leading-5 text-indigo-600 bg-indigo-100 rounded-full">
            <Button
              onClick={() => {
                onEditOpen(cell.row.original.id, cell.row.index)
              }}
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </Button>
          </span>

          <span className="inline-flex px-2 ml-3 text-sm leading-5 text-red-600 bg-red-100 rounded-full">
            <Button
              onClick={() => {
                onDeleteOpen(cell.row.original.id, cell.row.index)
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </Button>
          </span>
        </>
      )
    },
  },
]

export const exercise_column = ({ onView, onEditOpen, onDeleteOpen }) => [
  {
    Header: 'Name',
    accessor: (d) => d.name || '',
  },
  {
    Header: 'Type',
    accessor: (d) => d.type.toUpperCase() || '-',
  },
  {
    Header: 'Description',
    id: 'description',
    accessor: (d) => d.description || '-',
  },
  {
    Header: 'Status',
    id: 'status',
    accessor: (d) =>
      d.status == true ? (
        <>
          <span className="px-2 text-green-500 rounded-full text-sm font-semibold">
            ACTIVE
          </span>
        </>
      ) : (
        <>
          <span className="px-2 text-red-600 text-sm font-semibold rounded-full">
            INACTIVE
          </span>
        </>
      ),
  },
  {
    Header: 'Action',
    Cell: (cell) => {
      return (
        <>
          {cell.row.original.type.toLowerCase() === 'web' && (
            <span className="inline-flex px-2 text-sm leading-5 text-green-600 bg-green-100 rounded-full">
              <Button
                onClick={() => {
                  onView(cell.row.original.id)
                }}
                className="text-green-900 border-0"
                size="default"
                variant="outlined"
              >
                View<span className="sr-only">, </span>
              </Button>
            </span>
          )}

          <span className="inline-flex px-2 ml-3 text-sm leading-5 text-indigo-600 bg-indigo-100 rounded-full">
            <Button
              onClick={() => {
                onEditOpen(cell.row.original.id, cell.row.index)
              }}
              className="text-indigo-900 border-0"
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </Button>
          </span>

          <span className="inline-flex px-2 ml-3 text-sm leading-5 text-red-600 bg-red-100 rounded-full">
            <Button
              onClick={() => {
                onDeleteOpen(cell.row.original.id, cell.row.index)
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </Button>
          </span>
        </>
      )
    },
  },
]

export const school_column = ({ onEditOpen, onDeleteOpen, onViewOpen }) => [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Address',
    accessor: 'address',
  },
  {
    Header: 'Contact Name',
    accessor: (d) => d.point_of_contact_name || '-',
  },
  {
    Header: 'Contact Email',
    accessor: (d) => d.point_of_contact_email || '-',
  },
  {
    Header: 'Contact Number',
    accessor: (d) => d.point_of_contact_number || '-',
  },
  {
    Header: 'State',
    accessor: 'state',
  },
  {
    Header: 'City',
    accessor: 'city',
  },
  {
    Header: 'Pincode',
    accessor: 'pincode',
  },
  {
    Header: 'Action',
    Cell: (cell) => {
      return (
        <>
          <span className="inline-flex px-2 py-0.5 text-sm leading-5 text-green-600 font-semibold bg-green-100 rounded-full mr-3">
            <button
              onClick={() => {
                onViewOpen(cell.row.original.id, cell.row.index)
              }}
            >
              View<span className="sr-only">, </span>
            </button>
          </span>

          <span className="inline-flex px-2 text-sm leading-5 text-indigo-600 bg-indigo-100 rounded-full">
            <Button
              onClick={() => {
                onEditOpen(cell.row.original.id, cell.row.index)
              }}
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </Button>
          </span>

          {/* {JSON.parse(localStorage.getItem('itkKey')).role !== 'HEAD_PRINCIPAL' &&
            <span className="inline-flex px-2 ml-3 text-sm leading-5 text-red-600 bg-red-100 rounded-full">
              <Button
                onClick={() => {
                  onDeleteOpen(cell.row.original.id, cell.row.index)
                }}
                size="default"
                variant="outlined"
              >
                Delete
                <span className="sr-only">, </span>
              </Button>
            </span>
          } */}
        </>
      )
    },
  },
]

export const teacher_column = ({ onteacherEditOpen, onteacherDeleteOpen }) => [
  {
    Header: 'Name',
    accessor: (d) => d.firstName + ' ' + (d.lastName ? d.lastName : ''),
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Contact',
    accessor: 'number',
  },
  {
    Header: 'Action',
    Cell: (cell) => {
      return (
        <>
          <span className="inline-flex px-2 text-sm leading-5 text-indigo-600 bg-indigo-100 rounded-full">
            <Button
              onClick={() => {
                onteacherEditOpen(cell.row.original.id, cell.row.index)
              }}
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </Button>
          </span>
          {JSON.parse(localStorage.getItem('itkKey')).role !== 'HEAD_PRINCIPAL' &&
            <span className="inline-flex px-2 ml-3 text-sm leading-5 text-red-600 bg-red-100 rounded-full">
              <Button
                onClick={() => {
                  onteacherDeleteOpen(cell.row.original.id, cell.row.index)
                }}
                size="default"
                variant="outlined"
              >
                Delete
                <span className="sr-only">, </span>
              </Button>
            </span>}
        </>
      )
    },
  },
]

export const student_column = ({ onstudentEditOpen, onstudentDeleteOpen }) => [
  {
    Header: 'Name',
    accessor: (d) => d.firstName + ' ' + (d.lastName ? d.lastName : ''),
  },
  {
    Header: 'Date of Birth',
    accessor: (d) => d.date_of_birth || '-',
  },
  {
    Header: 'Grade',
    accessor: (d) => d.class?.grade.grade || '-',
  },
  {
    Header: 'Division',
    accessor: (d) => d.class?.division.division || '-',
  },
  {
    Header: 'Username',
    accessor: (d) => d?.user?.username || '-',
  },
  {
    Header: 'Action',
    Cell: (cell) => {
      return (
        <>
          <span className="inline-flex px-2 text-sm font-semibold shadow-sm leading-5 bg-green-100 rounded-full">
            <Link
              to={`/student-exercise/${cell.row.original.id}`}
              className="text-green-600 hover:text-green-600"
              size="default"
              variant="outlined"
            >
              Exercise<span className="sr-only">, </span>
            </Link>
          </span>

          <span className="inline-flex px-2 text-sm leading-5 text-indigo-600 bg-indigo-100 rounded-full ml-3">
            <Button
              onClick={() => {
                onstudentEditOpen(cell.row.original.id, cell.row.index)
              }}
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </Button>
          </span>
          {JSON.parse(localStorage.getItem('itkKey')).role !== 'HEAD_PRINCIPAL' &&
            <span className="inline-flex px-2 ml-3 text-sm leading-5 text-red-600 bg-red-100 rounded-full">
              <Button
                onClick={() => {
                  onstudentDeleteOpen(cell.row.original.id, cell.row.index)
                }}
                size="default"
                variant="outlined"
              >
                Delete
                <span className="sr-only">, </span>
              </Button>
            </span>
          }
        </>
      )
    },
  },
]

export const class_column = ({ onclassEditOpen, onclassDeleteOpen }) => [
  {
    Header: 'Grade',
    accessor: (d) => d?.grade.grade || '-',
  },
  {
    Header: 'Division',
    accessor: (d) => d?.division.division || '-',
  },
  {
    Header: 'Action',
    Cell: (cell) => {
      return (
        <>
          <span className="inline-flex px-2 text-sm font-semibold shadow-sm leading-5 bg-green-100 rounded-full">
            <Link
              to={`/class-exercise/${cell.row.original.id}`}
              className="text-green-600 hover:text-green-600"
              size="default"
              variant="outlined"
            >
              Exercise<span className="sr-only">, </span>
            </Link>
          </span>

          <span className="inline-flex px-2 text-sm leading-5 text-indigo-600 bg-indigo-100 rounded-full ml-3">
            <Button
              onClick={() => {
                onclassEditOpen(cell.row.original.id, cell.row.index)
              }}
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </Button>
          </span>
          {JSON.parse(localStorage.getItem('itkKey')).role !== 'HEAD_PRINCIPAL' &&
            <span className="inline-flex px-2 ml-3 text-sm leading-5 text-red-600 bg-red-100 rounded-full">
              <Button
                onClick={() => {
                  onclassDeleteOpen(cell.row.original.id, cell.row.index)
                }}
                size="default"
                variant="outlined"
              >
                Delete
                <span className="sr-only">, </span>
              </Button>
            </span>
          }
        </>
      )
    },
  },
]

export const grade_column = ({ onEditOpen, onDeleteOpen }) => [
  {
    Header: 'Grade',
    accessor: 'grade',
  },
  {
    Header: 'School Name',
    accessor: (d) => d.school.name || '-',
  },
  {
    Header: 'Action',
    Cell: (cell) => {
      return (
        <>
          <span className="inline-flex px-2 text-sm leading-5 text-indigo-600 bg-indigo-100 rounded-full">
            <Button
              onClick={() => {
                onEditOpen(cell.row.original.id, cell.row.index)
              }}
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </Button>
          </span>

          <span className="inline-flex px-2 ml-3 text-sm leading-5 text-red-600 bg-red-100 rounded-full">
            <Button
              onClick={() => {
                onDeleteOpen(cell.row.original.id, cell.row.index)
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </Button>
          </span>
        </>
      )
    },
  },
]

export const division_column = ({ onEditOpen, onDeleteOpen }) => [
  {
    Header: 'Division',
    accessor: 'division',
  },
  {
    Header: 'School Name',
    accessor: (d) => d.school.name || '-',
  },
  {
    Header: 'Action',
    Cell: (cell) => {
      return (
        <>
          <span className="inline-flex px-2 text-sm leading-5 text-indigo-600 bg-indigo-100 rounded-full">
            <Button
              onClick={() => {
                onEditOpen(cell.row.original.id, cell.row.index)
              }}
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </Button>
          </span>

          <span className="inline-flex px-2 ml-3 text-sm leading-5 text-red-600 bg-red-100 rounded-full">
            <Button
              onClick={() => {
                onDeleteOpen(cell.row.original.id, cell.row.index)
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </Button>
          </span>
        </>
      )
    },
  },
]

export const student_master_column = ({ onEditOpen, onDeleteOpen }) => [
  {
    Header: 'Name',
    accessor: (d) => d.firstName + ' ' + (d.lastName ? d.lastName : ''),
  },
  {
    Header: 'Date of Birth',
    accessor: (d) => d.date_of_birth || '-',
  },
  {
    Header: 'School',
    accessor: (d) => d.school.name || '-',
  },
  {
    Header: 'Class',
    accessor: (d) =>
      d.class?.grade?.grade + ' ' + d.class?.division?.division || '-',
  },
  {
    Header: 'Username',
    accessor: (d) => d?.user?.username || '-',
  },
  {
    Header: 'Action',
    Cell: (cell) => {
      return (
        <>
          <span className="inline-flex px-2 text-sm leading-5 text-indigo-600 bg-indigo-100 rounded-full">
            <Button
              onClick={() => {
                onEditOpen(cell.row.original.id, cell.row.index)
              }}
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </Button>
          </span>

          {JSON.parse(localStorage.getItem('itkKey')).role !== 'HEAD_PRINCIPAL' &&
            <span className="inline-flex px-2 ml-3 text-sm leading-5 text-red-600 bg-red-100 rounded-full">
              <Button
                onClick={() => {
                  onDeleteOpen(cell.row.original.id, cell.row.index)
                }}
                size="default"
                variant="outlined"
              >
                Delete
                <span className="sr-only">, </span>
              </Button>
            </span>
          }
        </>
      )
    },
  },
]

export const class_exercise_column = ({ onEditOpen }) => [
  {
    Header: 'Exercise Name',
    accessor: (d) => d.name || '',
  },
  {
    Header: 'Status',
    id: 'status',
    accessor: (d) =>
      d.status == true ? (
        <>
          <span className="px-2 text-green-500 rounded-full text-sm font-semibold">
            ENABLED
          </span>
        </>
      ) : (
        <>
          <span className="px-2 text-red-600 text-sm font-semibold rounded-full">
            DISABLE
          </span>
        </>
      ),
  },
  {
    Header: 'Action',
    Cell: (cell) => {
      return (
        <>
          {cell.row.original.status == true ? (
            <span className="inline-flex px-2 text-sm leading-5 text-red-600 bg-red-100 rounded-full">
              <Button
                onClick={() => {
                  onEditOpen(cell.row.original.exerciseId, cell.row.index)
                }}
                className="text-red-900 "
                size="default"
                variant="outlined"
              >
                Disable<span className="sr-only">, </span>
              </Button>
            </span>
          ) : (
            <span className="inline-flex px-2 ml-3 text-sm leading-5 text-green-600 bg-green-100 rounded-full">
              <Button
                onClick={() => {
                  onEditOpen(cell.row.original.exerciseId, cell.row.index)
                }}
                size="default"
                variant="outlined"
              >
                Enable
                <span className="sr-only">, </span>
              </Button>
            </span>
          )}
        </>
      )
    },
  },
]
