import { useState, useEffect, useCallback } from 'react'
import HalfGeneral from '../components/half-general-slideover'
import Button from '../components/button'
import app_api from '../config/api'
import Table from '../components/tables/table'
import { exercise_column } from '../components/tables/tableheader'
import { Sidebar } from '../components/navigation/sidebar'
import ConfirmDialog from '../components/dialog/confirmation_dialog'
import { Formik } from 'formik'
import { exerciseSchema } from '../schema'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Switch } from '@headlessui/react'
import { classNames } from '../helpers/classname'
import Select from 'react-select'
import { PhotoIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { useNavigate } from 'react-router-dom'

const initialModalState = {
  type: '',
  state: false,
  index: null,
  edit_id: '',
  data: {
    name: '',
    type: '',
  },
}

const exerciseType = [
  { value: 'maze', label: 'Maze' },
  { value: 'game', label: 'Game' },
  { value: 'app', label: 'App' },
  { value: 'web', label: 'Web' },
]

const ExercisePage = () => {
  const [modal, setModal] = useState(initialModalState)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [category, setCategory] = useState([])
  const [schoolData, setSchoolData] = useState([])
  const [confirmationDialog, setConfirmationDialog] = useState(false)
  const [roleName, setRoleName] = useState('')
  const [TabData, setTabData] = useState([])
  const [enabled, setEnabled] = useState(true)
  const [
    inactiveStatusConfirmationDialog,
    setInactiveStatusConfirmationDialog,
  ] = useState(false)
  const [activeStatusConfirmationDialog, setActiveStatusConfirmationDialog] =
    useState(false)

  // Pagination Start
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(15)
  const [totalPages, setTotalPages] = useState(1)
  const [count, setCount] = useState(0)
  const [searchFilter, setSearchFilter] = useState('')
  // Pagination End

  const navigate = useNavigate()

  const goPrev = () => {
    if (page > 0) setPage((prev) => --prev)
  }

  const goNext = () => {
    if (page < totalPages - 1) setPage((prev) => ++prev)
  }

  const renderTable = useCallback(() => {
    return (
      <>
        <Table
          progPage={page}
          totalCount={count}
          columns={exercise_column({ onView, onEditOpen, onDeleteOpen })}
          data={TabData}
          onEditClose={cleanModalData}
          setSearchFilter={setSearchFilter}
        />
      </>
    )
  }, [TabData])

  useEffect(() => {
    getExerciseData()
  }, [page, limit, searchFilter])

  const onDeleteOpen = (id, index) => {
    setModal((prev) => ({ ...prev, id: id, index: index }))
    setConfirmationDialog(true)
  }

  const cleanModalData = () => {
    setModal(initialModalState)
  }

  const onView = (id) => {
    navigate(`${id}`)
  }

  const onEditOpen = (id, index) => {
    setModal((prev) => ({
      ...prev,
      type: 'edit',
      edit_id: id,
      index: index,
      state: true,
      data: TabData[index],
    }))
    setEnabled(TabData[index].status)
  }

  const onDeleteCategory = () => {
    const { id, index } = modal
    app_api
      .delete(`/exercise-master/${id}`)
      .then((res) => {
        toast.success('Deleted Successfully')
        getExerciseData()
        setConfirmationDialog(false)
      })
      .catch((err) => {
        setError(err.toString())
        toast.error(err.response.data.message)
      })
  }

  const getExerciseData = () => {
    let url = `exercise-master/type/WEB?page=${page}&size=${limit}&searchFilter=${searchFilter}`
    app_api
      .get(url)
      .then((res) => res.data)
      .then((res) => {
        setError(null)
        setCategory(res.data.data)
        setTabData(res.data.data)
        setCount(res.data.count)
        setTotalPages(res.data.count / limit)
        setLoading(false)
      })
      .catch((err) => {
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const renderModal = () => {
    const { type, state, edit_id, data } = modal
    data.schoolIdArray = data.Schoolusers?.map((e) => e.school.id)
    return (
      <Formik
        initialValues={data}
        validationSchema={exerciseSchema}
        enableReinitialize
        onSubmit={(values, { setSubmitting, resetForm }) => {
          if (type === 'add') {
            app_api
              .post('exercise-master', { ...values, status: enabled })
              .then((res) => {
                cleanModalData()
                getExerciseData()
                setSubmitting(false)
                resetForm()
                toast.success('Created Successfully')
              })
              .catch((err) => {
                if (err.response.status == 424) {
                  toast.error('Duplicate Entry')
                } else {
                  toast.error('Something Went Wrong')
                }
                cleanModalData()
                setSubmitting(false)
              })
          } else {
            delete values.Schoolusers
            app_api
              .patch(`/exercise-master/${edit_id}`, {
                ...values,
                status: enabled,
              })
              .then((res) => {
                getExerciseData()
                cleanModalData()
                setSubmitting(false)
                resetForm()
                toast.success('Updated Successfully')
              })
              .catch((err) => {
                toast.error(err.response.data.message)
              })
          }
        }}
      >
        {({
          handleBlur,
          Formik,
          handleChange,
          handleSubmit,
          setFieldTouched,
          setValues,
          values,
          touched,
          isValid,
          isSubmitting,
          errors,
        }) => (
          <HalfGeneral
            title={type === 'add' ? 'Add Exercise' : 'Edit Exercise'}
            open={state}
            setOpen={() => cleanModalData()}
          >
            <form onSubmit={handleSubmit} noValidate>
              <div className="mt-4 text-left">
                <div className="mt-4"></div>
                <div className="flex">
                  <label className="block text-sm font-medium text-gray-700">
                    Name
                  </label>
                  <span className="text-red-700 ml-1">*</span>
                </div>
                <input
                  name="name"
                  label="Enter Name"
                  value={values.name}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="Enter Exercise Name..."
                />
                {touched.name && (
                  <p className="text-red-700 error_msg">{errors.name}</p>
                )}
                <div className="mt-4"></div>
                <div className="grid grid-cols-4 gap-4 flex flex-wrap">
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Type
                      </label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <Select
                      className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="type"
                      id="type"
                      isSearchable
                      loadingMessage="Getting type..."
                      placeholder="Select exercise type"
                      value={
                        values.type
                          ? {
                              value: exerciseType?.find(
                                (e) => e.value == values.type
                              )?.value,
                              label: exerciseType?.find(
                                (e) => e.value == values.type
                              )?.label,
                            }
                          : null
                      }
                      options={exerciseType}
                      onChange={(option) => {
                        setValues({
                          ...values,
                          type: option.value,
                        })
                      }}
                      onBlur={handleBlur}
                    />
                    {touched.type && (
                      <p className="text-red-700 error_msg">{errors.type}</p>
                    )}
                  </div>
                </div>
                <div className="mt-4"></div>
                <div className="flex">
                  <label className="block text-sm font-medium text-gray-700">
                    Description
                  </label>
                  {/* <span className='text-red-700 ml-1'>*</span> */}
                </div>
                <textarea
                  name="description"
                  label="Enter Description"
                  value={values.description}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  rows={4}
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="Enter Description..."
                />
                {touched.description && (
                  <p className="text-red-700 error_msg">{errors.description}</p>
                )}
                <div className="mt-4"></div>
                <div className="mt-4 text-left">
                  <div
                    onDragOver={(e) => e.preventDefault()}
                    onDrop={(e) => {
                      e.preventDefault()
                      setValues({
                        ...values,
                        fileName: e.dataTransfer.files[0],
                      })
                    }}
                    className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10"
                  >
                    <div className="text-center">
                      <PhotoIcon
                        className="mx-auto h-12 w-12 text-gray-300"
                        aria-hidden="true"
                      />
                      <div className="mt-4 flex text-sm leading-6 text-primary">
                        <label
                          htmlFor="fileName"
                          className="relative flex cursor-pointer rounded-md font-semibold text-focus:ring-primary focus-within:outline-none focus-within:ring-2 focus-within:ring-focus:ring-primary focus-within:ring-offset-2 hover:text-primary-300"
                        >
                          <span>Upload a file</span>
                          <input
                            id="fileName"
                            name="fileName"
                            type="file"
                            accept=".png, .jpg, .jpeg, .mp4"
                            className="sr-only"
                            onChange={(e) => {
                              setValues({
                                ...values,
                                fileName: e.target.files[0],
                              })
                            }}
                          />

                          <p className="pl-1">or drag and drop</p>
                        </label>
                      </div>
                      <p className="text-xs leading-5 flex w-full items-center justify-center gap-3 text-gray-600">
                        {values.fileName
                          ? values?.fileName?.name
                          : 'PNG, JPG, JPEG, MP4'}
                        {values.fileName && (
                          <XMarkIcon
                            className="w-4 text-red-500 cursor-pointer"
                            onClick={() => {
                              setValues({
                                ...values,
                                fileName: '',
                              })
                            }}
                          />
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-4">
                  <Switch.Group
                    as="div"
                    className="flex items-center justify-start"
                  >
                    <span className="flex flex-grow flex-col">
                      <Switch.Label
                        as="span"
                        className="text-sm font-medium text-gray-900"
                        passive
                      >
                        Exercise Status
                      </Switch.Label>
                    </span>
                    <Switch
                      checked={enabled}
                      onChange={setEnabled}
                      className={classNames(
                        enabled ? 'bg-indigo-600' : 'bg-gray-200',
                        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 toggleButton'
                      )}
                    >
                      <span
                        aria-hidden="true"
                        className={classNames(
                          enabled ? 'translate-x-5' : 'translate-x-0',
                          'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                        )}
                      />
                    </Switch>
                  </Switch.Group>
                </div>
                <div className="mt-4 sm:mt-6">
                  <Button type="submit" disabled={isSubmitting}>
                    {isSubmitting
                      ? type === 'add'
                        ? 'Adding...'
                        : 'Saving...'
                      : type === 'add'
                      ? 'Add Exercise'
                      : 'Update Exercise'}
                  </Button>
                </div>
              </div>
            </form>
          </HalfGeneral>
        )}
      </Formik>
    )
  }

  return (
    <Sidebar>
      <ConfirmDialog
        setOpen={setConfirmationDialog}
        open={confirmationDialog}
        onDelete={onDeleteCategory}
      />
      {renderModal()}
      <div className="px-4 pt-2 sm:px-6 lg:px-8 ContainerUI sticky">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-3xl font-semibold text-gray-900">Exercise</h1>
          </div>
          <div className="sm:mt-0 sm:ml-16 sm:flex-none">
            <Button
              onClick={() => {
                setEnabled(true)
                setModal((prev) => ({ ...prev, state: true, type: 'add' }))
              }}
              className="flex justify-center items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5 mt-0 mr-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 4.5v15m7.5-7.5h-15"
                />
              </svg>
              Add
            </Button>
          </div>
        </div>
        {loading ? (
          <div className="flex items-center justify-center">
            <div
              className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <>
            <div className="absolute my-5 w-8/12 flex justify-end items-center text-right right-0 mr-2"></div>
            {renderTable()}
            <nav
              className="flex flex-col md:flex-row md:items-center md:justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
              aria-label="Pagination"
            >
              <div>
                <p className="text-sm text-gray-700">
                  Showing{' '}
                  <select
                    onChange={(e) => {
                      setLimit(e.target.value)
                      setPage(0)
                    }}
                    value={limit}
                  >
                    <option value={count}>{count}</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="200">200</option>
                  </select>{' '}
                  of <span className="font-medium">{count}</span> results
                </p>
              </div>
              <div className="flex items-center md:justify-end">
                <span
                  onClick={goPrev}
                  className="relative mr-3 items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 border hover:bg-indigo-800 hover:text-white duration-500 cursor-pointer"
                >
                  Previous
                </span>
                <span className="text-sm">
                  Page {page + 1}
                  <span className="ml-1"></span>/ {Math.ceil(totalPages)}
                </span>
                <span
                  onClick={goNext}
                  className="relative ml-3 items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 border hover:bg-indigo-800 hover:text-white duration-500 cursor-pointer"
                >
                  Next
                </span>
              </div>
            </nav>
          </>
        )}
      </div>
      <ToastContainer
        closeButton={false}
        closeOnClick={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
        autoClose={2000}
        hideProgressBar={false}
      />
    </Sidebar>
  )
}

export default ExercisePage
