import { Navigate, Outlet } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import Auth from '../../pages/auth'
import jwtDecode from 'jwt-decode'
import app_api from '../../config/api'
import Cookie from 'js-cookie'
const userAuth = () => {
  return true
}
const ProtectedRoutes = () => {
  const isAuth = userAuth()
  return isAuth ? <Outlet /> : <Navigate to="/logout" />

  // useEffect(() => {
  //   if (Cookie.get("moldMFAKey")) {
  //     window.history.pushState(null, null,window.location.href);
  //   }
  // }, []);

  // if (Cookie.get("moldMFAKey")) {
  //   return <Outlet />;
  // } else {
  //   return <Navigate to='/' />;
  // }
}

export default ProtectedRoutes
