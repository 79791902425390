import React, { Component, Fragment, createRef } from 'react'
import Blockly from 'blockly/core'
import BlocklyJS from 'blockly/javascript'
import locale from 'blockly/msg/en'
import 'blockly/blocks'
import p5 from 'p5'
import planck from 'planck'
// TODO: add p5.dom
// import '@code-dot-org/p5.play/lib/p5.play'
// Custom blocks imports
import { Block, Category, Sep } from './index'
import '../../blocks/customBlocks'
import '../../blocks/generator'

// window.planck = planck
window.p5 = p5

await import('p5/lib/addons/p5.sound')
// await import('@code-dot-org/p5.play/lib/p5.play')

Blockly.setLocale(locale)

class GameBlocklyComponent extends Component {
  constructor(props) {
    super(props)
    this.myRef = createRef()
    let propCategories = {
      Shape: ['al_game_create_rect', 'al_game_fill_shape'],
    }
    let initialToolbar = [
      <Category name="General" key="generalCategory" colour="210">
        <Block type="set_color"></Block>
        <Block type="set_text_input"></Block>
        <Block type="al_numerical_input"></Block>
        <Block type="al_boolean"></Block>
        <Block type="al_keyboard_type_input"></Block>
      </Category>,
      <Sep key="generalSeparator" />,
      <Category name="Main" key="mainCategory" colour="10">
        <Block type="al_game_setup"></Block>
        <Block type="al_game_draw"></Block>
      </Category>,
    ]
    Object.keys(propCategories).forEach((category, idx) => {
      initialToolbar.push(
        <Category
          name={category}
          key={`control${category}`}
          colour={((idx + 1) * 40 + 10).toString()}
        >
          {propCategories[category].map((blockName) => (
            <Block type={blockName}></Block>
          ))}
        </Category>
      )
    })
    this.state = {
      initialToolbar: initialToolbar,
    }
  }

  Sketch = (p) => {
    // console.log(p)
    // let sprite
    p.setup = () => {
      p.createCanvas(300, 200)
    }

    p.draw = () => {
      // console.log('drawing')
      p.ellipse(p.width / 2, p.height / 2, 50, 50)
    }
  }

  componentDidMount() {
    // const p5play = new window.p5
    const { initialXml, children, ...rest } = this.props

    this.myp5 = new p5(this.Sketch, this.myRef.current)
    // this.myp5.play = P5.play
    // console.log(this.myp5)
    // this.myp5.createSprite(0, 0, 30, 30)
    // console.log(p5play)
    // this.play = p5play
    // let sprite = new p5.Sprite()
    // console.log(sprite)
    this.primaryWorkspace = Blockly.inject(this.blocklyDiv, {
      toolbox: this.toolbox,
      zoom: {
        controls: true,
        wheel: true,
        startScale: 1.0,
        maxScale: 3,
        minScale: 0.3,
        scaleSpeed: 1.2,
      },
      ...rest,
    })

    if (initialXml) {
      Blockly.Xml.domToWorkspace(
        Blockly.Xml.textToDom(initialXml),
        this.primaryWorkspace
      )
    }
    this.primaryWorkspace.addChangeListener(this.onEveryChange)
  }

  componentWillUnmount() {
    this.myp5.remove()
    // this.onEveryChange()
  }

  onEveryChange = () => {
    this.saveCode()
    this.saveXml()
  }

  saveCode = () => {
    var code = BlocklyJS.workspaceToCode(this.primaryWorkspace)
    this.myp5.clear()
    let codeSplitForSetup = code.split('__SETUP__')
    if (codeSplitForSetup.length > 0) {
      // there is setup code
      let setupCode = '',
        restCode = ''
      codeSplitForSetup.forEach((code, idx) => {
        if (!!(idx % 2)) {
          setupCode += code
        } else {
          restCode += code
        }
      })
    }
    // TODO
    /**
     * 
     * this.myp5.setup = () => {
      this.myp5.createCanvas(300, 200)
    }
     */

    // eval(code)
    // this.Sketch
    // let updateScreenCode = {
    //   cid: this.props.current_screen,
    //   code: code,
    // }
    // this.props.onUpdateCode(updateScreenCode)
  }

  saveXml = () => {
    var xml = Blockly.Xml.workspaceToDom(this.primaryWorkspace)
    var xml_text = Blockly.Xml.domToText(xml)
    // console.log('xml', xml_text)
    // let updateScreenXml = {
    //   cid: this.props.current_screen,
    //   xml: xml_text,
    // }
    // this.props.onUpdateXml(updateScreenXml)
  }

  get workspace() {
    return this.primaryWorkspace
  }

  setXml(xml) {
    Blockly.Xml.domToWorkspace(
      Blockly.Xml.textToDom(xml),
      this.primaryWorkspace
    )
  }

  render() {
    // console.log('rendering blockly component', this.props, this.state)
    return (
      <Fragment>
        <div>
          <div
            ref={(e) => (this.blocklyDiv = e)}
            className="h-[70%] w-6/12 absolute"
          />
          <xml
            xmlns="https://developers.google.com/blockly/xml"
            is="blockly"
            ref={(toolbox) => {
              this.toolbox = toolbox
            }}
          >
            {this.state.initialToolbar}
          </xml>
          <div ref={this.myRef} className="w-3/12 absolute right-0">
            P5 container
          </div>
        </div>
      </Fragment>
    )
  }
}

// const mapStateToProps = (state) => ({
//   components: state.component.components,
// })

// export default connect(mapStateToProps, null)(GameBlocklyComponent)
export default GameBlocklyComponent
