import { useState, useEffect, useCallback } from 'react'
import HalfGeneral from '../components/half-general-slideover'
import Button from '../components/button'
import app_api from '../config/api'
import Table from '../components/tables/table'
import {
  class_column,
  school_column,
  student_column,
  teacher_column,
} from '../components/tables/tableheader'
import { Sidebar } from '../components/navigation/sidebar'
import ConfirmDialog from '../components/dialog/confirmation_dialog'
import { Formik } from 'formik'
import {
  teacherSchema,
  studentSchema,
  schoolSchema,
  classSchema,
} from '../schema'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Switch } from '@headlessui/react'
import Select from 'react-select'
import { ArrowLeftCircleIcon } from '@heroicons/react/24/outline'
import { useNavigate, useParams } from 'react-router-dom'

const initialModalState = {
  type: '',
  state: false,
  index: null,
  edit_id: '',
  data: {
    name: '',
    address: '',
    state: '',
    city: '',
    pincode: '',
    point_of_contact_name: '',
    point_of_contact_email: '',
    point_of_contact_number: '',
  },
}

const initialTeacherModalState = {
  t_type: '',
  t_state: false,
  t_index: null,
  t_edit_id: '',
  t_data: {
    firstName: '',
    lastName: '',
    email: '',
    role: '',
    username: '',
    status: '',
  },
}

const initialStudentModalState = {
  s_type: '',
  s_state: false,
  s_index: null,
  s_edit_id: '',
  s_data: {
    firstName: '',
    lastName: '',
    classId: '',
    date_of_birth: '',
    status: '',
    username: '',
    passwordType: '',
  },
}

const initialClassModalState = {
  c_type: '',
  c_state: false,
  c_index: null,
  c_edit_id: '',
  c_data: {
    gradeId: '',
    divisionId: '',
  },
}

const passwordTypeOption = [
  {
    id: 'easy',
    name: 'EASY',
  },
  {
    id: 'difficult',
    name: 'DIFFICULT',
  },
]

const studentPasswordTypeOption = [
  {
    id: 'auto',
    name: 'AUTO',
  },
  {
    id: 'manual',
    name: 'MANUAL',
  },
]

const School = () => {
  const [modal, setModal] = useState(initialModalState)
  const [teacherModal, setTeacherModal] = useState(initialTeacherModalState)
  const [studentModal, setStudentModal] = useState(initialStudentModalState)
  const [classModal, setClassModal] = useState(initialClassModalState)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [category, setCategory] = useState([])
  const [schoolData, setSchoolData] = useState([])
  const [confirmationDialog, setConfirmationDialog] = useState(false)
  const [t_confirmationDialog, t_setConfirmationDialog] = useState(false)
  const [s_confirmationDialog, s_setConfirmationDialog] = useState(false)
  const [c_confirmationDialog, c_setConfirmationDialog] = useState(false)
  const [TabData, setTabData] = useState([])
  const [enabled, setEnabled] = useState(true)
  const [schoolView, setSchoolView] = useState(false)
  const [schoolViewData, setSchoolViewData] = useState([])
  const [teacherData, setTeacherData] = useState([])
  const [studentData, setStudentData] = useState([])
  const [classData, setClassData] = useState([])
  const [divisionData, setDivisionData] = useState([])
  const [gradeData, setGradeData] = useState([])
  const [stateData, setStateData] = useState([])

  // Pagination Start
  // School pagination
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(15)
  const [totalPages, setTotalPages] = useState(1)
  const [count, setCount] = useState(0)
  const [searchFilter, setSearchFilter] = useState('')

  // teacher pagination
  const [t_page, t_setPage] = useState(0)
  const [t_limit, t_setLimit] = useState(15)
  const [t_totalPages, t_setTotalPages] = useState(1)
  const [t_count, t_setCount] = useState(0)
  const [t_searchFilter, t_setSearchFilter] = useState('')

  // student pagination
  const [s_page, s_setPage] = useState(0)
  const [s_limit, s_setLimit] = useState(15)
  const [s_totalPages, s_setTotalPages] = useState(1)
  const [s_count, s_setCount] = useState(0)
  const [s_searchFilter, s_setSearchFilter] = useState('')

  // class pagination
  const [c_page, c_setPage] = useState(0)
  const [c_limit, c_setLimit] = useState(15)
  const [c_totalPages, c_setTotalPages] = useState(1)
  const [c_count, c_setCount] = useState(0)
  const [c_searchFilter, c_setSearchFilter] = useState('')
  // Pagination End

  const [currenttab, setCurrenttab] = useState('Classes')
  const [tabs, setTabs] = useState([
    { name: 'Classes', current: true },
    { name: 'Student', current: false },
    { name: 'Teachers', current: false },
  ])

  const navigate = useNavigate()
  const param = useParams()

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const goPrev = () => {
    if (page > 0) setPage((prev) => --prev)
  }

  const t_goPrev = () => {
    if (t_page > 0) t_setPage((prev) => --prev)
  }

  const s_goPrev = () => {
    if (s_page > 0) s_setPage((prev) => --prev)
  }

  const c_goPrev = () => {
    if (c_page > 0) c_setPage((prev) => --prev)
  }

  const goNext = () => {
    if (page < totalPages - 1) setPage((prev) => ++prev)
  }

  const t_goNext = () => {
    if (t_page < t_totalPages - 1) t_setPage((prev) => ++prev)
  }

  const s_goNext = () => {
    if (s_page < s_totalPages - 1) s_setPage((prev) => ++prev)
  }

  const c_goNext = () => {
    if (c_page < c_totalPages - 1) c_setPage((prev) => ++prev)
  }

  const onViewOpen = (id, index) => {
    navigate(`/school/${id}`)
    getDivisionData(id)
    getGradeData(id)
    setCurrenttab('Classes')
    JSON.parse(localStorage.getItem('itkKey')).role !== 'TEACHER' &&
      setSchoolViewData(TabData[index])
    getStudentData(id)
    getTeacherData(id)
    getClassData(id)
    setSchoolView(true)
  }

  const getParticularTeacher = () => {
    const userID = JSON.parse(localStorage.getItem('itkKey')).id
    app_api
      .get(`/teacher-master/userid/${userID}`)
      .then((res) => res.data)
      .then((res) => {
        const data = res.data
        setSchoolViewData(data.school)
        onViewOpen(data.schoolId, 0)
        // setTeacherData(res.data)
      })
      .catch((err) => {
        setError(err.toString())
        toast.error(err.response.data.message)
      })
  }

  const getParticularDataFromPrinciple = () => {
    const userID = JSON.parse(localStorage.getItem('itkKey')).id
    app_api
      .get(
        `/school-master/principle/${userID}?page=${page}&size=${limit}&searchFilter=${searchFilter}`
      )
      .then((res) => res.data)
      .then((res) => {
        setError(null)
        setCategory(res.data.school)
        setTabData(res.data.school)
        setCount(res.data.count)
        setTotalPages(res.data.count / limit)
        setLoading(false)
      })
  }

  const getTeacherData = (id) => {
    if (param.id != undefined) {
      app_api
        .get(
          `/teacher-master/all/${id}?page=${t_page}&size=${t_limit}&searchFilter=${t_searchFilter}`
        )
        .then((res) => res.data)
        .then((res) => {
          setTeacherData(res.data.data)
          t_setCount(res.data.count)
          t_setTotalPages(res.data.count / limit)
        })
        .catch((err) => {
          setError(err.toString())
          toast.error(err.response.data.message)
        })
    }
  }

  const getDivisionData = (id) => {
    app_api
      .get(`/division-master/school/${id}`)
      .then((res) => res.data)
      .then((res) => {
        setDivisionData(res.data)
      })
      .catch((err) => {
        setError(err.toString())
        toast.error(err.response.data.message)
      })
  }

  const getGradeData = (id) => {
    app_api
      .get(`/grade-master/school/${id}`)
      .then((res) => res.data)
      .then((res) => {
        setGradeData(res.data)
      })
      .catch((err) => {
        setError(err.toString())
        toast.error(err.response.data.message)
      })
  }

  const getClassData = (id) => {
    if (param.id != undefined) {
      app_api
        .get(
          `/class-master/all/${id}?page=${c_page}&size=${c_limit}&searchFilter=${c_searchFilter}`
        )
        .then((res) => res.data)
        .then((res) => {
          setClassData(res.data.data)
          c_setCount(res.data.count)
          c_setTotalPages(res.data.count / limit)
        })
        .catch((err) => {
          setError(err.toString())
          toast.error(err.response.data.message)
        })
    }
  }

  const getStudentData = (id) => {
    if (param.id != undefined) {
      app_api
        .get(
          `/student-master/school/${id}?page=${s_page}&size=${s_limit}&searchFilter=${s_searchFilter}`
        )
        .then((res) => res.data)
        .then((res) => {
          setStudentData(res.data.data)
          s_setCount(res.data.count)
          s_setTotalPages(res.data.count / limit)
        })
        .catch((err) => {
          setError(err.toString())
          toast.error(err.response.data.message)
        })
    }
  }

  const tabChangeHandler = (data, index) => {
    if (data) {
      let tabd_data = tabs
      tabd_data = tabd_data.map((e) => {
        if (e.current == true) {
          return {
            name: e.name,
            current: false,
          }
        } else {
          return {
            name: e.name,
            current: e.current,
          }
        }
      })
      tabd_data[index].current = true
      setTabs(tabd_data)
      if (tabd_data[index].name == 'Classes') {
        getClassData(param.id)
      } else if (tabd_data[index].name == 'Teachers') {
        getTeacherData(param.id)
      } else if (tabd_data[index].name == 'Student') {
        getStudentData(param.id)
      }
      setCurrenttab(tabd_data[index].name)
    }
  }

  const renderTable = useCallback(() => {
    return (
      <>
        <Table
          progPage={page}
          totalCount={count}
          columns={school_column({ onEditOpen, onDeleteOpen, onViewOpen })}
          data={TabData}
          onEditClose={cleanModalData}
          setSearchFilter={setSearchFilter}
        />
      </>
    )
  }, [TabData])

  const renderTeacherTable = useCallback(() => {
    return (
      <>
        <Table
          progPage={t_page}
          totalCount={t_count}
          columns={teacher_column({ onteacherEditOpen, onteacherDeleteOpen })}
          data={teacherData}
          onEditClose={cleanTeacherModalData}
          setSearchFilter={t_setSearchFilter}
        />
      </>
    )
  }, [teacherData])

  const renderStudentTable = useCallback(() => {
    return (
      <>
        <Table
          progPage={s_page}
          totalCount={s_count}
          columns={student_column({ onstudentEditOpen, onStudentDeleteOpen })}
          data={studentData}
          onEditClose={cleanStudentModalData}
          setSearchFilter={s_setSearchFilter}
        />
      </>
    )
  }, [studentData])

  const renderClassTable = useCallback(() => {
    return (
      <>
        <Table
          progPage={c_page}
          totalCount={c_count}
          columns={class_column({ onclassEditOpen, onclassDeleteOpen })}
          data={classData}
          onEditClose={cleanClassModalData}
          setSearchFilter={c_setSearchFilter}
        />
      </>
    )
  }, [classData])

  useEffect(() => {
    JSON.parse(localStorage.getItem('itkKey')).role === 'HEAD_PRINCIPAL'
      ? getParticularDataFromPrinciple()
      : getCategoryData()
  }, [page, limit, searchFilter])

  useEffect(() => {
    getTeacherData(schoolViewData.id)
  }, [t_page, t_limit, t_searchFilter])

  useEffect(() => {
    getStudentData(schoolViewData.id)
  }, [s_page, s_limit, s_searchFilter])

  useEffect(() => {
    getClassData(schoolViewData.id)
  }, [c_page, c_limit, c_searchFilter])

  const onDeleteOpen = (id, index) => {
    setModal((prev) => ({ ...prev, id: id, index: index }))
    setConfirmationDialog(true)
  }

  const onteacherDeleteOpen = (id, index) => {
    setTeacherModal((prev) => ({ ...prev, t_edit_id: id, t_index: index }))
    t_setConfirmationDialog(true)
  }

  const onStudentDeleteOpen = (id, index) => {
    setStudentModal((prev) => ({ ...prev, s_edit_id: id, s_index: index }))
    s_setConfirmationDialog(true)
  }

  const onclassDeleteOpen = (id, index) => {
    setClassModal((prev) => ({ ...prev, c_edit_id: id, c_index: index }))
    c_setConfirmationDialog(true)
  }

  const cleanModalData = () => {
    setModal(initialModalState)
  }

  const cleanTeacherModalData = () => {
    setTeacherModal(initialTeacherModalState)
  }

  const cleanStudentModalData = () => {
    setStudentModal(initialStudentModalState)
  }

  const cleanClassModalData = () => {
    setClassModal(initialClassModalState)
  }

  const onEditOpen = (id, index) => {
    setModal((prev) => ({
      ...prev,
      type: 'edit',
      edit_id: id,
      index: index,
      state: true,
      data: TabData[index],
    }))
    setEnabled(TabData[index].status)
  }

  const onteacherEditOpen = (id, index) => {
    setTeacherModal((prev) => ({
      ...prev,
      t_type: 'edit',
      t_edit_id: id,
      t_index: index,
      t_state: true,
      t_data: teacherData[index],
    }))
    setEnabled(teacherData[index].user.status)
  }

  const onstudentEditOpen = (id, index) => {
    setStudentModal((prev) => ({
      ...prev,
      s_type: 'edit',
      s_edit_id: id,
      s_index: index,
      s_state: true,
      s_data: studentData[index],
    }))
    setEnabled(studentData[index].user.status)
  }

  const onclassEditOpen = (id, index) => {
    setClassModal((prev) => ({
      ...prev,
      c_type: 'edit',
      c_edit_id: id,
      c_index: index,
      c_state: true,
      c_data: classData[index],
    }))
  }

  const onDeleteCategory = () => {
    const { id, index } = modal
    app_api
      .delete(`/school-master/${id}`)
      .then((res) => {
        toast.success('Deleted Successfully')
        getCategoryData()
        setConfirmationDialog(false)
      })
      .catch((err) => {
        setError(err.toString())
        toast.error(err.response.data.message)
      })
  }

  const teacherDeleteCategory = () => {
    const { t_edit_id, index } = teacherModal
    app_api
      .delete(`/teacher-master/${t_edit_id}`)
      .then((res) => {
        toast.success('Deleted Successfully')
        getTeacherData(schoolViewData.id)
        setConfirmationDialog(false)
      })
      .catch((err) => {
        setError(err.toString())
        toast.error(err.response.data.message)
      })
  }

  const onstudentDeleteOpen = () => {
    const { s_edit_id, index } = studentModal
    app_api
      .delete(`/student-master/${s_edit_id}`)
      .then((res) => {
        toast.success('Deleted Successfully')
        getStudentData(schoolViewData.id)
        setConfirmationDialog(false)
      })
      .catch((err) => {
        setError(err.toString())
        toast.error(err.response.data.message)
      })
  }

  const onClassDeleteOpen = () => {
    const { c_edit_id, index } = classModal
    app_api
      .delete(`/class-master/${c_edit_id}`)
      .then((res) => {
        toast.success('Deleted Successfully')
        getClassData(schoolViewData.id)
        setConfirmationDialog(false)
      })
      .catch((err) => {
        setError(err.toString())
        toast.error(err.response.data.message)
      })
  }

  const getCategoryData = () => {
    let url = `school-master/all?page=${page}&size=${limit}&searchFilter=${searchFilter}`
    app_api
      .get(url)
      .then((res) => res.data)
      .then((res) => {
        setError(null)
        setCategory(res.data.data)
        setTabData(res.data.data)
        setCount(res.data.count)
        setTotalPages(res.data.count / limit)
        setLoading(false)
      })
      .catch((err) => {
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const getSchoolData = () => {
    let url = `school-master/many`
    app_api
      .get(url)
      .then((res) => res.data)
      .then((res) => {
        setError(null)
        setSchoolData(res.data.data)
        setLoading(false)
      })
      .catch((err) => {
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const getState = () => {
    let url = `auth/state`
    app_api
      .get(url)
      .then((res) => res.data)
      .then((res) => {
        setError(null)
        setStateData(res.data)
        setLoading(false)
      })
      .catch((err) => {
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  useEffect(() => {
    getState()
    // getCategoryData()
    getSchoolData()
  }, [])

  useEffect(() => {
    JSON.parse(localStorage.getItem('itkKey')).role === 'ADMIN'
      ? getCategoryData()
      : JSON.parse(localStorage.getItem('itkKey')).role === 'TEACHER' &&
        getParticularTeacher()
  }, [])

  useEffect(() => {
    if (param.id) {
      getClassData(param.id)
      setSchoolView(true)
    } else {
      setSchoolView(false)
    }
  }, [param])
  const renderModal = () => {
    const { type, state, edit_id, data } = modal
    return (
      <Formik
        initialValues={data}
        validationSchema={schoolSchema}
        enableReinitialize
        onSubmit={(values, { setSubmitting, resetForm }) => {
          if (type === 'add') {
            app_api
              .post('school-master', { ...values })
              .then((res) => {
                cleanModalData()
                getCategoryData()
                setSubmitting(false)
                resetForm()
                toast.success('Created Successfully')
              })
              .catch((err) => {
                if (err.response.status == 424) {
                  toast.error('Duplicate Entry')
                } else {
                  toast.error(err.response.data.message)
                }
                cleanModalData()
                setSubmitting(false)
              })
          } else {
            delete values.Schoolusers
            app_api
              .patch(`/school-master/${edit_id}`, {
                ...values,
                status: enabled,
              })
              .then((res) => {
                getCategoryData()
                cleanModalData()
                setSubmitting(false)
                resetForm()
                toast.success('Updated Successfully')
              })
              .catch((err) => {
                toast.error(err.response.data.message)
              })
          }
        }}
      >
        {({
          handleBlur,
          Formik,
          handleChange,
          handleSubmit,
          setFieldTouched,
          setValues,
          values,
          touched,
          isValid,
          isSubmitting,
          errors,
        }) => (
          <HalfGeneral
            title={type === 'add' ? 'Add School' : 'Edit School'}
            open={state}
            setOpen={() => cleanModalData()}
          >
            <form onSubmit={handleSubmit} noValidate>
              <div className="mt-4 text-left">
                <div className="mt-4"></div>
                <div className="flex">
                  <label className="block text-sm font-medium text-gray-700">
                    School Name
                  </label>
                  <span className="text-red-700 ml-1">*</span>
                </div>
                <input
                  name="name"
                  label="Enter Name"
                  value={values.name}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="Enter School Name..."
                />
                {touched.name && (
                  <p className="text-red-700 error_msg">{errors.name}</p>
                )}
                <div className="grid grid-cols-5 gap-2 flex flex-wrap mt-4">
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Contact Name
                      </label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <input
                      name="point_of_contact_name"
                      label="point_of_contact_name"
                      value={values.point_of_contact_name}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter Contact Name..."
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                    {touched.point_of_contact_name && (
                      <p className="text-red-700 error_msg">
                        {errors.point_of_contact_name}
                      </p>
                    )}
                  </div>
                  <div className="col-span-2">
                    <div className=""></div>
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Contact Email
                      </label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <input
                      name="point_of_contact_email"
                      label="point_of_contact_email"
                      value={values.point_of_contact_email}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter Contact Email..."
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                    {touched.point_of_contact_email && (
                      <p className="text-red-700 error_msg">
                        {errors.point_of_contact_email}
                      </p>
                    )}
                  </div>
                  <div className="col-span-1">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Contact Number
                      </label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <input
                      name="point_of_contact_number"
                      label="Enter Contact Number"
                      value={values.point_of_contact_number}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      placeholder="Contact Number..."
                    />
                    {touched.point_of_contact_number && (
                      <p className="text-red-700 mb-1 error_msg">
                        {errors.point_of_contact_number}
                      </p>
                    )}
                  </div>
                </div>
                <div className="mt-4"></div>
                <div className="flex">
                  <label className="block text-sm font-medium text-gray-700">
                    School Address
                  </label>
                  <span className="text-red-700 ml-1">*</span>
                </div>
                <input
                  name="address"
                  label="address"
                  value={values.address}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Enter School Address..."
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                />
                {touched.address && (
                  <p className="text-red-700 error_msg">{errors.address}</p>
                )}
                <div className="mt-4"></div>
                <div className="grid grid-cols-5 gap-2 flex flex-wrap">
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        State
                      </label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <Select
                      className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="state"
                      id="state"
                      isSearchable
                      loadingMessage="Getting State..."
                      placeholder="Select State"
                      value={
                        values.state
                          ? {
                              value: stateData?.find(
                                (e) => e.name == values.state
                              )?.isoCode,
                              label: stateData?.find(
                                (e) => e.name == values.state
                              )?.name,
                            }
                          : null
                      }
                      options={stateData?.map((l) => ({
                        ...l,
                        label: l.name,
                        value: l.isoCode,
                      }))}
                      onChange={(option) => {
                        setValues({
                          ...values,
                          state: option.label,
                        })
                      }}
                      onBlur={handleBlur}
                    />
                    {touched.state && (
                      <p className="text-red-700 error_msg">{errors.state}</p>
                    )}
                  </div>
                  <div className="col-span-2">
                    <div className=""></div>
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        City
                      </label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <input
                      name="city"
                      label="city"
                      value={values.city}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="Enter City..."
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                    {touched.city && (
                      <p className="text-red-700 error_msg">{errors.city}</p>
                    )}
                  </div>
                  <div className="col-span-1">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Pincode
                      </label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <input
                      name="pincode"
                      label="Enter Pincode"
                      value={values.pincode}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      placeholder="Pincode..."
                    />
                    {touched.pincode && (
                      <p className="text-red-700 mb-1 error_msg">
                        {errors.pincode}
                      </p>
                    )}
                  </div>
                </div>
                <div className="mt-4 sm:mt-6">
                  <Button
                    type="submit"
                    disabled={
                      isSubmitting ||
                      JSON.parse(localStorage.getItem('itkKey')).role ===
                        'HEAD_PRINCIPAL'
                    }
                  >
                    {isSubmitting
                      ? type === 'add'
                        ? 'Adding...'
                        : 'Saving...'
                      : type === 'add'
                      ? 'Add School'
                      : 'Update School'}
                  </Button>
                </div>
              </div>
            </form>
          </HalfGeneral>
        )}
      </Formik>
    )
  }

  const renderTeacherModal = () => {
    const { t_type, t_state, t_edit_id, t_data } = teacherModal
    t_data.username = t_data.user?.username
    t_data.passwordType = t_data.user?.passwordType
    t_data.password = t_data.user?.password
    console.log(t_data)
    return (
      <Formik
        initialValues={t_data}
        validationSchema={teacherSchema}
        enableReinitialize
        onSubmit={(values, { setSubmitting, resetForm }) => {
          if (t_type === 'add') {
            app_api
              .post('teacher-master', {
                ...values,
                schoolId: schoolViewData.id,
                role: 'TEACHER',
                status: enabled,
              })
              .then((res) => {
                cleanTeacherModalData()
                getTeacherData(schoolViewData.id)
                setSubmitting(false)
                resetForm()
                toast.success('Created Successfully')
              })
              .catch((err) => {
                if (err.response.status == 424) {
                  toast.error('Duplicate Entry')
                } else {
                  toast.error(err.response.data.message)
                }
                cleanTeacherModalData()
                setSubmitting(false)
              })
          } else {
            delete values.Schoolusers
            app_api
              .patch(`/teacher-master/${t_edit_id}`, {
                ...values,
                schoolId: schoolViewData.id,
                role: 'TEACHER',
                status: enabled,
              })
              .then((res) => {
                getTeacherData(schoolViewData.id)
                cleanTeacherModalData()
                setSubmitting(false)
                resetForm()
                toast.success('Updated Successfully')
              })
              .catch((err) => {
                toast.error(err.response.data.message)
              })
          }
        }}
      >
        {({
          handleBlur,
          Formik,
          handleChange,
          handleSubmit,
          setFieldTouched,
          setValues,
          values,
          touched,
          isValid,
          isSubmitting,
          errors,
        }) => (
          <HalfGeneral
            title={t_type === 'add' ? 'Add Teacher' : 'Edit Teacher'}
            open={t_state}
            setOpen={() => cleanTeacherModalData()}
          >
            <form onSubmit={handleSubmit} noValidate>
              <div className="mt-4 text-left">
                <div className="mt-4"></div>
                <div className="flex">
                  <label className="block text-sm font-medium text-gray-700">
                    First Name
                  </label>
                  <span className="text-red-700 ml-1">*</span>
                </div>
                <input
                  name="firstName"
                  label="Enter Name"
                  value={values.firstName}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="Enter First Name..."
                />
                {touched.firstName && (
                  <p className="text-red-700 error_msg">{errors.firstName}</p>
                )}
                <div className="mt-4"></div>
                <div className="flex">
                  <label className="block text-sm font-medium text-gray-700">
                    Last Name
                  </label>
                  <span className="text-red-700 ml-1">*</span>
                </div>
                <input
                  name="lastName"
                  label="lastName"
                  value={values.lastName}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Enter Last Name..."
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                />
                {touched.lastName && (
                  <p className="text-red-700 error_msg">{errors.lastName}</p>
                )}
                <div className="mt-4"></div>
                <div className="flex">
                  <label className="block text-sm font-medium text-gray-700">
                    Email
                  </label>
                  <span className="text-red-700 ml-1">*</span>
                </div>
                <input
                  name="email"
                  label="email"
                  value={values.email}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Enter Email Address..."
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                />
                {touched.email && (
                  <p className="text-red-700 error_msg">{errors.email}</p>
                )}
                <div className="mt-4"></div>
                <div className="grid grid-cols-4 gap-4 flex flex-wrap">
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Username
                      </label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <input
                      name="username"
                      label="Enter Name"
                      value={values.username}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      placeholder="Enter Username..."
                    />
                    {touched.username && (
                      <p className="text-red-700 error_msg">
                        {errors.username}
                      </p>
                    )}
                  </div>
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Password Type
                      </label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <Select
                      className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="passwordType"
                      id="passwordType"
                      isSearchable
                      loadingMessage="Getting type..."
                      placeholder="Select a type"
                      value={
                        values.passwordType
                          ? studentPasswordTypeOption?.map((l) => ({
                              value: studentPasswordTypeOption?.find(
                                (e) => e.id == values.passwordType
                              )?.id,
                              label: studentPasswordTypeOption?.find(
                                (e) => e.id == values.passwordType
                              )?.name,
                            }))
                          : null
                      }
                      options={studentPasswordTypeOption?.map((l) => ({
                        ...l,
                        label: l.name,
                        value: l.id,
                      }))}
                      onChange={(option) => {
                        let random_pass
                        if (!values.passwordType) {
                          if (option.value == 'auto')
                            random_pass =
                              values.firstName.toLowerCase() +
                              Math.floor(Math.random() * 90 + 10)
                          else random_pass = ''
                          setValues({
                            ...values,
                            passwordType: option.value,
                            password: random_pass,
                          })
                        } else {
                          setValues({
                            ...values,
                            passwordType: option.value,
                          })
                        }
                      }}
                      onBlur={handleBlur}
                    />
                    {touched.passwordType && (
                      <p className="text-red-700 mb-1 error_msg">
                        {errors.passwordType}
                      </p>
                    )}
                  </div>
                </div>
                <div className="mt-4"></div>
                {values.passwordType != null && (
                  <>
                    <div className="grid grid-cols-4 gap-4 flex flex-wrap">
                      <div className="col-span-2"></div>
                      <div className="col-span-2">
                        <input
                          name="password"
                          type="text"
                          value={values.password}
                          min={4}
                          disabled={values.passwordType == 'auto'}
                          autoComplete="off"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder="Enter Password..."
                          className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        />
                        {touched.password && (
                          <p className="text-red-700 mb-1 error_msg">
                            {errors.password}
                          </p>
                        )}
                      </div>
                    </div>
                  </>
                )}
                <div className="mt-4">
                  <Switch.Group
                    as="div"
                    className="flex items-center justify-between"
                  >
                    <span className="flex flex-grow flex-col">
                      <Switch.Label
                        as="span"
                        className="text-sm font-medium text-gray-900"
                        passive
                      >
                        User Status
                      </Switch.Label>
                    </span>
                    <Switch
                      checked={enabled}
                      onChange={setEnabled}
                      className={classNames(
                        enabled ? 'bg-indigo-600' : 'bg-gray-200',
                        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 toggleButton'
                      )}
                    >
                      <span
                        aria-hidden="true"
                        className={classNames(
                          enabled ? 'translate-x-5' : 'translate-x-0',
                          'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                        )}
                      />
                    </Switch>
                  </Switch.Group>
                </div>
                <div className="mt-4 sm:mt-6">
                  <Button
                    type="submit"
                    disabled={
                      isSubmitting ||
                      JSON.parse(localStorage.getItem('itkKey')).role ===
                        'HEAD_PRINCIPAL'
                    }
                  >
                    {isSubmitting
                      ? t_type === 'add'
                        ? 'Adding...'
                        : 'Saving...'
                      : t_type === 'add'
                      ? 'Add User'
                      : 'Update User'}
                  </Button>
                </div>
              </div>
            </form>
          </HalfGeneral>
        )}
      </Formik>
    )
  }

  const renderStudentModal = () => {
    const { s_type, s_state, s_edit_id, s_data } = studentModal
    s_data.username = s_data.user?.username
    s_data.passwordType = s_data.user?.passwordType
    s_data.password = s_data.user?.password
    return (
      <Formik
        initialValues={s_data}
        validationSchema={studentSchema}
        enableReinitialize
        onSubmit={(values, { setSubmitting, resetForm }) => {
          if (s_type === 'add') {
            app_api
              .post('student-master', {
                ...values,
                schoolId: schoolViewData.id,
                role: 'STUDENT',
                status: enabled,
              })
              .then((res) => {
                cleanStudentModalData()
                getStudentData(schoolViewData.id)
                setSubmitting(false)
                resetForm()
                toast.success('Created Successfully')
              })
              .catch((err) => {
                if (err.response.status == 424) {
                  toast.error('Duplicate Entry')
                } else {
                  toast.error(err.response.data.message)
                }
                cleanStudentModalData()
                setSubmitting(false)
              })
          } else {
            delete values.Schoolusers
            app_api
              .patch(`/student-master/${s_edit_id}`, {
                ...values,
                schoolId: schoolViewData.id,
                role: 'TEACHER',
                status: enabled,
              })
              .then((res) => {
                getStudentData(schoolViewData.id)
                cleanStudentModalData()
                setSubmitting(false)
                resetForm()
                toast.success('Updated Successfully')
              })
              .catch((err) => {
                toast.error(err.response.data.message)
              })
          }
        }}
      >
        {({
          handleBlur,
          Formik,
          handleChange,
          handleSubmit,
          setFieldTouched,
          setValues,
          values,
          touched,
          isValid,
          isSubmitting,
          errors,
        }) => (
          <HalfGeneral
            title={s_type === 'add' ? 'Add Student' : 'Edit Student'}
            open={s_state}
            setOpen={() => cleanStudentModalData()}
          >
            <form onSubmit={handleSubmit} noValidate>
              <div className="mt-4 text-left">
                <div className="mt-4"></div>
                <div className="flex">
                  <label className="block text-sm font-medium text-gray-700">
                    First Name
                  </label>
                  <span className="text-red-700 ml-1">*</span>
                </div>
                <input
                  name="firstName"
                  label="Enter Name"
                  value={values.firstName}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="Enter First Name..."
                />
                {touched.firstName && (
                  <p className="text-red-700 error_msg">{errors.firstName}</p>
                )}
                <div className="mt-4"></div>
                <div className="flex">
                  <label className="block text-sm font-medium text-gray-700">
                    Last Name
                  </label>
                  <span className="text-red-700 ml-1">*</span>
                </div>
                <input
                  name="lastName"
                  label="lastName"
                  value={values.lastName}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Enter Last Name..."
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                />
                {touched.lastName && (
                  <p className="text-red-700 error_msg">{errors.lastName}</p>
                )}
                <div className="mt-4"></div>
                <div className="flex">
                  <label className="block text-sm font-medium text-gray-700">
                    Date Of Birth
                  </label>
                  <span className="text-red-700 ml-1">*</span>
                </div>
                <input
                  name="date_of_birth"
                  label="date_of_birth"
                  type="date"
                  value={values.date_of_birth}
                  max={new Date().toISOString().split('T')[0]}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="Enter date of birth..."
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                />
                {touched.date_of_birth && (
                  <p className="text-red-700 error_msg">
                    {errors.date_of_birth}
                  </p>
                )}
                <div className="mt-4"></div>
                <div className="flex">
                  <label className="block text-sm font-medium text-gray-700">
                    Class
                  </label>
                  <span className="text-red-700 ml-1">*</span>
                </div>
                <Select
                  className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="classId"
                  id="classId"
                  isSearchable
                  loadingMessage="Getting type..."
                  placeholder="Select a type"
                  value={
                    values.classId
                      ? classData?.map((l) => ({
                          value: classData?.find((e) => e.id == values.classId)
                            ?.id,
                          label:
                            classData?.find((e) => e.id == values.classId)
                              ?.grade.grade +
                            ' - ' +
                            classData?.find((e) => e.id == values.classId)
                              ?.division.division,
                        }))
                      : null
                  }
                  options={classData?.map((l) => ({
                    ...l,
                    label: l.grade.grade + ' - ' + l.division.division,
                    value: l.id,
                  }))}
                  onChange={(option) => {
                    setValues({
                      ...values,
                      classId: option.value,
                    })
                  }}
                  onBlur={handleBlur}
                />
                {touched.classId && (
                  <p className="text-red-700 error_msg">{errors.classId}</p>
                )}
                <div className="mt-4"></div>
                <div className="grid grid-cols-4 gap-4 flex flex-wrap">
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Username
                      </label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <input
                      name="username"
                      label="Enter Name"
                      value={values.username}
                      autoComplete="off"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      placeholder="Enter Username..."
                    />
                    {touched.username && (
                      <p className="text-red-700 error_msg">
                        {errors.username}
                      </p>
                    )}
                  </div>
                  <div className="col-span-2">
                    <div className="flex">
                      <label className="block text-sm font-medium text-gray-700">
                        Password Type
                      </label>
                      <span className="text-red-700 ml-1">*</span>
                    </div>
                    <Select
                      className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      name="passwordType"
                      id="passwordType"
                      isSearchable
                      loadingMessage="Getting type..."
                      placeholder="Select a type"
                      value={
                        values.passwordType
                          ? studentPasswordTypeOption?.map((l) => ({
                              value: studentPasswordTypeOption?.find(
                                (e) => e.id == values.passwordType
                              )?.id,
                              label: studentPasswordTypeOption?.find(
                                (e) => e.id == values.passwordType
                              )?.name,
                            }))
                          : null
                      }
                      options={studentPasswordTypeOption?.map((l) => ({
                        ...l,
                        label: l.name,
                        value: l.id,
                      }))}
                      onChange={(option) => {
                        let random_pass
                        if (!values.passwordType) {
                          if (option.value == 'auto')
                            random_pass =
                              values.firstName.toLowerCase() +
                              Math.floor(Math.random() * 90 + 10)
                          else random_pass = ''
                          setValues({
                            ...values,
                            passwordType: option.value,
                            password: random_pass,
                          })
                        } else {
                          setValues({
                            ...values,
                            passwordType: option.value,
                          })
                        }
                      }}
                      onBlur={handleBlur}
                    />
                    {touched.passwordType && (
                      <p className="text-red-700 mb-1 error_msg">
                        {errors.passwordType}
                      </p>
                    )}
                  </div>
                </div>
                <div className="mt-4"></div>
                {values.passwordType != null && (
                  <>
                    <div className="grid grid-cols-4 gap-4 flex flex-wrap">
                      <div className="col-span-2"></div>
                      <div className="col-span-2">
                        <input
                          name="password"
                          type="text"
                          value={values.password}
                          min={4}
                          disabled={values.passwordType == 'auto'}
                          autoComplete="off"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder="Enter Password..."
                          className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        />
                        {touched.password && (
                          <p className="text-red-700 mb-1 error_msg">
                            {errors.password}
                          </p>
                        )}
                      </div>
                    </div>
                  </>
                )}
                <div className="mt-4">
                  <Switch.Group as="div" className="flex items-center justify-">
                    <span className="flex flex-grow flex-col">
                      <Switch.Label
                        as="span"
                        className="text-sm font-medium text-gray-900"
                        passive
                      >
                        Student Status
                      </Switch.Label>
                    </span>
                    <Switch
                      checked={enabled}
                      onChange={setEnabled}
                      className={classNames(
                        enabled ? 'bg-indigo-600' : 'bg-gray-200',
                        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 toggleButton'
                      )}
                    >
                      <span
                        aria-hidden="true"
                        className={classNames(
                          enabled ? 'translate-x-5' : 'translate-x-0',
                          'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                        )}
                      />
                    </Switch>
                  </Switch.Group>
                </div>
                <div className="mt-4 sm:mt-6">
                  <Button
                    type="submit"
                    disabled={
                      isSubmitting ||
                      JSON.parse(localStorage.getItem('itkKey')).role ===
                        'HEAD_PRINCIPAL'
                    }
                  >
                    {isSubmitting
                      ? s_type === 'add'
                        ? 'Adding...'
                        : 'Saving...'
                      : s_type === 'add'
                      ? 'Add User'
                      : 'Update User'}
                  </Button>
                </div>
              </div>
            </form>
          </HalfGeneral>
        )}
      </Formik>
    )
  }

  const renderClassModal = () => {
    const { c_type, c_state, c_edit_id, c_data } = classModal
    return (
      <Formik
        initialValues={c_data}
        validationSchema={classSchema}
        enableReinitialize
        onSubmit={(values, { setSubmitting, resetForm }) => {
          if (c_type === 'add') {
            app_api
              .post('class-master', { ...values, schoolId: schoolViewData.id })
              .then((res) => {
                cleanClassModalData()
                getClassData(schoolViewData.id)
                setSubmitting(false)
                resetForm()
                toast.success('Created Successfully')
              })
              .catch((err) => {
                if (err.response.status == 424) {
                  toast.error('Duplicate Entry')
                } else {
                  toast.error(err.response.data.message)
                }
                cleanClassModalData()
                setSubmitting(false)
              })
          } else {
            app_api
              .patch(`/class-master/${c_edit_id}`, {
                ...values,
                schoolId: schoolViewData.id,
              })
              .then((res) => {
                getClassData(schoolViewData.id)
                cleanClassModalData()
                setSubmitting(false)
                resetForm()
                toast.success('Updated Successfully')
              })
              .catch((err) => {
                toast.error(err.response.data.message)
              })
          }
        }}
      >
        {({
          handleBlur,
          Formik,
          handleChange,
          handleSubmit,
          setFieldTouched,
          setValues,
          values,
          touched,
          isValid,
          isSubmitting,
          errors,
        }) => (
          <HalfGeneral
            title={c_type === 'add' ? 'Add Class' : 'Edit Class'}
            open={c_state}
            setOpen={() => cleanClassModalData()}
          >
            <form onSubmit={handleSubmit} noValidate>
              <div className="mt-4 text-left">
                <div className="mt-4"></div>
                <div className="flex">
                  <label className="block text-sm font-medium text-gray-700">
                    Grade
                  </label>
                  <span className="text-red-700 ml-1">*</span>
                </div>
                <Select
                  className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="gradeId"
                  id="gradeId"
                  isSearchable
                  loadingMessage="Getting Grade..."
                  placeholder="Select Grade"
                  value={
                    values.gradeId
                      ? {
                          value: gradeData?.find((e) => e.id == values.gradeId)
                            ?.id,
                          label: gradeData?.find((e) => e.id == values.gradeId)
                            ?.grade,
                        }
                      : null
                  }
                  options={gradeData?.map((l) => ({
                    ...l,
                    label: l.grade,
                    value: l.id,
                  }))}
                  onChange={(option) => {
                    setValues({
                      ...values,
                      gradeId: option.value,
                    })
                  }}
                  onBlur={handleBlur}
                />
                {touched.gradeId && (
                  <p className="text-red-700 error_msg">{errors.gradeId}</p>
                )}
                <div className="mt-4"></div>
                <div className="flex">
                  <label className="block text-sm font-medium text-gray-700">
                    Division
                  </label>
                  <span className="text-red-700 ml-1">*</span>
                </div>
                <Select
                  className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="divisionId"
                  id="divisionId"
                  isSearchable
                  loadingMessage="Getting Division..."
                  placeholder="Select Division"
                  value={
                    values.divisionId
                      ? {
                          value: divisionData?.find(
                            (e) => e.id == values.divisionId
                          )?.id,
                          label: divisionData?.find(
                            (e) => e.id == values.divisionId
                          )?.division,
                        }
                      : null
                  }
                  options={divisionData?.map((l) => ({
                    ...l,
                    label: l.division,
                    value: l.id,
                  }))}
                  onChange={(option) => {
                    setValues({
                      ...values,
                      divisionId: option.value,
                    })
                  }}
                  onBlur={handleBlur}
                />
                {touched.divisionId && (
                  <p className="text-red-700 error_msg">{errors.divisionId}</p>
                )}
                <div className="mt-4 sm:mt-6">
                  <Button
                    type="submit"
                    disabled={
                      isSubmitting ||
                      JSON.parse(localStorage.getItem('itkKey')).role ===
                        'HEAD_PRINCIPAL'
                    }
                  >
                    {isSubmitting
                      ? c_type === 'add'
                        ? 'Adding...'
                        : 'Saving...'
                      : c_type === 'add'
                      ? 'Add Class'
                      : 'Update Class'}
                  </Button>
                </div>
              </div>
            </form>
          </HalfGeneral>
        )}
      </Formik>
    )
  }

  return (
    <Sidebar>
      <ConfirmDialog
        setOpen={setConfirmationDialog}
        open={confirmationDialog}
        onDelete={onDeleteCategory}
      />
      <ConfirmDialog
        setOpen={t_setConfirmationDialog}
        open={t_confirmationDialog}
        onDelete={teacherDeleteCategory}
      />
      <ConfirmDialog
        setOpen={s_setConfirmationDialog}
        open={s_confirmationDialog}
        onDelete={onstudentDeleteOpen}
      />
      <ConfirmDialog
        setOpen={c_setConfirmationDialog}
        open={c_confirmationDialog}
        onDelete={onClassDeleteOpen}
      />
      {renderTeacherModal()}
      {renderStudentModal()}
      {renderClassModal()}
      {!schoolView && (
        <>
          {renderModal()}
          <div className="px-4 pt-2 sm:px-6 lg:px-8 ContainerUI sticky">
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto">
                <h1 className="text-3xl font-semibold text-gray-900">
                  Schools
                </h1>
              </div>
              {JSON.parse(localStorage.getItem('itkKey')).role !==
                'HEAD_PRINCIPAL' && (
                <div className="sm:mt-0 sm:ml-16 sm:flex-none">
                  <Button
                    onClick={() => {
                      setEnabled(true)
                      setModal((prev) => ({
                        ...prev,
                        state: true,
                        type: 'add',
                      }))
                    }}
                    className="flex justify-center items-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5 mt-0 mr-2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                    Add
                  </Button>
                </div>
              )}
            </div>
            {loading ? (
              <div className="flex items-center justify-center">
                <div
                  className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              <>
                <div className="absolute my-5 w-8/12 flex justify-end items-center text-right right-0 mr-2"></div>
                {renderTable()}
                <nav
                  className="flex flex-col md:flex-row md:items-center md:justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
                  aria-label="Pagination"
                >
                  <div>
                    <p className="text-sm text-gray-700">
                      Showing{' '}
                      <select
                        onChange={(e) => {
                          setLimit(e.target.value)
                          setPage(0)
                        }}
                        value={limit}
                      >
                        <option value={count}>{count}</option>
                        <option value="10">10</option>
                        <option value="15">15</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="200">200</option>
                      </select>{' '}
                      of <span className="font-medium">{count}</span> results
                    </p>
                  </div>
                  <div className="flex items-center md:justify-end">
                    <span
                      onClick={goPrev}
                      className="relative mr-3 items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 border hover:bg-indigo-800 hover:text-white duration-500 cursor-pointer"
                    >
                      Previous
                    </span>
                    <span className="text-sm">
                      Page {page + 1}
                      <span className="ml-1"></span>/ {Math.ceil(totalPages)}
                    </span>
                    <span
                      onClick={goNext}
                      className="relative ml-3 items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 border hover:bg-indigo-800 hover:text-white duration-500 cursor-pointer"
                    >
                      Next
                    </span>
                  </div>
                </nav>
              </>
            )}
          </div>
        </>
      )}
      {schoolView && (
        <>
          {renderModal()}
          <div className="px-4 pt-2 sm:px-6 lg:px-8 ContainerUI sticky">
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto">
                {JSON.parse(localStorage.getItem('itkKey')).role !==
                  'TEACHER' && (
                  <ArrowLeftCircleIcon
                    className="w-10 h-10 text-[#08ACA2] text-black p-0.5 rounded-full hover:text-white hover:bg-[#08ACA2] duration-500 cursor-pointer"
                    onClick={() => {
                      setSchoolView(false)
                      navigate('/schools')
                    }}
                  />
                )}
                <h1 className="text-3xl ml-2 font-semibold text-gray-900">
                  {schoolViewData?.name}
                </h1>
              </div>
            </div>
            <div>
              <div className="sm:hidden">
                <label htmlFor="tabs" className="sr-only">
                  Select a tab
                </label>
                {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                <select
                  id="tabs"
                  name="tabs"
                  className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  defaultValue={tabs.find((tab) => tab.current).name}
                >
                  {tabs.map((tab) => (
                    <option key={tab.name}>{tab.name}</option>
                  ))}
                </select>
              </div>
              <div className="hidden sm:block">
                <div className="border-b border-gray-200 ">
                  <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                    {tabs
                      .filter((tab) =>
                        JSON.parse(localStorage.getItem('itkKey')).role ===
                        'TEACHER'
                          ? tab.name !== 'Teachers'
                          : tab
                      )
                      .map((tab, i) => (
                        <span
                          key={tab.name}
                          className={classNames(
                            tab.current
                              ? 'border-indigo-500 text-indigo-600'
                              : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                            'whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer'
                          )}
                          aria-current={tab.current ? 'page' : undefined}
                          onClick={() => {
                            tabChangeHandler(tab, i)
                          }}
                        >
                          {tab.name}
                        </span>
                      ))}
                  </nav>
                </div>
              </div>
            </div>
            {loading ? (
              <div className="flex items-center justify-center">
                <div
                  className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              <>
                {currenttab == 'Teachers' && (
                  <>
                    <div className="absolute my-4 w-8/12 flex justify-end items-center text-right right-0 mr-8">
                      {JSON.parse(localStorage.getItem('itkKey')).role !==
                        'HEAD_PRINCIPAL' && (
                        <div className="w-1/4">
                          <Button
                            onClick={() => {
                              setEnabled(true)
                              setTeacherModal((prev) => ({
                                ...prev,
                                t_state: true,
                                t_type: 'add',
                              }))
                            }}
                            className="flex justify-end items-center px-2 py-2"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-5 h-5 mt-0 mr-2"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 4.5v15m7.5-7.5h-15"
                              />
                            </svg>
                            Add
                          </Button>
                        </div>
                      )}
                    </div>
                    {renderTeacherTable()}
                    <nav
                      className="flex flex-col md:flex-row md:items-center md:justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
                      aria-label="Pagination"
                    >
                      <div>
                        <p className="text-sm text-gray-700">
                          Showing{' '}
                          <select
                            onChange={(e) => {
                              t_setLimit(e.target.value)
                              t_setPage(0)
                            }}
                            value={t_limit}
                          >
                            <option value={t_count}>{t_count}</option>
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                          </select>{' '}
                          of <span className="font-medium">{t_count}</span>{' '}
                          results
                        </p>
                      </div>
                      <div className="flex items-center md:justify-end">
                        <span
                          onClick={t_goPrev}
                          className="relative mr-3 items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 border hover:bg-indigo-800 hover:text-white duration-500 cursor-pointer"
                        >
                          Previous
                        </span>
                        <span className="text-sm">
                          Page {t_page + 1}
                          <span className="ml-1"></span>/{' '}
                          {Math.ceil(t_totalPages)}
                        </span>
                        <span
                          onClick={t_goNext}
                          className="relative ml-3 items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 border hover:bg-indigo-800 hover:text-white duration-500 cursor-pointer"
                        >
                          Next
                        </span>
                      </div>
                    </nav>
                  </>
                )}
                {currenttab == 'Student' && (
                  <>
                    <div className="absolute my-4 w-8/12 flex justify-end items-center text-right right-0 mr-8">
                      {/* <div className="w-1/4">
                        <Button
                          onClick={() => {
                            setEnabled(true)
                            setStudentModal((prev) => ({
                              ...prev,
                              s_state: true,
                              s_type: 'add',
                            }))
                          }}
                          className="flex justify-end items-center px-2 py-2"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-5 h-5 mt-0 mr-2"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M12 4.5v15m7.5-7.5h-15"
                            />
                          </svg>
                          Add
                        </Button>
                      </div> */}
                    </div>
                    {renderStudentTable()}
                    <nav
                      className="flex flex-col md:flex-row md:items-center md:justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
                      aria-label="Pagination"
                    >
                      <div>
                        <p className="text-sm text-gray-700">
                          Showing{' '}
                          <select
                            onChange={(e) => {
                              s_setLimit(e.target.value)
                              s_setPage(0)
                            }}
                            value={s_limit}
                          >
                            <option value={s_count}>{s_count}</option>
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                          </select>{' '}
                          of <span className="font-medium">{s_count}</span>{' '}
                          results
                        </p>
                      </div>
                      <div className="flex items-center md:justify-end">
                        <span
                          onClick={s_goPrev}
                          className="relative mr-3 items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 border hover:bg-indigo-800 hover:text-white duration-500 cursor-pointer"
                        >
                          Previous
                        </span>
                        <span className="text-sm">
                          Page {s_page + 1}
                          <span className="ml-1"></span>/{' '}
                          {Math.ceil(s_totalPages)}
                        </span>
                        <span
                          onClick={s_goNext}
                          className="relative ml-3 items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 border hover:bg-indigo-800 hover:text-white duration-500 cursor-pointer"
                        >
                          Next
                        </span>
                      </div>
                    </nav>
                  </>
                )}
                {currenttab == 'Classes' && (
                  <>
                    <div className="absolute my-4 w-8/12 flex justify-end items-center text-right right-0 mr-8">
                      {JSON.parse(localStorage.getItem('itkKey')).role !==
                        'HEAD_PRINCIPAL' && (
                        <div className="w-1/4">
                          <Button
                            onClick={() => {
                              setEnabled(true)
                              setClassModal((prev) => ({
                                ...prev,
                                c_state: true,
                                c_type: 'add',
                              }))
                            }}
                            className="flex justify-end items-center px-2 py-2"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-5 h-5 mt-0 mr-2"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 4.5v15m7.5-7.5h-15"
                              />
                            </svg>
                            Add
                          </Button>
                        </div>
                      )}
                    </div>
                    {renderClassTable()}
                    <nav
                      className="flex flex-col md:flex-row md:items-center md:justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
                      aria-label="Pagination"
                    >
                      <div>
                        <p className="text-sm text-gray-700">
                          Showing{' '}
                          <select
                            onChange={(e) => {
                              c_setLimit(e.target.value)
                              c_setPage(0)
                            }}
                            value={c_limit}
                          >
                            <option value={c_count}>{c_count}</option>
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                          </select>{' '}
                          of <span className="font-medium">{c_count}</span>{' '}
                          results
                        </p>
                      </div>
                      <div className="flex items-center md:justify-end">
                        <span
                          onClick={c_goPrev}
                          className="relative mr-3 items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 border hover:bg-indigo-800 hover:text-white duration-500 cursor-pointer"
                        >
                          Previous
                        </span>
                        <span className="text-sm">
                          Page {c_page + 1}
                          <span className="ml-1"></span>/{' '}
                          {Math.ceil(c_totalPages)}
                        </span>
                        <span
                          onClick={c_goNext}
                          className="relative ml-3 items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 border hover:bg-indigo-800 hover:text-white duration-500 cursor-pointer"
                        >
                          Next
                        </span>
                      </div>
                    </nav>
                  </>
                )}
              </>
            )}
          </div>
        </>
      )}
      <ToastContainer
        closeButton={false}
        closeOnClick={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
        autoClose={2000}
        hideProgressBar={false}
      />
    </Sidebar>
  )
}

export default School
