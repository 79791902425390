//  brand colors
export const k_itk_blue = '#06A199'
export const k_itk_yellow = '#FFC135'
export const k_itk_red = '#E4001B'
export const k_itk_gray = '#D6D7D8'
// meta component Ids
export const k_screenid = '5e2d56e82b1498060ce0a12c'
export const k_buttonid = '5e2d562d5c1b1049f0598157'
export const k_labelid = '5e2d57022b1498060ce0a12d'
export const k_textInputid = '5eaaf466fe24462574db5a35'
export const k_imageid = '5eaaf727fe24462574db5a36'
export const k_rowid = '5eab0155fe24462574db5a37'
export const k_columnid = '5eab0187fe24462574db5a38'
export const k_dataid = '5f330469fffa891db28c48a4'
export const k_switchid = '5f747a3ec1de491d064ed1b0'
export const k_videoid = '5f7d49bef8f14a40a0550e16'
export const k_dateTimeid = '5f9aa899f8f8ef0482badeb0'
export const k_iconid = '5fc85a3c7298d2041104a7ed'
export const k_radioButtonid = '5fcb2c1a7298d2041104a7f5'
export const k_customContainer = '60a3b93880553e03894be0b6'