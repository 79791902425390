import React from 'react'

const Layout = (props) => {
  if (props.children.length < 3) {
    return <h1 className="text-red-600">Please pass three children</h1>
  }
  return (
    <div className="flex mb-3">
      <div className="relative w-8/12 border-r-2">
        {props?.children?.[0]}
        {props.actionButton && (
          <div className="sticky bottom-0 left-0 right-0 flex justify-end px-4 pt-4 pb-5 bg-white border-t border-gray">
            {props.actionButton}
          </div>
        )}
      </div>
      <div className="flex-col w-4/12">
        <div className="pb-2 mb-2 border-b border-gray">
          {props?.children?.[1]}
        </div>
        <div
          className="pl-3 mt-2 overflow-y-scroll text-sm"
          style={{ height: '100vh' }}
        >
          {props?.children?.[2]}
        </div>
      </div>
    </div>
  )
}

export default Layout
