import * as actionTypes from '../actionTypes'

const firstState = {
  components: [],
  fetchLoading: false,
  fetchError: null,
  addLoading: false,
  addError: null,
  editLoading: false,
  editError: null,
  renameLoading: false,
  renameError: null,
  deleteLoading: false,
  deleteError: null,
  xmlLoading: false,
  xmlError: null,
  codeLoading: false,
  codeError: null,
  uploadLoading: false,
  uploadError: null,
}

const main = (state = firstState, action) => {
  switch (action.type) {
    case actionTypes.ADD_COMPONENT:
      return {
        ...state,
        addLoading: true,
      }
    case actionTypes.ADD_COMPONENT_SUCCESS:
      let x = {}
      x = action.payload
      return {
        ...state,
        addLoading: false,
        components: [...state.components, x.data],
      }
    case actionTypes.ADD_COMPONENT_FAILURE:
      return {
        ...state,
        addLoading: false,
        addError: action.error,
      }
    case actionTypes.FETCH_COMPONENTS:
      return {
        ...state,
        fetchLoading: true,
      }
    case actionTypes.FETCH_COMPONENTS_SUCCESS:
      return {
        ...state,
        components: action.payload,
        fetchLoading: false,
      }
    case actionTypes.FETCH_COMPONENTS_FAILURE:
      return {
        ...state,
        fetchLoading: false,
        fetchError: action.error,
      }
    case actionTypes.CHANGE_PROPERTY:
      return {
        ...state,
        editLoading: true,
      }
    case actionTypes.CHANGE_PROPERTY_SUCCESS:
      return {
        ...state,
        editLoading: false,
        editError: null,
        components: state.components.map((x) => {
          if (x._id === action.payload.cid) {
            return {
              ...x,
              properties: {
                ...x.properties,
                [action.payload.property_name]: {
                  ...x.properties[action.payload.property_name],
                  value: action.payload.property_value,
                },
              },
            }
          }
          return x
        }),
      }
    case actionTypes.CHANGE_PROPERTY_FAILURE:
      return {
        ...state,
        editLoading: false,
        editError: action.error,
      }
    case actionTypes.CHANGE_PARENT:
      return {
        ...state,
        editLoading: true,
      }
    case actionTypes.CHANGE_PARENT_SUCCESS:
      let newParentChildren = state.components.find(
        (x) => x._id === action.new_parent
      ).children
      if (action.position !== null && action.position !== undefined)
        newParentChildren.splice(action.position, 0, action.childId)
      else newParentChildren.push(action.childId)
      let oldParentChildren = state.components.find(
        (x) => x._id === action.old_parent
      ).children
      oldParentChildren.splice(oldParentChildren.indexOf(action.childId), 1)
      return {
        ...state,
        components: state.components.map((x) => {
          if (x._id === action.childId) {
            return {
              ...x,
              parent: action.new_parent,
            }
          } else if (x._id === action.new_parent) {
            return {
              ...x,
              children: newParentChildren,
            }
          } else if (x._id === action.old_parent) {
            return {
              ...x,
              children: oldParentChildren,
            }
          }
          return x
        }),
        editLoading: false,
      }
    case actionTypes.CHANGE_PARENT_FAILURE:
      return {
        ...state,
        editLoading: false,
        editError: action.error,
      }
    case actionTypes.ADD_CHILD:
      return {
        ...state,
        editLoading: true,
      }
    case actionTypes.ADD_CHILD_SUCCESS:
      let componentList = state.components.find(
        (x) => x._id === action.new_parent
      ).children
      if (action.pos === null || action.pos === undefined) {
        componentList.push(action.childId)
      } else {
        componentList.splice(action.pos, 0, action.childId)
      }
      return {
        ...state,
        components: state.components.map((x) => {
          if (x._id === action.new_parent) {
            return {
              ...x,
              children: componentList,
            }
          }
          return x
        }),
        editLoading: false,
      }
    case actionTypes.DROP_CHILD:
      return {
        ...state,
        editLoading: true,
      }
    case actionTypes.DROP_CHILD_SUCCESS:
      return {
        ...state,
        components: state.components.map((x) => {
          if (x._id === action.old_parent) {
            return {
              ...x,
              children: x.children.filter((t) => t !== action.childId),
            }
          }
          return x
        }),
        editLoading: false,
      }
    case actionTypes.RENAME_COMPONENT:
      return {
        ...state,
        renameLoading: true,
      }
    case actionTypes.RENAME_COMPONENT_SUCCESS:
      return {
        ...state,
        components: state.components.map((x) => {
          if (x._id === action.payload.cid) {
            return {
              ...x,
              name: action.payload.new_name,
            }
          }
          return x
        }),
        renameLoading: false,
      }
    case actionTypes.RENAME_COMPONENT_FAILURE:
      return {
        ...state,
        renameLoading: false,
        renameError: action.error,
      }
    case actionTypes.CHANGE_POSITION:
      return {
        ...state,
        editLoading: true,
      }
    case actionTypes.CHANGE_POSITION_SUCCESS:
      let parentChildren = state.components.find(
        (x) => x._id === action.payload.parent_id
      ).children
      parentChildren.splice(parentChildren.indexOf(action.payload.cid), 1)
      if (
        action.payload.new_position !== null &&
        action.payload.new_position !== undefined
      ) {
        parentChildren.splice(
          action.payload.new_position,
          0,
          action.payload.cid
        )
      } else {
        parentChildren.push(action.payload.cid)
      }
      return {
        ...state,
        components: state.components.map((t) => {
          if (t._id === action.payload.parent_id) {
            return {
              ...t,
              children: parentChildren,
            }
          }
          return t
        }),
        editLoading: false,
        editError: null,
      }
    case actionTypes.CHANGE_POSITION_FAILURE:
      return {
        ...state,
        editLoading: false,
        editError: action.error,
      }
    case actionTypes.UPDATE_SCREEN_XML:
      return {
        ...state,
        xmlLoading: true,
      }
    case actionTypes.UPDATE_SCREEN_XML_SUCCESS:
      return {
        ...state,
        components: state.components.map((t) => {
          if (t._id === action.cid) {
            return {
              ...t,
              xml: action.xml,
            }
          }
          return t
        }),
        xmlLoading: false,
        xmlError: null,
      }
    case actionTypes.UPDATE_SCREEN_XML_FAILURE:
      return {
        ...state,
        xmlLoading: false,
        xmlError: action.error,
      }
    case actionTypes.UPDATE_SCREEN_CODE:
      return {
        ...state,
        codeLoading: true,
      }
    case actionTypes.UPDATE_SCREEN_CODE_SUCCESS:
      return {
        ...state,
        components: state.components.map((t) => {
          if (t._id === action.cid) {
            return {
              ...t,
              code: action.code,
            }
          }
          return t
        }),
        codeLoading: false,
        codeError: null,
      }
    case actionTypes.UPDATE_SCREEN_CODE_FAILURE:
      return {
        ...state,
        codeLoading: false,
        codeError: action.error,
      }
    case actionTypes.DUPLICATE_COMPONENT:
      return {
        ...state,
        editLoading: true,
      }
    case actionTypes.DELETE_COMPONENT:
      return {
        ...state,
        deleteLoading: true,
      }
    case actionTypes.DELETE_COMPONENT_SUCCESS:
      return {
        ...state,
        components: state.components.filter((t) => t._id !== action.cid),
        deleteLoading: false,
      }
    case actionTypes.DELETE_COMPONENT_FAILURE:
      return {
        ...state,
        deleteLoading: false,
        deleteError: action.error,
      }
    case actionTypes.DELETE_CHILDREN_SUCCESS:
      return {
        ...state,
        components: state.components.filter(
          (c) => !action.childids.includes(c._id)
        ),
        deleteLoading: false,
        deleteError: null,
      }
    case actionTypes.DELETE_CHILDREN_FAILURE:
      return {
        ...state,
        deleteLoading: false,
        deleteError: action.error,
      }
    case actionTypes.UPLOAD_IMAGE:
      return {
        ...state,
        uploadLoading: true,
      }
    case actionTypes.UPLOAD_IMAGE_SUCCESS:
      return {
        ...state,
        uploadLoading: false,
        components: state.components.map((c) => {
          if (c._id === action.cid) {
            if (Array.isArray(c.properties.src.value)) {
              c.properties.src.value.map((i) => {
                i.selected = 0
                return null
              })
              c.properties.src.value.push({
                name: action.name,
                location: action.location,
                selected: 1,
              })
            }
          }
          return c
        }),
      }
    case actionTypes.UPLOAD_IMAGE_FAILURE:
      return {
        ...state,
        uploadLoading: false,
        uploadError: action.error,
      }
    case actionTypes.CHANGE_IMAGE:
      return {
        ...state,
        editLoading: true,
      }
    case actionTypes.CHANGE_IMAGE_SUCCESS:
      return {
        ...state,
        editLoading: false,
        components: state.components.map((c) => {
          if (c._id === action.cid) {
            if (Array.isArray(c.properties.src.value)) {
              c.properties.src.value.map((i) => {
                i.selected = 0
                if (i.location === action.location) i.selected = 1
                return null
              })
            }
          }
          return c
        }),
      }
    case actionTypes.CHANGE_IMAGE_FAILURE:
      return {
        ...state,
        editLoading: false,
        editError: action.error,
      }
    default:
      return state
  }
}

export default main
