import { useState, useEffect, useCallback } from 'react'
import HalfGeneral from '../components/half-general-slideover'
import Button from '../components/button'
import app_api from '../config/api'
import Table from '../components/tables/table'
import { exercise_column } from '../components/tables/tableheader'
import { Sidebar } from '../components/navigation/sidebar'
import ConfirmDialog from '../components/dialog/confirmation_dialog'
import { Formik } from 'formik'
import { exerciseSchema } from '../schema'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Switch } from '@headlessui/react'
import { classNames } from '../helpers/classname'
import Select from 'react-select'
import { PhotoIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { useNavigate, useParams } from 'react-router-dom'
import ExerciseSidebar from '../components/navigation/exerciseSidebar'

const SingleExercisePage = () => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [TabData, setTabData] = useState({})
  const [enabled, setEnabled] = useState(true)
  const [currentExercise, setCurrentExercise] = useState(null)

  useEffect(() => {
    getExerciseData()
  }, [])

  const params = useParams()

  const getExerciseData = () => {
    let url = `exercise-master/${params.exerciseId}`
    app_api
      .get(url)
      .then((res) => res.data)
      .then((res) => {
        setError(null)
        setTabData(res.data)
        console.log(res.data?.levelSequence)
        let current = res.data?.WebLevel.find(
          (wl) => wl.id === res.data?.levelSequence[0]
        )
        console.log(current)
        // setCurrentExercise(current || null)
        setLoading(false)
      })
      .catch((err) => {
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const updateCurrentExercise = (c) => {
    let content = (TabData?.WebLevel || []).find((wl) => wl.id === c.id)
    setCurrentExercise(content)
  }

  const goToNext = () => {
    let courseOrder = Array.from(TabData?.levelSequence || [])
    const findIndex = courseOrder.findIndex((id) => id === currentExercise.id)
    // let allCompleted =
    //   courseContent.filter((c) => c.status === 'PENDING').length === 0
    // if (allCompleted) navigate('/success')
    // else {
    //  setCurrentExercise(courseContent[findIndex + 1])
    // }
    if (findIndex < courseOrder.length - 1) {
      setCurrentExercise(TabData.WebLevel[findIndex + 1])
    } else {
      setCurrentExercise(TabData.WebLevel?.[0])
    }
  }

  return (
    <Sidebar isCollapsed={true}>
      <div className="px-4 pt-2 sm:px-6 lg:px-8 ContainerUI sticky">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-3xl font-semibold text-gray-900">
              Exercise: {TabData?.name}
            </h1>
          </div>
        </div>
        {loading ? (
          <div className="flex items-center justify-center">
            <div
              className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : error !== null ? (
          <span>{error}</span>
        ) : (
          <div className="justify-end items-center text-right">
            <ExerciseSidebar
              categories={TabData?.WebLevel.sort(
                (a, b) =>
                  TabData.levelSequence.indexOf(a.id) -
                  TabData.levelSequence.indexOf(b.id)
              )}
              currentContent={currentExercise}
              setCurrentExercise={updateCurrentExercise}
              goToNext={goToNext}
            />
          </div>
        )}
      </div>
      <ToastContainer
        closeButton={false}
        closeOnClick={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
        autoClose={2000}
        hideProgressBar={false}
      />
    </Sidebar>
  )
}

export default SingleExercisePage
