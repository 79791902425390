import React from 'react'
// Component
// Constants
import {
  k_buttonid,
  k_labelid,
  k_textInputid,
  k_imageid,
  k_videoid,
  k_rowid,
  k_columnid,
  k_dataid,
  k_switchid,
  k_dateTimeid,
  k_iconid,
  k_radioButtonid,
  k_customContainer,
} from '../../constants'
// static files
import button from '../../assets/images/button.svg'
import label from '../../assets/images/label.svg'
import textBox from '../../assets/images/password.svg'
import imgSvg from '../../assets/images/imageBlue.svg'
import vidSvg from '../../assets/images/videoBlue.svg'
import row from '../../assets/images/row.svg'
import column from '../../assets/images/column.svg'
import data from '../../assets/images/tableDB.svg'
import dateTime from '../../assets/images/dateTimeBlack.svg'
import switchIcon from '../../assets/images/switch.svg'
import icon from '../../assets/images/iconBlue.svg'
import radio from '../../assets/images/radioButtonBlack.svg'
import styled from 'styled-components'

const MetaComponentDiv = styled.div`
  width: 120px;
  min-width: 120px;
  height: 17px;
  padding: 4px 0;
  border: 1px solid var(--itk-gray-color);
  border-radius: 25px;
  margin-top: 5px;
  margin-bottom: 2px;
  background-color: white;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  position: relative;
  cursor: grab;

  &:hover {
    box-shadow: 1px 2px 4px 1px var(--itk-gray-color),
      -1px -2px 4px 1px rgba(255, 255, 255, 0.5);
    font-weight: bold;
    color: var(--itk-blue-color);
  }

  &:active {
    box-shadow: none;
    font-weight: normal;
  }

  & img {
    margin: auto 10px;
    height: 15px;
    width: 15px;
  }

  & > i {
    position: absolute;
    right: 0;
    padding: 5px 10px;
    float: right;
  }

  @media screen and (min-width: 1000px) and (max-width: 1100px) {
    width: 90px;
    height: 15px;
  }

  @media screen and (min-width: 1101px) and (max-width: 1200px) {
    width: 125px;
    height: 20px;
  }

  @media screen and (min-width: 1201px) and (max-width: 1400px) {
    width: 150px;
    height: 25px;
    & img {
      margin: auto 15px;
      height: 17px;
      width: 17px;
    }
  }

  @media screen and (min-width: 1401px) and (max-width: 1600px) {
    width: 180px;
    height: 30px;

    & img {
      margin: auto 17px;
      height: 20px;
      width: 20px;
    }
  }

  @media screen and (min-width: 1601px) {
    width: 220px;
    height: 35px;
    margin: 5px 30px 2px 30px;
    & img {
      margin: auto 20px;
      height: 25px;
      width: 25px;
    }
  }
`

const dragStartHandler = (e) => {
  e.dataTransfer.effectAllowed = 'copy'
  e.dataTransfer.setData('type', e.target.getAttribute('data-type'))
  // console.log(e.dataTransfer)
  return true
}

export const metaComponent = (props) => {
  let image = null
  let helperText = null
  switch (props.id) {
    case k_buttonid:
      image = <img src={button} alt="button" />
      break
    case k_labelid:
      image = <img src={label} alt="label" />
      break
    case k_textInputid:
      image = <img src={textBox} alt="textbox" />
      break
    case k_imageid:
      image = <img src={imgSvg} alt="Image" />
      break
    case k_videoid:
      image = <img src={vidSvg} alt="video" />
      break
    case k_rowid:
      image = <img src={row} alt="row" />
      helperText = 'Place components next to each other'
      break
    case k_columnid:
      image = <img src={column} alt="column" />
      helperText = 'Place components above and below each other'
      break
    case k_customContainer:
      image = <img src={row} alt="container" />
      helperText = 'Place components dynamically, based on table data'
      break
    case k_dataid:
      image = <img src={data} alt="data" />
      break
    case k_switchid:
      image = <img src={switchIcon} alt="switch" />
      break
    case k_dateTimeid:
      image = <img src={dateTime} alt="Date time input" />
      break
    case k_iconid:
      image = <img src={icon} alt="Icon" />
      break
    case k_radioButtonid:
      image = <img src={radio} alt="Radio button" />
      break
    default:
      break
  }

  const firstLetter = props.name.charAt(0)
  const firstLetterCap = firstLetter.toUpperCase()
  const remainingLetters = props.name.slice(1)
  const capitalizedWord = firstLetterCap + remainingLetters

  return (
    <div
      className="flex flex-col gap-1"
      data-type={props.c_type}
      onDragStart={dragStartHandler}
      draggable
    >
      <div
        data-type={props.c_type}
        className="flex justify-start px-1 font-semibold"
      >
        <span data-type={props.c_type} className="text-sm">
          {capitalizedWord}
        </span>
      </div>
      <div
        data-type={props.c_type}
        className="flex justify-center h-28 w-full bg-gray-100 items-center border border-gray-100 rounded-md"
      >
        {image}
      </div>
    </div>
  )
}
