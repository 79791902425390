import { useState, useEffect, useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import Button from '../components/button'
import app_api from '../config/api'
import Table from '../components/tables/table'
import { class_exercise_column } from '../components/tables/tableheader'
import { Sidebar } from '../components/navigation/sidebar'
import ConfirmDialog from '../components/dialog/confirmation_dialog'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const initialModalState = {
  type: '',
  state: false,
  index: null,
  edit_id: '',
  data: {
  },
}

const StudentExercise = () => {
  const [modal, setModal] = useState(initialModalState)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [category, setCategory] = useState([])
  const [schoolData, setSchoolData] = useState([])
  const [confirmationDialog, setConfirmationDialog] = useState(false)
  const [roleName, setRoleName] = useState('')
  const [TabData, setTabData] = useState([])
  const [enabled, setEnabled] = useState(true)
  // Pagination Start
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(15)
  const [totalPages, setTotalPages] = useState(1)
  const [count, setCount] = useState(0)
  const [searchFilter, setSearchFilter] = useState('')
  // Pagination End
  const params = useParams()

  const goPrev = () => {
    if (page > 0) setPage((prev) => --prev)
  }

  const goNext = () => {
    if (page < totalPages - 1) setPage((prev) => ++prev)
  }

  const renderTable = useCallback(() => {
    return (
      <>
       <Table
          progPage={page}
          totalCount={count}
          columns={class_exercise_column({ onEditOpen })}
          data={TabData}
          setSearchFilter={setSearchFilter}
        />
      </>
    )
  }, [TabData])

  useEffect(() => {
    getCategoryData()
  }, [page, limit, searchFilter, params])

  const onEditOpen = (id, index) => {
    setModal((prev) => ({ ...prev, id: id, index: index , data: TabData[index]}))
    setConfirmationDialog(true)
  }

  const getCategoryData = () => {
    let url = `student-master/exercise/${params.id}?page=${page}&size=${limit}&searchFilter=${searchFilter}`
    app_api
      .get(url)
      .then((res) => res.data)
      .then((res) => {
        setError(null)
        setCategory(res.data.data)
        setTabData(res.data.data)
        setCount(res.data.count)
        setTotalPages(res.data.count / limit)
        setLoading(false)
      })
      .catch((err) => {
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  const onEditClassExercise = () => {
    let payload
    if(modal.data.status ==  true){
        payload = {
            status: false
        }
    }
    else{
        payload = {
            status: true
        }
    }
    payload.id = modal.data.id
    let url = `student-master/exercise/${modal.id}`
    app_api
      .patch(url,payload)
      .then((res) => res.data)
      .then((res) => {
        toast.success('Updated Successfully')
        getCategoryData()
        setConfirmationDialog(false)
      })
      .catch((err) => {
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  return (
    <Sidebar>
      <ConfirmDialog
        setOpen={setConfirmationDialog}
        open={confirmationDialog}
        onDelete={onEditClassExercise}
        description = {TabData[modal.index]?.status == true ? 'Do you really want to disable this exercise?' : 'Do you really want to enable this exercise?'}
        confirmationButtonText = {TabData[modal.index]?.status == true ? 'Disable' : 'Enable'}
      />
      <div className="px-4 pt-2 sm:px-6 lg:px-8 ContainerUI sticky">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-3xl font-semibold text-gray-900">Student Exercise</h1>
          </div>
          <div className="sm:mt-0 sm:ml-16 sm:flex-none">
            <Button
              onClick={() =>{
                setEnabled(true)
                setModal((prev) => ({ ...prev, state: true, type: 'add' }))
              }}
              className="flex justify-center items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5 mt-0 mr-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 4.5v15m7.5-7.5h-15"
                />
              </svg>
              Add
            </Button>
          </div>
        </div>
        {loading ? (
          <div className="flex items-center justify-center">
            <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) :
          <>
        <div className="absolute my-5 w-8/12 flex justify-end items-center text-right right-0 mr-2">
      </div>
        {renderTable()}
          <nav
        className="flex flex-col md:flex-row md:items-center md:justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
        aria-label="Pagination"
      >
        <div>
          <p className="text-sm text-gray-700">
            Showing{' '}
            <select
              onChange={(e) => {
                setLimit(e.target.value)
                setPage(0)
              }}
              value={limit}
            >
              <option value={count}>{count}</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
            </select>{' '}
            of <span className="font-medium">{count}</span> results
          </p>
        </div>
        <div className="flex items-center md:justify-end">
          <span
            onClick={goPrev}
            className="relative mr-3 items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 border hover:bg-indigo-800 hover:text-white duration-500 cursor-pointer"
          >
            Previous
          </span>
          <span className="text-sm">
            Page{' '}{page + 1}
            <span className='ml-1'></span>/ {Math.ceil(totalPages)}
          </span>
          <span
            onClick={goNext}
            className="relative ml-3 items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 border hover:bg-indigo-800 hover:text-white duration-500 cursor-pointer"
          >
            Next
          </span>
        </div>
      </nav>
      </>}
      </div>
      <ToastContainer
        closeButton={false}
        closeOnClick={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false} 
        autoClose={2000}
        hideProgressBar={false}/>
    </Sidebar>
  )
}

export default StudentExercise
