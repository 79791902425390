import { Fragment, useState, useEffect, Children } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon, CheckCircleIcon, ExclamationCircleIcon, DocumentMagnifyingGlassIcon } from '@heroicons/react/24/outline'
export default function HalfGeneralSlideover(props) {
  const { title, open, setOpen, children, handleClick, type, pageType } = props

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto half_slideover">
                <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                  {
                    type == 'PAID' || type =='Issue Refund' ? <>
                        <div className="bg-green-800 px-4 py-6 sm:px-6 ">
                          <div className="flex justify-between">
                            <div className='flex items-center'>
                            <Dialog.Title className="text-base font-semibold leading-6 text-white">
                              {title}
                            </Dialog.Title>
                            <div className='text-center text-white ml-3'>
                                <CheckCircleIcon className='w-5 text-green-200 ' />
                              </div>
                              <div className='text-center text-white ml-1'>
                                <span className="">{type}</span>
                              </div>
                            </div>
                            <div className="ml-3 flex h-7 items-center">
                              <button
                                type="button"
                                className="rounded-md bg-green-700 text-green-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                onClick={() => setOpen(false)}
                              >
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                      :
                      <>
                      <div className="bg-indigo-900 px-4 py-6 sm:px-6">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="text-base font-semibold leading-6 text-white">
                          {title}
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                            onClick={() => setOpen(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                      </>
                  }
                  {
                    pageType == 'Teacher' && (
                      <>
                        <div className='bg-yellow-300 text-orange-800 font-semibold py-1 flex justify-center items-center'>
                          <DocumentMagnifyingGlassIcon className='w-5 text-yellow-600 mr-2 ' />
                          <span className='text-yellow-600'> Location Manager should check all details properly</span>
                        </div>   
                      </>
                    )
                  }
                    <div className='px-4 w-full'>
                        {children}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
