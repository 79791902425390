import React, { Component, Fragment } from 'react'
// Redux
import { connect } from 'react-redux'
import { changeProperty_OnTheGo } from '../../../redux/actions'
import styled from 'styled-components'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline'

const ChangableUnitDiv = styled.div`
  width: 80%;
  display: flex;

  & input {
    width: 50% !important;
  }

  & select {
    width: 50% !important;
    height: 20px;
  }
`

const PropertyDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 0;
  border-bottom: lightgray 1px solid;
  row-gap: 5px;

  & input {
    width: 40%;
    font-size: 12px;
    box-sizing: border-box;
    padding-left: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 0.3em;
    border-color: var(--itk-gray-color);
    border-width: 1px;

    &:focus {
      box-shadow: 0px 0px 3px 3px var(--itk-yellow-color);
    }
  }

  & select {
    width: 40%;
    font-size: 12px;
    box-sizing: border-box;
    padding-left: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 0.3em;
    border-color: var(--itk-gray-color);
    border-width: 1px;

    &:focus {
      box-shadow: 0px 0px 3px 3px var(--itk-yellow-color);
    }
  }

  & label {
    text-transform: capitalize;
    font-size: 12px;
    flex: 1;
  }
`

const RowLabelDiv = styled.div`
  display: flex;
  align-items: center;
`

class SpecialProperty extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: this.props.value,
      changed: false,
    }
  }

  async shouldComponentUpdate(nextProps, nextState) {
    if (this.props.id !== nextProps.id) {
      this.setState({
        value: nextProps.value,
      })
      return true
    }
    return false
  }

  onEditClick = () => {
    this.setState({ changed: true })
  }

  onEdit = (e) => {
    this.props.onTheGoEdit(this.props.id, this.props.name, e.target.value)
    this.setState({ value: e.target.value, changed: true })
  }

  onSpecialEdit = (e, inputtype) => {
    switch (inputtype) {
      case 'changableUnit':
        let value = ''
        if (this.state.value.match(/(\d*\.?\d*)(.*)/))
          value = this.state.value.match(/(\d*\.?\d*)(.*)/)[1]
        else if (!!this.state.value) value = this.state.value
        this.props.onTheGoEdit(
          this.props.id,
          this.props.name,
          value + e.target.value
        )
        this.setState({ value: value + e.target.value, changed: true })
        break
      case 'changableUnitValue':
        let unit = ''
        if (this.state.value.match(/(\d*\.?\d*)(.*)/))
          unit = this.state.value.match(/(\d*\.?\d*)(.*)/)[2]
        else if (!!this.state.value) unit = this.state.value
        this.props.onTheGoEdit(
          this.props.id,
          this.props.name,
          e.target.value + unit
        )
        this.setState({ value: e.target.value + unit, changed: true })
        break
      default:
        break
    }
  }

  onSave = () => {
    this.setState({ changed: false })
    this.props.edit(this.props.id, this.props.name, this.state.value)
  }

  onCancel = () => {
    this.props.onTheGoEdit(this.props.id, this.props.name, this.props.value)
    this.setState({ value: this.props.value, changed: false })
  }

  render() {
    let name = this.props.name.replace(this.props.subCategory, '')
    let inputbox = (
      <input
        type="textbox"
        name={this.props.name}
        value={this.state.value}
        onChange={this.onEdit}
      />
    )
    if (
      this.props.name === 'width' ||
      this.props.name === 'height' ||
      this.props.name === 'fontSize' ||
      this.props.name === 'borderRadius' ||
      this.props.name === 'borderWidth' ||
      this.props.name.match(/padding.*/) ||
      this.props.name.match(/margin.*/) ||
      this.props.name === 'columnGap' ||
      this.props.name === 'gap'
    ) {
      if (this.state.value.match(/px/)) {
        inputbox = (
          <div className="relative m-1 rounded-md w-full">
            <input
              type="number"
              id={this.props.name}
              name={this.props.name}
              value={this.state.value.replace(/px/, '')}
              onChange={(e) => this.onSpecialEdit(e, 'changableUnitValue')}
              className="block w-full no-border rounded-md bg-transparent border-0 px-2 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6"
              // placeholder={this.props.name}
            />
            <div className="absolute inset-y-0 right-0 flex items-center">
              <select
                value="px"
                className="h-full rounded-md border-0 bg-transparent text-gray-500 sm:text-sm"
                onChange={(e) => this.onSpecialEdit(e, 'changableUnit')}
              >
                <option value=""></option>
                <option value="px">px</option>
                <option value="%">%</option>
              </select>
            </div>
          </div>
        )
      } else if (this.state.value.match(/%/)) {
        inputbox = (
          <div className="relative m-1 rounded-md w-full">
            <input
              type="number"
              id={this.props.name}
              name={this.props.name}
              value={this.state.value.replace(/%/, '')}
              onChange={(e) => this.onSpecialEdit(e, 'changableUnitValue')}
              className="block w-full no-border rounded-md bg-transparent border-0 px-2  text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6"
              // placeholder={this.props.name}
            />
            <div className="absolute inset-y-0 right-0 flex items-center">
              <select
                value="%"
                className="h-full rounded-md border-0 bg-transparent text-gray-500 sm:text-sm"
                onChange={(e) => this.onSpecialEdit(e, 'changableUnit')}
              >
                <option value=""></option>
                <option value="px">px</option>
                <option value="%">%</option>
              </select>
            </div>
          </div>
        )
      } else {
        inputbox = (
          <div className="relative m-1 rounded-md w-full">
            <input
              type="number"
              id={this.props.name}
              name={this.props.name}
              value={this.state.value}
              onChange={(e) => this.onSpecialEdit(e, 'changableUnitValue')}
              className="block w-full no-border rounded-md bg-transparent border-0 px-2 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6"
              // placeholder={this.props.name}
            />
            <div className="absolute inset-y-0 right-0 flex items-center">
              <select
                value=""
                className="h-full rounded-md border-0 bg-transparent text-gray-500 sm:text-sm"
                onChange={(e) => this.onSpecialEdit(e, 'changableUnit')}
              >
                <option value=""></option>
                <option value="px">px</option>
                <option value="%">%</option>
              </select>
            </div>
          </div>
        )
      }
    }

    name = name.replace(/([A-Z])/g, '')

    return (
      <div className="mt-1 w-20">
          {inputbox}
        {this.state.changed && (
          <div className='flex'>
            <button onClick={this.onSave}>
              <CheckCircleIcon className="text-green-500 w-6" />
            </button>
            <button onClick={this.onCancel}>
              <XCircleIcon className="text-red-500 w-6" />
            </button>
          </div>
        )}
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  onTheGoEdit: (id, propertyName, value) =>
    dispatch(changeProperty_OnTheGo(id, propertyName, value)),
})

export default connect(null, mapDispatchToProps)(SpecialProperty)
