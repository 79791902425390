import { Routes, Route } from 'react-router-dom'
import './App.css'
import Auth from './pages/auth'
import SingleExamPage from './pages/single_exam_page'
import ProtectedRoutes from './components/protected routes/ProtectedRoutes'
import { Logout } from './pages/logout'
import NotFound from './pages/NotFound'
import { AccessControl } from './components/auth/accessControl'
import ResetAuth from './pages/resetAuth'
import ForgotPasssword from './pages/forgotPassword'
import UserPage from './pages/userPage'
import School from './pages/school'
import ExercisePage from './pages/exercise'
import GradeMaster from './pages/gradeMaster'
import DivisionMaster from './pages/divisionMaster'
import StudentMaster from './pages/studentsMaster'
import ClassExercise from './pages/classExercise'
import StudentExercise from './pages/studentExercise'
import SingleExercisePage from './pages/singleExercise'
import Project from './pages/Project/Project'
import { Provider } from 'react-redux'
import store from './redux/store'
import { GameLab } from './pages/GameLab'
import { GameExercises } from './components/gameExercises'
import { MazeLab } from './pages/MazeLab'
import ProjectList from './pages/ProjectList/ProjectList'
import { MazeLabMaster } from './components/mazeExercises'

function App() {
  return (
    <Routes>
      <Route path="/" element={<Auth />} />

      <Route element={<ProtectedRoutes />}>
        <Route path="logout" element={<Logout />} />
        <Route
          path="/exam/:id"
          element={
            <AccessControl requiredRole={['ADMIN', 'HEAD_PRINCIPAL']}>
              <SingleExamPage />
            </AccessControl>
          }
        />
        <Route
          path="/class-exercise/:id"
          element={
            <AccessControl
              requiredRole={['ADMIN', 'TEACHER', 'HEAD_PRINCIPAL']}
            >
              <ClassExercise />
            </AccessControl>
          }
        />
        <Route
          path="/student-exercise/:id"
          element={
            <AccessControl
              requiredRole={['ADMIN', 'TEACHER', 'HEAD_PRINCIPAL']}
            >
              <StudentExercise />
            </AccessControl>
          }
        />
        <Route
          path="users"
          element={
            <AccessControl requiredRole={['ADMIN']}>
              <UserPage />
            </AccessControl>
          }
        ></Route>
        <Route
          path="schools"
          element={
            <AccessControl
              requiredRole={['ADMIN', 'TEACHER', 'HEAD_PRINCIPAL']}
            >
              <School />
            </AccessControl>
          }
        ></Route>
        <Route
          path="school/:id"
          element={
            <AccessControl
              requiredRole={['ADMIN', 'TEACHER', 'HEAD_PRINCIPAL']}
            >
              <School />
            </AccessControl>
          }
        ></Route>
        <Route
          path="grade-master"
          element={
            <AccessControl
              requiredRole={['ADMIN', 'HEAD_PRINCIPAL', 'TEACHER']}
            >
              <GradeMaster />
            </AccessControl>
          }
        ></Route>
        <Route
          path="division-master"
          element={
            <AccessControl
              requiredRole={['ADMIN', 'HEAD_PRINCIPAL', 'TEACHER']}
            >
              <DivisionMaster />
            </AccessControl>
          }
        ></Route>
        <Route
          path="student-master"
          element={
            <AccessControl
              requiredRole={['ADMIN', 'TEACHER', 'HEAD_PRINCIPAL']}
            >
              <StudentMaster />
            </AccessControl>
          }
        ></Route>
        <Route
          path="web-lab"
          element={
            <AccessControl
              requiredRole={['ADMIN', 'TEACHER', 'HEAD_PRINCIPAL']}
            >
              <ExercisePage />
            </AccessControl>
          }
        ></Route>
        <Route
          path="web-lab/:exerciseId"
          element={
            <AccessControl
              requiredRole={['ADMIN', 'TEACHER', 'HEAD_PRINCIPAL']}
            >
              <SingleExercisePage />
            </AccessControl>
          }
        ></Route>
        <Route
          path="app-lab"
          element={
            <AccessControl
              requiredRole={['ADMIN', 'TEACHER', 'HEAD_PRINCIPAL']}
            >
              <ProjectList />
            </AccessControl>
          }
        ></Route>
        <Route
          path="app-lab/:pid"
          element={
            <AccessControl
              requiredRole={['ADMIN', 'TEACHER', 'HEAD_PRINCIPAL']}
            >
              <Provider store={store}>
                <Project />
              </Provider>
            </AccessControl>
          }
        ></Route>
        <Route
          path="game-lab"
          element={
            <AccessControl
              requiredRole={['ADMIN', 'TEACHER', 'HEAD_PRINCIPAL']}
            >
              <GameExercises />
            </AccessControl>
          }
        ></Route>
        <Route
          path="game-lab/:id"
          element={
            <AccessControl
              requiredRole={['ADMIN', 'TEACHER', 'HEAD_PRINCIPAL']}
            >
              <GameLab />
            </AccessControl>
          }
        ></Route>
        <Route
          path="maze-lab"
          element={
            <AccessControl
              requiredRole={['ADMIN', 'TEACHER', 'HEAD_PRINCIPAL']}
            >
              <MazeLabMaster />
            </AccessControl>
          }
        ></Route>
        <Route
          path="maze-lab/:id"
          element={
            <AccessControl
              requiredRole={['ADMIN', 'TEACHER', 'HEAD_PRINCIPAL']}
            >
              <MazeLab />
            </AccessControl>
          }
        ></Route>
      </Route>
      <Route path="resetpassword" element={<ResetAuth />} />
      <Route path="*" element={<NotFound />} />
      <Route path="reset-password/:id" element={<ForgotPasssword />} />
    </Routes>
  )
}

export default App
