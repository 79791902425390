import * as Blockly from 'blockly/core'
import 'blockly/javascript'

// const specialProperties = [
//   "text",
//   "type",
//   "name",
//   "placeholder",
//   "src",
//   "alt",
//   "label",
//   "selected",
// ];
const textboxAttributes = ['type', 'placeholder', 'name']
const datetimeAttributes = ['type']
const radioButtonAttributes = ['checked', 'textContent']

Blockly.JavaScript['when_do_screen'] = function (block) {
  var screen = block.getFieldValue('current_screen_input')
  var statements_do = Blockly.JavaScript.statementToCode(block, 'do')
  var code = `if (screenDiv.id === '_${screen}') {
        ${statements_do}
    };`
  // console.log("when_do_screen code", code);
  return code
}

Blockly.JavaScript['al_screen_getter'] = function (block) {
  var dropdown_set_screen_options = block.getFieldValue('current_screen_input')
  var dropdown_screen_properties = block.getFieldValue('screen_properties')
  // TODO: Assemble JavaScript into code variable.
  var code = `window.getComputedStyle(document.getElementById('_${dropdown_set_screen_options}')).${dropdown_screen_properties}`
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, null]
}

Blockly.JavaScript['al_screen_setter'] = function (block) {
  var dropdown_set_screen_options = block.getFieldValue('current_screen_input')
  var dropdown_screen_properties = block.getFieldValue('screen_properties')
  var value_set_screen =
    Blockly.JavaScript.valueToCode(block, 'set screen', null) || '""'
  // if (!value_set_screen.match('state')) {
  //     value_set_screen = "\"" + value_set_screen + "\""
  // }
  // TODO: Assemble JavaScript into code variable.
  var code = `document.getElementById('_${dropdown_set_screen_options}').style.${dropdown_screen_properties} = ${value_set_screen};`
  // TODO: Change ORDER_NONE to the correct strength.
  return code
}

Blockly.JavaScript['al_move_screen'] = function (block) {
  var dropdown_screen_options = block.getFieldValue('screen_input')
  // TODO: Assemble JavaScript into code variable.
  // var code = 'this.props.navigation.navigate("' + dropdown_screen_options + '");';
  var code = `onNavigate('/${dropdown_screen_options}');`
  return code
}

Blockly.JavaScript['when_do_buttons'] = function (block) {
  var button_input = block.getFieldValue('button_input')
  // var dropdown_action_block = block.getFieldValue('action_options');
  var statements_do = Blockly.JavaScript.statementToCode(block, 'do')
  // TODO: Assemble JavaScript into code variable.
  var code = `
if (button${button_input}) {
  button${button_input}.addEventListener('click', async function () {
    ${statements_do}
  });
}
`
  // console.log("when_do_buttons code", code);
  return code
}

Blockly.JavaScript['al_button_getter'] = function (block) {
  var button_input = block.getFieldValue('button_input')
  var button_properties = block.getFieldValue('button_properties')
  // TODO: Assemble JavaScript into code variable.
  var code = ``
  if (button_properties === 'textContent') {
    //     code = 'this.state["' + dropdown_component_input + '"].' + button_properties;
    code = `document.getElementById('_${button_input}').textContent`
  } else {
    //     code = 'this.state["' + dropdown_component_input + '"].style.' + button_properties;
    code = `window.getComputedStyle(document.getElementById('_${button_input}')).${button_properties}`
  }
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, null]
}

Blockly.JavaScript['al_button_setter'] = function (block) {
  var button_input = block.getFieldValue('button_input')
  var button_properties = block.getFieldValue('button_properties')
  var value_set_button =
    Blockly.JavaScript.valueToCode(block, 'set button', null) || '""'
  // if (!value_set_button.match('state')) {
  //     value_set_button = "\"" + value_set_button + "\""
  // }
  // TODO: Assemble JavaScript into code variable.
  var code = ``
  if (button_properties === 'textContent') {
    code = `document.getElementById('_${button_input}').textContent = ${value_set_button};`
  } else {
    code = `document.getElementById('_${button_input}').style.${button_properties} = ${value_set_button};`
  }
  return code
}

Blockly.JavaScript['al_label_getter'] = function (block) {
  var label_id = block.getFieldValue('label_input')
  var label_prop = block.getFieldValue('label_properties')
  // TODO: Assemble JavaScript into code variable.
  var code = ``
  if (label_prop === 'textContent') {
    code = `document.getElementById('_${label_id}').textContent`
  } else {
    code = `window.getComputedStyle(document.getElementById('_${label_id}')).${label_prop}`
  }
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, null]
}

Blockly.JavaScript['al_label_setter'] = function (block) {
  var label_id = block.getFieldValue('label_input')
  var label_prop = block.getFieldValue('label_properties')
  var set_string =
    Blockly.JavaScript.valueToCode(block, 'set label', null) || '""'
  // TODO: Assemble JavaScript into code variable.
  var code = ``
  if (label_prop === 'textContent') {
    code = `document.getElementById('_${label_id}').textContent = ${set_string};`
  } else {
    code = `document.getElementById('_${label_id}').style.${label_prop} = ${set_string};`
  }
  return code
}

Blockly.JavaScript['al_text_input_change'] = function (block) {
  var textbox_id = block.getFieldValue('textInput_input')
  var event_type = block.getFieldValue('event_type')
  var action = Blockly.JavaScript.statementToCode(block, 'action')
  var code = `
if (textbox${textbox_id}) {
    textbox${textbox_id}.addEventListener('${event_type}', function () {
        ${action}
    });
}
`
  // console.log("al_text_input_change code", code);
  return code
}

Blockly.JavaScript['al_text_input_getter'] = function (block) {
  var textbox_id = block.getFieldValue('textInput_input')
  var textbox_prop = block.getFieldValue('text_input_properties')
  // TODO: Assemble JavaScript into code variable.
  var code = ``
  if (textbox_prop === 'value') {
    code = `document.getElementById('_${textbox_id}').value`
  } else if (textboxAttributes.includes(textbox_prop)) {
    code = `document.getElementById('_${textbox_id}').getAttribute('${textbox_prop}')`
  } else {
    code = `window.getComputedStyle(document.getElementById('_${textbox_id}')).${textbox_prop}`
  }
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, null]
}

Blockly.JavaScript['al_text_input_setter'] = function (block) {
  var textbox_id = block.getFieldValue('textInput_input')
  var textbox_prop = block.getFieldValue('text_input_properties')
  var set_value =
    Blockly.JavaScript.valueToCode(block, 'set text input', null) || '""'
  // TODO: Assemble JavaScript into code variable.
  var code = ``
  if (textbox_prop === 'value') {
    code = `document.getElementById('_${textbox_id}').value = ${set_value};`
  } else if (textboxAttributes.includes(textbox_prop)) {
    code = `document.getElementById('_${textbox_id}').setAttribute('${textbox_prop}', ${set_value});`
  } else {
    code = `document.getElementById('_${textbox_id}').style.${textbox_prop} = ${set_value};`
  }
  return code
}

Blockly.JavaScript['al_image_getter'] = function (block) {
  var image_id = block.getFieldValue('image_input')
  var image_prop = block.getFieldValue('image_properties')
  // TODO: Assemble JavaScript into code variable.
  var code = `window.getComputedStyle(document.getElementById('_${image_id}')).${image_prop}`
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, null]
}

Blockly.JavaScript['al_image_setter'] = function (block) {
  var image_id = block.getFieldValue('image_input')
  var image_prop = block.getFieldValue('image_properties')
  var set_value =
    Blockly.JavaScript.valueToCode(block, 'set image', null) || '""'
  // TODO: Assemble JavaScript into code variable.
  var code = `document.getElementById('_${image_id}').style.${image_prop} = ${set_value};`
  return code
}

Blockly.JavaScript['al_radio_button_getter'] = function (block) {
  var radio_id = block.getFieldValue('radio_button_input')
  var radio_prop = block.getFieldValue('radio_button_properties')
  // TODO: Assemble JavaScript into code variable.
  var code = ``
  if (radioButtonAttributes.includes(radio_prop)) {
    if (radio_prop === 'textContent') {
      code = `document.getElementById('_${radio_id}').${radio_prop}`
    } else if (radio_prop === 'checked') {
      code = `document.getElementById('_${radio_id}').querySelector("input").${radio_prop}`
    }
  } else {
    code = `window.getComputedStyle(document.getElementById('_${radio_id}')).${radio_prop}`
  }
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, null]
}

Blockly.JavaScript['al_radio_button_setter'] = function (block) {
  var radio_id = block.getFieldValue('radio_button_input')
  var radio_prop = block.getFieldValue('radio_button_properties')
  var set_value =
    Blockly.JavaScript.valueToCode(block, 'set radio button', null) || '""'
  // TODO: Assemble JavaScript into code variable.
  var code = ``
  if (radioButtonAttributes.includes(radio_prop)) {
    if (radio_prop === 'textContent') {
      code = `document.getElementById('_${radio_id}').childNodes[0].${radio_prop} = ${set_value};
document.getElementById("_${radio_id}").querySelector("input").value = ${set_value};`
    } else if (radio_prop === 'checked') {
      code = `document.getElementById('_${radio_id}').querySelector("input").${radio_prop} = ${set_value};`
    }
  } else {
    code = `document.getElementById('_${radio_id}').style.${radio_prop} = ${set_value};`
  }
  return code
}

Blockly.JavaScript['al_radio_button_get_selected'] = function (block) {
  var group_name = block.getFieldValue('radio_button_input')
  var code = `(group?.${group_name} || "")`
  // console.log("al_radio_button_change code", code);
  return [code, null]
}

Blockly.JavaScript['al_radio_button_group_set'] = function (block) {
  var group_name = block.getFieldValue('radio_button_input')
  var set_value =
    Blockly.JavaScript.valueToCode(block, 'set group value', null) || '""'
  let code = `
group["${group_name}"] = ${set_value};
let input = document.querySelectorAll('input[name="${group_name}"]');
input.forEach((i) => {
    if (i.id == ${set_value}) i.checked = true;
    else i.checked = false;
});
`
  return code
}

Blockly.JavaScript['al_icon_event'] = function (block) {
  var icon_id = block.getFieldValue('icon_input')
  var event = block.getFieldValue('event_type')
  var action = Blockly.JavaScript.statementToCode(block, 'action')
  let code = `if (icon${icon_id}) {
  icon${icon_id}.addEventListener('${event}', function () {
    ${action}
  });
}`
  return code
}

// Blockly.JavaScript["al_icon_getter"] = function (block) {
//   var icon_id = block.getFieldValue("icon_input");
//   var prop = block.getFieldValue("icon_properties");
//   var code = "";
//   if (prop === "title") {
//     code = `document.getElementById('_${icon_id}').getAttribute('title')`;
//   } else {
//     code = `document.getElementById('_${icon_id}').style.${prop}`;
//   }
//   return [code, null];
// };

Blockly.JavaScript['al_datetime_input_getter'] = function (block) {
  var dt_id = block.getFieldValue('datetime_input')
  var prop = block.getFieldValue('datetime_properties')
  var code = ''
  if (prop === 'value') {
    code = `document.getElementById('_${dt_id}').value`
  } else if (datetimeAttributes.includes(prop)) {
    code = `document.getElementById('_${dt_id}').getAttribute('${prop}')`
  } else {
    code = `document.getElementById('_${dt_id}').style.${prop}`
  }
  return [code, null]
}

Blockly.JavaScript['al_datetime_input_setter'] = function (block) {
  var dt_id = block.getFieldValue('datetime_input')
  var prop = block.getFieldValue('datetime_properties')
  var set_value =
    Blockly.JavaScript.valueToCode(block, 'set value', null) || '""'
  var code = ''
  if (prop === 'value') {
    code = `document.getElementById('_${dt_id}').value = ${set_value};`
  } else if (datetimeAttributes.includes(prop)) {
    code = `document.getElementById('_${dt_id}').setAttribute('${prop}', ${set_value});`
  } else {
    code = `document.getElementById('_${dt_id}').style.${prop} = ${set_value};`
  }
  return code
}

Blockly.JavaScript['al_datetime_event'] = function (block) {
  var dt_id = block.getFieldValue('datetime_input')
  var event = block.getFieldValue('event_type')
  var action = Blockly.JavaScript.statementToCode(block, 'action')
  var code = `if (dateTimeInput${dt_id}) {
  dateTimeInput${dt_id}.addEventListener('${event}', function () {
    ${action}
  });
}`
  // console.log("al_datetime_event code", code);
  return code
}

Blockly.JavaScript['al_switch_input_getter'] = function (block) {
  var switch_id = block.getFieldValue('switch_input')
  //   var prop = block.getFieldValue("switch_properties");
  var code = `document.getElementById("_${switch_id}").querySelector("input").checked`
  return [code, null]
}

Blockly.JavaScript['al_switch_input_setter'] = function (block) {
  var switch_id = block.getFieldValue('switch_input')
  //   var prop = block.getFieldValue("switch_properties");
  var set_value =
    Blockly.JavaScript.valueToCode(block, 'set value', null) || '""'
  var code = `if (document.getElementById("_${switch_id}").querySelector("input").checked !== ${set_value}) {
  document.getElementById("_${switch_id}").querySelector("input").click();
};`
  return code
}

Blockly.JavaScript['al_switch_event'] = function (block) {
  var switch_id = block.getFieldValue('switch_input')
  var event = block.getFieldValue('event_type')
  var action = Blockly.JavaScript.statementToCode(block, 'action')
  var code = `if (switch${switch_id}) {
    let input = switch${switch_id}.querySelector("input");
    input.addEventListener("${event}", function () {
        ${action}
    });
  }`
  // console.log("al_switch_event code", code);
  return code
}

Blockly.JavaScript['set_color'] = function (block) {
  var get_color = block.getFieldValue('COLOR')
  // TODO: Assemble JavaScript into code variable.
  var code = `"${get_color}"`
  return [code, null]
}

Blockly.JavaScript['set_text_input'] = function (block) {
  var text_name = block.getFieldValue('NAME')
  // TODO: Assemble JavaScript into code variable.
  var code = `"${text_name}"`
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, null]
}

Blockly.JavaScript['al_numerical_input'] = function (block) {
  var number_number_input = block.getFieldValue('number_input')
  // TODO: Assemble JavaScript into code variable.
  // var code = number_number_input
  // TODO: Change ORDER_NONE to the correct strength.
  return [number_number_input, null]
}

Blockly.JavaScript['al_boolean'] = function (block) {
  var dropdown_boolean = block.getFieldValue('boolean')
  // TODO: Assemble JavaScript into code variable.
  var code = `"${dropdown_boolean}"`
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, null]
}

Blockly.JavaScript['al_keyboard_type_input'] = function (block) {
  var dropdown_keyboard_type_options = block.getFieldValue(
    'keyboard_type_options'
  )
  // TODO: Assemble JavaScript into code variable.
  var code = `"${dropdown_keyboard_type_options}"`
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, null]
}

Blockly.JavaScript['al_screen_justification'] = function (block) {
  var dropdown_justification_options = block.getFieldValue(
    'justification_options'
  )
  // TODO: Assemble JavaScript into code variable.
  var code = `"${dropdown_justification_options}"`
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, null]
}

Blockly.JavaScript['al_screen_alignment'] = function (block) {
  var dropdown_alignment_options = block.getFieldValue('alignment_options')
  // TODO: Assemble JavaScript into code variable.
  var code = `"${dropdown_alignment_options}"`
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, null]
}

Blockly.JavaScript['al_image_size'] = function (block) {
  var resize = block.getFieldValue('image_resize')
  var code = `"${resize}"`
  return [code, null]
}

Blockly.JavaScript['al_insert_table_row'] = function (block) {
  // var table_id = block.getFieldValue('table_input_field');
  // var column_id = block.getFieldValue('column_input_field');
  // var row_index = Blockly.JavaScript.valueToCode(block, 'row_number', null) || "\"\"";
  // var get_value_from = Blockly.JavaScript.valueToCode(block, 'get_value_from', null) || "\"\"";
  return null
}

Blockly.JavaScript['al_insert_table_row_end'] = function (block) {
  var table_id = block.getFieldValue('table_input_field')
  let table = JSON.parse(localStorage.getItem('applab_myTables')).find(
    (t) => t.id === table_id
  )
  let columnData = table['columnOrder'].map((cId) => {
    return [
      cId,
      Blockly.JavaScript.valueToCode(block, 'column_input' + cId, null) || "''",
    ]
  })
  let code = `await addRowEnd('` + table_id + `', {`
  columnData.forEach((cData) => {
    code += `"${cData[0]}": ${cData[1]},`
  })
  code += `});`
  return code
}

Blockly.JavaScript['al_update_table_data'] = function (block) {
  var table_id = block.getFieldValue('table_input_field')
  var column_id = block.getFieldValue('column_input_field')
  var conditional_value =
    Blockly.JavaScript.valueToCode(block, 'get_value', null) || "''"
  var column1_id = block.getFieldValue('column_input1_field')
  var setter_value =
    Blockly.JavaScript.valueToCode(block, 'set_value', null) || "''"
  var code = `await updateOneRow('${table_id}', '${column_id}', ${conditional_value}, '${column1_id}', ${setter_value});`
  return code
}

Blockly.JavaScript['al_update_table_all_data'] = function (block) {
  var table_id = block.getFieldValue('table_input_field')
  var column_id = block.getFieldValue('column_input_field')
  var conditional_value =
    Blockly.JavaScript.valueToCode(block, 'get_value', null) || "''"
  var column1_id = block.getFieldValue('column_input1_field')
  var setter_value =
    Blockly.JavaScript.valueToCode(block, 'set_value', null) || "''"
  var code = `await updateAllRows('${table_id}', '${column_id}', ${conditional_value}, '${column1_id}', ${setter_value});`
  return code
}

Blockly.JavaScript['al_delete_one_row'] = function (block) {
  var table_id = block.getFieldValue('table_input_field')
  var column_id = block.getFieldValue('column_input_field')
  var condition_value =
    Blockly.JavaScript.valueToCode(block, 'condition_value', null) || "''"
  var code = `await deleteFirstRow('${table_id}', '${column_id}', ${condition_value});`
  return code
}

Blockly.JavaScript['al_delete_one_row_conditional'] = function (block) {
  var table_id = block.getFieldValue('table_input_field')
  var condition_value = Blockly.JavaScript.valueToCode(
    block,
    'condition_value',
    null
  )
  var code = `await deleteFirstRowCondition('${table_id}', \`${condition_value}\`);`
  return code
}

Blockly.JavaScript['al_delete_multiple_row'] = function (block) {
  var table_id = block.getFieldValue('table_input_field')
  var column_id = block.getFieldValue('column_input_field')
  var condition_value =
    Blockly.JavaScript.valueToCode(block, 'condition_value', null) || "''"
  var code = `await deleteRows('${table_id}', '${column_id}', ${condition_value});`
  return code
}

Blockly.JavaScript['al_delete_multiple_row_conditional'] = function (block) {
  var table_id = block.getFieldValue('table_input_field')
  var condition = Blockly.JavaScript.valueToCode(block, 'condition_value', null)
  var code = `await deleteRowsCondition('${table_id}', \`${condition}\`);`
  return code
}

Blockly.JavaScript['al_get_table_row'] = function (block) {
  var tableId = block.getFieldValue('table_input_field')
  var columnId = block.getFieldValue('column_input_field')
  var index = Blockly.JavaScript.valueToCode(block, 'row_number', null) || "''"
  var code = `await getColumnVal('${tableId}', '${columnId}', ${index})`
  return [code, null]
}

Blockly.JavaScript['al_get_table_row_conditional'] = function (block) {
  var tableId = block.getFieldValue('table_input_field')
  var columnId = block.getFieldValue('column_input_field')
  var value = Blockly.JavaScript.valueToCode(block, 'where_value', null) || "''"
  var code = `await getFirstRowValue('${tableId}', '${columnId}', ${value});`
  return [code, null]
}

Blockly.JavaScript['al_get_table_row_multiple_conditional'] = function (block) {
  var tableId = block.getFieldValue('table_input_field')
  var condition =
    Blockly.JavaScript.valueToCode(block, 'where_value', null) || ''
  var code = `await getFirstRow('${tableId}', \`${condition}\`);`
  return [code, null]
}

Blockly.JavaScript['al_get_table_row_multiple_conditional_male'] = function (
  block
) {
  var tableId = block.getFieldValue('table_input_field')
  var condition =
    Blockly.JavaScript.valueToCode(block, 'where_value', null) || ''
  var code = `await getFirstRow('${tableId}', \`${condition}\`);`
  return code
}

Blockly.JavaScript['al_get_table_rows'] = function (block) {
  var table_id = block.getFieldValue('table_input_field')
  var code = `await getRows('${table_id}')`
  return [code, null]
}

Blockly.JavaScript['al_get_table_rows_conditional'] = function (block) {
  var tableId = block.getFieldValue('table_input_field')
  var columnId = block.getFieldValue('column_input_field')
  var value = Blockly.JavaScript.valueToCode(block, 'where_value', null) || "''"
  var code = `await getRowsValue('${tableId}', '${columnId}', ${value});`
  return [code, null]
}

Blockly.JavaScript['al_get_table_rows_multiple_conditional'] = function (
  block
) {
  var tableId = block.getFieldValue('table_input_field')
  var condition =
    Blockly.JavaScript.valueToCode(block, 'where_value', null) || ''
  var code = `await getRowsConditional('${tableId}', \`${condition}\`);`
  return [code, null]
}

Blockly.JavaScript['al_where_row_column'] = function (block) {
  return null
}

Blockly.JavaScript['al_column_condition'] = function (block) {
  var column_id = block.getFieldValue('column_input_field')
  var condition = block.getFieldValue('comparison_operator')
  var value =
    Blockly.JavaScript.valueToCode(block, 'condition_value', null) || "''"
  var code = `data['${column_id}'] ${condition} ${value}`
  return [code, null]
}

Blockly.JavaScript['al_next_row'] = function (block) {
  return null
}

/**
 *
 * MAZE LAB
 *
 */

Blockly.JavaScript['move_forward'] = function (block) {
  if (block.getRootBlock() !== null) {
    // if (block.getRootBlock().type === 'when_run' || block.getRootBlock().type === 'procedure') {

    return 'maze.move();\n'
    // }
  }

  return ''
}

Blockly.JavaScript['turn_right'] = function (block) {
  if (block.getRootBlock() !== null) {
    // if (block.getRootBlock().type === 'when_run') {
    return 'maze.turn_right();\n'
    // }
  }

  return ''
}

Blockly.JavaScript['turn_left'] = function (block) {
  if (block.getRootBlock() !== null) {
    // if (block.getRootBlock().type === 'when_run') {
    return 'maze.turn_left();\n'
    // }
  }

  return ''
}

Blockly.JavaScript['when_run'] = function (block) {
  var statements_all_maze_statements = Blockly.JavaScript.statementToCode(
    block,
    'all_maze_statements'
  )
  // TODO: Assemble JavaScript into code variable.
  var code = statements_all_maze_statements
  return code
}

Blockly.JavaScript['check_question_block'] = function (block) {
  var value_name = Blockly.JavaScript.valueToCode(
    block,
    'NAME',
    Blockly.JavaScript.ORDER_NONE
  )
  // TODO: Assemble JavaScript into code variable.
  var code = 'maze.check_question("' + value_name + '")'
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['add_to_basket'] = function (block) {
  var value_number = Blockly.JavaScript.valueToCode(
    block,
    'number',
    Blockly.JavaScript.ORDER_NONE
  )
  var value_collectibles = Blockly.JavaScript.valueToCode(
    block,
    'collectibles',
    Blockly.JavaScript.ORDER_NONE
  )
  // TODO: Assemble JavaScript into code variable.
  var code =
    'maze.add_to_basket(' + value_number + ',"' + value_collectibles + '");\n'
  return code
}

Blockly.JavaScript['collect'] = function (block) {
  var value_name = Blockly.JavaScript.valueToCode(
    block,
    'NAME',
    Blockly.JavaScript.ORDER_NONE
  )
  // TODO: Assemble JavaScript into code variable.
  var code = 'maze.collect("' + value_name + '"); \n'
  return code
}

Blockly.JavaScript['deposit'] = function (block) {
  var value_name = Blockly.JavaScript.valueToCode(
    block,
    'NAME',
    Blockly.JavaScript.ORDER_NONE
  )
  // TODO: Assemble JavaScript into code variable.
  // var code = 'self.state.maze.deposit("' + value_name + '"); \n';
  var code = 'maze.xs("' + value_name + '"); \n'
  return code
}

Blockly.JavaScript['orange'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = ''
  if (block.getParent()) {
    if (block.getParent().type === 'controls_if') {
      code = 'maze.check_if_collect("orange")'
    } else {
      code = 'orange'
    }
  }
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['apple'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = ''
  if (block.getParent()) {
    if (block.getParent().type === 'controls_if') {
      code = 'maze.check_if_collect("apple")'
    } else {
      code = 'apple'
    }
  }

  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['bone'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = ''
  if (block.getParent()) {
    if (block.getParent().type === 'controls_if') {
      code = 'maze.check_if_collect("bone")'
    } else {
      code = 'bone'
    }
  }

  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['bug'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = ''
  if (block.getParent()) {
    if (block.getParent().type === 'controls_if') {
      code = 'maze.check_if_collect("bug1")'
    } else {
      code = 'bug1'
    }
  }

  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['bug2'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = ''
  if (block.getParent()) {
    if (block.getParent().type === 'controls_if') {
      code = 'maze.check_if_collect("bug2")'
    } else {
      code = 'bug2'
    }
  }

  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['caterpillar'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = ''
  if (block.getParent()) {
    if (block.getParent().type === 'controls_if') {
      code = 'maze.check_if_collect("caterpillar")'
    } else {
      code = 'caterpillar'
    }
  }

  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['scorpio'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = ''
  if (block.getParent()) {
    if (block.getParent().type === 'controls_if') {
      code = 'maze.check_if_collect("scorpio")'
    } else {
      code = 'scorpio'
    }
  }

  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['berries'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = ''
  if (block.getParent()) {
    if (block.getParent().type === 'controls_if') {
      code = 'maze.check_if_collect("berries")'
    } else {
      code = 'berries'
    }
  }

  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['orange'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = ''
  if (block.getParent()) {
    if (block.getParent().type === 'controls_if') {
      code = 'maze.check_if_collect("orange")'
    } else {
      code = 'orange'
    }
  }

  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['pomegranate'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = ''
  if (block.getParent()) {
    if (block.getParent().type === 'controls_if') {
      code = 'maze.check_if_collect("pomegranate")'
    } else {
      code = 'pomegranate'
    }
  }

  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['pear'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = ''
  if (block.getParent()) {
    if (block.getParent().type === 'controls_if') {
      code = 'maze.check_if_collect("pear")'
    } else {
      code = 'pear'
    }
  }

  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['cherries'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = ''
  if (block.getParent()) {
    if (block.getParent().type === 'controls_if') {
      code = 'maze.check_if_collect("cherries")'
    } else {
      code = 'cherries'
    }
  }

  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['kiwi'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = ''
  if (block.getParent()) {
    if (block.getParent().type === 'controls_if') {
      code = 'maze.check_if_collect("kiwi")'
    } else {
      code = 'kiwi'
    }
  }

  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['strawberry'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = ''
  if (block.getParent()) {
    if (block.getParent().type === 'controls_if') {
      code = 'maze.check_if_collect("strawberry")'
    } else {
      code = 'strawberry'
    }
  }

  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['snowflake'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = ''
  if (block.getParent()) {
    if (block.getParent().type === 'controls_if') {
      code = 'maze.check_if_collect("snowflake")'
    } else {
      code = 'snowflake'
    }
  }

  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['strawberries'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = ''
  if (block.getParent()) {
    if (block.getParent().type === 'controls_if') {
      code = 'maze.check_if_collect("strawberries")'
    } else {
      code = 'strawberries'
    }
  }

  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['corns'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = ''
  if (block.getParent()) {
    if (block.getParent().type === 'controls_if') {
      code = 'maze.check_if_collect("corns")'
    } else {
      code = 'corns'
    }
  }

  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['carrot'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = ''
  if (block.getParent()) {
    if (block.getParent().type === 'controls_if') {
      code = 'maze.check_if_collect("carrot")'
    } else {
      code = 'carrot'
    }
  }

  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['cream_flower'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = ''
  if (block.getParent()) {
    if (block.getParent().type === 'controls_if') {
      code = 'maze.check_if_collect("cream_flower")'
    } else {
      code = 'cream_flower'
    }
  }

  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['red_flower'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = ''
  if (block.getParent()) {
    if (block.getParent().type === 'controls_if') {
      code = 'maze.check_if_collect("red_flower")'
    } else {
      code = 'red_flower'
    }
  }

  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['green_balloon'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = ''
  if (block.getParent()) {
    if (block.getParent().type === 'controls_if') {
      code = 'maze.check_if_collect("green_balloon")'
    } else {
      code = 'green_balloon'
    }
  }

  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['football'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = ''
  if (block.getParent()) {
    if (block.getParent().type === 'controls_if') {
      code = 'maze.check_if_collect("football")'
    } else {
      code = 'football'
    }
  }

  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['red_ball'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = ''
  if (block.getParent()) {
    if (block.getParent().type === 'controls_if') {
      code = 'maze.check_if_collect("red_ball")'
    } else {
      code = 'red_ball'
    }
  }

  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['volleyball'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = ''
  if (block.getParent()) {
    if (block.getParent().type === 'controls_if') {
      code = 'maze.check_if_collect("volleyball")'
    } else {
      code = 'volleyball'
    }
  }

  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['white_ball'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = ''
  if (block.getParent()) {
    if (block.getParent().type === 'controls_if') {
      code = 'maze.check_if_collect("white_ball")'
    } else {
      code = 'white_ball'
    }
  }

  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['purple_ball'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = ''
  if (block.getParent()) {
    if (block.getParent().type === 'controls_if') {
      code = 'maze.check_if_collect("purple_ball")'
    } else {
      code = 'purple_ball'
    }
  }

  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['blue_ball'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = ''
  if (block.getParent()) {
    if (block.getParent().type === 'controls_if') {
      code = 'maze.check_if_collect("blue_ball")'
    } else {
      code = 'blue_ball'
    }
  }

  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['pink_ball'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = ''
  if (block.getParent()) {
    if (block.getParent().type === 'controls_if') {
      code = 'maze.check_if_collect("pink_ball")'
    } else {
      code = 'pink_ball'
    }
  }

  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['maroon_balloon'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = ''
  if (block.getParent()) {
    if (block.getParent().type === 'controls_if') {
      code = 'maze.check_if_collect("maroon_baloon")'
    } else {
      code = 'maroon_baloon'
    }
  }

  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['pumpkin'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = ''
  if (block.getParent()) {
    if (block.getParent().type === 'controls_if') {
      code = 'maze.check_if_collect("pumpkin")'
    } else {
      code = 'pumpkin'
    }
  }

  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['gold_gemstone'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = ''
  if (block.getParent()) {
    if (block.getParent().type === 'controls_if') {
      code = 'maze.check_if_collect("gold_gemstone")'
    } else {
      code = 'gold_gemstone'
    }
  }

  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['blue_gemstone'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = ''
  if (block.getParent()) {
    if (block.getParent().type === 'controls_if') {
      code = 'maze.check_if_collect("blue_gemstone")'
    } else {
      code = 'blue_gemstone'
    }
  }

  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['pot'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = ''
  if (block.getParent()) {
    if (block.getParent().type === 'controls_if') {
      code = 'maze.check_if_collect("pot")'
    } else {
      code = 'pot'
    }
  }

  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['blue_balloon'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = ''
  if (block.getParent()) {
    if (block.getParent().type === 'controls_if') {
      code = 'maze.check_if_collect("blue_balloon")'
    } else {
      code = 'blue_balloon'
    }
  }

  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['red_balloon'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = ''
  if (block.getParent()) {
    if (block.getParent().type === 'controls_if') {
      code = 'maze.check_if_collect("red_balloon")'
    } else {
      code = 'red_balloon'
    }
  }

  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['purple_baloon'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = ''
  if (block.getParent()) {
    if (block.getParent().type === 'controls_if') {
      code = 'maze.check_if_collect("purple_baloon")'
    } else {
      code = 'purple_baloon'
    }
  }

  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['pink_flower'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = 'pink_flower'
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['bee'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = 'bee'
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['mz_orange_question_input'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = 'maze_cond_orange_question'
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['mz_yellow_question_input'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = 'maze_cond_yellow_question'
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['rabbit'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = 'rabbit'
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['mz_yellow_question_input'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = 'yellow_question'
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['mz_orange_question_input'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = 'orange_question'
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['red_fish'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = 'red_fish'
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['blue_fish'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = 'blue_fish'
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['yellow_qt'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = 'yellow_qt'
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['purple_qt'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = 'purple_qt'
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['gold_fish'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = 'gold_fish'

  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['yellow_fish'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = 'yellow_fish'
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['yellow_flower'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = 'yellow_flower'
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['duck'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = 'duck'
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['rock'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = 'rock'
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['lotus'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = 'lotus'
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['carrot'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = 'carrot'
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['beetroot'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = 'beetroot'
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['honey'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = 'honey'
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE]
}
//------------------------------------------------------------------------//
//STORY LAB BLOCKS
//-----------------------------------------------------------------------//
Blockly.JavaScript['create_sprite_lab1'] = function (block) {
  var dropdown_image = block.getFieldValue('image')
  var text_sprite_name = block.getFieldValue('sprite_name')

  var value_x_location = Blockly.JavaScript.valueToCode(
    block,
    'X location',
    Blockly.JavaScript.ORDER_NONE
  )
  var value_y_location = Blockly.JavaScript.valueToCode(
    block,
    'Y location',
    Blockly.JavaScript.ORDER_NONE
  )
  // TODO: Assemble JavaScript into code variable.
  var code =
    'this.createSprite("' +
    text_sprite_name.replace(/\s/g, '') +
    '","' +
    dropdown_image +
    '","' +
    parseInt(value_x_location) +
    '","' +
    parseInt(value_y_location) +
    '","' +
    100 +
    '","' +
    100 +
    '","' +
    'this.state.level' +
    '");\n'
  return code
}

Blockly.JavaScript['create_sprite_lab2'] = function (block) {
  var dropdown_image = block.getFieldValue('image')
  var text_sprite_name = block.getFieldValue('sprite_name')

  var value_x_location = Blockly.JavaScript.valueToCode(
    block,
    'X location',
    Blockly.JavaScript.ORDER_NONE
  )
  var value_y_location = Blockly.JavaScript.valueToCode(
    block,
    'Y location',
    Blockly.JavaScript.ORDER_NONE
  )
  // TODO: Assemble JavaScript into code variable.
  var code =
    'this.createSprite("' +
    text_sprite_name.replace(/\s/g, '') +
    '","' +
    dropdown_image +
    '","' +
    parseInt(value_x_location) +
    '","' +
    parseInt(value_y_location) +
    '","' +
    100 +
    '","' +
    100 +
    '","' +
    'this.state.level' +
    '");\n'
  return code
}

Blockly.JavaScript['create_sprite_lab3'] = function (block) {
  var dropdown_image = block.getFieldValue('image')
  var text_sprite_name = block.getFieldValue('sprite_name')

  var value_x_location = Blockly.JavaScript.valueToCode(
    block,
    'X location',
    Blockly.JavaScript.ORDER_NONE
  )
  var value_y_location = Blockly.JavaScript.valueToCode(
    block,
    'Y location',
    Blockly.JavaScript.ORDER_NONE
  )
  // TODO: Assemble JavaScript into code variable.
  var code =
    'this.createSprite("' +
    text_sprite_name.replace(/\s/g, '') +
    '","' +
    dropdown_image +
    '","' +
    parseInt(value_x_location) +
    '","' +
    parseInt(value_y_location) +
    '","' +
    100 +
    '","' +
    100 +
    '","' +
    'this.state.level' +
    '");\n'
  return code
}

Blockly.JavaScript['create_sprite_lab4'] = function (block) {
  var dropdown_image = block.getFieldValue('image')
  var text_sprite_name = block.getFieldValue('sprite_name')

  var value_x_location = Blockly.JavaScript.valueToCode(
    block,
    'X location',
    Blockly.JavaScript.ORDER_NONE
  )
  var value_y_location = Blockly.JavaScript.valueToCode(
    block,
    'Y location',
    Blockly.JavaScript.ORDER_NONE
  )
  // TODO: Assemble JavaScript into code variable.
  var code =
    'this.createSprite("' +
    text_sprite_name.replace(/\s/g, '') +
    '","' +
    dropdown_image +
    '","' +
    parseInt(value_x_location) +
    '","' +
    parseInt(value_y_location) +
    '","' +
    100 +
    '","' +
    100 +
    '","' +
    'this.state.level' +
    '");\n'
  return code
}

Blockly.JavaScript['change_sprite_lab1'] = function (block) {
  var text_sprite_name = block.getFieldValue('sprite_name')
  var dropdown_image = block.getFieldValue('image')

  var value_x_location = Blockly.JavaScript.valueToCode(
    block,
    'X location',
    Blockly.JavaScript.ORDER_NONE
  )
  var value_y_location = Blockly.JavaScript.valueToCode(
    block,
    'Y location',
    Blockly.JavaScript.ORDER_NONE
  )

  // TODO: Assemble JavaScript into code variable.
  var code =
    'this.changeSprite("' +
    text_sprite_name.replace(/\s/g, '') +
    '","' +
    dropdown_image +
    '","' +
    parseInt(value_x_location) +
    '","' +
    parseInt(value_y_location) +
    '");\n'

  return code
}

Blockly.JavaScript['change_sprite_lab2'] = function (block) {
  var text_sprite_name = block.getFieldValue('sprite_name')
  var dropdown_image = block.getFieldValue('image')

  var value_x_location = Blockly.JavaScript.valueToCode(
    block,
    'X location',
    Blockly.JavaScript.ORDER_NONE
  )
  var value_y_location = Blockly.JavaScript.valueToCode(
    block,
    'Y location',
    Blockly.JavaScript.ORDER_NONE
  )

  // TODO: Assemble JavaScript into code variable.
  var code =
    'this.changeSprite("' +
    text_sprite_name.replace(/\s/g, '') +
    '","' +
    dropdown_image +
    '","' +
    parseInt(value_x_location) +
    '","' +
    parseInt(value_y_location) +
    '");\n'

  return code
}

Blockly.JavaScript['change_sprite_lab3'] = function (block) {
  var text_sprite_name = block.getFieldValue('sprite_name')
  var dropdown_image = block.getFieldValue('image')

  var value_x_location = Blockly.JavaScript.valueToCode(
    block,
    'X location',
    Blockly.JavaScript.ORDER_NONE
  )
  var value_y_location = Blockly.JavaScript.valueToCode(
    block,
    'Y location',
    Blockly.JavaScript.ORDER_NONE
  )

  // TODO: Assemble JavaScript into code variable.
  var code =
    'this.changeSprite("' +
    text_sprite_name.replace(/\s/g, '') +
    '","' +
    dropdown_image +
    '","' +
    parseInt(value_x_location) +
    '","' +
    parseInt(value_y_location) +
    '");\n'

  return code
}

Blockly.JavaScript['change_sprite_lab4'] = function (block) {
  var text_sprite_name = block.getFieldValue('sprite_name')
  var dropdown_image = block.getFieldValue('image')

  var value_x_location = Blockly.JavaScript.valueToCode(
    block,
    'X location',
    Blockly.JavaScript.ORDER_NONE
  )
  var value_y_location = Blockly.JavaScript.valueToCode(
    block,
    'Y location',
    Blockly.JavaScript.ORDER_NONE
  )

  // TODO: Assemble JavaScript into code variable.
  var code =
    'this.changeSprite("' +
    text_sprite_name.replace(/\s/g, '') +
    '","' +
    dropdown_image +
    '","' +
    parseInt(value_x_location) +
    '","' +
    parseInt(value_y_location) +
    '");\n'

  return code
}

Blockly.JavaScript['set_background'] = function (block) {
  var dropdown_set_background = block.getFieldValue('set_background')

  // TODO: Assemble JavaScript into code variable.

  var code =
    'this.setState({background:' + '"' + dropdown_set_background + '"}); \n'

  return code
}

Blockly.JavaScript['move_around'] = function (block) {
  var dropdown_direction_1 = block.getFieldValue('direction 1')

  var dropdown_direction_2 = block.getFieldValue('direction 2')

  var number_speed_input = block.getFieldValue('speed input')

  // TODO: Assemble JavaScript into code variable.

  var code = 'move_up_down();'

  // TODO: Change ORDER_NONE to the correct strength.

  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['set_text_color'] = function (block) {
  var text_text_variable_name = block.getFieldValue('text variable name')

  var value_name = Blockly.JavaScript.valueToCode(
    block,
    'NAME',
    Blockly.JavaScript.ORDER_ATOMIC
  )

  // TODO: Assemble JavaScript into code variable.

  var code = '...;\n'

  return code
}

Blockly.JavaScript['move_randomly_with_speed'] = function (block) {
  var number_name = block.getFieldValue('NAME')

  // TODO: Assemble JavaScript into code variable.
  var code = 'move_random(' + number_name + ');'
  // TODO: Change ORDER_NONE to the correct strength.

  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['grow_sprite'] = function (block) {
  var value_name = Blockly.JavaScript.valueToCode(
    block,
    'NAME',
    Blockly.JavaScript.ORDER_NONE
  )

  // TODO: Assemble JavaScript into code variable.

  var code = 'growing(' + Number(value_name) + ');'

  // TODO: Change ORDER_NONE to the correct strength.

  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['if_right_edge'] = function (block) {
  // TODO: Assemble JavaScript into code variable.

  var code = 'if_right_edge();'

  // TODO: Change ORDER_NONE to the correct strength.

  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['if_left_edge'] = function (block) {
  // TODO: Assemble JavaScript into code variable.

  var code = 'if_left_edge();'

  // TODO: Change ORDER_NONE to the correct strength.

  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['shrink_sprite'] = function (block) {
  var value_name = Blockly.JavaScript.valueToCode(
    block,
    'NAME',
    Blockly.JavaScript.ORDER_NONE
  )

  // TODO: Assemble JavaScript into code variable.

  var code = 'shrinking(' + Number(value_name) + ');'

  // TODO: Change ORDER_NONE to the correct strength.

  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['when_run'] = function (block) {
  var statements_all_maze_statements = Blockly.JavaScript.statementToCode(
    block,
    'all_maze_statements'
  )

  // TODO: Assemble JavaScript into code variable.

  var code = statements_all_maze_statements

  return code
}

Blockly.JavaScript['when_button_pressed'] = function (block) {
  var dropdown_button_option = block.getFieldValue('button_direction')
  var statements_name = Blockly.JavaScript.statementToCode(block, 'NAME')
  var params = statements_name.split('!')
  var direction = params[1]
  var gap_jump = params[2]
  var speed = params[3]
  var sprite_name = params[0].split('.')[4]

  // TODO: Assemble JavaScript into code variable.
  var code =
    'this.onKeyPressed(' +
    '"' +
    dropdown_button_option +
    '"' +
    ',' +
    '"' +
    direction +
    '"' +
    ',' +
    '"' +
    gap_jump +
    '"' +
    ',' +
    '"' +
    speed +
    '"' +
    ',' +
    '"' +
    sprite_name +
    '"' +
    ');\n'

  return code
}

Blockly.JavaScript['sprite_begins'] = function (block) {
  var text_sprite_name = block.getFieldValue('sprite_name')

  var value_begins = Blockly.JavaScript.valueToCode(
    block,
    'begins',
    Blockly.JavaScript.ORDER_NONE
  )

  // TODO: Assemble JavaScript into code variable.

  var code =
    'if(this.sprite.' +
    text_sprite_name.replace(/\s/g, '') +
    '){this.sprite.' +
    text_sprite_name.replace(/\s/g, '') +
    '.' +
    value_begins +
    ';} \n'

  return code
}

Blockly.JavaScript['when_sprite_touches'] = function (block) {
  var text_name = block.getFieldValue('sprite 1')

  var text_other = block.getFieldValue('sprite 2')

  var statements_name = Blockly.JavaScript.statementToCode(block, 'NAME')

  var random = Math.floor(Math.random() * 100 + 1)

  // TODO: Assemble JavaScript into code variable.

  var code =
    'const ' +
    text_name +
    text_other +
    random +
    '=()=>{requestAnimationFrame(' +
    text_name +
    text_other +
    random +
    ');if(typeof this.sprite.' +
    text_name +
    ' !== "undefined" && typeof this.sprite.' +
    text_other +
    ' !== "undefined"){if(this.sprite.' +
    text_name.replace(/\s/g, '') +
    '.isTouching(this.sprite.' +
    text_other.replace(/\s/g, '') +
    ')){' +
    statements_name +
    '}}};' +
    text_name +
    text_other +
    random +
    '();\n'
  code += 'this.clearRequestList(' + text_name + text_other + random + ');\n'
  return code
}

Blockly.JavaScript['when_sprite_touches_surface'] = function (block) {
  var text_sprite_1 = block.getFieldValue('sprite_name')

  var dropdown_surface_name = block.getFieldValue('surface')

  var statements_name = Blockly.JavaScript.statementToCode(block, 'NAME')
  // TODO: Assemble JavaScript into code variable.
  var code =
    'const ' +
    text_sprite_1 +
    dropdown_surface_name +
    '=()=>{requestAnimationFrame(' +
    text_sprite_1 +
    dropdown_surface_name +
    ');if(typeof this.sprite.' +
    text_sprite_1 +
    ' !== "undefined"){if(this.sprite.' +
    text_sprite_1.replace(/\s/g, '') +
    '.if_on_edge(' +
    '"' +
    dropdown_surface_name +
    '"' +
    ')){' +
    statements_name +
    '}}};' +
    text_sprite_1 +
    dropdown_surface_name +
    '();\n'

  return code
}

Blockly.JavaScript['set_text'] = function (block) {
  var text_text_variable = block.getFieldValue('text variable')

  var text_text_input = block.getFieldValue('text input')

  var value_x_location = Blockly.JavaScript.valueToCode(
    block,
    'x location',
    Blockly.JavaScript.ORDER_ATOMIC
  )

  var value_y_location = Blockly.JavaScript.valueToCode(
    block,
    'y location',
    Blockly.JavaScript.ORDER_ATOMIC
  )

  // TODO: Assemble JavaScript into code variable.

  var code = '...;\n'

  return code
}

Blockly.JavaScript['hide_sprite'] = function (block) {
  var text_sprite_name = block.getFieldValue('sprite_name')

  // TODO: Assemble JavaScript into code variable.

  var code = 'this.sprite.' + text_sprite_name + '.hide();\n'

  return code
}

Blockly.JavaScript['show_sprite'] = function (block) {
  var text_sprite_name = block.getFieldValue('sprite_name')

  // TODO: Assemble JavaScript into code variable.

  var code = 'this.sprite.' + text_sprite_name + '.show();\n'

  return code
}

Blockly.JavaScript['numerical_input_value'] = function (block) {
  var number_numerical_input_value = block.getFieldValue(
    'numerical_input_value'
  )

  // TODO: Assemble JavaScript into code variable.

  var code = number_numerical_input_value

  // TODO: Change ORDER_NONE to the correct strength.

  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['default_x'] = function (block) {
  var number_numerical_input_value = block.getFieldValue('default_x')

  // TODO: Assemble JavaScript into code variable.

  var code = number_numerical_input_value

  // TODO: Change ORDER_NONE to the correct strength.

  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['default_y'] = function (block) {
  var number_numerical_input_value = block.getFieldValue('default_y')

  // TODO: Assemble JavaScript into code variable.

  var code = number_numerical_input_value

  // TODO: Change ORDER_NONE to the correct strength.

  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['random_x'] = function (block) {
  var code = Math.floor(Math.random() * 300 + 1)

  // TODO: Change ORDER_NONE to the correct strength.

  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['random_y'] = function (block) {
  // TODO: Assemble JavaScript into code variable.

  var code = Math.floor(Math.random() * 100 + 1)

  // TODO: Change ORDER_NONE to the correct strength.

  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['sprite_stops'] = function (block) {
  var text_sprite_name = block.getFieldValue('sprite name')

  var value_stops_movements = Blockly.JavaScript.valueToCode(
    block,
    'stops movements',
    Blockly.JavaScript.ORDER_ATOMIC
  )

  // TODO: Assemble JavaScript into code variable.

  var code = '...;\n'

  return code
}

Blockly.JavaScript['move_left'] = function (block) {
  var dropdown_direction = block.getFieldValue('direction')

  // var value_left_distance = Blockly.JavaScript.valueToCode(block, 'left distance', Blockly.JavaScript.ORDER_NONE);

  var value_speed = Blockly.JavaScript.valueToCode(
    block,
    'speed',
    Blockly.JavaScript.ORDER_NONE
  )

  // TODO: Assemble JavaScript into code variable.
  var code = ''
  // var code = 'this.sprite.' + text_sprite_name.replace(/\s/g, "") + ';sprite.' + text_sprite_name.replace(/\s/g, "") + '.degree=' + dropdown_direction + ';' + 'sprite.' + text_sprite_name.replace(/\s/g, "") + '.move_forward();'
  if (block.getParent() && block.getParent().getParent()) {
    // console.log(block.getParent().getParent().type, "h")
    if (block.getParent().getParent().type === 'when_button_pressed') {
      code = '!' + dropdown_direction + '!' + 0 + '!' + value_speed + '!'
    } else {
      code = undefined
    }
  }

  // TODO: Change ORDER_NONE to the correct strength.

  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['every_movement'] = function (block) {
  // TODO: Assemble JavaScript into code variable.

  var code = '...'

  // TODO: Change ORDER_NONE to the correct strength.

  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['stops_every_movement'] = function (block) {
  var text_sprite_name = block.getFieldValue('sprite name')

  // TODO: Assemble JavaScript into code variable.

  var code =
    'this.tsprite.' + text_sprite_name + '.' + 'stop_all_animations()' + ';\n'

  return code
}

Blockly.JavaScript['when_clicked'] = function (block) {
  var dropdown_clicked_entity = block.getFieldValue('clicked entity')

  var text_entity_name = block.getFieldValue('entity name')

  var statements_name = Blockly.JavaScript.statementToCode(block, 'NAME')

  // TODO: Assemble JavaScript into code variable.
  var code =
    'if(self.sprite.' +
    text_entity_name +
    "){this.state.canvas.addEventListener('click', (e) => { var x = e.clientX-this.state.canvas.offsetLeft, y = e.clientY-this.state.canvas.offsetTop; if (self.sprite." +
    text_entity_name +
    '.onClick(x, y)) { ' +
    statements_name +
    '; }; })};\n'
  return code
}

Blockly.JavaScript['text_color'] = function (block) {
  var colour_name = block.getFieldValue('NAME')

  // TODO: Assemble JavaScript into code variable.

  var code = '...'

  // TODO: Change ORDER_NONE to the correct strength.

  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['create_text_input'] = function (block) {
  var text_variable = block.getFieldValue('text_variable')

  var speech_bubble = block.getFieldValue('speech_bubble')

  var text_value = block.getFieldValue('text_value')

  var value_x_location = Blockly.JavaScript.valueToCode(
    block,
    'x location',
    Blockly.JavaScript.ORDER_NONE
  )

  var value_y_location = Blockly.JavaScript.valueToCode(
    block,
    'y location',
    Blockly.JavaScript.ORDER_NONE
  )

  // TODO: Assemble JavaScript into code variable.

  var code =
    'this.createSprite("' +
    text_variable +
    '","' +
    speech_bubble +
    '",' +
    parseInt(value_x_location) +
    ',' +
    parseInt(value_y_location) +
    ',200,100,"' +
    text_value +
    '");\n'
  // var code = "";

  return code
}

Blockly.JavaScript['set_text_size'] = function (block) {
  var text_text_variable = block.getFieldValue('text variable')

  var value_name = Blockly.JavaScript.valueToCode(
    block,
    'NAME',
    Blockly.JavaScript.ORDER_ATOMIC
  )

  // TODO: Assemble JavaScript into code variable.

  var code = '...;\n'

  return code
}

Blockly.JavaScript['create_button'] = function (block) {
  var text_button_name = block.getFieldValue('button name')

  var number_width = block.getFieldValue('width')

  var number_height = block.getFieldValue('height')

  var value_x_position = Blockly.JavaScript.valueToCode(
    block,
    'X position',
    Blockly.JavaScript.ORDER_ATOMIC
  )

  var value_y_position = Blockly.JavaScript.valueToCode(
    block,
    'Y position',
    Blockly.JavaScript.ORDER_ATOMIC
  )

  // TODO: Assemble JavaScript into code variable.

  var code =
    'this.createSprite("' +
    text_button_name +
    '","' +
    '' +
    '",' +
    parseInt(value_x_position) +
    ',' +
    parseInt(value_y_position) +
    ',' +
    parseInt(number_width) +
    ',' +
    parseInt(number_height) +
    ',"' +
    '' +
    '");\n'

  return code
}

Blockly.JavaScript['set_button_colour'] = function (block) {
  var text_button_name = block.getFieldValue('button name')

  var value_name = Blockly.JavaScript.valueToCode(
    block,
    'NAME',
    Blockly.JavaScript.ORDER_NONE
  )

  // TODO: Assemble JavaScript into code variable.

  var code =
    'self.sprite.' + text_button_name + '.color=' + '"' + value_name + '";\n'

  return code
}

Blockly.JavaScript['color_select'] = function (block) {
  var colour_color = block.getFieldValue('color')
  // TODO: Assemble JavaScript into code variable.
  var code = colour_color
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['set_button_text'] = function (block) {
  var text_button_name = block.getFieldValue('button name')

  var text_text_input = block.getFieldValue('text input')

  // TODO: Assemble JavaScript into code variable.

  var code =
    'self.sprite.' +
    text_button_name +
    '.textValue=' +
    '"' +
    text_text_input +
    '";\n'

  return code
}

Blockly.JavaScript['set_button_text_colour'] = function (block) {
  var text_button_name = block.getFieldValue('button name')

  var value_name = Blockly.JavaScript.valueToCode(
    block,
    'NAME',
    Blockly.JavaScript.ORDER_NONE
  )

  // TODO: Assemble JavaScript into code variable.

  var code =
    'self.sprite.' +
    text_button_name +
    '.textColor=' +
    '"' +
    value_name +
    '";\n'

  return code
}

Blockly.JavaScript['set_button_text_size'] = function (block) {
  var text_button_name = block.getFieldValue('button name')

  var value_name = Blockly.JavaScript.valueToCode(
    block,
    'NAME',
    Blockly.JavaScript.ORDER_ATOMIC
  )

  // TODO: Assemble JavaScript into code variable.

  var code =
    'self.sprite.' + text_button_name + '.textSize=' + '"' + value_name + '";\n'

  return code
}

Blockly.JavaScript['text_value'] = function (block) {
  var text_name = block.getFieldValue('NAME')

  // TODO: Assemble JavaScript into code variable.

  var code = '...'

  // TODO: Change ORDER_NONE to the correct strength.

  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['create_screen'] = function (block) {
  var text_screen_name = block.getFieldValue('screen name')

  // TODO: Assemble JavaScript into code variable.

  var code = '...;\n'

  return code
}

Blockly.JavaScript['change_screens'] = function (block) {
  var text_screen_name_1 = block.getFieldValue('screen name 1')

  var text_screen_name_2 = block.getFieldValue('screen name 2')

  // TODO: Assemble JavaScript into code variable.

  var code = '...;\n'

  return code
}

Blockly.JavaScript['move_left_right'] = function (block) {
  var value_name = Blockly.JavaScript.valueToCode(
    block,
    'NAME',
    Blockly.JavaScript.ORDER_NONE
  )

  // TODO: Assemble JavaScript into code variable.

  var code = 'move_left_right(' + Number(value_name) + ');'

  // TODO: Change ORDER_NONE to the correct strength.

  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['move_up_down'] = function (block) {
  var value_name = Blockly.JavaScript.valueToCode(
    block,
    'NAME',
    Blockly.JavaScript.ORDER_NONE
  )

  // TODO: Assemble JavaScript into code variable.

  var code = 'move_up_down(' + Number(value_name) + ');'

  // TODO: Change ORDER_NONE to the correct strength.

  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['move_down'] = function (block) {
  var value_name = Blockly.JavaScript.valueToCode(
    block,
    'NAME',
    Blockly.JavaScript.ORDER_NONE
  )

  // TODO: Assemble JavaScript into code variable.

  var code = 'move_south_edge(' + Number(value_name) + ');'

  // TODO: Change ORDER_NONE to the correct strength.

  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['move_up'] = function (block) {
  var value_name = Blockly.JavaScript.valueToCode(
    block,
    'NAME',
    Blockly.JavaScript.ORDER_NONE
  )

  // TODO: Assemble JavaScript into code variable.

  var code = 'move_north_edge(' + Number(value_name) + ');'

  // TODO: Change ORDER_NONE to the correct strength.

  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['sl_create_text'] = function (block) {
  var value_name = Blockly.JavaScript.valueToCode(
    block,
    'NAME',
    Blockly.JavaScript.ORDER_NONE
  )

  // TODO: Assemble JavaScript into code variable.

  var code = 'move_north_edge(' + Number(value_name) + ');'

  // TODO: Change ORDER_NONE to the correct strength.

  return [code, Blockly.JavaScript.ORDER_NONE]
}

Blockly.JavaScript['al_next_row'] = function (block) {
  return null
}

Blockly.JavaScript['al_game_setup'] = function (block) {
  var statements_do = Blockly.JavaScript.statementToCode(block, 'do')
  var code = `__SETUP__${statements_do}__SETUP__`
  // console.log("when_do_screen code", code);
  return code
}

Blockly.JavaScript['al_game_draw'] = function (block) {
  var statements_do = Blockly.JavaScript.statementToCode(block, 'do')
  var code = `${statements_do}`
  return code
}

Blockly.JavaScript['al_game_create_rect'] = function (block) {
  var x_input = Blockly.JavaScript.valueToCode(block, 'x_input', null) || 0
  var y_input = Blockly.JavaScript.valueToCode(block, 'y_input', null) || 0
  var width_input =
    Blockly.JavaScript.valueToCode(block, 'width_input', null) || 50
  var height_input =
    Blockly.JavaScript.valueToCode(block, 'height_input', null) || 50
  if (typeof x_input === 'string') x_input = parseInt(x_input)
  if (typeof y_input === 'string') y_input = parseInt(y_input)
  if (typeof width_input === 'string') width_input = parseInt(width_input)
  if (typeof height_input === 'string') height_input = parseInt(height_input)
  // TODO: Assemble JavaScript into code variable.
  var code = `this.myp5.rect(${x_input},${y_input},${width_input},${height_input});`
  // TODO: Change ORDER_NONE to the correct strength.
  return code
}

Blockly.JavaScript['al_game_fill_shape'] = function (block) {
  var color_input =
    Blockly.JavaScript.valueToCode(block, 'set_color', null) || 'black'
  var code = `this.myp5.fill(${color_input})`
  return code
}
