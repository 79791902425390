import { TrashIcon } from '@heroicons/react/24/outline'
import React from 'react'
import { FaSortDown } from 'react-icons/fa'
// Styles
// Static
import styled from 'styled-components'

const ListItem = styled.li`
  margin-top: 10px;
  margin-bottom: 10px;

  ${(props) => props.selected && 'color: var(--itk-blue-color);'}
`

const SingleComponentNameLabel = styled.label`
  margin-top: 15px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 3px;
  box-sizing: border-box;
  cursor: grab;

  &:hover {
    font-weight: bold;
  }

  @media screen and (max-width: 1000px) {
    padding-left: 0px;
    padding-right: 0px;
  }

  @media screen and (min-width: 1000px) {
    padding-left: 5px;
    padding-right: 5px;
  }

  @media screen and (min-width: 1100px) {
    padding-left: 10px;
    padding-right: 10px;
  }

  @media screen and (min-width: 1600px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`

export const SingleComponent = (props) => {
  const [open, setOpen] = React.useState(true)
  if (props.caret) {
    return (
      <ListItem
        draggable
        data-comptype="advanced"
        data-id={props.id}
        data-parent={props.parent}
        onDragLeaveCapture={props.dragLeave}
        onDragOverCapture={props.dragOver}
        onDragStartCapture={props.dragStart}
        onDragEnd={props.dragEnd}
        onDropCapture={props.drop}
        selected={props.selected}
        onClickCapture={() => props.onSelect(props.id)}
      >
        <div className='flex justify-between w-full items-center'>
          <div className='flex items-center' onClick={()=>{setOpen(!open)}}>
            <FaSortDown className='h-6 w-6 cursor-pointer mt-2' />
            <SingleComponentNameLabel
              data-comptype="advanced"
              data-id={props.id}
              data-parent={props.parent}
            >
              {props.name}
            </SingleComponentNameLabel>
          </div>
          <TrashIcon className='h-6 w-6 cursor-pointer' onClick={() => props.onDelete(props.id, props.parent)} />
        </div>
        {open && props.children}
      </ListItem>
    )
  } else {
    return (
      <ListItem
        draggable={!!props.dragStart}
        data-comptype="simple"
        data-id={props.id}
        data-parent={props.parent}
        onDragLeave={props.dragLeave}
        onDragOver={props.dragOver}
        onDragStart={props.dragStart}
        onDragEnd={props.dragEnd}
        onDrop={props.drop}
        selected={props.selected}
        onClickCapture={() => props.onSelect(props.id)}
      >
        <div className='flex justify-between w-full items-center'>
          <SingleComponentNameLabel
            data-comptype="simple"
            data-id={props.id}
            data-parent={props.parent}
          >
            {props.name}
          </SingleComponentNameLabel>
          <TrashIcon className='h-6 w-6 cursor-pointer' onClick={() => props.onDelete(props.id, props.parent)} />
        </div>
      </ListItem>
    )
  }
}
