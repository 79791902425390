import React from 'react'
// Components
import Backdrop from '../backdrop/backdrop'
import styled from 'styled-components'

const ModalContainerDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 500;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const ModalDesignDiv = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

const ModalDiv = styled.div`
  background-color: #f2f2f2;
  z-index: 510;
  width: 500px;
  padding: 45px;
  box-sizing: border-box;
  transition: all 0.7s ease-out;
  box-shadow: 2px 2px 4px black;
  border-radius: 1em;
`

const ModalHeaderDiv = styled.div`
  position: fixed;
  right: 15px;
  top: 10px;

  & > span {
    font-size: 30px;
    color: gray;
    font-weight: bold;
    cursor: pointer;

    &:hover {
      color: black;
    }

    &:focus {
      color: black;
    }
  }
`

export const Modal = (props) => {
  return (
    <ModalContainerDiv>
      <Backdrop onClose={props.no} />
      <ModalDesignDiv>
        <ModalDiv
          style={{
            transform: props.show ? 'translateY(0)' : 'translateY(-100vh)',
            opacity: props.show ? '1' : '0',
          }}
        >
          {props.children}
          <ModalHeaderDiv onClick={props.no}>
            <span>&times;</span>
          </ModalHeaderDiv>
        </ModalDiv>
      </ModalDesignDiv>
    </ModalContainerDiv>
  )
}
