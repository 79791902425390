import React, { useEffect, useState } from 'react'
import app_api from '../config/api'
import { Sidebar } from './navigation/sidebar'
import { useNavigate } from 'react-router-dom'

export const MazeLabMaster = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const navigate = useNavigate()

  useEffect(() => {
    getExercises()
  }, [])

  const getExercises = () => {
    app_api
      .get('exercise-master/type/MAZE')
      .then((res) => res.data.data)
      .then((res) => {
        setData(res.data)
      })
      .catch((err) => {
        setError(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const onLevelView = (id) => {
    navigate(`${id}`)
  }

  return (
    <Sidebar>
      <div className="pl-5">
        <h1>Maze lab list</h1>
        <table bordered="">
          <thead>
            <tr>
              <th>Name</th>
              <th>Description</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data.map((r, idx) => (
              <tr key={idx}>
                <td>{r.name}</td>
                <td>{r.description}</td>
                <td>
                  <button onClick={() => onLevelView(r.id)}>Select</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Sidebar>
  )
}
