import React, { Component } from 'react'
// Redux
import { connect } from 'react-redux'
import {
  renameComponent,
  addComponent,
  changeParent,
  changePosition,
} from '../../redux/actions/index'
// Other components
import { SingleComponent } from './singleComponent/singleComponent'
// Constants
import { k_itk_red } from '../../constants'
import styled from 'styled-components'
import app_api from '../../config/api'

const ContainerDiv = styled.div`
  row-gap: 20px;

  & h4 {
    color: var(--itk-blue-color);
  }

  & hr {
    border-color: var(--itk-blue-color);
  }
  & > div {
    flex: 1;
  }
`

const VisibleComponentsDiv = styled.div`
  overflow-y: auto;
`

const MyUl = styled.ul`
  list-style-type: none;
  margin-left: 25px;
  margin-top: 5px;
  margin-bottom: 5px;

  @media screen and (min-width: 1601px) and (max-width: 1800px) {
    margin-left: 25px;
  }

  @media screen and (min-width: 1401px) and (max-width: 1600px) {
    margin-left: 12px;
  }

  @media screen and (min-width: 1201px) and (max-width: 1400px) {
    margin-left: 9px;
  }

  @media screen and (min-width: 1001px) and (max-width: 1200px) {
    margin-left: 7px;
  }

  @media screen and (max-width: 1000px) {
    margin-left: 5px;
  }
`

const InvisibleComponentDiv = styled.div`
  width: 100%;
`

const InvisibleComponentsDiv = styled.div`
  overflow-y: auto;
`

class ComponentList extends Component {
  constructor(props) {
    super(props)
    this.componentTree = []
    this.invisibleComponents = []
  }

  limitComponent = () => {
    let limitingComponentTypes = ['data']
    let compExists = false
    let screen = this.props.components.find(
      (comp) => comp._id === this.props.screenId
    )
    if (screen.children.length !== 0) {
      screen.children.map((childId) => {
        let childComp = this.props.components.find(
          (comp) => comp._id === childId
        )
        if (limitingComponentTypes.includes(childComp.c_type_name)) {
          compExists = true
        }
        return null
      })
    }
    return compExists
  }

  dragStartHandler = (e) => {
    e.stopPropagation()
    e.dataTransfer.effectAllowed = 'move'
    e.dataTransfer.setData('changeOrder', true)
    e.dataTransfer.setData('id', e.target.getAttribute('data-id'))
    e.dataTransfer.setData('parent', e.target.getAttribute('data-parent'))
    let component = this.props.components.find(
      (c) => c._id === e.target.getAttribute('data-id')
    )
    this.props.getChildIds(component._id)
    return true
  }

  dragOverHandler = (e) => {
    e.preventDefault()
    e.target.style.borderBottom = '2px solid ' + k_itk_red
    e.target.style.borderLeft = '2px solid ' + k_itk_red
    return false
  }

  dragLeaveHandler = (e) => {
    e.preventDefault()
    e.target.style.borderBottom = null
    e.target.style.borderLeft = null
  }

  dragEndHandler = (e) => {
    e.preventDefault()
    this.props.emptyChildIds()
  }

  dropHandler = (e) => {
    e.stopPropagation()
    e.preventDefault()
    e.target.style.borderBottom = null
    e.target.style.borderLeft = null
    let drop_id = e.target.getAttribute('data-id')
    let drop_type = e.target.getAttribute('data-comptype')
    let drop_parent = e.target.getAttribute('data-parent')
    let drop_pos
    if (e.dataTransfer.getData('changeOrder') === 'true') {
      let my_id = e.dataTransfer.getData('id')
      let my_parent = e.dataTransfer.getData('parent')
      if (this.props.draggedChildIds.includes(drop_id)) {
        alert(
          'So you want to put a parent inside its own child. \nOh human, why you want them to have an awkward family conversation?'
        )
      } else {
        // console.log('my id ', my_id, ' my parent ', my_parent, ' drop id ', drop_id, 'drop parent ', drop_parent, ' drop comp type ', drop_compType)
        if (my_id !== drop_parent) {
          if (drop_id === this.props.screenId) {
            // console.log('moving to screen')
            this.props.changeParent(my_parent, this.props.screenId, my_id, null)
          } else {
            if (drop_type === 'advanced') {
              // console.log('moving to advanced component ', drop_id, ' my id ', my_id)
              this.props.changeParent(my_parent, drop_id, my_id, null)
            } else if (drop_type === 'simple') {
              if (drop_parent !== my_parent) {
                drop_pos = this.props.components
                  .find((x) => x._id === drop_parent)
                  .children.indexOf(drop_id)
                // console.log('moving to another parent ', drop_parent, ' at position ', drop_pos)
                this.props.changeParent(my_parent, drop_parent, my_id, drop_pos)
              } else {
                drop_pos = this.props.components
                  .find((x) => x._id === my_parent)
                  .children.indexOf(drop_id)
                // console.log('changing position ', my_id, ' at position ', drop_pos)
                this.props.changePosition(my_id, drop_pos)
              }
            }
          }
        }
      }
    } else {
      let type = e.dataTransfer.getData('type')
      if (['data'].includes(type)) {
        // console.log('adding invisible simple component.')
        if (!this.limitComponent()) {
          this.props.addComponent(
            this.props.projectId,
            type,
            this.props.screenId,
            null
          )
        } else {
          alert('Only one ' + type + ' type component is allowed per screen')
        }
      } else {
        if (drop_id === this.props.screenId) {
          // console.log('adding to screen')
          this.props.addComponent(
            this.props.projectId,
            type,
            this.props.screenId,
            null
          )
        } else {
          if (drop_type === 'advanced') {
            // console.log('adding component on advanced ', type, drop_id)
            this.props.addComponent(this.props.projectId, type, drop_id, null)
          } else if (drop_type === 'simple') {
            drop_pos = this.props.components
              .find((x) => x._id === drop_parent)
              .children.indexOf(drop_id)
            // console.log('adding component on simple ', type, drop_parent, drop_pos)
            this.props.addComponent(
              this.props.projectId,
              type,
              drop_parent,
              drop_pos
            )
          }
        }
      }
    }
    this.props.emptyChildIds()
    return false
  }

  getVisibleComponent = (componentId) => {
    let comp = {}
    comp = this.props.components.find((comp) => comp._id === componentId)
    if (comp !== undefined) {
      if (comp.type === 'advanced') {
        return (
          <SingleComponent
            key={comp._id}
            id={comp._id}
            name={comp.name}
            parent={comp.parent}
            dragStart={this.dragStartHandler}
            dragOver={this.dragOverHandler}
            dragLeave={this.dragLeaveHandler}
            dragEnd={this.dragEndHandler}
            drop={this.dropHandler}
            caret
            selected={this.props.selectedComponent === comp._id}
            onSelect={this.props.onSelect}
            onDelete={this.props.onDelete}
          >
            <MyUl className="block">
              {comp.children.length !== 0 &&
                comp.children.map((c) => {
                  return this.getVisibleComponent(c)
                })}
            </MyUl>
          </SingleComponent>
        )
      } else if (comp.type === 'simple') {
        return (
          <SingleComponent
            key={comp._id}
            id={comp._id}
            name={comp.name}
            parent={comp.parent}
            dragStart={this.dragStartHandler}
            dragOver={this.dragOverHandler}
            dragLeave={this.dragLeaveHandler}
            dragEnd={this.dragEndHandler}
            drop={this.dropHandler}
            selected={this.props.selectedComponent === comp._id}
            onSelect={this.props.onSelect}
            onDelete={this.props.onDelete}
          />
        )
      }
    }
    return null
  }

  getInvisibleComponents = (screenId) => {
    let screenComp = this.props.components.find((comp) => comp._id === screenId)
    if (screenComp !== undefined && screenComp.children.length !== 0) {
      return screenComp.children.map((cid) => {
        let childComp = this.props.components.find((comp) => comp._id === cid)
        if (childComp?.type === 'invisibleSimple') {
          return (
            <SingleComponent
              key={cid}
              id={cid}
              name={childComp.name}
              parent={childComp.parent}
              drop={this.dropHandler}
              selected={this.props.selectedComponent === cid}
              onSelect={this.props.onSelect}
              onDelete={this.props.onDelete}
            />
          )
        }
        return null
      })
    }
  }

  render() {
    this.componentTree = this.getVisibleComponent(this.props.screenId)
    this.invisibleComponents = this.getInvisibleComponents(this.props.screenId)
    return (
      <ContainerDiv className="relative w-full h-full flex flex-col gap-x-[20px]">
        <div className="">
          <h4>Visible</h4>
          <hr />
          <div className="overflow-y-auto">
            <MyUl>{this.componentTree}</MyUl>
          </div>
        </div>
        <InvisibleComponentDiv>
          <h4>Invisible</h4>
          <hr />
          <InvisibleComponentsDiv>
            <MyUl>{this.invisibleComponents}</MyUl>
          </InvisibleComponentsDiv>
        </InvisibleComponentDiv>
      </ContainerDiv>
    )
  }
}

const mapStateToProps = (state) => ({
  project: state.projects.projects,
  components: state.component.components,
})

const mapDispatchToProps = (dispatch) => ({
  renameComponent: (component) => dispatch(renameComponent(component)),
  addComponent: (projectId, type, parentId, pos) =>
    dispatch(addComponent(projectId, type, parentId, pos)),
  changePosition: (cid, new_position) =>
    dispatch(changePosition(cid, new_position)),
  changeParent: (oldParentId, newParentId, childId, pos) =>
    dispatch(changeParent(oldParentId, newParentId, childId, pos)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ComponentList)
