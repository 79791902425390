import { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition, Disclosure } from '@headlessui/react'
import {
  Bars3BottomLeftIcon,
  XMarkIcon,
  UserIcon,
  KeyIcon,
  ChevronRightIcon,
  UserPlusIcon,
  AcademicCapIcon,
  NewspaperIcon,
  DevicePhoneMobileIcon,
  ComputerDesktopIcon,
  PuzzlePieceIcon,
  MapIcon,
  BuildingLibraryIcon,
  UserGroupIcon,
} from '@heroicons/react/24/outline'

import { UserCircleIcon } from '@heroicons/react/24/solid'
import { MdFamilyRestroom } from 'react-icons/md'
import { classNames } from '../../helpers/classname'
import { Link, Navigate } from 'react-router-dom'
import logo from '../../assets/icons/logo.png'
import { PiStudent } from 'react-icons/pi'
import { SiGoogleclassroom } from 'react-icons/si'
import { LiaSchoolSolid } from 'react-icons/lia'
const navigation = [
  {
    name: 'Users',
    href: '/users',
    icon: UserGroupIcon,
    current: false,
    requiredRole: ['ADMIN'],
  },
  {
    name: 'Students',
    href: '/student-master',
    icon: PiStudent,
    current: false,
    requiredRole: ['ADMIN', 'TEACHER', 'HEAD_PRINCIPAL'],
  },
  {
    name: 'Schools',
    href: '/schools',
    icon: LiaSchoolSolid,
    current: false,
    requiredRole: ['ADMIN', 'TEACHER', 'HEAD_PRINCIPAL'],
  },
  {
    name: 'Grade',
    href: '/grade-master',
    icon: SiGoogleclassroom,
    current: false,
    requiredRole: ['ADMIN', 'TEACHER'],
  },
  {
    name: 'Division',
    href: '/division-master',
    icon: BuildingLibraryIcon,
    current: false,
    requiredRole: ['ADMIN', 'TEACHER'],
  },
  {
    name: 'Exercise',
    current: false,
    children: [
      {
        name: 'App Lab',
        href: '/app-lab',
        icon: DevicePhoneMobileIcon,
        current: true,
        requiredRole: ['ADMIN', 'TEACHER'],
      },
      {
        name: 'Web Lab',
        href: '/web-lab',
        icon: ComputerDesktopIcon,
        current: true,
        requiredRole: ['ADMIN', 'TEACHER'],
      },
      {
        name: 'Game Lab',
        href: '/game-lab',
        icon: PuzzlePieceIcon,
        current: false,
        requiredRole: ['ADMIN', 'TEACHER'],
      },
      {
        name: 'Maze Lab',
        href: '/maze-lab',
        icon: MapIcon,
        current: false,
        requiredRole: ['ADMIN', 'TEACHER'],
      },
      // {
      //   name: 'Maze Lab',
      //   href: '/student',
      //   icon: AcademicCapIcon,
      //   current: false,
      //   requiredRole: ['ADMIN'],
      // },
      // {
      //   name: 'Game Lab',
      //   href: '/family',
      //   icon: MdFamilyRestroom,
      //   current: false,
      //   requiredRole: ['ADMIN'],
      // }
    ],
    icon: NewspaperIcon,
    current: false,
    requiredRole: ['ADMIN', 'TEACHER'],
  },
]

export const Sidebar = (props) => {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [pathname, setPathname] = useState('/leads')
  const [moldKey, setmoldKey] = useState(
    JSON.parse(localStorage.getItem('itkKey'))
  )
  const [isOpen, setIsOpen] = useState(false)

  const handleToggle = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    localStorage.getItem('itkKey') ? (
      <Navigate to="/leads" />
    ) : (
      <Navigate to="/" />
    )
    setPathname(window.location.pathname)
  }, [window.location.pathname, moldKey])

  useEffect(() => {
    try {
      setmoldKey(JSON.parse(localStorage.getItem('itkKey')))
    } catch (err) {}
  }, [])

  return (
    <>
      <div className="overflow-hidden">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 md:hidden"
            onClose={() => setSidebarOpen(false)}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex md:hidden">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-col bg-indigo-800 pt-5 pb-4">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex-shrink-0 items-center px-4 ">
                    <img
                      className="h-10 w-auto"
                      src={logo}
                      alt="Your Company"
                    />
                  </div>
                  <div className="mt-5 flex-1 overflow-y-auto">
                    <nav className="flex-3 space-y-1 h-4/6 px-2">
                      {navigation
                        .filter((item) =>
                          item.requiredRole.includes(moldKey.role)
                        )
                        .map((item) => (
                          <li key={item.name}>
                            {!item.children ? (
                              <Link
                                to={item.href}
                                className={classNames(
                                  pathname === item.href
                                    ? 'bg-[#F5FCFF] text-black hover:text-black'
                                    : 'text-black hover:bg-[#F5FCFF] hover:text-black',
                                  'group flex items-center px-3 py-2 text-m font-medium rounded-md mt-2 ml-2 mr-2'
                                )}
                              >
                                {/* <item.icon className={classNames(
                                  pathname === item.href
                                  ? 'text-black'
                                  : 'text-black',
                                  'mr-3 h-5 w-5 flex-shrink-0'
                                )} aria-hidden="true" /> */}
                                {item.name}
                              </Link>
                            ) : (
                              <Disclosure
                                as="div"
                                defaultOpen={item.children.some(
                                  (child) => child.href === pathname
                                )}
                              >
                                {({ open }) => (
                                  <>
                                    <Disclosure.Button
                                      className={classNames(
                                        open
                                          ? 'bg-white text-blue-900 hover:text-blue-900'
                                          : 'text-white hover:bg-white-200 hover:bg-slate-500 hover:text-gray-600',
                                        'w-11/12 group flex items-center px-3 py-2 text-m font-medium rounded-md mt-2 ml-2 mr-2'
                                      )}
                                    >
                                      {/* <item.icon className={classNames(
                                        open ? 'text-indigo-800'
                                          : 'text-white hover:bg-indigo-200',
                                        'mr-3 h-5 w-5 flex-shrink-0'
                                      )} aria-hidden="true" /> */}
                                      {item.name}
                                      <ChevronRightIcon
                                        className={classNames(
                                          open
                                            ? 'rotate-90 text-gray-500'
                                            : 'text-gray-400',
                                          'ml-auto h-5 w-5 shrink-0'
                                        )}
                                        aria-hidden="true"
                                      />
                                    </Disclosure.Button>
                                    <Disclosure.Panel
                                      as="ul"
                                      className="mt-1 px-2"
                                    >
                                      {item.children.map((subItem) => (
                                        <li key={subItem.name}>
                                          <Link
                                            to={subItem.href}
                                            className={classNames(
                                              pathname === subItem.href
                                                ? 'bg-white text-blue-900 hover:text-blue-900'
                                                : 'text-white hover:bg-white-200 hover:bg-slate-500 hover:text-gray-600',
                                              'group flex items-center px-3 py-2 text-m font-medium rounded-md mt-2 ml-2 mr-2'
                                            )}
                                          >
                                            {/* <subItem.icon className={classNames(
                                              pathname === subItem.href
                                                ? 'text-indigo-800'
                                                : 'text-white hover:bg-indigo-200',
                                              'mr-3 h-5 w-5 flex-shrink-0'
                                            )} aria-hidden="true" /> */}
                                            {subItem.name}
                                          </Link>
                                        </li>
                                      ))}
                                    </Disclosure.Panel>
                                  </>
                                )}
                              </Disclosure>
                            )}
                          </li>
                        ))}
                      <li>
                        <Link
                          to="/resetpassword"
                          className="mt-3 text-white hover:bg-slate-500 hover:text-gray-600 group flex items-center px-3 py-2 text-m font-medium rounded-md"
                        >
                          <KeyIcon
                            className="mr-3 h-6 w-6 flex-shrink-0 text-white"
                            aria-hidden="true"
                          />
                          Reset Password
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={'/logout'}
                          className={classNames(
                            'px-3 text-white hover:bg-indigo-200 group flex items-center px-2 py-2 text-base font-medium rounded-md'
                          )}
                        >
                          <UserIcon
                            className="mr-4 h-6 w-6 flex-shrink-0 text-white"
                            aria-hidden="true"
                          />
                          Logout
                        </Link>
                      </li>
                    </nav>
                  </div>
                  <div></div>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0" aria-hidden="true"></div>
            </div>
          </Dialog>
        </Transition.Root>

        <div
          className={`hidden md:fixed md:inset-y-0 md:block md:flex md:flex-col  ${
            props.isCollapsed
              ? 'md:w-24 items-center justify-center'
              : 'md:w-64 '
          }`}
        >
          <div className="flex flex-grow flex-col overflow-y-auto border-r-2 border-gray-200 bg-white">
            <div className="flex flex-shrink-0 px-1 w-full mt-2">
              <img
                className={`${
                  props.isCollapsed ? 'h-10' : 'h-14'
                } items-center w-auto`}
                src={logo}
                alt="Your Company"
              />
            </div>
            <div className="flex flex-1 flex-col mt-4">
              <nav className="flex-3 space-y-1 h-4/6 absolute overflow-y-auto w-full px-2 pb-4">
                <ul role="list" className="-mx-2 space-y-1">
                  {navigation
                    .filter((item) => item.requiredRole.includes(moldKey.role))
                    .map((item) => (
                      <li key={item.name}>
                        {!item.children ? (
                          <Link
                            to={item.href}
                            className={classNames(
                              pathname === item.href
                                ? 'bg-[#F5FCFF] text-[#09678C] hover:text-[#09678C]'
                                : 'text-black hover:bg-[#F5FCFF] hover:text-black',
                              `group flex items-center px-3 py-2 ${
                                props.isCollapsed ? 'justify-center' : ''
                              } text-m font-medium rounded-md mt-2 ml-2 mr-2 duration-500`
                            )}
                          >
                            <item.icon
                              className={classNames(
                                pathname === item.href
                                  ? 'text-black'
                                  : 'text-black',
                                `mr-3 h-5 w-5 flex-shrink-0 ${
                                  props.isCollapsed ? 'block' : 'hidden'
                                }`
                              )}
                              aria-hidden="true"
                            />
                            <span
                              className={`${
                                props.isCollapsed ? 'hidden' : 'block'
                              }`}
                            >
                              {item.name}
                            </span>
                          </Link>
                        ) : (
                          <Disclosure
                            as="div"
                            defaultOpen={item.children.some(
                              (child) => child.href === pathname
                            )}
                          >
                            {({ open }) => (
                              <>
                                <Disclosure.Button
                                  className={classNames(
                                    open
                                      ? 'bg-[#F5FCFF] text-[#09678C] hover:text-[#09678C]'
                                      : 'text-black hover:bg-[#F5FCFF] hover:text-black',
                                    `w-11/12 group flex items-center px-3 py-2 ${
                                      props.isCollapsed ? 'justify-center' : ''
                                    } text-m font-medium rounded-md mt-2 ml-2 mr-2 duration-500`
                                  )}
                                >
                                  <item.icon
                                    className={classNames(
                                      pathname === item.href
                                        ? 'text-black'
                                        : 'text-black',
                                      `h-5 w-5 flex-shrink-0 ${
                                        props.isCollapsed
                                          ? 'block ml-2'
                                          : 'hidden'
                                      }`
                                    )}
                                    aria-hidden="true"
                                  />
                                  <span
                                    className={`${
                                      props.isCollapsed ? 'hidden' : 'block'
                                    }`}
                                  >
                                    {item.name}
                                  </span>
                                  <ChevronRightIcon
                                    className={classNames(
                                      open
                                        ? 'rotate-90 text-gray-500'
                                        : 'text-gray-400',
                                      'ml-auto h-5 w-5 shrink-0'
                                    )}
                                    aria-hidden="true"
                                  />
                                </Disclosure.Button>
                                <Disclosure.Panel as="ul" className="mt-1 px-2">
                                  {item.children.map((subItem) => (
                                    <li key={subItem.name}>
                                      <Link
                                        to={subItem.href}
                                        className={classNames(
                                          pathname === subItem.href
                                            ? 'bg-[#F5FCFF] text-[#09678C] hover:text-[#09678C]'
                                            : 'text-black hover:bg-[#F5FCFF] hover:text-black',
                                          'group flex items-center px-3 py-2 text-m font-medium rounded-md mt-2 ml-2 mr-2'
                                        )}
                                      >
                                        <subItem.icon
                                          className={classNames(
                                            pathname === subItem.href
                                              ? 'bg-[#F5FCFF] text-[#09678C] hover:text-[#09678C]'
                                              : 'text-black hover:bg-[#F5FCFF] hover:text-black',
                                            'mr-3 h-5 w-5 flex-shrink-0'
                                          )}
                                          aria-hidden="true"
                                        />
                                        <span
                                          className={`${
                                            props.isCollapsed
                                              ? 'hidden'
                                              : 'block'
                                          }`}
                                        >
                                          {subItem.name}
                                        </span>
                                      </Link>
                                    </li>
                                  ))}
                                </Disclosure.Panel>
                              </>
                            )}
                          </Disclosure>
                        )}
                      </li>
                    ))}
                </ul>
              </nav>
              <div className="mt-auto group items-center px-2 py-2 text-sm font-medium rounded-md mb-2">
                <hr className="text-gray-300 mb-3 mt-2"></hr>
                <Link
                  to="/logout"
                  className={`flex ${
                    props.isCollapsed ? 'justify-center' : 'justify-between'
                  } items-center gap-2 mt-2 hover:text-teal-800 duration-500`}
                >
                  <div
                    className={`flex items-center gap-2 ${
                      props.isCollapsed ? 'hidden' : 'block'
                    }`}
                  >
                    <UserCircleIcon className="w-12 text-gray-400" />
                    <div className="flex flex-col">
                      <h2 className="text-base font-semibold">
                        {moldKey.firstName.toUpperCase()}
                      </h2>
                      <h2 className="text-sm opacity-75">{moldKey.role}</h2>
                      {/* <h2 className="text-sm opacity-75">{moldKey.email}</h2> */}
                    </div>
                  </div>
                  <div className="flex items-center justify-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className=" h-6 w-6 flex-shrink-0"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"
                      />
                    </svg>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`flex flex-1 flex-col ${
            props.isCollapsed ? 'md:pl-24' : 'md:pl-64'
          } minHeight`}
        >
          <div className="sticky top-0 z-10 flex h-16 flex-shrink-0 bg-white shadow md:hidden">
            <button
              type="button"
              className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>

          <main className="">{props.children}</main>
        </div>
      </div>
    </>
  )
}
