import React, { Component, useEffect, useState } from 'react'
import { Sidebar } from '../../components/navigation/sidebar'
import GameBlocklyComponent from '../../components/blockly/gameBlockly'
import app_api from '../../config/api'
import { useParams } from 'react-router-dom'
import ExerciseSidebar from '../../components/navigation/exerciseSidebar'

export const GameLab = (props) => {
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const [currentExercise, setCurrentExercise] = useState(null)

  const params = useParams()

  useEffect(() => {
    getGameExercise()
  }, [])

  function getGameExercise() {
    setLoading(true)
    app_api
      .get(`exercise-master/${params.id}`)
      .then((res) => res.data)
      .then((res) => {
        setData(res.data)
      })
      .catch((err) => {
        setError(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const updateCurrentExercise = (c) => {
    let content = (data?.WebLevel || []).find((wl) => wl.id === c.id)
    setCurrentExercise(content)
  }

  const goToNext = () => {
    let courseOrder = Array.from(data?.levelSequence || [])
    const findIndex = courseOrder.findIndex((id) => id === currentExercise.id)
    // let allCompleted =
    //   courseContent.filter((c) => c.status === 'PENDING').length === 0
    // if (allCompleted) navigate('/success')
    // else {
    //  setCurrentExercise(courseContent[findIndex + 1])
    // }
    if (findIndex < courseOrder.length - 1) {
      setCurrentExercise(data.WebLevel[findIndex + 1])
    } else {
      setCurrentExercise(data.WebLevel?.[0])
    }
  }

  return (
    <Sidebar isCollapsed={true}>
      {loading ? (
        <div className="flex items-center justify-center">
          <div
            className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
            role="status"
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : error !== null ? (
        <span>{error}</span>
      ) : Object.keys(data).length > 0 ? (
        <>
          <h1>{data?.name}</h1>
          <div className="justify-end items-center text-right">
            <ExerciseSidebar
              categories={data?.WebLevel.sort(
                (a, b) =>
                  data.levelSequence.indexOf(a.id) -
                  data.levelSequence.indexOf(b.id)
              )}
              currentContent={currentExercise}
              setCurrentExercise={updateCurrentExercise}
              goToNext={goToNext}
            >
              <GameBlocklyComponent
                ref={(e) => e}
                readOnly={false}
                move={{
                  scrollbars: true,
                  drag: true,
                  wheel: true,
                }}
              />
            </ExerciseSidebar>
          </div>
        </>
      ) : (
        <div className="flex items-center justify-center">
          <div
            className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
            role="status"
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
    </Sidebar>
  )
}
