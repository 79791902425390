import React, { useEffect, useState } from 'react'
import { DocumentIcon } from '@heroicons/react/24/outline'
import axios from 'axios'
import { ContentDiv } from '../theme/html_render'
import Layout from '../layout/layout'
import Button from '../components/button'

const ReadingMaterial = ({ description, additionalLinks, goToNext }) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [content, setContent] = useState('')
  const [buttonDisabled, setButtonDisabled] = useState(true)

  useEffect(() => {
    resetScroller()
    axios
      .get(description)
      .then((res) => {
        setLoading(false)
        setContent(res.data)
      })
      .catch((err) => {
        setLoading(false)
        setError(
          err?.response?.data?.message ||
            'Error getting reading material. Please reload'
        )
      })
  }, [description])

  useEffect(() => {
    resetScroller()
    let details = document.querySelectorAll('details')
    details.forEach((d) => d.addEventListener('click', toggleDetails))
  }, [content])

  const resetScroller = () => {
    const scroller = document.getElementById('scroller')
    let height = scroller.clientHeight
    let scrollHeight = scroller.scrollHeight
    if (scrollHeight > height) {
      setButtonDisabled(true)
    } else {
      setButtonDisabled(false)
    }
    scroller.scrollTop = 0
  }

  const toggleDetails = (e) => {
    if (e.currentTarget.hasAttribute('open')) {
      e.currentTarget.removeAttribute('open')
    } else {
      e.currentTarget.setAttribute('open', '')
    }
  }

  // const markAsComplete = () => {
  //   markComplete(courseContentId, 'COMPLETED', {}, true)
  // }

  function scrolling() {
    const scroller = document.getElementById('scroller')
    let height = scroller.clientHeight
    let scrollHeight = scroller.scrollHeight - height
    let scrollTop = scroller.scrollTop
    let percent = Math.floor((scrollTop / scrollHeight) * 100)
    if (percent >= 90 && buttonDisabled) {
      setButtonDisabled(false)
    }
  }

  return (
    <>
      <Layout
        actionButton={
          true ? (
            <Button
              onClick={goToNext}
              className="w-auto bg-green-500 hover:bg-green-600"
            >
              Utkarsh
            </Button>
          ) : (
            <Button
              disabled={buttonDisabled}
              // onClick={markAsComplete}
              className="w-auto"
            >
              Mark as complete
            </Button>
          )
        }
      >
        <div
          id="scroller"
          className="h-screen overflow-y-scroll w-100 bg-gray-50"
          onScroll={() => scrolling()}
        >
          {loading ? (
            <p className="text-sm">Loading...</p>
          ) : error !== null ? (
            <p className="text-sm text-red-700">{error}</p>
          ) : (
            <ContentDiv
              className="px-10 pt-5 pb-15"
              dangerouslySetInnerHTML={{ __html: content }}
            ></ContentDiv>
          )}
        </div>
        <div className="pt-4 pb-2 pl-2 ml-2 text-lg font-medium">
          Additional Links
        </div>
        <nav className="sticky flex">
          <div className="w-full px-2 ">
            {additionalLinks.map((a, idx) => (
              <a
                key={idx}
                className="flex items-center px-2 py-2 border-b border-gray hover:bg-sky-100"
                target="_blank"
                href={a.link}
                rel="noreferrer"
              >
                <div
                  className="rounded-full bg-blue-100 p-1.5 border-4 mr-2"
                  style={{ backgroundColor: '#DCF5FF', borderColor: '#F5FCFF' }}
                >
                  <DocumentIcon
                    className="w-4 h-4"
                    style={{ color: '#09678C' }}
                  />
                </div>
                <div className="w-10/12">
                  <p className="text-sm">{a.title}</p>
                  <p className="w-full text-xs text-gray-500 truncate">
                    {a.link}
                  </p>
                </div>
              </a>
            ))}
          </div>
        </nav>
      </Layout>
    </>
  )
}
export default ReadingMaterial
