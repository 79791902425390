import React, { Component, Fragment } from 'react'
import { SketchPicker } from 'react-color'
// Redux
import { connect } from 'react-redux'
import {
  changeProperty_OnTheGo,
  changeImage,
  uploadImage,
} from '../../../redux/actions'
// Constants
import {
  k_switchid,
  k_imageid,
  k_rowid,
  k_dateTimeid,
  k_iconid,
  k_radioButtonid,
  k_customContainer,
} from '../../../constants'
import styled from 'styled-components'
import { FaAlignCenter, FaAlignJustify, FaAlignLeft, FaAlignRight, FaStrikethrough, FaUnderline } from "react-icons/fa";
import { RiEmphasis, RiSeparator, RiSubtractFill, RiSubtractLine } from "react-icons/ri";
import { LuAlignHorizontalJustifyCenter, LuAlignHorizontalJustifyEnd, LuAlignHorizontalJustifyStart, LuAlignHorizontalSpaceAround, LuAlignHorizontalSpaceBetween } from "react-icons/lu";
import { RxSpaceEvenlyHorizontally } from "react-icons/rx";
import { CheckCircleIcon } from '@heroicons/react/24/outline'
import { XCircleIcon } from '@heroicons/react/24/solid'
import { CiAlignBottom, CiAlignCenterH, CiAlignCenterV, CiAlignLeft, CiAlignRight, CiAlignTop } from 'react-icons/ci'

const ColorDiv = styled.div`
  width: 36px;
  height: 14px;
  border-radius: 2px;
  margin: 8px 2px 8px 2px;
`

const ColorSwatchDiv = styled.div`
  padding: 1px;
  background: #fff;
  border: 1px solid rgb(226 232 240);
  border-radius: 1px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  display: inline-block;
  cursor: pointer;
  width: 100%;
  border-radius: 0.375rem;
`

const CoverDiv = styled.div`
  position: absolute;
  right: 0;
  z-index: 2;
  width: 100%;
`

const SwitchLabel = styled.label`
  position: relative;
  display: inline-block;
  flex: none;
  height: 17px;
  width: 100%;
  & input {
    opacity: 0;
    width: 0;
    height: 0;
  }
`

const SliderDiv = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 43px;
  float: right;
  background-color: var(--itk-gray-color);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 9px;

  &:before {
    position: absolute;
    content: '';
    height: 15px;
    width: 15px;
    left: 1px;
    bottom: 1px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }

  input:checked + & {
    background-color: var(--itk-blue-color);
  }

  input:focus + & {
    box-shadow: 0 0 1px var(--itk-blue-color);
  }

  input:checked + &:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
`

const UploadImageLabel = styled.label`
  flex: none;
  margin-right: 2px;
`

const UploadImageInput = styled.input`
  display: none;
`

const StatusDiv = styled.div`
  margin-right: 5px;
`

const IconSelect = styled.select`
  font-family: 'FontAwesome', 'Comfortaa', cursive;
  font-family: 'Font-Awesome', 'Comfortaa', cursive;
  width: 100%;

  & > option {
    font-family: 'FontAwesome', 'Comfortaa', cursive;
    font-family: 'Font-Awesome', 'Comfortaa', cursive;
  }
`

class Property extends Component {
  constructor(props) {
    super(props);

    let initialValue;

    if (Array.isArray(this.props.value) && this.props.value.length > 0) {
      const firstItem = this.props.value[0];

      if (firstItem && 'location' in firstItem) {
        const selectedImage = this.props.value.find((i) => i.selected === 1);
        initialValue = selectedImage ? selectedImage.location : null;
      }
    }

    this.state = {
      value: initialValue || this.props.value,
      changed: false,
    };
  }

  async shouldComponentUpdate(nextProps, nextState) {
    if (this.props.id !== nextProps.id) {
      if (Array.isArray(nextProps.value) && 'location' in nextProps.value[0]) {
        let selectedImage = nextProps.value.find(
          (i) => i.selected === 1
        ).location
        this.setState({
          value: selectedImage,
        })
      } else {
        this.setState({
          value: nextProps.value,
        })
      }
      return true
    }
    return false
  }

  onEditClick = () => {
    this.setState({ changed: true })
  }

  onEdit = (e) => {
    if (e.target) {
      this.props.onTheGoEdit(this.props.id, this.props.name, e.target.value)
      this.setState({ value: e.target.value, changed: true })
    } else {
      this.props.onTheGoEdit(this.props.id, this.props.name, e)
      this.setState({ value: e, changed: true })
    }
  }

  onSpecialEdit = (e, inputtype) => {
    switch (inputtype) {
      case 'checkbox_visible':
        if (e.target.value === 'flex') {
          this.props.onTheGoEdit(this.props.id, this.props.name, 'none')
          this.setState({ value: 'none', changed: true })
        } else {
          this.props.onTheGoEdit(this.props.id, this.props.name, 'flex')
          this.setState({ value: 'flex', changed: true })
        }
        break
      case 'checkbox_bold':
        if (e.target.value === 'bold') {
          this.props.onTheGoEdit(this.props.id, this.props.name, 'normal')
          this.setState({ value: 'normal', changed: true })
        } else {
          this.props.onTheGoEdit(this.props.id, this.props.name, 'bold')
          this.setState({ value: 'bold', changed: true })
        }
        break
      case 'checkbox_italic':
        if (e.target.value === 'italic') {
          this.props.onTheGoEdit(this.props.id, this.props.name, 'normal')
          this.setState({ value: 'normal', changed: true })
        } else {
          this.props.onTheGoEdit(this.props.id, this.props.name, 'italic')
          this.setState({ value: 'italic', changed: true })
        }
        break
      case 'checkbox_trueFalse':
        if (e.target.value === 'true') {
          this.props.onTheGoEdit(this.props.id, this.props.name, 'false')
          this.setState({ value: 'false', changed: true })
        } else {
          this.props.onTheGoEdit(this.props.id, this.props.name, 'true')
          this.setState({ value: 'true', changed: true })
        }
        break
      case 'dropdown_textDecoration':
        if (e.target.value === '') {
          this.props.onTheGoEdit(this.props.id, this.props.name, '')
          this.setState({ value: '', changed: true })
        } else {
          let decorations = this.state.value.split(' ')
          if (decorations.indexOf(e.target.value) === -1) {
            let newValue = this.state.value
            newValue += ' ' + e.target.value
            this.props.onTheGoEdit(this.props.id, this.props.name, newValue)
            this.setState({ value: newValue, changed: true })
          } else {
            decorations.splice(decorations.indexOf(e.target.value), 1)
            this.props.onTheGoEdit(
              this.props.id,
              this.props.name,
              decorations.join(' ')
            )
            this.setState({ value: decorations.join(' '), changed: true })
          }
        }
        break
      case 'color':
        let newVal = e.hex
        this.props.onTheGoEdit(this.props.id, this.props.name, newVal)
        this.setState({ value: newVal, changed: true })
        break
      case 'selectImage':
        this.props.changeImage(this.props.id, e.target.value)
        this.setState({ value: e.target.value })
        break
      case 'changableUnit':
        let value = ''
        if (this.state.value.match(/(\d*\.?\d*)(.*)/))
          value = this.state.value.match(/(\d*\.?\d*)(.*)/)[1]
        else if (!!this.state.value) value = this.state.value
        this.props.onTheGoEdit(
          this.props.id,
          this.props.name,
          value + e.target.value
        )
        this.setState({ value: value + e.target.value, changed: true })
        break
      case 'changableUnitValue':
        let unit = ''
        if (this.state.value.match(/(\d*\.?\d*)(.*)/))
          unit = this.state.value.match(/(\d*\.?\d*)(.*)/)[2]
        else if (!!this.state.value) unit = this.state.value
        this.props.onTheGoEdit(
          this.props.id,
          this.props.name,
          e.target.value + unit
        )
        this.setState({ value: e.target.value + unit, changed: true })
        break
      default:
        break
    }
  }

  onImageSelect = (e) => {
    let newImage = new FormData()
    newImage.append('cid', this.props.id)
    newImage.append('image', e.target.files[0])
    this.props.uploadImage(newImage)
  }

  onSave = () => {
    this.setState({ changed: false })
    this.props.edit(this.props.id, this.props.name, this.state.value)
  }

  onCancel = () => {
    this.props.onTheGoEdit(this.props.id, this.props.name, this.props.value)
    this.setState({ value: this.props.value, changed: false })
  }

  render() {
    let name = this.props.name
    let inputbox = (
      <input
        type="textbox"
        name={this.props.name}
        value={this.state.value}
        onChange={this.onEdit}
        className="block w-full rounded-md border-0 py-1 pl-4 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
      />
    )
    switch (name) {
      case 'value':
        if (this.props.cTypeId === k_switchid) {
          name = 'default value'
          inputbox = (
            <SwitchLabel>
              <input
                type="checkbox"
                value={this.state.value}
                checked={this.state.value === 'true'}
                onChange={(e) => this.onSpecialEdit(e, 'checkbox_trueFalse')}
              />
              <SliderDiv></SliderDiv>
            </SwitchLabel>
          )
        } else if (this.props.cTypeId === k_dateTimeid) {
          return null
        }
        break
      case 'disabled':
        name = 'disable'
        inputbox = (
          <SwitchLabel>
            <input
              type="checkbox"
              value={this.state.value}
              checked={this.state.value === 'true'}
              onChange={(e) => this.onSpecialEdit(e, 'checkbox_trueFalse')}
            />
            <SliderDiv></SliderDiv>
          </SwitchLabel>
        )
        break
      case 'thumbColor':
        name = 'thumb'
        inputbox = (
          <div style={{ position: 'relative' }}>
            <ColorSwatchDiv onClick={()=>{this.setState({changed: !this.state.changed})}}>
              <ColorDiv
                style={{ backgroundColor: this.state.value }}
              ></ColorDiv>
            </ColorSwatchDiv>
            {this.state.changed ? (
              <CoverDiv>
                <SketchPicker
                  color={this.state.value}
                  onChange={(e) => this.onSpecialEdit(e, 'color')}
                />
              </CoverDiv>
            ) : null}
          </div>
        )
        break
      case 'trueTrackColor':
        name = 'true'
        inputbox = (
          <div style={{ position: 'relative' }}>
            <ColorSwatchDiv onClick={()=>{this.setState({changed: !this.state.changed})}}>
              <ColorDiv
                style={{ backgroundColor: this.state.value }}
              ></ColorDiv>
            </ColorSwatchDiv>
            {this.state.changed ? (
              <CoverDiv>
                <SketchPicker
                  color={this.state.value}
                  onChange={(e) => this.onSpecialEdit(e, 'color')}
                />
              </CoverDiv>
            ) : null}
          </div>
        )
        break
      case 'falseTrackColor':
        name = 'false'
        inputbox = (
          <div style={{ position: 'relative' }}>
            <ColorSwatchDiv onClick={()=>{this.setState({changed: !this.state.changed})}}>
              <ColorDiv
                style={{ backgroundColor: this.state.value }}
              ></ColorDiv>
            </ColorSwatchDiv>
            {this.state.changed ? (
              <CoverDiv>
                <SketchPicker
                  color={this.state.value}
                  onChange={(e) => this.onSpecialEdit(e, 'color')}
                />
              </CoverDiv>
            ) : null}
          </div>
        )
        break
      case 'labelPosition':
        return null
      case 'flexDirection':
        if (this.props.cTypeId === k_customContainer) {
          name = 'Placement direction'
          inputbox = (
            <select value={this.state.value} onChange={this.onEdit}
              className='block w-full rounded-md border-0 py-1 pl-4 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mt-2'>
              <option value="column">column</option>
              <option value="row">row</option>
              <option value="column-reverse">reversed column</option>
              <option value="row-reverse">reversed row</option>
            </select>
          )
        } else {
          name = 'Label position'
          if (this.props.cTypeId === k_switchid) {
            inputbox = (
              <select value={this.state.value} onChange={this.onEdit}
                className='block w-full rounded-md border-0 py-1 pl-4 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mt-2'>
                <option value="column">top</option>
                <option value="row">left</option>
                <option value="column-reverse">bottom</option>
                <option value="row-reverse">right</option>
              </select>
            )
          } else if (this.props.cTypeId === k_radioButtonid) {
            inputbox = (
              <select value={this.state.value} onChange={this.onEdit} className='block w-full rounded-md border-0 py-1 pl-4 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mt-2'>
                <option value="row">left</option>
                <option value="row-reverse">right</option>
              </select>
            )
          }
        }
        break
      case 'src':
        name = 'Source'
        if (
          Array.isArray(this.props.value) &&
          'location' in this.props.value[0]
        ) {
          inputbox = (
            <Fragment>
              <StatusDiv>
                {this.props.uploadLoading ? (
                  <div className="loading">
                    <i className="fa fa-spinner fa-spin"></i>
                  </div>
                ) : (
                  this.props.uploadError !== null && (
                    <i
                      className="fa fa-exclamation-triangle error"
                      title={this.props.uploadError}
                    />
                  )
                )}
              </StatusDiv>
              <UploadImageLabel htmlFor="imageUpload">
                <i className="fa fa-upload"></i>
              </UploadImageLabel>
              <UploadImageInput
                id="imageUpload"
                type="file"
                accept={
                  this.props.cTypeId === k_imageid ? 'image/*' : 'video/mp4'
                }
                onChange={this.onImageSelect}
              />
              <select className='block w-full rounded-md border-0 py-1 pl-4 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mt-2'
                value={this.state.value}
                onChange={(e) => this.onSpecialEdit(e, 'selectImage')}
              >
                {this.props.value.map((i) => (
                  <option value={i.location} key={i.location}>
                    {i.name}
                  </option>
                ))}
              </select>
            </Fragment>
          )
        }
        break
      case 'alt':
        name = 'Placeholder'
        break
      case 'type':
        inputbox = (
          <select className='block mt-2 w-full rounded-md border-0 py-1 pl-4 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mt-2' value={this.state.value} onChange={this.onEdit}>
            <option value="text">text</option>
            <option value="number">number</option>
            <option value="password">password</option>
            <option value="email">e-mail</option>
          </select>
        )
        break
      case 'resizeMode':
        inputbox = (
          <select className='block w-full h-full rounded-md border-0 py-1 pl-4 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mt-2' value={this.state.value} onChange={this.onEdit}>
            <option value="contain">contain</option>
            <option value="cover">cover</option>
            <option value="center">center</option>
          </select>
        )
        break
      case 'objectFit':
        name = 'Coverage'
        inputbox = (
          <select className='block w-full h-full rounded-md border-0 py-1 pl-4 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mt-2' value={this.state.value} onChange={this.onEdit}>
            <option value="contain">contain</option>
            <option value="cover">cover</option>
            <option value="center">center</option>
          </select>
        )
        break
      case 'color':
        inputbox = (
          <div style={{ position: 'relative' }}>
            <ColorSwatchDiv onClick={()=>{this.setState({changed: !this.state.changed})}}>
              <ColorDiv
                style={{ backgroundColor: this.state.value }}
              ></ColorDiv>
            </ColorSwatchDiv>
            {this.state.changed ? (
              <CoverDiv>
                <SketchPicker
                  color={this.state.value}
                  onChange={(e) => this.onSpecialEdit(e, 'color')}
                />
              </CoverDiv>
            ) : null}
          </div>
        )
        if (this.props.cTypeId !== k_iconid) {
          name = 'font color'
        }
        break
      case 'display':
        name = 'visible'
        inputbox = (
          <SwitchLabel>
            <input
              type="checkbox"
              value={this.state.value}
              checked={this.state.value === 'flex'}
              onChange={(e) => this.onSpecialEdit(e, 'checkbox_visible')}
            />
            <SliderDiv></SliderDiv>
          </SwitchLabel>
        )
        break
      case 'borderColor':
        inputbox = (
          <div className='mt-2 relative' style={{ position: 'relative' }}>
            <ColorSwatchDiv onClick={()=>{this.setState({changed: !this.state.changed})}}>
              <ColorDiv
                style={{ backgroundColor: this.state.value }}
              ></ColorDiv>
            </ColorSwatchDiv>
            {this.state.changed ? (
              <CoverDiv>
                <SketchPicker
                  color={this.state.value}
                  onChange={(e) => this.onSpecialEdit(e, 'color')}
                />
              </CoverDiv>
            ) : null}
          </div>
        )
        break
      case 'backgroundColor':
        inputbox = (
          <div style={{ position: 'relative' }}>
            <ColorSwatchDiv onClick={()=>{this.setState({changed: !this.state.changed})}}>
              <ColorDiv
                style={{ backgroundColor: this.state.value }}
              ></ColorDiv>
            </ColorSwatchDiv>
            {this.state.changed ? (
              <CoverDiv>
                <SketchPicker
                  color={this.state.value}
                  onChange={(e) => this.onSpecialEdit(e, 'color')}
                />
              </CoverDiv>
            ) : null}
          </div>
        )
        break
      case 'fontFamily':
        inputbox = (
          <select className='block w-full rounded-md border-0 py-1.5 pl-4 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mt-2' value={this.state.value} onChange={this.onEdit}>
            <option value="applab_serif" style={{ fontFamily: 'serif' }}>
              Serif
            </option>
            <option
              value="applab_sans-serif"
              style={{ fontFamily: 'sans-serif' }}
            >
              Sans-Serif
            </option>
            <option value="applab_cursive" style={{ fontFamily: 'cursive' }}>
              Block-Type
            </option>
            <option value="applab_fantasy" style={{ fontFamily: 'fantasy' }}>
              Fantasy
            </option>
            <option
              value="applab_monospace"
              style={{ fontFamily: 'monospace' }}
            >
              Monospace
            </option>
          </select>
        )
        break
      case 'fontWeight':
        name = 'Bold'
        inputbox = (
          <SwitchLabel>
            <input
              type="checkbox"
              value={this.state.value}
              checked={this.state.value === 'bold'}
              onChange={(e) => this.onSpecialEdit(e, 'checkbox_bold')}
            />
            <SliderDiv></SliderDiv>
          </SwitchLabel>
        )
        break
      case 'fontStyle':
        name = 'Italic'
        inputbox = (
          <SwitchLabel>
            <input
              type="checkbox"
              className='mt-4'
              value={this.state.value}
              checked={this.state.value === 'italic'}
              onChange={(e) => this.onSpecialEdit(e, 'checkbox_italic')}
            />
            <SliderDiv></SliderDiv>
          </SwitchLabel>
        )
        break
      case 'textDecorationLine':
        inputbox = (
          <div className='flex justify-center items-center w-full rounded-md border divide-x-2 cursor-pointer'>
            <div className='p-2 w-1/4 flex justify-center items-center' onClick={() => this.onSpecialEdit('none', 'dropdown_textDecoration')}>
              <RiSubtractLine className='w-6 ' />
            </div>
            <div className='p-2 w-1/4 flex justify-center items-center' onClick={() => this.onSpecialEdit('line-through', 'dropdown_textDecoration')}>
              <FaStrikethrough className='w-6 ' />
            </div>
            <div className='p-2 w-1/4 flex justify-center items-center' onClick={() => this.onSpecialEdit('underline', 'dropdown_textDecoration')}>
              <FaUnderline className='w-6 ' />
            </div>
            <div className='p-2 w-1/4 flex justify-center items-center' onClick={(e) => this.onSpecialEdit(e, 'dropdown_textDecoration')}>
              <RiSeparator className='w-6 ' />
            </div>
          </div>
        )
        break
      case 'textDecorationStyle':
        inputbox = (
          <div className='flex justify-center items-center w-full rounded-md border divide-x-2 cursor-pointer'>
            <div className='p-2 w-1/3 flex justify-center items-center' onClick={() => this.onEdit('dashed')}>
              <RiSubtractFill className={`w-6 ${this.state.value === 'dashed' && 'text-[#05A6A1]'}`} />
            </div>
            <div className='p-2 w-1/3 flex justify-center items-center' onClick={() => this.onEdit('dotted')}>
              <RiSeparator className={`w-6 ${this.state.value === 'dotted' && 'text-[#05A6A1]'}`} />
            </div>
            <div className='p-2 w-1/3 flex justify-center items-center' onClick={() => this.onEdit('solid')}>
              <RiEmphasis className={`w-6 ${this.state.value === 'solid' && 'text-[#05A6A1]'}`} />
            </div>
          </div>
        )
        break
      case 'textAlign':
        inputbox = (
          <div className='flex justify-center items-center w-full rounded-md border divide-x-2 cursor-pointer'>
            <div className='p-2 w-1/4 flex justify-center items-center' onClick={() => this.onEdit('left')}>
              <FaAlignLeft className={`w-6 ${this.state.value === 'left' && 'text-[#05A6A1]'}`} />
            </div>
            <div className='p-2 w-1/4 flex justify-center items-center' onClick={() => this.onEdit('center')}>
              <FaAlignCenter className={`w-6 ${this.state.value === 'center' && 'text-[#05A6A1]'}`} />
            </div>
            <div className='p-2 w-1/4 flex justify-center items-center' onClick={() => this.onEdit('right')}>
              <FaAlignRight className={`w-6 ${this.state.value === 'right' && 'text-[#05A6A1]'}`} />
            </div>
            <div className='p-2 w-1/4 flex justify-center items-center' onClick={() => this.onEdit("justify")}>
              <FaAlignJustify className={`w-6 ${this.state.value === 'justify' && 'text-[#05A6A1]'}`} />
            </div>
          </div>
        )
        break
      case 'borderStyle':
        inputbox = (
          <div className='flex justify-center items-center w-full rounded-md border divide-x-2 mt-2 cursor-pointer'>
            <div className='p-2 w-1/3 flex justify-center items-center' onClick={() => this.onEdit('dashed')}>
              <RiSubtractFill className={`w-6 ${this.state.value === 'dashed' && 'text-[#05A6A1]'}`} />
            </div>
            <div className='p-2 w-1/3 flex justify-center items-center' onClick={() => this.onEdit('dotted')}>
              <RiSeparator className={`w-6 ${this.state.value === 'dotted' && 'text-[#05A6A1]'}`} />
            </div>
            <div className='p-2 w-1/3 flex justify-center items-center' onClick={() => this.onEdit('solid')}>
              <RiEmphasis className={`w-6 ${this.state.value === 'solid' && 'text-[#05A6A1]'}`} />
            </div>
          </div>
        )
        break
      case 'justifyContent':
        if (this.props.cTypeId !== k_rowid) name = 'vertical alignment'
        else name = 'horizontal alignment'
        inputbox = (
          <div className='flex justify-center items-center w-full rounded-md border divide-x-2 cursor-pointer'>
            <div className='p-2 w-1/6 flex justify-center items-center' onClick={() => this.onEdit('center')}>
              <LuAlignHorizontalJustifyCenter className={`w-6 ${this.state.value === 'center' && 'text-[#05A6A1]'}`} />
            </div>
            <div className='p-2 w-1/6 flex justify-center items-center' onClick={() => this.onEdit('flex-start')}>
              <LuAlignHorizontalJustifyStart className={`w-6 ${this.state.value === 'flex-start' && 'text-[#05A6A1]'}`} />
            </div>
            <div className='p-2 w-1/6 flex justify-center items-center' onClick={() => this.onEdit('flex-end')}>
              <LuAlignHorizontalJustifyEnd className={`w-6 ${this.state.value === 'flex-end' && 'text-[#05A6A1]'}`} />
            </div>
            <div className='p-2 w-1/6 flex justify-center items-center' onClick={() => this.onEdit("space-around")}>
              <LuAlignHorizontalSpaceAround className={`w-6 ${this.state.value === 'space-around' && 'text-[#05A6A1]'}`} />
            </div>
            <div className='p-2 w-1/6 flex justify-center items-center' onClick={() => this.onEdit("space-evenly")}>
              <RxSpaceEvenlyHorizontally className={`w-6 ${this.state.value === 'space-evenly' && 'text-[#05A6A1]'}`} />
            </div>
            <div className='p-2 w-1/6 flex justify-center items-center' onClick={() => this.onEdit("space-between")}>
              <LuAlignHorizontalSpaceBetween className={`w-6 ${this.state.value === 'space-between' && 'text-[#05A6A1]'}`} />
            </div>
          </div>
        )
        break
      case 'alignItems':
        if (this.props.cTypeId !== k_rowid) name = 'horizontal alignment'
        else name = 'vertical alignment'
        inputbox = (
          <div className='flex justify-center items-center w-full rounded-md border divide-x-2 cursor-pointer'>
            <div className='p-2 w-1/3 flex justify-center items-center' onClick={() => this.onEdit('center')}>
              <CiAlignTop className={`w-6 ${this.state.value === 'center' && 'text-[#05A6A1]'}`} />
            </div>
            <div className='p-2 w-1/3 flex justify-center items-center' onClick={() => this.onEdit('flex-start')}>
              <CiAlignCenterV className={`w-6 ${this.state.value === 'flex-start' && 'text-[#05A6A1]'}`} />
            </div>
            <div className='p-2 w-1/3 flex justify-center items-center' onClick={() => this.onEdit('flex-end')}>
              <CiAlignBottom className={`w-6 ${this.state.value === 'flex-end' && 'text-[#05A6A1]'}`} />
            </div>
          </div>
        )
        break
      case 'alignSelf':
        name = 'take space in the same axis'
        inputbox = (
          <select className='block w-full rounded-md border-0 py-1 pl-4 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mt-2' value={this.state.value} onChange={this.onEdit}>
            <option value="center">center</option>
            <option value="stretch">contain parent</option>
          </select>
        )
        break
      case 'flex':
        name = 'take space in the opposite axis'
        inputbox = (
          <select className='block w-full rounded-md border-0 py-1 pl-4 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mt-2' value={this.state.value} onChange={this.onEdit}>
            <option value="none">none</option>
            <option value="0">0</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
          </select>
        )
        break
      case 'table':
        inputbox = (
          <select className='block w-full rounded-md border-0 py-1 pl-4 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mt-2' value={this.state.value} onChange={this.onEdit}>
            <option value={null}>No table selected or created</option>
            {JSON.parse(localStorage.getItem('applab_myTables')).map(
              (table) => (
                <option key={table.id} value={table.id}>
                  {table.name}
                </option>
              )
            )}
          </select>
        )
        break
      case 'useNativeControls':
        name = 'showVideoControls'
        inputbox = (
          <SwitchLabel>
            <input
              type="checkbox"
              value={this.state.value}
              checked={this.state.value === 'true'}
              onChange={(e) => this.onSpecialEdit(e, 'checkbox_trueFalse')}
            />
            <SliderDiv></SliderDiv>
          </SwitchLabel>
        )
        break
      case 'isMuted':
        name = 'mute'
        inputbox = (
          <SwitchLabel>
            <input
              type="checkbox"
              value={this.state.value}
              checked={this.state.value === 'true'}
              onChange={(e) => this.onSpecialEdit(e, 'checkbox_trueFalse')}
            />
            <SliderDiv></SliderDiv>
          </SwitchLabel>
        )
        break
      case 'isLooping':
        name = 'looping'
        inputbox = (
          <SwitchLabel>
            <input
              type="checkbox"
              value={this.state.value}
              checked={this.state.value === 'true'}
              onChange={(e) => this.onSpecialEdit(e, 'checkbox_trueFalse')}
            />
            <SliderDiv></SliderDiv>
          </SwitchLabel>
        )
        break
      case 'autoplay':
        name = 'autoplay'
        inputbox = (
          <SwitchLabel>
            <input
              type="checkbox"
              value={this.state.value}
              checked={this.state.value === 'true'}
              onChange={(e) => this.onSpecialEdit(e, 'checkbox_trueFalse')}
            />
            <SliderDiv></SliderDiv>
          </SwitchLabel>
        )
        break
      case 'columnGap':
        name = 'Spacing in between'
        break
      case 'gap':
        name = 'Spacing in between'
        break
      case 'mode':
        inputbox = (
          <select className='block w-full rounded-md border-0 py-1 pl-4 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mt-2' value={this.state.value} onChange={this.onEdit}>
            <option value="date">Date</option>
            <option value="time">Time</option>
          </select>
        )
        break
      case 'icon':
        inputbox = (
          <select className='block w-full rounded-md border-0 py-1 pl-4 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mt-2' value={this.state.value} onChange={this.onEdit}>
            <option value="align-left">align-left</option>
            <option value="align-right">align-right</option>
            <option value="amazon">amazon</option>
            <option value="ambulance">ambulance</option>
            <option value="anchor">anchor</option>
            <option value="android">android</option>
            <option value="angellist">angellist</option>
            <option value="angle-double-down">angle-double-down</option>
            <option value="angle-double-left">angle-double-left</option>
            <option value="angle-double-right">angle-double-right</option>
            <option value="angle-double-up">angle-double-up</option>
            <option value="angle-left">angle-left</option>
            <option value="angle-right">angle-right</option>
            <option value="angle-up">angle-up</option>
            <option value="apple">apple</option>
            <option value="archive">archive</option>
            <option value="area-chart">area-chart</option>
            <option value="arrow-circle-down">arrow-circle-down</option>
            <option value="arrow-circle-left">arrow-circle-left</option>
            <option value="arrow-circle-o-down">arrow-circle-o-down</option>
            <option value="arrow-circle-o-left">arrow-circle-o-left</option>
            <option value="arrow-circle-o-right">arrow-circle-o-right</option>
            <option value="arrow-circle-o-up">arrow-circle-o-up</option>
            <option value="arrow-circle-right">arrow-circle-right</option>
            <option value="arrow-circle-up">arrow-circle-up</option>
            <option value="arrow-down">arrow-down</option>
            <option value="arrow-left">arrow-left</option>
            <option value="arrow-right">arrow-right</option>
            <option value="arrow-up">arrow-up</option>
            <option value="arrows">arrows</option>
            <option value="arrows-alt">arrows-alt</option>
            <option value="arrows-h">arrows-h</option>
            <option value="arrows-v">arrows-v</option>
            <option value="asterisk">asterisk</option>
            <option value="at">at</option>
            <option value="automobile">automobile</option>
            <option value="backward">backward</option>
            <option value="balance-scale">balance-scale</option>
            <option value="ban">ban</option>
            <option value="bank">bank</option>
            <option value="bar-chart">bar-chart</option>
            <option value="bar-chart-o">bar-chart-o</option>
            <option value="battery-full">battery-full</option>
            <option value="beer">beer</option>
            <option value="behance">behance</option>
            <option value="behance-square">behance-square</option>
            <option value="bell">bell</option>
            <option value="bell-o">bell-o</option>
            <option value="bell-slash">bell-slash</option>
            <option value="bell-slash-o">bell-slash-o</option>
            <option value="bicycle">bicycle</option>
            <option value="binoculars">binoculars</option>
            <option value="birthday-cake">birthday-cake</option>
            <option value="bitbucket">bitbucket</option>
            <option value="bitbucket-square">bitbucket-square</option>
            <option value="bitcoin">bitcoin</option>
            <option value="black-tie">black-tie</option>
            <option value="bold">bold</option>
            <option value="bolt">bolt</option>
            <option value="bomb">bomb</option>
            <option value="book">book</option>
            <option value="bookmark">bookmark</option>
            <option value="bookmark-o">bookmark-o</option>
            <option value="briefcase">briefcase</option>
            <option value="btc">btc</option>
            <option value="bug">bug</option>
            <option value="building">building</option>
            <option value="building-o">building-o</option>
            <option value="bullhorn">bullhorn</option>
            <option value="bullseye">bullseye</option>
            <option value="bus">bus</option>
            <option value="cab">cab</option>
            <option value="calendar">calendar</option>
            <option value="camera">camera</option>
            <option value="car">car</option>
            <option value="caret-up">caret-up</option>
            <option value="cart-plus">cart-plus</option>
            <option value="cc">cc</option>
            <option value="cc-amex">cc-amex</option>
            <option value="cc-jcb">cc-jcb</option>
            <option value="cc-paypal">cc-paypal</option>
            <option value="cc-stripe">cc-stripe</option>
            <option value="cc-visa">cc-visa</option>
            <option value="chain">chain</option>
            <option value="check">check</option>
            <option value="chevron-left">chevron-left</option>
            <option value="chevron-right">chevron-right</option>
            <option value="chevron-up">chevron-up</option>
            <option value="child">child</option>
            <option value="chrome">chrome</option>
            <option value="circle">circle</option>
            <option value="circle-o">circle-o</option>
            <option value="circle-o-notch">circle-o-notch</option>
            <option value="circle-thin">circle-thin</option>
            <option value="clipboard">clipboard</option>
            <option value="clock-o">clock-o</option>
            <option value="clone">clone</option>
            <option value="close">close</option>
            <option value="cloud">cloud</option>
            <option value="cloud-download">cloud-download</option>
            <option value="cloud-upload">cloud-upload</option>
            <option value="cny">cny</option>
            <option value="code">code</option>
            <option value="code-fork">code-fork</option>
            <option value="codepen">codepen</option>
            <option value="coffee">coffee</option>
            <option value="cog">cog</option>
            <option value="cogs">cogs</option>
            <option value="columns">columns</option>
            <option value="comment">comment</option>
            <option value="comment-o">comment-o</option>
            <option value="commenting">commenting</option>
            <option value="commenting-o">commenting-o</option>
            <option value="comments">comments</option>
            <option value="comments-o">comments-o</option>
            <option value="compass">compass</option>
            <option value="compress">compress</option>
            <option value="connectdevelop">connectdevelop</option>
            <option value="contao">contao</option>
            <option value="copy">copy</option>
            <option value="copyright">copyright</option>
            <option value="creative-commons">creative-commons</option>
            <option value="credit-card">credit-card</option>
            <option value="crop">crop</option>
            <option value="crosshairs">crosshairs</option>
            <option value="css3">css3</option>
            <option value="cube">cube</option>
            <option value="cubes">cubes</option>
            <option value="cut">cut</option>
            <option value="cutlery">cutlery</option>
            <option value="dashboard">dashboard</option>
            <option value="dashcube">dashcube</option>
            <option value="database">database</option>
            <option value="dedent">dedent</option>
            <option value="delicious">delicious</option>
            <option value="desktop">desktop</option>
            <option value="deviantart">deviantart</option>
            <option value="diamond">diamond</option>
            <option value="digg">digg</option>
            <option value="dollar">dollar</option>
            <option value="download">download</option>
            <option value="dribbble">dribbble</option>
            <option value="dropbox">dropbox</option>
            <option value="drupal">drupal</option>
            <option value="edit">edit</option>
            <option value="eject">eject</option>
            <option value="ellipsis-h">ellipsis-h</option>
            <option value="ellipsis-v">ellipsis-v</option>
            <option value="empire">empire</option>
            <option value="envelope">envelope</option>
            <option value="envelope-o">envelope-o</option>
            <option value="eur">eur</option>
            <option value="euro">euro</option>
            <option value="exchange">exchange</option>
            <option value="exclamation">exclamation</option>
            <option value="exclamation-circle">exclamation-circle</option>
            <option value="exclamation-triangle">exclamation-triangle</option>
            <option value="expand">expand</option>
            <option value="expeditedssl">expeditedssl</option>
            <option value="external-link">external-link</option>
            <option value="external-link-square">external-link-square</option>
            <option value="eye">eye</option>
            <option value="eye-slash">eye-slash</option>
            <option value="eyedropper">eyedropper</option>
            <option value="facebook">facebook</option>
            <option value="facebook-f">facebook-f</option>
            <option value="facebook-official">facebook-official</option>
            <option value="facebook-square">facebook-square</option>
            <option value="fast-backward">fast-backward</option>
            <option value="fast-forward">fast-forward</option>
            <option value="fax">fax</option>
            <option value="feed">feed</option>
            <option value="female">female</option>
            <option value="fighter-jet">fighter-jet</option>
            <option value="file">file</option>
            <option value="file-archive-o">file-archive-o</option>
            <option value="file-audio-o">file-audio-o</option>
            <option value="file-code-o">file-code-o</option>
            <option value="file-excel-o">file-excel-o</option>
            <option value="file-image-o">file-image-o</option>
            <option value="file-movie-o">file-movie-o</option>
            <option value="file-o">file-o</option>
            <option value="file-pdf-o">file-pdf-o</option>
            <option value="file-photo-o">file-photo-o</option>
            <option value="file-picture-o">file-picture-o</option>
            <option value="file-powerpoint-o">file-powerpoint-o</option>
            <option value="file-sound-o">file-sound-o</option>
            <option value="file-text">file-text</option>
            <option value="file-text-o">file-text-o</option>
            <option value="file-video-o">file-video-o</option>
            <option value="file-word-o">file-word-o</option>
            <option value="file-zip-o">file-zip-o</option>
            <option value="files-o">files-o</option>
            <option value="film">film</option>
            <option value="filter">filter</option>
            <option value="fire">fire</option>
            <option value="fire-extinguisher">fire-extinguisher</option>
            <option value="firefox">firefox</option>
            <option value="flag">flag</option>
            <option value="flag-checkered">flag-checkered</option>
            <option value="flag-o">flag-o</option>
            <option value="flash">flash</option>
            <option value="flask">flask</option>
            <option value="flickr">flickr</option>
            <option value="floppy-o">floppy-o</option>
            <option value="folder">folder</option>
            <option value="folder-o">folder-o</option>
            <option value="folder-open">folder-open</option>
            <option value="folder-open-o">folder-open-o</option>
            <option value="font">font</option>
            <option value="fonticons">fonticons</option>
            <option value="forumbee">forumbee</option>
            <option value="forward">forward</option>
            <option value="foursquare">foursquare</option>
            <option value="frown-o">frown-o</option>
            <option value="futbol-o">futbol-o</option>
            <option value="gamepad">gamepad</option>
            <option value="gavel">gavel</option>
            <option value="gbp">gbp</option>
            <option value="ge">ge</option>
            <option value="gear">gear</option>
            <option value="gears">gears</option>
            <option value="genderless">genderless</option>
            <option value="get-pocket">get-pocket</option>
            <option value="gg">gg</option>
            <option value="gg-circle">gg-circle</option>
            <option value="gift">gift</option>
            <option value="git">git</option>
            <option value="git-square">git-square</option>
            <option value="github">github</option>
            <option value="github-alt">github-alt</option>
            <option value="github-square">github-square</option>
            <option value="gittip">gittip</option>
            <option value="glass">glass</option>
            <option value="globe">globe</option>
            <option value="google">google</option>
            <option value="google-plus">google-plus</option>
            <option value="google-plus-square">google-plus-square</option>
            <option value="google-wallet">google-wallet</option>
            <option value="graduation-cap">graduation-cap</option>
            <option value="gratipay">gratipay</option>
            <option value="group">group</option>
            <option value="h-square">h-square</option>
            <option value="hacker-news">hacker-news</option>
            <option value="hand-grab-o">hand-grab-o</option>
            <option value="hand-lizard-o">hand-lizard-o</option>
            <option value="hand-o-down">hand-o-down</option>
            <option value="hand-o-left">hand-o-left</option>
            <option value="hand-o-right">hand-o-right</option>
            <option value="hand-o-up">hand-o-up</option>
            <option value="hand-paper-o">hand-paper-o</option>
            <option value="hand-peace-o">hand-peace-o</option>
            <option value="hand-pointer-o">hand-pointer-o</option>
            <option value="hand-rock-o">hand-rock-o</option>
            <option value="hand-scissors-o">hand-scissors-o</option>
            <option value="hand-spock-o">hand-spock-o</option>
            <option value="hand-stop-o">hand-stop-o</option>
            <option value="hdd-o">hdd-o</option>
            <option value="header">header</option>
            <option value="headphones">headphones</option>
            <option value="heart">heart</option>
            <option value="heart-o">heart-o</option>
            <option value="heartbeat">heartbeat</option>
            <option value="history">history</option>
            <option value="home">home</option>
            <option value="hospital-o">hospital-o</option>
            <option value="hotel">hotel</option>
            <option value="hourglass">hourglass</option>
            <option value="hourglass-1">hourglass-1</option>
            <option value="hourglass-2">hourglass-2</option>
            <option value="hourglass-3">hourglass-3</option>
            <option value="hourglass-end">hourglass-end</option>
            <option value="hourglass-half">hourglass-half</option>
            <option value="hourglass-o">hourglass-o</option>
            <option value="hourglass-start">hourglass-start</option>
            <option value="houzz">houzz</option>
            <option value="html5">html5</option>
            <option value="i-cursor">i-cursor</option>
            <option value="ils">ils</option>
            <option value="image">image</option>
            <option value="inbox">inbox</option>
            <option value="indent">indent</option>
            <option value="industry">industry</option>
            <option value="info">info</option>
            <option value="info-circle">info-circle</option>
            <option value="inr">inr</option>
            <option value="instagram">instagram</option>
            <option value="institution">institution</option>
            <option value="internet-explorer">internet-explorer</option>
            <option value="intersex">intersex</option>
            <option value="ioxhost">ioxhost</option>
            <option value="italic">italic</option>
            <option value="joomla">joomla</option>
            <option value="jpy">jpy</option>
            <option value="jsfiddle">jsfiddle</option>
            <option value="key">key</option>
            <option value="keyboard-o">keyboard-o</option>
            <option value="krw">krw</option>
            <option value="language">language</option>
            <option value="laptop">laptop</option>
            <option value="lastfm">lastfm</option>
            <option value="lastfm-square">lastfm-square</option>
            <option value="leaf">leaf</option>
            <option value="leanpub">leanpub</option>
            <option value="legal">legal</option>
            <option value="lemon-o">lemon-o</option>
            <option value="level-down">level-down</option>
            <option value="level-up">level-up</option>
            <option value="life-bouy">life-bouy</option>
            <option value="life-buoy">life-buoy</option>
            <option value="life-ring">life-ring</option>
            <option value="life-saver">life-saver</option>
            <option value="lightbulb-o">lightbulb-o</option>
            <option value="line-chart">line-chart</option>
            <option value="link">link</option>
            <option value="linkedin">linkedin</option>
            <option value="linkedin-square">linkedin-square</option>
            <option value="linux">linux</option>
            <option value="list">list</option>
            <option value="list-alt">list-alt</option>
            <option value="list-ol">list-ol</option>
            <option value="list-ul">list-ul</option>
            <option value="location-arrow">location-arrow</option>
            <option value="lock">lock</option>
            <option value="long-arrow-down">long-arrow-down</option>
            <option value="long-arrow-left">long-arrow-left</option>
            <option value="long-arrow-right">long-arrow-right</option>
            <option value="long-arrow-up">long-arrow-up</option>
            <option value="magic">magic</option>
            <option value="magnet">magnet</option>
            <option value="mars-stroke-v">mars-stroke-v</option>
            <option value="maxcdn">maxcdn</option>
            <option value="meanpath">meanpath</option>
            <option value="medium">medium</option>
            <option value="medkit">medkit</option>
            <option value="meh-o">meh-o</option>
            <option value="mercury">mercury</option>
            <option value="microphone">microphone</option>
            <option value="mobile">mobile</option>
            <option value="motorcycle">motorcycle</option>
            <option value="mouse-pointer">mouse-pointer</option>
            <option value="music">music</option>
            <option value="navicon">navicon</option>
            <option value="neuter">neuter</option>
            <option value="newspaper-o">newspaper-o</option>
            <option value="opencart">opencart</option>
            <option value="openid">openid</option>
            <option value="opera">opera</option>
            <option value="outdent">outdent</option>
            <option value="pagelines">pagelines</option>
            <option value="paper-plane-o">paper-plane-o</option>
            <option value="paperclip">paperclip</option>
            <option value="paragraph">paragraph</option>
            <option value="paste">paste</option>
            <option value="pause">pause</option>
            <option value="paw">paw</option>
            <option value="paypal">paypal</option>
            <option value="pencil">pencil</option>
            <option value="pencil-square-o">pencil-square-o</option>
            <option value="phone">phone</option>
            <option value="photo">photo</option>
            <option value="picture-o">picture-o</option>
            <option value="pie-chart">pie-chart</option>
            <option value="pied-piper">pied-piper</option>
            <option value="pied-piper-alt">pied-piper-alt</option>
            <option value="pinterest">pinterest</option>
            <option value="pinterest-p">pinterest-p</option>
            <option value="pinterest-square">pinterest-square</option>
            <option value="plane">plane</option>
            <option value="play">play</option>
            <option value="play-circle">play-circle</option>
            <option value="play-circle-o">play-circle-o</option>
            <option value="plug">plug</option>
            <option value="plus">plus</option>
            <option value="plus-circle">plus-circle</option>
            <option value="plus-square">plus-square</option>
            <option value="plus-square-o">plus-square-o</option>
            <option value="power-off">power-off</option>
            <option value="print">print</option>
            <option value="puzzle-piece">puzzle-piece</option>
            <option value="qq">qq</option>
            <option value="qrcode">qrcode</option>
            <option value="question">question</option>
            <option value="question-circle">question-circle</option>
            <option value="quote-left">quote-left</option>
            <option value="quote-right">quote-right</option>
            <option value="ra">ra</option>
            <option value="random">random</option>
            <option value="rebel">rebel</option>
            <option value="recycle">recycle</option>
            <option value="reddit">reddit</option>
            <option value="reddit-square">reddit-square</option>
            <option value="refresh">refresh</option>
            <option value="registered">registered</option>
            <option value="remove">remove</option>
            <option value="renren">renren</option>
            <option value="reorder">reorder</option>
            <option value="repeat">repeat</option>
            <option value="reply">reply</option>
            <option value="reply-all">reply-all</option>
            <option value="retweet">retweet</option>
            <option value="rmb">rmb</option>
            <option value="road">road</option>
            <option value="rocket">rocket</option>
            <option value="rotate-left">rotate-left</option>
            <option value="rotate-right">rotate-right</option>
            <option value="rouble">rouble</option>
            <option value="rss">rss</option>
            <option value="rss-square">rss-square</option>
            <option value="rub">rub</option>
            <option value="ruble">ruble</option>
            <option value="rupee">rupee</option>
            <option value="safari">safari</option>
            <option value="sliders">sliders</option>
            <option value="slideshare">slideshare</option>
            <option value="smile-o">smile-o</option>
            <option value="sort-asc">sort-asc</option>
            <option value="sort-desc">sort-desc</option>
            <option value="sort-down">sort-down</option>
            <option value="spinner">spinner</option>
            <option value="spoon">spoon</option>
            <option value="spotify">spotify</option>
            <option value="square">square</option>
            <option value="square-o">square-o</option>
            <option value="star">star</option>
            <option value="star-half">star-half</option>
            <option value="stop">stop</option>
            <option value="subscript">subscript</option>
            <option value="tablet">tablet</option>
            <option value="tachometer">tachometer</option>
            <option value="tag">tag</option>
            <option value="tags">tags</option>
            {/* <option value="align-left">&#xf036; align-left</option>
                    <option value="align-right">&#xf038; align-right</option>
                    <option value="amazon">&#xf270; amazon</option>
                    <option value="ambulance">&#xf0f9; ambulance</option>
                    <option value="anchor">&#xf13d; anchor</option>
                    <option value="android">&#xf17b; android</option>
                    <option value="angellist">&#xf209; angellist</option>
                    <option value="angle-double-down">&#xf103; angle-double-down</option>
                    <option value="angle-double-left">&#xf100; angle-double-left</option>
                    <option value="angle-double-right">&#xf101; angle-double-right</option>
                    <option value="angle-double-up">&#xf102; angle-double-up</option>
                    <option value="angle-left">&#xf104; angle-left</option>
                    <option value="angle-right">&#xf105; angle-right</option>
                    <option value="angle-up">&#xf106; angle-up</option>
                    <option value="apple">&#xf179; apple</option>
                    <option value="archive">&#xf187; archive</option>
                    <option value="area-chart">&#xf1fe; area-chart</option>
                    <option value="arrow-circle-down">&#xf0ab; arrow-circle-down</option>
                    <option value="arrow-circle-left">&#xf0a8; arrow-circle-left</option>
                    <option value="arrow-circle-o-down">&#xf01a; arrow-circle-o-down</option>
                    <option value="arrow-circle-o-left">&#xf190; arrow-circle-o-left</option>
                    <option value="arrow-circle-o-right">&#xf18e; arrow-circle-o-right</option>
                    <option value="arrow-circle-o-up">&#xf01b; arrow-circle-o-up</option>
                    <option value="arrow-circle-right">&#xf0a9; arrow-circle-right</option>
                    <option value="arrow-circle-up">&#xf0aa; arrow-circle-up</option>
                    <option value="arrow-down">&#xf063; arrow-down</option>
                    <option value="arrow-left">&#xf060; arrow-left</option>
                    <option value="arrow-right">&#xf061; arrow-right</option>
                    <option value="arrow-up">&#xf062; arrow-up</option>
                    <option value="arrows">&#xf047; arrows</option>
                    <option value="arrows-alt">&#xf0b2; arrows-alt</option>
                    <option value="arrows-h">&#xf07e; arrows-h</option>
                    <option value="arrows-v">&#xf07d; arrows-v</option>
                    <option value="asterisk">&#xf069; asterisk</option>
                    <option value="at">&#xf1fa; at</option>
                    <option value="automobile">&#xf1b9; automobile</option>
                    <option value="backward">&#xf04a; backward</option>
                    <option value="balance-scale">&#xf24e; balance-scale</option>
                    <option value="ban">&#xf05e; ban</option>
                    <option value="bank">&#xf19c; bank</option>
                    <option value="bar-chart">&#xf080; bar-chart</option>
                    <option value="bar-chart-o">&#xf080; bar-chart-o</option>
                    <option value="battery-full">&#xf240; battery-full</option>
                    <option value="beer">&#xf0fc; beer</option>
                    <option value="behance">&#xf1b4; behance</option>
                    <option value="behance-square">&#xf1b5; behance-square</option>
                    <option value="bell">&#xf0f3; bell</option>
                    <option value="bell-o">&#xf0a2; bell-o</option>
                    <option value="bell-slash">&#xf1f6; bell-slash</option>
                    <option value="bell-slash-o">&#xf1f7; bell-slash-o</option>
                    <option value="bicycle">&#xf206; bicycle</option>
                    <option value="binoculars">&#xf1e5; binoculars</option>
                    <option value="birthday-cake">&#xf1fd; birthday-cake</option>
                    <option value="bitbucket">&#xf171; bitbucket</option>
                    <option value="bitbucket-square">&#xf172; bitbucket-square</option>
                    <option value="bitcoin">&#xf15a; bitcoin</option>
                    <option value="black-tie">&#xf27e; black-tie</option>
                    <option value="bold">&#xf032; bold</option>
                    <option value="bolt">&#xf0e7; bolt</option>
                    <option value="bomb">&#xf1e2; bomb</option>
                    <option value="book">&#xf02d; book</option>
                    <option value="bookmark">&#xf02e; bookmark</option>
                    <option value="bookmark-o">&#xf097; bookmark-o</option>
                    <option value="briefcase">&#xf0b1; briefcase</option>
                    <option value="btc">&#xf15a; btc</option>
                    <option value="bug">&#xf188; bug</option>
                    <option value="building">&#xf1ad; building</option>
                    <option value="building-o">&#xf0f7; building-o</option>
                    <option value="bullhorn">&#xf0a1; bullhorn</option>
                    <option value="bullseye">&#xf140; bullseye</option>
                    <option value="bus">&#xf207; bus</option>
                    <option value="cab">&#xf1ba; cab</option>
                    <option value="calendar">&#xf073; calendar</option>
                    <option value="camera">&#xf030; camera</option>
                    <option value="car">&#xf1b9; car</option>
                    <option value="caret-up">&#xf0d8; caret-up</option>
                    <option value="cart-plus">&#xf217; cart-plus</option>
                    <option value="cc">&#xf20a; cc</option>
                    <option value="cc-amex">&#xf1f3; cc-amex</option>
                    <option value="cc-jcb">&#xf24b; cc-jcb</option>
                    <option value="cc-paypal">&#xf1f4; cc-paypal</option>
                    <option value="cc-stripe">&#xf1f5; cc-stripe</option>
                    <option value="cc-visa">&#xf1f0; cc-visa</option>
                    <option value="chain">&#xf0c1; chain</option>
                    <option value="check">&#xf00c; check</option>
                    <option value="chevron-left">&#xf053; chevron-left</option>
                    <option value="chevron-right">&#xf054; chevron-right</option>
                    <option value="chevron-up">&#xf077; chevron-up</option>
                    <option value="child">&#xf1ae; child</option>
                    <option value="chrome">&#xf268; chrome</option>
                    <option value="circle">&#xf111; circle</option>
                    <option value="circle-o">&#xf10c; circle-o</option>
                    <option value="circle-o-notch">&#xf1ce; circle-o-notch</option>
                    <option value="circle-thin">&#xf1db; circle-thin</option>
                    <option value="clipboard">&#xf0ea; clipboard</option>
                    <option value="clock-o">&#xf017; clock-o</option>
                    <option value="clone">&#xf24d; clone</option>
                    <option value="close">&#xf00d; close</option>
                    <option value="cloud">&#xf0c2; cloud</option>
                    <option value="cloud-download">&#xf0ed; cloud-download</option>
                    <option value="cloud-upload">&#xf0ee; cloud-upload</option>
                    <option value="cny">&#xf157; cny</option>
                    <option value="code">&#xf121; code</option>
                    <option value="code-fork">&#xf126; code-fork</option>
                    <option value="codepen">&#xf1cb; codepen</option>
                    <option value="coffee">&#xf0f4; coffee</option>
                    <option value="cog">&#xf013; cog</option>
                    <option value="cogs">&#xf085; cogs</option>
                    <option value="columns">&#xf0db; columns</option>
                    <option value="comment">&#xf075; comment</option>
                    <option value="comment-o">&#xf0e5; comment-o</option>
                    <option value="commenting">&#xf27a; commenting</option>
                    <option value="commenting-o">&#xf27b; commenting-o</option>
                    <option value="comments">&#xf086; comments</option>
                    <option value="comments-o">&#xf0e6; comments-o</option>
                    <option value="compass">&#xf14e; compass</option>
                    <option value="compress">&#xf066; compress</option>
                    <option value="connectdevelop">&#xf20e; connectdevelop</option>
                    <option value="contao">&#xf26d; contao</option>
                    <option value="copy">&#xf0c5; copy</option>
                    <option value="copyright">&#xf1f9; copyright</option>
                    <option value="creative-commons">&#xf25e; creative-commons</option>
                    <option value="credit-card">&#xf09d; credit-card</option>
                    <option value="crop">&#xf125; crop</option>
                    <option value="crosshairs">&#xf05b; crosshairs</option>
                    <option value="css3">&#xf13c; css3</option>
                    <option value="cube">&#xf1b2; cube</option>
                    <option value="cubes">&#xf1b3; cubes</option>
                    <option value="cut">&#xf0c4; cut</option>
                    <option value="cutlery">&#xf0f5; cutlery</option>
                    <option value="dashboard">&#xf0e4; dashboard</option>
                    <option value="dashcube">&#xf210; dashcube</option>
                    <option value="database">&#xf1c0; database</option>
                    <option value="dedent">&#xf03b; dedent</option>
                    <option value="delicious">&#xf1a5; delicious</option>
                    <option value="desktop">&#xf108; desktop</option>
                    <option value="deviantart">&#xf1bd; deviantart</option>
                    <option value="diamond">&#xf219; diamond</option>
                    <option value="digg">&#xf1a6; digg</option>
                    <option value="dollar">&#xf155; dollar</option>
                    <option value="download">&#xf019; download</option>
                    <option value="dribbble">&#xf17d; dribbble</option>
                    <option value="dropbox">&#xf16b; dropbox</option>
                    <option value="drupal">&#xf1a9; drupal</option>
                    <option value="edit">&#xf044; edit</option>
                    <option value="eject">&#xf052; eject</option>
                    <option value="ellipsis-h">&#xf141; ellipsis-h</option>
                    <option value="ellipsis-v">&#xf142; ellipsis-v</option>
                    <option value="empire">&#xf1d1; empire</option>
                    <option value="envelope">&#xf0e0; envelope</option>
                    <option value="envelope-o">&#xf003; envelope-o</option>
                    <option value="eur">&#xf153; eur</option>
                    <option value="euro">&#xf153; euro</option>
                    <option value="exchange">&#xf0ec; exchange</option>
                    <option value="exclamation">&#xf12a; exclamation</option>
                    <option value="exclamation-circle">&#xf06a; exclamation-circle</option>
                    <option value="exclamation-triangle">&#xf071; exclamation-triangle</option>
                    <option value="expand">&#xf065; expand</option>
                    <option value="expeditedssl">&#xf23e; expeditedssl</option>
                    <option value="external-link">&#xf08e; external-link</option>
                    <option value="external-link-square">&#xf14c; external-link-square</option>
                    <option value="eye">&#xf06e; eye</option>
                    <option value="eye-slash">&#xf070; eye-slash</option>
                    <option value="eyedropper">&#xf1fb; eyedropper</option>
                    <option value="facebook">&#xf09a; facebook</option>
                    <option value="facebook-f">&#xf09a; facebook-f</option>
                    <option value="facebook-official">&#xf230; facebook-official</option>
                    <option value="facebook-square">&#xf082; facebook-square</option>
                    <option value="fast-backward">&#xf049; fast-backward</option>
                    <option value="fast-forward">&#xf050; fast-forward</option>
                    <option value="fax">&#xf1ac; fax</option>
                    <option value="feed">&#xf09e; feed</option>
                    <option value="female">&#xf182; female</option>
                    <option value="fighter-jet">&#xf0fb; fighter-jet</option>
                    <option value="file">&#xf15b; file</option>
                    <option value="file-archive-o">&#xf1c6; file-archive-o</option>
                    <option value="file-audio-o">&#xf1c7; file-audio-o</option>
                    <option value="file-code-o">&#xf1c9; file-code-o</option>
                    <option value="file-excel-o">&#xf1c3; file-excel-o</option>
                    <option value="file-image-o">&#xf1c5; file-image-o</option>
                    <option value="file-movie-o">&#xf1c8; file-movie-o</option>
                    <option value="file-o">&#xf016; file-o</option>
                    <option value="file-pdf-o">&#xf1c1; file-pdf-o</option>
                    <option value="file-photo-o">&#xf1c5; file-photo-o</option>
                    <option value="file-picture-o">&#xf1c5; file-picture-o</option>
                    <option value="file-powerpoint-o">&#xf1c4; file-powerpoint-o</option>
                    <option value="file-sound-o">&#xf1c7; file-sound-o</option>
                    <option value="file-text">&#xf15c; file-text</option>
                    <option value="file-text-o">&#xf0f6; file-text-o</option>
                    <option value="file-video-o">&#xf1c8; file-video-o</option>
                    <option value="file-word-o">&#xf1c2; file-word-o</option>
                    <option value="file-zip-o">&#xf1c6; file-zip-o</option>
                    <option value="files-o">&#xf0c5; files-o</option>
                    <option value="film">&#xf008; film</option>
                    <option value="filter">&#xf0b0; filter</option>
                    <option value="fire">&#xf06d; fire</option>
                    <option value="fire-extinguisher">&#xf134; fire-extinguisher</option>
                    <option value="firefox">&#xf269; firefox</option>
                    <option value="flag">&#xf024; flag</option>
                    <option value="flag-checkered">&#xf11e; flag-checkered</option>
                    <option value="flag-o">&#xf11d; flag-o</option>
                    <option value="flash">&#xf0e7; flash</option>
                    <option value="flask">&#xf0c3; flask</option>
                    <option value="flickr">&#xf16e; flickr</option>
                    <option value="floppy-o">&#xf0c7; floppy-o</option>
                    <option value="folder">&#xf07b; folder</option>
                    <option value="folder-o">&#xf114; folder-o</option>
                    <option value="folder-open">&#xf07c; folder-open</option>
                    <option value="folder-open-o">&#xf115; folder-open-o</option>
                    <option value="font">&#xf031; font</option>
                    <option value="fonticons">&#xf280; fonticons</option>
                    <option value="forumbee">&#xf211; forumbee</option>
                    <option value="forward">&#xf04e; forward</option>
                    <option value="foursquare">&#xf180; foursquare</option>
                    <option value="frown-o">&#xf119; frown-o</option>
                    <option value="futbol-o">&#xf1e3; futbol-o</option>
                    <option value="gamepad">&#xf11b; gamepad</option>
                    <option value="gavel">&#xf0e3; gavel</option>
                    <option value="gbp">&#xf154; gbp</option>
                    <option value="ge">&#xf1d1; ge</option>
                    <option value="gear">&#xf013; gear</option>
                    <option value="gears">&#xf085; gears</option>
                    <option value="genderless">&#xf22d; genderless</option>
                    <option value="get-pocket">&#xf265; get-pocket</option>
                    <option value="gg">&#xf260; gg</option>
                    <option value="gg-circle">&#xf261; gg-circle</option>
                    <option value="gift">&#xf06b; gift</option>
                    <option value="git">&#xf1d3; git</option>
                    <option value="git-square">&#xf1d2; git-square</option>
                    <option value="github">&#xf09b; github</option>
                    <option value="github-alt">&#xf113; github-alt</option>
                    <option value="github-square">&#xf092; github-square</option>
                    <option value="gittip">&#xf184; gittip</option>
                    <option value="glass">&#xf000; glass</option>
                    <option value="globe">&#xf0ac; globe</option>
                    <option value="google">&#xf1a0; google</option>
                    <option value="google-plus">&#xf0d5; google-plus</option>
                    <option value="google-plus-square">&#xf0d4; google-plus-square</option>
                    <option value="google-wallet">&#xf1ee; google-wallet</option>
                    <option value="graduation-cap">&#xf19d; graduation-cap</option>
                    <option value="gratipay">&#xf184; gratipay</option>
                    <option value="group">&#xf0c0; group</option>
                    <option value="h-square">&#xf0fd; h-square</option>
                    <option value="hacker-news">&#xf1d4; hacker-news</option>
                    <option value="hand-grab-o">&#xf255; hand-grab-o</option>
                    <option value="hand-lizard-o">&#xf258; hand-lizard-o</option>
                    <option value="hand-o-down">&#xf0a7; hand-o-down</option>
                    <option value="hand-o-left">&#xf0a5; hand-o-left</option>
                    <option value="hand-o-right">&#xf0a4; hand-o-right</option>
                    <option value="hand-o-up">&#xf0a6; hand-o-up</option>
                    <option value="hand-paper-o">&#xf256; hand-paper-o</option>
                    <option value="hand-peace-o">&#xf25b; hand-peace-o</option>
                    <option value="hand-pointer-o">&#xf25a; hand-pointer-o</option>
                    <option value="hand-rock-o">&#xf255; hand-rock-o</option>
                    <option value="hand-scissors-o">&#xf257; hand-scissors-o</option>
                    <option value="hand-spock-o">&#xf259; hand-spock-o</option>
                    <option value="hand-stop-o">&#xf256; hand-stop-o</option>
                    <option value="hdd-o">&#xf0a0; hdd-o</option>
                    <option value="header">&#xf1dc; header</option>
                    <option value="headphones">&#xf025; headphones</option>
                    <option value="heart">&#xf004; heart</option>
                    <option value="heart-o">&#xf08a; heart-o</option>
                    <option value="heartbeat">&#xf21e; heartbeat</option>
                    <option value="history">&#xf1da; history</option>
                    <option value="home">&#xf015; home</option>
                    <option value="hospital-o">&#xf0f8; hospital-o</option>
                    <option value="hotel">&#xf236; hotel</option>
                    <option value="hourglass">&#xf254; hourglass</option>
                    <option value="hourglass-1">&#xf251; hourglass-1</option>
                    <option value="hourglass-2">&#xf252; hourglass-2</option>
                    <option value="hourglass-3">&#xf253; hourglass-3</option>
                    <option value="hourglass-end">&#xf253; hourglass-end</option>
                    <option value="hourglass-half">&#xf252; hourglass-half</option>
                    <option value="hourglass-o">&#xf250; hourglass-o</option>
                    <option value="hourglass-start">&#xf251; hourglass-start</option>
                    <option value="houzz">&#xf27c; houzz</option>
                    <option value="html5">&#xf13b; html5</option>
                    <option value="i-cursor">&#xf246; i-cursor</option>
                    <option value="ils">&#xf20b; ils</option>
                    <option value="image">&#xf03e; image</option>
                    <option value="inbox">&#xf01c; inbox</option>
                    <option value="indent">&#xf03c; indent</option>
                    <option value="industry">&#xf275; industry</option>
                    <option value="info">&#xf129; info</option>
                    <option value="info-circle">&#xf05a; info-circle</option>
                    <option value="inr">&#xf156; inr</option>
                    <option value="instagram">&#xf16d; instagram</option>
                    <option value="institution">&#xf19c; institution</option>
                    <option value="internet-explorer">&#xf26b; internet-explorer</option>
                    <option value="intersex">&#xf224; intersex</option>
                    <option value="ioxhost">&#xf208; ioxhost</option>
                    <option value="italic">&#xf033; italic</option>
                    <option value="joomla">&#xf1aa; joomla</option>
                    <option value="jpy">&#xf157; jpy</option>
                    <option value="jsfiddle">&#xf1cc; jsfiddle</option>
                    <option value="key">&#xf084; key</option>
                    <option value="keyboard-o">&#xf11c; keyboard-o</option>
                    <option value="krw">&#xf159; krw</option>
                    <option value="language">&#xf1ab; language</option>
                    <option value="laptop">&#xf109; laptop</option>
                    <option value="lastfm">&#xf202; lastfm</option>
                    <option value="lastfm-square">&#xf203; lastfm-square</option>
                    <option value="leaf">&#xf06c; leaf</option>
                    <option value="leanpub">&#xf212; leanpub</option>
                    <option value="legal">&#xf0e3; legal</option>
                    <option value="lemon-o">&#xf094; lemon-o</option>
                    <option value="level-down">&#xf149; level-down</option>
                    <option value="level-up">&#xf148; level-up</option>
                    <option value="life-bouy">&#xf1cd; life-bouy</option>
                    <option value="life-buoy">&#xf1cd; life-buoy</option>
                    <option value="life-ring">&#xf1cd; life-ring</option>
                    <option value="life-saver">&#xf1cd; life-saver</option>
                    <option value="lightbulb-o">&#xf0eb; lightbulb-o</option>
                    <option value="line-chart">&#xf201; line-chart</option>
                    <option value="link">&#xf0c1; link</option>
                    <option value="linkedin">&#xf0e1; linkedin</option>
                    <option value="linkedin-square">&#xf08c; linkedin-square</option>
                    <option value="linux">&#xf17c; linux</option>
                    <option value="list">&#xf03a; list</option>
                    <option value="list-alt">&#xf022; list-alt</option>
                    <option value="list-ol">&#xf0cb; list-ol</option>
                    <option value="list-ul">&#xf0ca; list-ul</option>
                    <option value="location-arrow">&#xf124; location-arrow</option>
                    <option value="lock">&#xf023; lock</option>
                    <option value="long-arrow-down">&#xf175; long-arrow-down</option>
                    <option value="long-arrow-left">&#xf177; long-arrow-left</option>
                    <option value="long-arrow-right">&#xf178; long-arrow-right</option>
                    <option value="long-arrow-up">&#xf176; long-arrow-up</option>
                    <option value="magic">&#xf0d0; magic</option>
                    <option value="magnet">&#xf076; magnet</option>
                    <option value="mars-stroke-v">&#xf22a; mars-stroke-v</option>
                    <option value="maxcdn">&#xf136; maxcdn</option>
                    <option value="meanpath">&#xf20c; meanpath</option>
                    <option value="medium">&#xf23a; medium</option>
                    <option value="medkit">&#xf0fa; medkit</option>
                    <option value="meh-o">&#xf11a; meh-o</option>
                    <option value="mercury">&#xf223; mercury</option>
                    <option value="microphone">&#xf130; microphone</option>
                    <option value="mobile">&#xf10b; mobile</option>
                    <option value="motorcycle">&#xf21c; motorcycle</option>
                    <option value="mouse-pointer">&#xf245; mouse-pointer</option>
                    <option value="music">&#xf001; music</option>
                    <option value="navicon">&#xf0c9; navicon</option>
                    <option value="neuter">&#xf22c; neuter</option>
                    <option value="newspaper-o">&#xf1ea; newspaper-o</option>
                    <option value="opencart">&#xf23d; opencart</option>
                    <option value="openid">&#xf19b; openid</option>
                    <option value="opera">&#xf26a; opera</option>
                    <option value="outdent">&#xf03b; outdent</option>
                    <option value="pagelines">&#xf18c; pagelines</option>
                    <option value="paper-plane-o">&#xf1d9; paper-plane-o</option>
                    <option value="paperclip">&#xf0c6; paperclip</option>
                    <option value="paragraph">&#xf1dd; paragraph</option>
                    <option value="paste">&#xf0ea; paste</option>
                    <option value="pause">&#xf04c; pause</option>
                    <option value="paw">&#xf1b0; paw</option>
                    <option value="paypal">&#xf1ed; paypal</option>
                    <option value="pencil">&#xf040; pencil</option>
                    <option value="pencil-square-o">&#xf044; pencil-square-o</option>
                    <option value="phone">&#xf095; phone</option>
                    <option value="photo">&#xf03e; photo</option>
                    <option value="picture-o">&#xf03e; picture-o</option>
                    <option value="pie-chart">&#xf200; pie-chart</option>
                    <option value="pied-piper">&#xf1a7; pied-piper</option>
                    <option value="pied-piper-alt">&#xf1a8; pied-piper-alt</option>
                    <option value="pinterest">&#xf0d2; pinterest</option>
                    <option value="pinterest-p">&#xf231; pinterest-p</option>
                    <option value="pinterest-square">&#xf0d3; pinterest-square</option>
                    <option value="plane">&#xf072; plane</option>
                    <option value="play">&#xf04b; play</option>
                    <option value="play-circle">&#xf144; play-circle</option>
                    <option value="play-circle-o">&#xf01d; play-circle-o</option>
                    <option value="plug">&#xf1e6; plug</option>
                    <option value="plus">&#xf067; plus</option>
                    <option value="plus-circle">&#xf055; plus-circle</option>
                    <option value="plus-square">&#xf0fe; plus-square</option>
                    <option value="plus-square-o">&#xf196; plus-square-o</option>
                    <option value="power-off">&#xf011; power-off</option>
                    <option value="print">&#xf02f; print</option>
                    <option value="puzzle-piece">&#xf12e; puzzle-piece</option>
                    <option value="qq">&#xf1d6; qq</option>
                    <option value="qrcode">&#xf029; qrcode</option>
                    <option value="question">&#xf128; question</option>
                    <option value="question-circle">&#xf059; question-circle</option>
                    <option value="quote-left">&#xf10d; quote-left</option>
                    <option value="quote-right">&#xf10e; quote-right</option>
                    <option value="ra">&#xf1d0; ra</option>
                    <option value="random">&#xf074; random</option>
                    <option value="rebel">&#xf1d0; rebel</option>
                    <option value="recycle">&#xf1b8; recycle</option>
                    <option value="reddit">&#xf1a1; reddit</option>
                    <option value="reddit-square">&#xf1a2; reddit-square</option>
                    <option value="refresh">&#xf021; refresh</option>
                    <option value="registered">&#xf25d; registered</option>
                    <option value="remove">&#xf00d; remove</option>
                    <option value="renren">&#xf18b; renren</option>
                    <option value="reorder">&#xf0c9; reorder</option>
                    <option value="repeat">&#xf01e; repeat</option>
                    <option value="reply">&#xf112; reply</option>
                    <option value="reply-all">&#xf122; reply-all</option>
                    <option value="retweet">&#xf079; retweet</option>
                    <option value="rmb">&#xf157; rmb</option>
                    <option value="road">&#xf018; road</option>
                    <option value="rocket">&#xf135; rocket</option>
                    <option value="rotate-left">&#xf0e2; rotate-left</option>
                    <option value="rotate-right">&#xf01e; rotate-right</option>
                    <option value="rouble">&#xf158; rouble</option>
                    <option value="rss">&#xf09e; rss</option>
                    <option value="rss-square">&#xf143; rss-square</option>
                    <option value="rub">&#xf158; rub</option>
                    <option value="ruble">&#xf158; ruble</option>
                    <option value="rupee">&#xf156; rupee</option>
                    <option value="safari">&#xf267; safari</option>
                    <option value="sliders">&#xf1de; sliders</option>
                    <option value="slideshare">&#xf1e7; slideshare</option>
                    <option value="smile-o">&#xf118; smile-o</option>
                    <option value="sort-asc">&#xf0de; sort-asc</option>
                    <option value="sort-desc">&#xf0dd; sort-desc</option>
                    <option value="sort-down">&#xf0dd; sort-down</option>
                    <option value="spinner">&#xf110; spinner</option>
                    <option value="spoon">&#xf1b1; spoon</option>
                    <option value="spotify">&#xf1bc; spotify</option>
                    <option value="square">&#xf0c8; square</option>
                    <option value="square-o">&#xf096; square-o</option>
                    <option value="star">&#xf005; star</option>
                    <option value="star-half">&#xf089; star-half</option>
                    <option value="stop">&#xf04d; stop</option>
                    <option value="subscript">&#xf12c; subscript</option>
                    <option value="tablet">&#xf10a; tablet</option>
                    <option value="tachometer">&#xf0e4; tachometer</option>
                    <option value="tag">&#xf02b; tag</option>
                    <option value="tags">&#xf02c; tags</option> */}
          </select >
        )
        break
      case 'size':
        inputbox = (
          <input
            type="number"
            name={this.props.name}
            value={this.state.value}
            onChange={this.onEdit}
            className="block w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            placeholder="Size"
          />
        )
        break
      default:
        break
    }
    if (
      this.props.name === 'width' ||
      this.props.name === 'height' ||
      this.props.name === 'fontSize' ||
      this.props.name === 'borderRadius' ||
      this.props.name === 'borderWidth' ||
      this.props.name.match(/padding.*/) ||
      this.props.name.match(/margin.*/) ||
      this.props.name === 'columnGap' ||
      this.props.name === 'gap'
    ) {
      if (this.state.value.match(/px/)) {
        inputbox = (
          <div className="relative mt-2 rounded-md shadow-sm w-full">
            <input
              type="number"
              id={this.props.name}
              name={this.props.name}
              value={this.state.value.replace(/px/, '')}
              onChange={(e) => this.onSpecialEdit(e, 'changableUnitValue')}
              className="block w-full rounded-md border-0 py-1 pl-4 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder={this.props.name}
            />
            <div className="absolute inset-y-0 right-0 flex items-center">
              <select
                value="px"
                className="h-full rounded-md border-0 bg-transparent py-1 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                onChange={(e) => this.onSpecialEdit(e, 'changableUnit')}
              >
                <option value=""></option>
                <option value="px">PX</option>
                <option value="%">%</option>
              </select>
            </div>
          </div>
        )
      } else if (this.state.value.match(/%/)) {
        inputbox = (
          <div className="relative mt-2 rounded-md shadow-sm w-full">
            <input
              type="number"
              id={this.props.name}
              name={this.props.name}
              value={this.state.value.replace(/%/, '')}
              onChange={(e) => this.onSpecialEdit(e, 'changableUnitValue')}
              className="block w-full rounded-md border-0 py-1 pl-4 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder={this.props.name}
            />
            <div className="absolute inset-y-0 right-0 flex items-center">
              <select
                value="%"
                className="h-full rounded-md border-0 bg-transparent py-1 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                onChange={(e) => this.onSpecialEdit(e, 'changableUnit')}
              >
                <option value=""></option>
                <option value="px">PX</option>
                <option value="%">%</option>
              </select>
            </div>
          </div>
        )
      } else {
        inputbox = (
          <div className="relative mt-2 rounded-md shadow-sm w-full">
            <input
              type="text"
              id={this.props.name}
              name={this.props.name}
              value={this.state.value}
              onChange={(e) => this.onSpecialEdit(e, 'changableUnitValue')}
              className="block w-full rounded-md border-0 py-1 pl-4 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder={this.props.name}
            />
            <div className="absolute inset-y-0 right-0 flex items-center">
              <select
                id="currency"
                name="currency"
                className="h-full rounded-md border-0 bg-transparent py-1 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                onChange={(e) => this.onSpecialEdit(e, 'changableUnit')}
              >
                <option value=""></option>
                <option value="px">PX</option>
                <option value="%">%</option>
              </select>
            </div>
          </div>
        )
      }
    }

    name = name.replace(/([A-Z])/g, ' $1')
    const firstLetter = name.charAt(0)
    const firstLetterCap = firstLetter.toUpperCase()
    const remainingLetters = name.slice(1)
    const capitalizedWord = firstLetterCap + remainingLetters

    return (<>
      {/* <div className='flex'>
        <label>{name}</label>
        {this.state.changed && (
          <Fragment>
            <button onClick={this.onSave}>
              <i className="fa fa-check-circle-o" />
            </button>
            <button onClick={this.onCancel}>
              <i className="fa fa-times" />
            </button>
          </Fragment>
        )}
        {inputbox}
      </div> */}

      <div className="mt-2 w-full">
        <label htmlFor={name} className="block text-sm font-medium leading-6 text-gray-900">
          {capitalizedWord}
        </label>
        <div>
          {inputbox}
        </div>
        {this.state.changed && (
          <div className='flex'>
            <button onClick={this.onSave}>
              <CheckCircleIcon className="text-green-500 w-6" />
            </button>
            <button onClick={this.onCancel}>
              <XCircleIcon className="text-red-500 w-6" />
            </button>
          </div>
        )}
      </div>
    </>
    )
  }
}

const mapStateToProps = (state) => ({
  uploadError: state.component.uploadError,
  uploadLoading: state.component.uploadLoading,
})

const mapDispatchToProps = (dispatch) => ({
  onTheGoEdit: (id, propertyName, value) =>
    dispatch(changeProperty_OnTheGo(id, propertyName, value)),
  changeImage: (cid, location) => dispatch(changeImage(cid, location)),
  uploadImage: (newImage) => dispatch(uploadImage(newImage)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Property)
