import React, { Component, Fragment } from 'react'
import Cookie from 'js-cookie'
// Redux
import { connect } from 'react-redux'
import * as actions from '../../redux/actions'
// API
import app_api from '../../config/api'
import { getMetaComponents } from '../../apis/constants'
// Router
// Components
// import Header from '../../components/navigation/header/header'
import { metaComponent as MetaComponent } from '../../components/metaComponent/metaComponent'
import ComponentList from '../../components/componentList/componentList'
import Preview from '../../components/preview/preview'
import PropertyList from '../../components/propertyList/propertyList'
import { Modal } from '../../components/ui/modal/modal'
// Constants
import { k_screenid } from '../../constants'
// Statics
import edit from '../../assets/images/editGray.svg'
// Blockly
import BlocklyComponent from '../../components/blockly'
import '../../blocks/customBlocks'
import '../../blocks/generator'
import { useParams } from 'react-router'
import styled from 'styled-components'
import { Sidebar } from '../../components/navigation/sidebar'
import { classNames } from '../../helpers/classname'
import Button from '../../components/button'
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  MagnifyingGlassIcon,
  MinusIcon,
  PlusCircleIcon,
  PlusIcon,
} from '@heroicons/react/24/outline'
import {
  CiAlignBottom,
  CiAlignCenterH,
  CiAlignCenterV,
  CiAlignLeft,
  CiAlignRight,
  CiAlignTop,
} from 'react-icons/ci'

const ContainerDiv = styled.div`
  width: 100%;
  margin-top: 10px;
`

const ProjectNameDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;

  & h1 {
    font-size: 18px;
    color: #5a5a5a;
    cursor: pointer;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 0.3em;

    &:hover {
      background-color: lightgrey;
    }

    &:active {
      color: black;
    }
  }

  & img {
    height: 15px;
  }
`

const ActionButtonsDiv = styled.div`
  max-width: 85%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;

  & button {
    font-weight: bold;
    padding: 5px 25px;
    border-radius: 0.9em;
    background-color: white;
    border: none;
    margin: 0 5px;
    box-shadow: 5px 5px 10px 0 var(--itk-gray-color),
      -5px -5px 10px 0 rgba(255, 255, 255, 0.5);
    color: var(--itk-blue-color);
    text-transform: uppercase;

    &:hover {
      box-shadow: none;
      border: 1px solid var(--itk-blue-color);
    }

    &:active {
      box-shadow: inset 3px 3px 5px 0 var(--itk-gray-color),
        inset -3px -3px 5px 0 rgba(255, 255, 255, 0.5);
      border: none;
    }

    &:disabled {
      box-shadow: none;
      border: 1px solid var(--itk-gray-color);
      color: var(--itk-gray-color);
      cursor: not-allowed;
    }
  }
`

const ScreenActionButtonsDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;

  & select {
    background-color: white;
    color: var(--itk-blue-color);
    font-weight: 700;
    text-align: center;
    /* for chrome select */
    text-align-last: center;
    border: none;
    border-radius: 0.9em;
    box-shadow: 0px 3px 6px var(--itk-gray-color);
    text-transform: uppercase;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url('../../assets/images/screen_downdown_icon_blue.svg');
    background-position: top 50% right 15%;
    background-repeat: no-repeat;

    & option {
      background-color: white;
      color: black;
      text-align: center;
      text-transform: uppercase;
      font-weight: 400;
    }
  }
`

const DesignAndBlockDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`

const DesignButton = styled.button`
  ${(props) =>
    props.showDesignBlock &&
    `background-color: var(--itk-blue-color) !important;
color: white !important;
border: 1px solid var(--itk-blue-color) !important;

&:active {
  box-shadow: inset 3px 3px 5px 0 rgb(19, 133, 125), inset -3px -3px 5px 0 rgba(255, 255, 255, 0.5);
  border: none
}`}
`

const DesignPageDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 1fr;
  column-gap: 10px;
  max-width: 85%;
  margin: 0 auto;
  flex: 1;
`

const BlocksPageDiv = styled.div`
  max-width: 85%;
  margin: 0 auto;
  z-index: 100;
`

const ColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #f5f6f7;

  ${(props) =>
    props.column === 'metaComponentList'
      ? 'min-width: 155px;'
      : props.column === 'previewScreenColumn'
      ? ' min-width: 310px;'
      : props.column === 'componentList'
      ? `min-width: 155px;
  overflow-x: auto;`
      : ''}
`

const ColumnHeaderH4 = styled.h4`
  color: var(--itk-blue-color);
  font-weight: bold;
  background-color: white;
  border: 1px solid var(--itk-gray-color);
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  padding: 6px 0;
  text-transform: uppercase;
`

const MetaComponentErrorDiv = styled.div`
  width: 100%;
  text-align: center;
  position: sticky;
  top: 0;
  z-index: 100;
`

const MetaComponentListDiv = styled.div`
  padding-top: 10px;
  /* border: 1px solid black; */
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 80vh;
  width: 100%;
  overflow-y: scroll;

  & details {
    width: 80%;

    & > summary {
      color: var(--itk-blue-color);
      text-align: left;
      font-weight: 700;
      padding-bottom: 10px;
      padding-top: 10px;
    }
  }
`

const PreviewScreenDiv = styled.div`
  background-image: url('../../assets/images/mobileScreen.svg');
  background-position: top;
  background-repeat: no-repeat;
  background-size: 266px 500px;
  margin-top: 5px;
  flex: 1;
  width: 100%;
`

const ComponentListDiv = styled.div`
  box-sizing: border-box;
  padding-top: 25px;
  padding-left: 20px;
  padding-right: 10px;
  padding-bottom: 10px;
  height: 80vh;
  width: 100%;
`

const PropertiesDiv = styled.div`
  box-sizing: border-box;
  padding-top: 25px;
  padding-left: 20px;
  padding-right: 10px;
  padding-bottom: 10px;
  height: 80vh;
  width: 100%;
`

const SpecialMessageSub = styled.sub`
  font-size: 10px;
  color: gray;
  margin-bottom: 10px;
`

const AddProjectModalDiv = styled.div`
  & > h1 {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    color: var(--itk-blue-color);
    margin-bottom: 10px;
  }

  & div {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }

  & label {
    text-transform: capitalize;
    font-size: 13px;
  }

  & input[type='textbox'] {
    padding: 10px;
    border: 1px solid var(--itk-blue-color);
  }

  & > button {
    background-color: var(--itk-blue-color);
    padding: 8px 15px;
    border-radius: 2em;
    border: none;
    width: 90px;
    color: white;
    font-weight: bold;
    float: right;
    text-transform: uppercase;
    box-shadow: 2px 2px 4px 0 var(--itk-gray-color);

    &:disabled {
      background-color: rgb(96, 189, 184);
      cursor: not-allowed;
    }
  }
`

function withParam(Component) {
  return (props) => <Component {...props} params={useParams()} />
}

class Project extends Component {
  constructor(props) {
    super(props)
    this.componentList = []
    document.title = 'ITK - My Project'
    this.state = {
      // project variables
      projectId: null,
      isProjectEdit: false,
      project: {
        name: null,
        description: null,
        current_screen: null,
      },
      element: 'Layout',
      show: false,
      // meta component variables
      metaComponent: [],
      uniqueMetaCategories: [],
      metaComponentLoading: false,
      metaComponentError: null,
      // class variables
      selectedComponent: null,
      componentProperties: {},
      componentsOfSelectedScreen: [],
      draggedComponents: [],
      showDesignBlock: true,
      pageDisplay: 'design',
      initialToolbar: [],
      isOpen: false,
      // firebase tables
      myTables: [],
      openCategory: null,
      isHovered: false,
      open: false,
    }
    this.userid = null
  }

  componentDidMount() {
    this.userid = JSON.parse(localStorage.getItem('itkKey')).id
    let { pid } = this.props.params
    this.setState({
      projectId: pid,
      metaComponentLoading: true,
    })
    this.props.fetchComponents(pid)
    this.props.getOneProject(pid)
    this.setState({
      openCategory: 'Design Elements',
    })
    app_api
      .get(getMetaComponents)
      .then((response) => {
        if (response.status >= 200) {
          let uniqueCategories = []
          response.data.data
            .filter((comp) => comp.c_type !== 'screen')
            .map((metaC) => {
              if (uniqueCategories.indexOf(metaC.category) === -1) {
                uniqueCategories.push(metaC.category)
              }
              return null
            })
          this.setState({
            metaComponent: response.data.data.filter(
              (comp) => comp.c_type !== 'screen'
            ),
            uniqueMetaCategories: uniqueCategories,
            metaComponentLoading: false,
          })
        } else {
          this.setState({
            metaComponentLoading: false,
            metaComponentError: response.data.message,
          })
        }
      })
      .catch((error) =>
        this.setState({
          metaComponentLoading: false,
          metaComponentError: error.message,
        })
      )
  }

  componentDidUpdate() {
    if (Object.keys(this.props.project).length !== 0) {
      let oneProject = {}
      Object.keys(this.props.project).map(
        (projId) => (oneProject = this.props.project[projId])
      )
      document.title = 'ITK - ' + oneProject.name
      let current_screen =
        this.state.project.current_screen || oneProject.current_screen
      if (
        this.state.componentsOfSelectedScreen.length === 0 &&
        Array.isArray(this.props.components) &&
        this.props.components.length !== 0
      ) {
        this.props.components
          .filter(
            (comp) =>
              comp._id === current_screen || comp.parent === current_screen
          )
          .map((comp) => {
            this.getComponent(comp._id)
            return null
          })
        this.onSelectComponent(current_screen)
      }
    }
  }

  showDesignBlock = (bool) => {
    this.setState({
      showDesignBlock: bool,
      pageDisplay: bool === true ? 'design' : 'block',
    })
  }

  onAddScreen = () => {
    this.props.addComponent(this.state.projectId, 'screen', null, null)
    this.setState({ selectedComponent: null, isOpen: false, isHovered:false })
  }

  onChangeActiveScreen = (value) => {
    this.getComponentsOfCurrentScreen(value, true)
    const project = {
      pid: this.state.projectId,
      current_screen: value,
    }
    this.props.editProject(project)
    this.handleMouseLeave()
  }

  onChangeProjectDetailsClick = () => {
    let oneProject = {}
    Object.keys(this.props.project).map(
      (projId) => (oneProject = this.props.project[projId])
    )
    this.setState((prevState) => {
      return {
        isProjectEdit: !prevState.isProjectEdit,
        project: {
          name: oneProject.name,
          description: oneProject.description,
        },
      }
    })
  }

  onChangeProjectDetails = (e) => {
    this.setState({
      project: {
        ...this.state.project,
        [e.target.name]: e.target.value,
      },
    })
  }

  onProjectDetailsUpdate = () => {
    let project = {
      pid: this.state.projectId,
      name: this.state.project.name,
      description: this.state.project.description,
    }
    this.props.editProject(project)
    this.setState((prevState) => {
      return { isProjectEdit: !prevState.isProjectEdit }
    })
  }

  onSelectComponent = (componentId) => {
    let componentProp = this.props.components.find(
      (comp) => comp._id === componentId
    )
    if (!!componentProp) {
      this.setState({
        selectedComponent: componentId,
        componentProperties: componentProp.properties,
      })
    }
  }

  getComponent = (compId) => {
    let existingComponents = this.componentList
    if (existingComponents.find((comp) => comp?._id === compId)) {
      // do nothing
    } else {
      existingComponents.push(
        this.props.components.find((comp) => comp?._id === compId)
      )
    }
    this.props.components
      .filter((comp) => comp._id === compId || comp.parent === compId)
      .map((comp) => {
        if (comp.type === 'advanced' && comp.children.length !== 0) {
          comp.children.map((com) => this.getComponent(com, existingComponents))
        }
        return null
      })
    this.setState({
      componentsOfSelectedScreen: this.componentList,
    })
  }

  getComponentsOfCurrentScreen = (current_screen, reload) => {
    this.componentList = []
    this.setState(
      {
        project: {
          ...this.state.project,
          current_screen: current_screen,
        },
      },
      () => {
        this.props.components
          .filter(
            (comp) =>
              comp._id === current_screen || comp.parent === current_screen
          )
          .map((comp) => {
            this.getComponent(comp._id)
            return null
          })
        if (!!reload) {
          this.onSelectComponent(current_screen)
        }
      }
    )
  }

  getDraggedChildIds = (compId) => {
    let component = this.props.components.find((c) => c._id === compId)
    let childIds = this.state.draggedComponents
    if (!childIds.includes(component._id)) {
      childIds.push(component._id)
      this.setState({ draggedComponents: childIds })
    }
    if (component.type === 'advanced' && component.children.length !== 0) {
      component.children.map((child) => this.getDraggedChildIds(child))
    }
  }

  emptyDraggedChildIds = () => {
    this.setState({ draggedComponents: [] })
  }

  handleMouseEnter = () => {
    this.setState({ isHovered: !this.state.isHovered })
  }

  handleMouseLeave = () => {
    this.setState({ isHovered: false, isOpen: false })
  }

  deleteComponent = (componentId) => {
    this.props.deleteComponent(componentId);
  };

  render() {
    localStorage.setItem('applab_myTables', JSON.stringify(this.state.myTables))
    let oneProject = {}
    Object.keys(this.props.project).map(
      (projId) => (oneProject = this.props.project[projId])
    )
    // let selectedComponent = this.props.components.find(c => c._id === this.state.selectedComponent)
    let thisScreen = this.props.components.find(
      (c) => c._id === oneProject.current_screen
    )
    return (
      <Sidebar isCollapsed={true}>
        {this.state.isProjectEdit && (
          <Modal
            show={this.state.isProjectEdit}
            no={this.onChangeProjectDetailsClick}
          >
            <AddProjectModalDiv>
              <h1>Edit Project</h1>
              <div>
                <label>Name</label>
                <input
                  type="textbox"
                  maxLength="50"
                  value={this.state.project.name}
                  name="name"
                  pattern="[a-zA-Z0-9_\s]+"
                  title="No special characters"
                  onChange={this.onChangeProjectDetails}
                  autoFocus
                />
                <SpecialMessageSub>
                  No special characters except underscore(_). Spaces allowed.
                </SpecialMessageSub>
              </div>
              <div>
                <label>Description</label>
                <input
                  type="textbox"
                  maxLength="60"
                  value={this.state.project.description}
                  name="description"
                  onChange={this.onChangeProjectDetails}
                />
                <SpecialMessageSub>Only 60 characters.</SpecialMessageSub>
              </div>
              <button onClick={this.onProjectDetailsUpdate}>Update</button>
            </AddProjectModalDiv>
          </Modal>
        )}
        {/* <Header projectid={this.state.projectId} downloadLink={oneProject.url} /> downloadLink={oneProject.downloadLink} */}
        <div className="w-full mt-2 min-h-screen mx-1 min-w-[1000px]">
          <div className="flex justify-between mx-8">
            <div className="flex flex-col w-40">
              <h1 className="font-bold text-2xl text-nowrap overflow-hidden text-ellipsis">
                {oneProject.name}
              </h1>
              <h5 className="text-gray-300">App lab</h5>
            </div>
            <div
              className={`${
                this.state.pageDisplay === 'design' ? 'block' : 'hidden'
              } flex items-center gap-2 relative`}
            >
              <div className="">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_1_2808)">
                    <path
                      d="M19 8H21C21.2652 8 21.5196 8.10536 21.7071 8.29289C21.8946 8.48043 22 8.73478 22 9V21C22 21.2652 21.8946 21.5196 21.7071 21.7071C21.5196 21.8946 21.2652 22 21 22H13C12.7348 22 12.4804 21.8946 12.2929 21.7071C12.1054 21.5196 12 21.2652 12 21V20H4C3.73478 20 3.48043 19.8946 3.29289 19.7071C3.10536 19.5196 3 19.2652 3 19V3C3 2.73478 3.10536 2.48043 3.29289 2.29289C3.48043 2.10536 3.73478 2 4 2H18C18.2652 2 18.5196 2.10536 18.7071 2.29289C18.8946 2.48043 19 2.73478 19 3V8ZM17 8V4H5V18H12V9C12 8.73478 12.1054 8.48043 12.2929 8.29289C12.4804 8.10536 12.7348 8 13 8H17ZM14 10V20H20V10H14Z"
                      fill="#242424"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1_2808">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              {this.props.projLoading || this.props.compLoading ? (
                <div className="loading">
                  <i className="fa fa-spinner fa-spin"></i> Loading
                </div>
              ) : this.props.projError !== null ? (
                <div className="error">
                  Project fetch error: {this.props.projError}
                </div>
              ) : this.props.compError !== null ? (
                <div className="error">
                  Component fetch error: {this.props.compError}
                </div>
              ) : (
                <ScreenActionButtonsDiv>
                  {this.state.showDesignBlock && (
                    <Fragment>
                      <div
                        className="flex font-bold items-center gap-2 cursor-pointer"
                        onClick={() =>
                          this.setState({ isOpen: !this.state.isOpen })
                        }
                      >
                        <h1>
                          {
                            this.props.components.find(
                              (l) => l?._id === oneProject?.current_screen
                            )?.name
                          }
                        </h1>
                        {this.state.isOpen ? (
                          <ChevronUpIcon className="w-3" />
                        ) : (
                          <ChevronDownIcon className="w-3" />
                        )}
                      </div>
                      {this.state.isOpen && (
                        <div
                          className={`absolute flex flex-col bg-white w-[200px] top-8 ml-20 items-start gap-2 p-2 shadow-lg rounded-md z-20`}
                        >
                          <div
                            className="flex justify-between gap-2 items-center w-full cursor-pointer"
                            onClick={() => this.handleMouseEnter()}
                          >
                            <div className="flex items-center gap-2 mx-2">
                              <svg
                                width="19"
                                height="19"
                                viewBox="0 0 19 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M4.75008 3.16671V15.8334H14.2501V3.16671H4.75008ZM3.95841 1.58337H15.0417C15.2517 1.58337 15.4531 1.66678 15.6015 1.81525C15.75 1.96371 15.8334 2.16508 15.8334 2.37504V16.625C15.8334 16.835 15.75 17.0364 15.6015 17.1848C15.4531 17.3333 15.2517 17.4167 15.0417 17.4167H3.95841C3.74845 17.4167 3.54709 17.3333 3.39862 17.1848C3.25016 17.0364 3.16675 16.835 3.16675 16.625V2.37504C3.16675 2.16508 3.25016 1.96371 3.39862 1.81525C3.54709 1.66678 3.74845 1.58337 3.95841 1.58337ZM9.50008 13.4584C9.71004 13.4584 9.91141 13.5418 10.0599 13.6902C10.2083 13.8387 10.2917 14.0401 10.2917 14.25C10.2917 14.46 10.2083 14.6614 10.0599 14.8098C9.91141 14.9583 9.71004 15.0417 9.50008 15.0417C9.29012 15.0417 9.08876 14.9583 8.94029 14.8098C8.79182 14.6614 8.70842 14.46 8.70842 14.25C8.70842 14.0401 8.79182 13.8387 8.94029 13.6902C9.08876 13.5418 9.29012 13.4584 9.50008 13.4584Z"
                                  fill="#242424"
                                />
                              </svg>
                              <span className="cursor-pointer text-nowrap overflow-hidden text-ellipsis max-w-[100px] truncate">
                                {oneProject.name}
                              </span>
                            </div>
                            <div>
                              {this.state.isHovered ? (
                                <ChevronLeftIcon className="w-3" />
                              ) : (
                                <ChevronRightIcon className="w-3" />
                              )}
                            </div>
                          </div>
                          {this.state.isHovered && (
                            <ul className="absolute top-0 -right-32 z-40 mt-2 bg-white rounded-md shadow-lg p-2 w-32 flex flex-col justify-center items-center cursor-pointer">
                              {this.props.components
                                .filter(
                                  (comp) =>
                                    comp.c_type === k_screenid &&
                                    comp._id !== oneProject?.current_screen
                                )
                                .map((comp) => (
                                  <li key={comp._id}>
                                    <p
                                      onClick={() =>
                                        this.onChangeActiveScreen(comp._id)
                                      }
                                    >
                                      {comp.name}
                                    </p>
                                  </li>
                                ))}
                            </ul>
                          )}
                          <button
                            onClick={this.onAddScreen}
                            className="cursor-pointer flex items-center gap-2 mx-2"
                          >
                            <PlusCircleIcon className="w-5" />
                            <span>Add Screen</span>
                          </button>
                        </div>
                      )}
                    </Fragment>
                  )}
                </ScreenActionButtonsDiv>
              )}
            </div>
            <div className="flex gap-4">
              <div>
                <button
                  className={`inline-flex justify-center w-full px-4 py-2 ${
                    this.state.pageDisplay === 'design'
                      ? 'bg-[#06a199] text-white'
                      : 'bg-white text-[#06a199]'
                  } text-xl font-bold border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm gap-2`}
                  onClick={() => this.showDesignBlock(true)}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_1_2614)">
                      <path
                        d="M13.8299 0.866633L19.1191 6.15497C19.1736 6.20938 19.2119 6.27783 19.2299 6.35272C19.2478 6.42761 19.2447 6.50601 19.2208 6.57922C19.1969 6.65242 19.1531 6.71757 19.0944 6.76742C19.0357 6.81728 18.9644 6.84989 18.8883 6.86163L17.6599 7.0533L12.9466 2.33997L13.1233 1.10247C13.1342 1.02594 13.1663 0.953982 13.2158 0.894636C13.2654 0.835289 13.3305 0.790897 13.4038 0.766424C13.4771 0.741951 13.5558 0.738362 13.6311 0.756059C13.7063 0.773757 13.7752 0.812042 13.8299 0.866633ZM3.82909 16.7916C6.93075 14.0158 10.4916 13.185 14.3649 12.1916L14.7366 8.8433L11.1558 5.26247L7.80742 5.63413C6.81409 9.50747 5.98326 13.0675 3.20742 16.17L2.04492 15.0083C4.40159 12.2583 5.28659 9.21413 6.46409 4.10747L11.7674 3.5183L16.4816 8.23247L15.8924 13.5358C10.7858 14.7141 7.74076 15.5983 4.99159 17.955L3.82909 16.7916ZM8.23242 11.7666C8.07324 11.6129 7.94627 11.429 7.85892 11.2256C7.77157 11.0223 7.72559 10.8036 7.72367 10.5823C7.72175 10.361 7.76392 10.1415 7.84772 9.93671C7.93152 9.73188 8.05528 9.5458 8.21176 9.38931C8.36825 9.23282 8.55434 9.10907 8.75917 9.02526C8.96399 8.94146 9.18346 8.89929 9.40476 8.90122C9.62606 8.90314 9.84476 8.94912 10.0481 9.03646C10.2514 9.12381 10.4353 9.25078 10.5891 9.40997C10.8885 9.72502 11.053 10.1446 11.0475 10.5792C11.042 11.0138 10.867 11.429 10.5597 11.7364C10.2524 12.0438 9.83723 12.219 9.40263 12.2247C8.96802 12.2303 8.54841 12.0659 8.23326 11.7666H8.23242Z"
                        fill={
                          this.state.pageDisplay === 'design'
                            ? 'white'
                            : '#06a199'
                        }
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1_2614">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>

                  <span>Design</span>
                </button>
              </div>
              <div>
                <button
                  onClick={() => this.showDesignBlock(false)}
                  className={`inline-flex justify-center w-full px-4 py-2 ${
                    this.state.pageDisplay === 'block'
                      ? 'bg-[#06a199] text-white'
                      : 'bg-white text-[#06a199]'
                  } text-xl font-bold border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm gap-2`}
                  disabled={this.state.componentsOfSelectedScreen.length === 0}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_1_2621)">
                      <path
                        d="M16.6667 2.5C17.1267 2.5 17.5 2.87333 17.5 3.33333V16.6667C17.5 17.1267 17.1267 17.5 16.6667 17.5H3.33333C2.87333 17.5 2.5 17.1267 2.5 16.6667V3.33333C2.5 2.87333 2.87333 2.5 3.33333 2.5H16.6667ZM9.32417 10.965L4.16667 11.875V15.8333H10.1817L9.32333 10.965H9.32417ZM15.8333 4.16667H9.8175L11.8742 15.8333H15.8333V4.16667ZM8.125 4.16667H4.16667V10.1817L9.035 9.32333L8.125 4.16667Z"
                        fill={
                          this.state.pageDisplay === 'block'
                            ? 'white'
                            : '#06a199'
                        }
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1_2621">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>

                  <span>Blocks</span>
                </button>
              </div>
            </div>
          </div>
          <br />
          {this.state.showDesignBlock ? (
            <div className="flex flex-col md:flex-row h-full">
              <div
                style={{ height: 'calc(100vh - 70.2px - 0.5rem' }}
                className="bg-transparent flex flex-col w-full md:w-1/4 border border-black p-2 overflow-y-auto"
              >
                <div className="overflow-y-auto h-[950px]">
                  <div className="flex gap-2 w-full p-2 bg-gray-200 justify-between">
                    <span
                      className={`cursor-pointer text-base flex justify-center items-center w-1/2 p-2 text-nowrap ${
                        this.state.element === 'Layout'
                          ? 'bg-white rounded-md shadow-md '
                          : 'hover:bg-white hover:rounded-md hover:shadow-md'
                      }`}
                      onClick={() => this.setState({ element: 'Layout' })}
                    >
                      Layouts
                    </span>
                    <span
                      className={`cursor-pointer text-base flex justify-center items-center w-1/2 p-2 ${
                        this.state.element !== 'Layout'
                          ? 'bg-white rounded-md shadow-md '
                          : 'hover:bg-white hover:rounded-md hover:shadow-md'
                      }`}
                      onClick={() => this.setState({ element: 'element' })}
                    >
                      Elements
                    </span>
                  </div>
                  {/* <div className="">
                    <div className="relative mt-2 rounded-md shadow-sm">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <MagnifyingGlassIcon
                          className="h-6 w-6 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                      <input
                        type="text"
                        name="text"
                        id="text"
                        className="block w-full rounded-md border-0 py-2 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Search"
                      />
                    </div>
                  </div> */}
                  <div className="mt-4">
                    {this.state.metaComponentLoading ? (
                      <div className="loading">
                        <i className="fa fa-spinner fa-spin"></i>
                      </div>
                    ) : this.state.metaComponentError !== null ? (
                      <div className="error">
                        {this.state.metaComponentError}
                      </div>
                    ) : this.state.element === 'Layout' ? (
                      this.state.uniqueMetaCategories
                        .filter((cat) => cat === 'Layout')
                        .map((cat) => {
                          if (cat !== undefined) {
                            return (
                              <div
                                className="grid grid-cols-1 sm:grid-cols-2 gap-4"
                                key={cat}
                              >
                                {this.state.metaComponent
                                  .filter((metaC) => metaC.category === cat)
                                  .map((metaComp) => (
                                    <MetaComponent
                                      key={metaComp._id}
                                      id={metaComp._id}
                                      name={metaComp.displayName}
                                      c_type={metaComp.c_type}
                                      icon={metaComp.icon}
                                    />
                                  ))}
                              </div>
                            )
                          } else {
                            return (
                              <details key="miscellaneous" open>
                                <summary>Miscellaneous</summary>
                                {this.state.metaComponent
                                  .filter((metaC) => metaC.category === cat)
                                  .map((metaComp) => (
                                    <MetaComponent
                                      key={metaComp._id}
                                      id={metaComp._id}
                                      name={metaComp.displayName}
                                      c_type={metaComp.c_type}
                                      icon={metaComp.icon}
                                    />
                                  ))}
                              </details>
                            )
                          }
                        })
                    ) : (
                      this.state.uniqueMetaCategories
                        .filter((cat) => cat !== 'Layout')
                        .map((cat) => {
                          if (cat !== undefined) {
                            return (
                              <>
                                <div
                                  className="flex w-full justify-between my-2 cursor-pointer"
                                  onClick={() =>
                                    this.setState({
                                      openCategory: cat,
                                      open: !this.state.open,
                                    })
                                  }
                                >
                                  <span className="font-bold text-base">
                                    {cat}
                                  </span>
                                  <span>
                                    {this.state.openCategory === cat ? (
                                      <ChevronUpIcon className="w-5 h-5" />
                                    ) : (
                                      <ChevronDownIcon className="w-5 h-5" />
                                    )}
                                  </span>
                                </div>
                                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                  {this.state.openCategory === cat &&
                                    this.state.metaComponent
                                      .filter((metaC) => metaC.category === cat)
                                      .map((metaComp) => (
                                        <MetaComponent
                                          key={metaComp._id}
                                          id={metaComp._id}
                                          name={metaComp.displayName}
                                          c_type={metaComp.c_type}
                                          icon={metaComp.icon}
                                        />
                                      ))}
                                </div>
                                <hr className="mt-2" />
                              </>
                            )
                          } else {
                            return (
                              <details
                                key="miscellaneous"
                                open={
                                  this.state.openCategory === 'miscellaneous'
                                }
                                onClick={() =>
                                  this.setState({
                                    openCategory: 'miscellaneous',
                                  })
                                }
                              >
                                <summary>Miscellaneous</summary>
                                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                  {this.state.metaComponent
                                    .filter((metaC) => metaC.category === cat)
                                    .map((metaComp) => (
                                      <MetaComponent
                                        key={metaComp._id}
                                        id={metaComp._id}
                                        name={metaComp.displayName}
                                        c_type={metaComp.c_type}
                                        icon={metaComp.icon}
                                      />
                                    ))}
                                </div>
                              </details>
                            )
                          }
                        })
                    )}
                  </div>
                </div>
              </div>
              <div className="bg-transparent flex flex-col w-full md:w-2/4 border border-black relative  ">
                <div
                  className={`appScreen bg-gray-100 flex ${
                    this.state.show ? 'z-0' : 'z-10'
                  }`}
                >
                  <Preview
                    projectId={this.state.projectId}
                    screen={oneProject.current_screen}
                    selectedComponent={this.state.selectedComponent}
                    draggedChildIds={this.state.draggedComponents}
                    getChildIds={this.getDraggedChildIds}
                    emptyChildIds={this.emptyDraggedChildIds}
                    onSelect={this.onSelectComponent}
                    onChange={() =>
                      this.getComponentsOfCurrentScreen(
                        oneProject.current_screen,
                        true
                      )
                    }
                    style={{ zIndex: this.state.show ? -1 : 1 }}
                  />
                </div>
                <div className="flex justify-between items-center px-2 border border-black bottom-0">
                  <div className="">
                    <span className="text-lg font-bold">Components</span>
                  </div>
                  <div className="flex gap-2 cursor-pointer">
                    <MinusIcon className="w-5 h-5" />
                    <svg
                      width="19"
                      height="19"
                      viewBox="0 0 19 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.54159 5.54168V2.37501C5.54159 2.16505 5.62499 1.96368 5.77346 1.81522C5.92193 1.66675 6.12329 1.58334 6.33325 1.58334H16.6249C16.8349 1.58334 17.0362 1.66675 17.1847 1.81522C17.3332 1.96368 17.4166 2.16505 17.4166 2.37501V12.6667C17.4166 12.8766 17.3332 13.078 17.1847 13.2265C17.0362 13.3749 16.8349 13.4583 16.6249 13.4583H13.4583V16.6195C13.4583 17.0596 13.1028 17.4167 12.661 17.4167H2.38046C2.27574 17.4168 2.17203 17.3962 2.07526 17.3562C1.97849 17.3162 1.89057 17.2575 1.81652 17.1834C1.74247 17.1094 1.68375 17.0214 1.64372 16.9247C1.6037 16.8279 1.58315 16.7242 1.58325 16.6195L1.58563 6.33888C1.58563 5.89872 1.94109 5.54168 2.38284 5.54168H5.54159ZM7.12492 5.54168H12.661C13.1012 5.54168 13.4583 5.89713 13.4583 6.33888V11.875H15.8333V3.16668H7.12492V5.54168ZM3.16896 7.12501L3.16659 15.8333H11.8749V7.12501H3.16896Z"
                        fill="#242424"
                      />
                    </svg>
                    <ChevronUpIcon
                      className="w-5 h-5"
                      onClick={() => {
                        this.setState({ show: !this.state.show })
                      }}
                    />
                  </div>
                </div>
                {this.state.show && (
                  <div
                    className={`bg-white absolute bottom-0 flex flex-col w-full overflow-auto ${
                      this.state.show ? 'h-1/2' : 'h-0'
                    }`}
                  >
                    <div className="flex justify-between items-center px-2 border border-black z-10 rounded-md">
                      <div className="">
                        <span className="text-lg font-bold">Components</span>
                      </div>
                      <div className="flex gap-2 cursor-pointer justify-center items-center">
                        <MinusIcon
                          className="w-5 h-5"
                          onClick={() =>
                            this.setState({ show: !this.state.show })
                          }
                        />
                        <svg
                          width="19"
                          height="19"
                          viewBox="0 0 19 19"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.54159 5.54168V2.37501C5.54159 2.16505 5.62499 1.96368 5.77346 1.81522C5.92193 1.66675 6.12329 1.58334 6.33325 1.58334H16.6249C16.8349 1.58334 17.0362 1.66675 17.1847 1.81522C17.3332 1.96368 17.4166 2.16505 17.4166 2.37501V12.6667C17.4166 12.8766 17.3332 13.078 17.1847 13.2265C17.0362 13.3749 16.8349 13.4583 16.6249 13.4583H13.4583V16.6195C13.4583 17.0596 13.1028 17.4167 12.661 17.4167H2.38046C2.27574 17.4168 2.17203 17.3962 2.07526 17.3562C1.97849 17.3162 1.89057 17.2575 1.81652 17.1834C1.74247 17.1094 1.68375 17.0214 1.64372 16.9247C1.6037 16.8279 1.58315 16.7242 1.58325 16.6195L1.58563 6.33888C1.58563 5.89872 1.94109 5.54168 2.38284 5.54168H5.54159ZM7.12492 5.54168H12.661C13.1012 5.54168 13.4583 5.89713 13.4583 6.33888V11.875H15.8333V3.16668H7.12492V5.54168ZM3.16896 7.12501L3.16659 15.8333H11.8749V7.12501H3.16896Z"
                            fill="#242424"
                          />
                        </svg>
                        <ChevronDownIcon
                          className="w-5 h-5"
                          onClick={() =>
                            this.setState({ show: !this.state.show })
                          }
                        />
                      </div>
                    </div>
                    <div className="p-2">
                      {this.props.addCLoading ? (
                        <div className="loading">
                          <i className="fa fa-spinner fa-spin"></i> Adding
                        </div>
                      ) : (
                        this.props.addCError !== null && (
                          <div className="error">
                            Adding error: {this.props.addCError}
                          </div>
                        )
                      )}
                      {this.props.editCLoading ? (
                        <div className="loading">
                          <i className="fa fa-spinner fa-spin"></i> Editing
                        </div>
                      ) : (
                        this.props.editCError !== null && (
                          <div className="error">
                            Editing error: {this.props.editCError}
                          </div>
                        )
                      )}
                      {this.props.deleteCLoading ? (
                        <div className="loading">
                          <i className="fa fa-spinner fa-spin"></i> Deleting
                        </div>
                      ) : (
                        this.props.deleteCError !== null && (
                          <div className="error">
                            Deleting error: {this.props.deleteCError}
                          </div>
                        )
                      )}
                      {this.props.renameLoading ? (
                        <div className="loading">
                          <i className="fa fa-spinner fa-spin"></i> Renaming
                        </div>
                      ) : (
                        this.props.renameError !== null && (
                          <div className="error">
                            Renaming error: {this.props.renameError}
                          </div>
                        )
                      )}
                      {this.props.compLoading ? (
                        <div className="loading">
                          <i className="fa fa-spinner fa-spin"></i> Fetching
                        </div>
                      ) : this.props.compError !== null ? (
                        <div className="error">
                          Fetching error: {this.props.compError}
                        </div>
                      ) : (
                        <ComponentList
                          projectId={this.state.projectId}
                          selectedComponent={this.state.selectedComponent}
                          draggedChildIds={this.state.draggedComponents}
                          onSelect={this.onSelectComponent}
                          getChildIds={this.getDraggedChildIds}
                          emptyChildIds={this.emptyDraggedChildIds}
                          screenId={oneProject.current_screen}
                          onDelete={this.deleteComponent}
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div
                style={{ height: 'calc(100vh - 70.2px - 0.5rem' }}
                className="bg-transparent flex flex-col w-full md:w-1/4 border border-black overflow-y-auto"
              >
                {/* <div className='flex mx-4 mt-4 flex justify-between'>
                  <CiAlignLeft className='w-10 h-10' />
                  <CiAlignCenterH className='w-10 h-10' />
                  <CiAlignRight className='w-10 h-10' />
                  <CiAlignTop className='w-10 h-10' />
                  <CiAlignCenterV className='w-10 h-10' />
                  <CiAlignBottom className='w-10 h-10' />
                </div> */}
                <div className="flex flex-col mt-4 mx-4 overflow-y-auto h-[850px] ">
                  {this.state.selectedComponent === null ? (
                    <div>Select a component to see/edit its properties</div>
                  ) : (
                    <div className="w-full">
                      {this.props.addCLoading ? (
                        <div className="loading">
                          <i className="fa fa-spinner fa-spin"></i> Adding
                        </div>
                      ) : (
                        this.props.addCError !== null && (
                          <div className="error">
                            Adding error: {this.props.addCError}
                          </div>
                        )
                      )}
                      {this.props.editCLoading ? (
                        <div className="loading">
                          <i className="fa fa-spinner fa-spin"></i> Editing
                        </div>
                      ) : (
                        this.props.editCError !== null && (
                          <div className="error">
                            Editing error: {this.props.editCError}
                          </div>
                        )
                      )}
                      {this.props.deleteCLoading ? (
                        <div className="loading">
                          <i className="fa fa-spinner fa-spin"></i> Deleting
                        </div>
                      ) : (
                        this.props.deleteCError !== null && (
                          <div className="error">
                            Deleting error: {this.props.deleteCError}
                          </div>
                        )
                      )}
                      {this.props.renameLoading ? (
                        <div
                        // className={styles.renameLoading}
                        >
                          <i className="fa fa-spinner fa-spin"></i> Renaming
                        </div>
                      ) : (
                        this.props.renameError !== null && (
                          <div className="error">
                            Renaming error: {this.props.renameError}
                          </div>
                        )
                      )}
                      {this.props.compLoading ? (
                        <div className="loading">
                          <i className="fa fa-spinner fa-spin"></i> Fetching
                        </div>
                      ) : this.props.compError !== null ? (
                        <div className="error">
                          Fetching error: {this.props.compError}
                        </div>
                      ) : (
                        <PropertyList
                          id={this.state.selectedComponent}
                          projectId={this.state.projectId}
                          rootScreen={oneProject.root_screen}
                          screenId={oneProject.current_screen}
                          parent={
                            this.props.components.find(
                              (c) => c._id === this.state.selectedComponent
                            )?.parent
                          }
                          compName={
                            this.props.components.find(
                              (c) => c._id === this.state.selectedComponent
                            )?.name
                          }
                          cTypeId={
                            this.props.components.find(
                              (c) => c._id === this.state.selectedComponent
                            )?.c_type
                          }
                          isAdvanced={
                            this.props.components.find(
                              (c) => c._id === this.state.selectedComponent
                            )?.type === 'advanced'
                          }
                          properties={this.state.componentProperties}
                          onChange={this.getComponentsOfCurrentScreen}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <BlocksPageDiv>
              {this.props.compLoading ? (
                <div className="loading">
                  <i className="fa fa-spinner fa-spin"></i> Fetching
                </div>
              ) : this.props.compError !== null ? (
                <div className="error">
                  Fetching error: {this.props.compError}
                </div>
              ) : (
                <BlocklyComponent
                  ref={(e) => (this.simpleWorkspace = e)}
                  readOnly={false}
                  initialXml={thisScreen.xml}
                  move={{
                    scrollbars: true,
                    drag: true,
                    wheel: true,
                  }}
                  current_screen={oneProject.current_screen}
                  onUpdateXml={this.props.updateScreenXml}
                  onUpdateCode={this.props.updateScreenCode}
                />
              )}
            </BlocksPageDiv>
          )}
        </div>
      </Sidebar>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    project: state.projects.projects,
    projLoading: state.projects.projectLoading,
    projError: state.projects.projectError,
    projEditLoading: state.projects.editLoading,
    projEditError: state.projects.editError,
    components: state.component.components,
    compLoading: state.component.fetchLoading,
    compError: state.component.fetchError,
    addCLoading: state.component.addLoading,
    addCError: state.component.addError,
    editCLoading: state.component.editLoading,
    editCError: state.component.editError,
    deleteCLoading: state.component.deleteLoading,
    deleteCError: state.component.deleteError,
    renameLoading: state.component.renameLoading,
    renameError: state.component.renameError,
    xmlLoading: state.component.xmlLoading,
    xmlError: state.component.xmlError,
    codeLoading: state.component.codeLoading,
    codeError: state.component.codeError,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getOneProject: (projectId) => dispatch(actions.fetchOneProject(projectId)),
    editProject: (project) => dispatch(actions.editProject(project)),
    updateScreenCode: (updatedComponentCode) =>
      dispatch(actions.updateCode(updatedComponentCode)),
    updateScreenCode_OnTheGo: (updatedComponentCode) =>
      dispatch(actions.updateCode_OnTheGo(updatedComponentCode)),
    updateScreenXml: (updatedComponentXml) =>
      dispatch(actions.updateXml(updatedComponentXml)),
    updateScreenXml_OnTheGo: (updatedComponent) =>
      dispatch(actions.updateXml_OnTheGo(updatedComponent)),
    changeProperty: (newValues) => dispatch(actions.changeProperty(newValues)),
    addComponent: (projectId, componentType, parentId, pos) =>
      dispatch(actions.addComponent(projectId, componentType, parentId, pos)),
    fetchComponents: (projectId) =>
      dispatch(actions.fetchComponents(projectId)),
    renameComponent: (component) =>
      dispatch(actions.renameComponent(component)),
    deleteComponent: (componentId, parent) =>
      dispatch(actions.deleteComponent(componentId, parent)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withParam(Project))
