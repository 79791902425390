import React, { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
  Bars3BottomLeftIcon,
  XMarkIcon,
  CheckCircleIcon,
  CloudArrowUpIcon,
  ArrowUturnLeftIcon,
  PencilIcon,
  CommandLineIcon,
  BookOpenIcon,
  FolderIcon,
  DocumentIcon,
} from '@heroicons/react/24/outline'
// import ExerciseIcon from '../../assets/icons/exercise_icon'
// import ProblemSetIcon from '../../assets/icons/problem_set'
// import ProjectIcon from '../../assets/icons/project_icon'
// import ReadingMaterialIcon from '../../assets/icons/reading_material'
import EditorLayout from '../../layout/editor/editor_layout'
import ProgramSet from '../../pages/programSet'
import ReadingMaterial from '../../pages/readingMaterial'
import { useNavigate } from 'react-router-dom'

const getIcon = (color) => {
  return {
    EXERCISE: <CommandLineIcon className="w-4 h-4" style={{ color }} />,
    PROBLEM_SET: <FolderIcon className="w-5 h-5" style={{ color }} />,
    PROJECT: <FolderIcon className="w-5 h-5" style={{ color }} />,
    READING_MATERIAL: <BookOpenIcon className="w-5 h-5" style={{ color }} />,
  }
}

const getChildren = (goToNext, currentContent) => {
  return {
    EXERCISE: (
      <EditorLayout
        goToNext={goToNext}
        language={currentContent?.codeType}
        courseContentId={currentContent?.id}
        initialCode={currentContent?.initialCode}
        instruction={currentContent?.description}
        testCases={currentContent?.testCases}
      />
    ),
    PROBLEM_SET: (
      <ProgramSet
        goToNext={goToNext}
        title={currentContent?.title}
        description={currentContent?.description}
      />
    ),
    PROJECT: (
      <ProgramSet
        goToNext={goToNext}
        title={currentContent?.title}
        description={currentContent?.description}
      />
    ),
    READING_MATERIAL: (
      <ReadingMaterial
        courseContentId={currentContent?.id}
        additionalLinks={currentContent?.additionalLinks || []}
        description={currentContent?.description}
        goToNext={goToNext}
      />
    ),
  }
}

const ExerciseSidebar = ({
  categories,
  currentContent,
  setCurrentExercise,
  goToNext,
  children,
}) => {
  const navigate = useNavigate()

  const renderCategories = () => {
    return categories.map((c, idx) => {
      return (
        <div
          key={c?.id}
          className={`flex justify-between items-baseline pl-5 pr-2 py-2 rounded-md cursor-pointer hover:bg-sky-100 ${
            c?.id === currentContent?.id && 'bg-sky-100'
          }`}
        >
          <span
            className={`
              ${c?.id === currentContent?.id ? 'mt-1 text-primary' : 'mt-1'}`}
          >
            {
              getIcon(c?.id === currentContent?.id ? '#09678C' : '#344054')[
                c.type
              ]
            }
          </span>
          <div
            className={`flex justify-between items-start flex-1 text-sm mb-1
              ${c?.id === currentContent?.id ? 'text-primary' : ''}
            `}
            onClick={() => {
              setCurrentExercise(c)
            }}
          >
            <span>
              {c.title}
              <br></br>
              <span
                className="inline-flex items-center text-gray-400"
                // style={{ fontSize: '12px' }}
              >
                <span className="pl-1">{c.duration}</span>
              </span>
            </span>
            {/* <span className="ml-1">
              {c?.status === 'PENDING' ? null : c?.status === 'SUBMITTED' ? (
                <CloudArrowUpIcon className="w-5 h-5" />
              ) : (
                <CheckCircleIcon className="w-5 h-5" />
              )}
            </span> */}
          </div>
        </div>
      )
    })
  }

  const goBack = () => {
    navigate(-1)
  }

  return (
    <div className="flex">
      <div className="border-r flex flex-col" style={{ width: '19rem' }}>
        <div className="flex flex-col flex-grow pt-5 overflow-y-auto bg-white">
          <div className="flex items-center justify-end flex-shrink-0 px-4">
            <ArrowUturnLeftIcon
              className="w-5 h-5 cursor-pointer"
              onClick={goBack}
            />
          </div>
          <div className="flex flex-col flex-1 mt-5 overflow-y-auto">
            <nav className="h-full px-2 pb-4">
              <h2 className="pb-2 mb-2 ml-3 font-bold border-b border-gray">
                Course Content
              </h2>
              <div>{renderCategories()}</div>
            </nav>
          </div>
        </div>
      </div>
      <div className="flex flex-col flex-1">
        <div className="flex flex-col flex-1">
          <div className="sticky top-0 z-10 flex flex-shrink-0 h-16 bg-white shadow md:hidden">
            <button
              type="button"
              className="px-4 text-gray-500 border-r border-gray-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-black md:hidden"
              onClick={() => {}}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3BottomLeftIcon className="w-6 h-6" aria-hidden="true" />
            </button>
          </div>

          <main>
            {children
              ? children
              : getChildren(goToNext, currentContent)[currentContent?.type]}
          </main>
        </div>
        <div></div>
      </div>
    </div>
  )
}

export default ExerciseSidebar
