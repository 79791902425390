import React from 'react'
// Components
import Property from '../property/property'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline'
import SpecialProperty from '../specialProperty/specialProperty'

export const PropertyCategory = (props) => {
  const [open, setOpen] = React.useState(false)
  let uniqueSubCategories = []
  let subCatProperties = []
  props.categoryProperties.map((property) => {
    if ('subCategory' in property) {
      if (!uniqueSubCategories.includes(property.subCategory)) {
        uniqueSubCategories.push(property.subCategory)
      }
    }
    return null
  })
  return (
    <div className='flex flex-col w-full'>
      <div className='flex w-full justify-between my-2 cursor-pointer' onClick={() => setOpen(!open)}>
        <span className='font-bold text-lg'>{props.categoryName}</span>
        <span>{open ? <ChevronUpIcon className='w-5 h-5' /> : <ChevronDownIcon className='w-5 h-5' />}</span>
      </div>
      <div className='grid grid-cols-2 gap-2'>
      {open && props.categoryProperties
        .filter((propertyName) => !('subCategory' in propertyName))
        .map((propertyName) => {
          if(propertyName.name === 'width' || propertyName.name === 'height' || propertyName.name === 'borderRadius' || propertyName.name === 'borderWidth' || propertyName.name === 'fontSize' || propertyName.name === 'fontFamily' || propertyName.name === 'fontWeight' || propertyName.name === 'fontStyle' || propertyName.name === 'thumbColor' || propertyName.name === 'trueTrackColor' || propertyName.name === 'falseTrackColor' || propertyName.name === 'borderColor'|| propertyName.name === 'borderStyle' || propertyName.name === 'disabled' || propertyName.name === 'display'){
            return (
              <div className='col-span-1'>
              <Property
                key={propertyName.name}
                id={props.id}
                name={propertyName.name}
                value={propertyName.value}
                cTypeId={props.cTypeId}
                edit={props.edit}
              />
              </div>
            )
          }
          return (
            <div className='col-span-2'>
            <Property
              key={propertyName.name}
              id={props.id}
              name={propertyName.name}
              value={propertyName.value}
              cTypeId={props.cTypeId}
              edit={props.edit}
            />
            </div>
          )
        })}
        </div>
      {open && Array.isArray(uniqueSubCategories) && uniqueSubCategories.length !== 0 &&
        <div className='relative px-2 flex justify-center items-center'>
          <div className=' border border-black h-80 w-full flex justify-center items-center rounded-md'>
            {props.categoryProperties.map((cat) => {
              return (
                <>
                  {cat.subCategory === 'margin' && cat.name === 'marginTop' &&
                    <p className='absolute top-0 inset-x-0 flex justify-center items-center'>
                      <SpecialProperty
                        key={cat.name}
                        id={props.id}
                        name={cat.name}
                        value={cat.value}
                        cTypeId={props.cTypeId}
                        edit={props.edit}
                        subCategory={props.subCategory}
                      />
                    </p>}
                  {cat.subCategory === 'margin' && cat.name === 'marginRight' &&
                    <p className='absolute right-0 mr-4 inset-y-0 flex items-center '>
                      <SpecialProperty
                        key={cat.name}
                        id={props.id}
                        name={cat.name}
                        value={cat.value}
                        cTypeId={props.cTypeId}
                        edit={props.edit}
                        subCategory={props.subCategory}
                      />
                    </p>}
                  {cat.subCategory === 'margin' && cat.name === 'marginLeft' &&
                    <p className='absolute left-0 ml-2 inset-y-0 flex items-center'>
                      <SpecialProperty
                        key={cat.name}
                        id={props.id}
                        name={cat.name}
                        value={cat.value}
                        cTypeId={props.cTypeId}
                        edit={props.edit}
                        subCategory={props.subCategory}
                      />
                    </p>}
                  {cat.subCategory === 'margin' && cat.name === 'marginBottom' &&
                    <p className='absolute bottom-0 inset-x-0 items-center flex justify-center mb-1'>
                      <SpecialProperty
                        key={cat.name}
                        id={props.id}
                        name={cat.name}
                        value={cat.value}
                        cTypeId={props.cTypeId}
                        edit={props.edit}
                        subCategory={props.subCategory}
                      />
                    </p>}
                </>)
            })}
            <div className='border-2 border-[#05A6A1] h-1/2 w-1/2 relative rounded-md'>
              {props.categoryProperties.map((cat) => {
                return (
                  <>
                    {cat.subCategory === 'padding' && cat.name === 'paddingTop' &&
                      <p className='absolute top-0 inset-x-0 flex justify-center items-center'>
                        <SpecialProperty
                          key={cat.name}
                          id={props.id}
                          name={cat.name}
                          value={cat.value}
                          cTypeId={props.cTypeId}
                          edit={props.edit}
                          subCategory={props.subCategory}
                        />
                      </p>}
                    {cat.subCategory === 'padding' && cat.name === 'paddingRight' &&
                      <p className='absolute right-0 inset-y-0 flex items-center pr-2'>
                        <SpecialProperty
                          key={cat.name}
                          id={props.id}
                          name={cat.name}
                          value={cat.value}
                          cTypeId={props.cTypeId}
                          edit={props.edit}
                          subCategory={props.subCategory}
                        />
                      </p>}
                    {cat.subCategory === 'padding' && cat.name === 'paddingLeft' &&
                      <p className='absolute left-0 inset-y-0 flex items-center'>
                        <SpecialProperty
                          key={cat.name}
                          id={props.id}
                          name={cat.name}
                          value={cat.value}
                          cTypeId={props.cTypeId}
                          edit={props.edit}
                          subCategory={props.subCategory}
                        />
                      </p>}
                    {cat.subCategory === 'padding' && cat.name === 'paddingBottom' &&
                      <p className='absolute bottom-0 inset-x-0 items-center flex justify-center mb-1'>
                        <SpecialProperty
                          key={cat.name}
                          id={props.id}
                          name={cat.name}
                          value={cat.value}
                          cTypeId={props.cTypeId}
                          edit={props.edit}
                          subCategory={props.subCategory}
                        />
                      </p>}
                  </>)
              })}
              <div className="absolute top-0 left-1/2 transform -mt-1.5 -mr-1.5 bg-[#05A6A1] rounded-full w-3 h-3" />
              <div className="absolute right-0 top-1/2 transform -mr-1.5 bg-[#05A6A1] rounded-full w-3 h-3" />
              <div className="absolute bottom-0 left-1/2 transform -mb-1.5 bg-[#05A6A1] rounded-full w-3 h-3" />
              <div className="absolute left-0 top-1/2 transform -ml-1.5 bg-[#05A6A1] rounded-full w-3 h-3" />
            </div>
            <div className="absolute top-0 left-1/2 transform -mt-1.5 bg-[#E4E7EC] rounded-full w-3 h-3" />
            <div className="absolute right-0 top-1/2 transform mr-0.5 bg-[#E4E7EC] rounded-full w-3 h-3" />
            <div className="absolute bottom-0 left-1/2 transform -mb-1.5 bg-[#E4E7EC] rounded-full w-3 h-3" />
            <div className="absolute left-0 top-1/2 transform ml-0.5 bg-[#E4E7EC] rounded-full w-3 h-3" />
          </div>
        </div>
      }
      <hr className='mt-2' />
    </div>
  )
}
