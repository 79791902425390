import React, { Component, Fragment } from 'react'
import Blockly from 'blockly/core'
import BlocklyJS from 'blockly/javascript'
import locale from 'blockly/msg/en'
import 'blockly/blocks'
// Redux
import { connect } from 'react-redux'
// Custom blocks imports
import { Block, Category, Value, Field, Shadow, Sep } from './index'
import '../../blocks/customBlocks'
import '../../blocks/generator'
// Constants
import {
  k_buttonid,
  k_labelid,
  k_screenid,
  k_textInputid,
  k_imageid,
  k_dataid,
  k_radioButtonid,
  k_iconid,
  k_dateTimeid,
  k_switchid,
} from '../../constants'

Blockly.setLocale(locale)

class BlocklyComponent extends Component {
  constructor(props) {
    super(props)
    this.getComponents = (compId, compArr) => {
      let component = this.props.components.find((x) => x._id === compId)
      if (compArr.find((x) => x._id === compId)) {
        return null
      } else {
        compArr.push(component)
        if (component.type === 'advanced' && component.children.length !== 0) {
          component.children.map((child) => this.getComponents(child, compArr))
        }
        return compArr
      }
    }
    this.generateXML = (componentList) => {
      let xml = [
        <Category name="Control" key="controlCategory" colour="120">
          <Block type="controls_whileUntil"></Block>
          <Block type="controls_for"></Block>
          <Block type="controls_forEach"></Block>
          <Block type="controls_repeat"></Block>
          <Block type="controls_repeat_ext">
            <Value name="TIMES">
              <Shadow type="al_numerical_input">
                <Field name="number_input">10</Field>
              </Shadow>
            </Value>
          </Block>
          <Block type="controls_flow_statements"></Block>
        </Category>,
        <Category name="Logic" key="logicCategory" colour="210">
          <Block type="logic_boolean"></Block>
          <Block type="controls_if"></Block>
          <Block type="controls_ifelse"></Block>
          <Block type="logic_compare"></Block>
          <Block type="logic_operation"></Block>
          <Block type="logic_negate" />
          <Block type="logic_null" />
          <Block type="logic_ternary" />
        </Category>,
        // <Category name="Lists" key="listsCategory" colour="260">
        //     <Block type="lists_create_empty"></Block>
        //     <Block type="lists_empty"></Block>
        //     <Block type="list_repeat"></Block>
        //     <Block type="lists_reverse"></Block>
        //     <Block type="lists_isEmpty"></Block>
        //     <Block type="lists_length"></Block>
        //     <Block type="lists_create_with"></Block>
        //     <Block type="lists_create_with_container"></Block>
        //     <Block type="lists_create_with_item"></Block>
        //     <Block type="lists_getIndex"></Block>
        //     <Block type="lists_setIndex"></Block>
        //     <Block type="lists_getSublist"></Block>
        //     <Block type="lists_sort"></Block>
        //     <Block type="lists_split"></Block>
        // </Category>,
        <Sep key="sharedSeparator" />,
        <Category name="General" key="generalCategory" colour="210">
          <Block type="set_color"></Block>
          <Block type="set_text_input"></Block>
          <Block type="al_numerical_input"></Block>
          <Block type="al_boolean"></Block>
          <Block type="al_keyboard_type_input"></Block>
          <Block type="al_screen_justification"></Block>
          <Block type="al_screen_alignment"></Block>
          <Block type="al_move_screen"></Block>
          <Block type="al_image_size"></Block>
        </Category>,
        <Sep key="generalSeparator" />,
      ]
      componentList.forEach((comp) => {
        switch (comp.c_type) {
          case k_screenid:
            xml.push(
              <Category key={comp._id} name={comp.name} colour="60">
                <Block type="when_do_screen"></Block>
                <Block type="al_screen_getter"></Block>
                <Block type="al_screen_setter"></Block>
                <Block type="al_move_screen"></Block>
                <Block type="al_screen_justification"></Block>
                <Block type="al_screen_alignment"></Block>
              </Category>
            )
            break
          case k_buttonid:
            xml.push(
              <Category key={comp._id} name={comp.name} colour="150">
                <Block type="when_do_buttons"></Block>
                <Block type="al_button_getter"></Block>
                <Block type="al_button_setter"></Block>
                <Block type="set_color"></Block>
                <Block type="set_text_input"></Block>
              </Category>
            )
            break
          case k_labelid:
            xml.push(
              <Category key={comp._id} name={comp.name} colour="30">
                <Block type="al_label_getter"></Block>
                <Block type="al_label_setter"></Block>
                <Block type="set_color"></Block>
                <Block type="set_text_input"></Block>
              </Category>
            )
            break
          case k_textInputid:
            xml.push(
              <Category key={comp._id} name={comp.name} colour="0">
                <Block type="al_text_input_change"></Block>
                <Block type="al_text_input_getter"></Block>
                <Block type="al_text_input_setter"></Block>
                <Block type="al_keyboard_type_input"></Block>
              </Category>
            )
            break
          case k_imageid:
            xml.push(
              <Category key={comp._id} name={comp.name} colour="270">
                <Block type="al_image_getter"></Block>
                <Block type="al_image_setter"></Block>
                <Block type="al_image_size"></Block>
              </Category>
            )
            break
          case k_radioButtonid:
            xml.push(
              <Category key={comp._id} name={comp.name} colour="300">
                <Block type="al_radio_button_get_selected"></Block>
                <Block type="al_radio_button_group_set"></Block>
                <Block type="al_radio_button_getter"></Block>
                <Block type="al_radio_button_setter"></Block>
              </Category>
            )
            break
          case k_iconid:
            xml.push(
              <Category key={comp._id} name={comp.name} colour="330">
                <Block type="al_icon_event"></Block>
                {/* <Block type="al_icon_getter"></Block> */}
              </Category>
            )
            break
          case k_dateTimeid:
            xml.push(
              <Category key={comp._id} name={comp.name} colour="120">
                <Block type="al_datetime_event"></Block>
                <Block type="al_datetime_input_getter"></Block>
                <Block type="al_datetime_input_setter"></Block>
              </Category>
            )
            break
          case k_switchid:
            xml.push(
              <Category key={comp._id} name={comp.name} colour="240">
                <Block type="al_switch_event"></Block>
                <Block type="al_switch_input_getter"></Block>
                <Block type="al_switch_input_setter"></Block>
              </Category>
            )
            break
          case k_dataid:
            xml.push(
              <Category key={comp._id} name={comp.name} colour="90">
                {/* <Block type="al_insert_table_row"></Block> */}
                <Block type="al_insert_table_row_end"></Block>
                <Block type="al_column_condition"></Block>
                <Block type="al_get_table_row"></Block>
                <Block type="al_get_table_row_conditional"></Block>
                <Block type="al_get_table_row_multiple_conditional"></Block>
                <Block type="al_get_table_row_multiple_conditional_male"></Block>
                <Block type="al_get_table_rows"></Block>
                <Block type="al_get_table_rows_conditional"></Block>
                <Block type="al_get_table_rows_multiple_conditional"></Block>
                <Block type="al_update_table_data"></Block>
                <Block type="al_update_table_all_data"></Block>
                <Block type="al_delete_one_row"></Block>
                <Block type="al_delete_one_row_conditional"></Block>
                <Block type="al_delete_multiple_row"></Block>
                <Block type="al_delete_multiple_row_conditional"></Block>
                {/* <Block type="al_where_row_column"></Block>
                            <Block type="al_next_row"></Block> */}
              </Category>
            )
            break
          // case k_customContainer:
          //     xml.push(<Category key={comp._id} name={comp.name} colour="45">
          //         <Block type="al_create_new_component"></Block>
          //     </Category>)
          //     break;
          default:
            break
        }
      })
      xml.push(
        <Sep key="variableSeparator" />,
        <Category
          name="Variables"
          colour="360"
          custom="VARIABLE"
          key="variableCategory"
        ></Category>
      )
      return xml
    }
    let compList = this.getComponents(this.props.current_screen, [])
    localStorage.setItem(
      'applab_componentList',
      JSON.stringify([
        ...compList.filter((c) => c.c_type !== k_screenid),
        ...this.props.components.filter((c) => c.c_type === k_screenid),
      ])
    )
    localStorage.setItem('applab_currentScreen', this.props.current_screen)
    let initialToolbar = this.generateXML(compList)
    this.state = {
      initialToolbar: initialToolbar,
    }
  }

  componentDidMount() {
    const { initialXml, children, ...rest } = this.props
    this.primaryWorkspace = Blockly.inject(this.blocklyDiv, {
      toolbox: this.toolbox,
      zoom: {
        controls: true,
        wheel: true,
        startScale: 1.0,
        maxScale: 3,
        minScale: 0.3,
        scaleSpeed: 1.2,
      },
      ...rest,
    })

    if (initialXml) {
      Blockly.Xml.domToWorkspace(
        Blockly.Xml.textToDom(initialXml),
        this.primaryWorkspace
      )
    }
    this.primaryWorkspace.addChangeListener(this.onEveryChange)
  }

  // componentWillUnmount() {
  //     this.onEveryChange()
  // }

  onEveryChange = () => {
    this.saveCode()
    this.saveXml()
  }

  saveCode = () => {
    var code = BlocklyJS.workspaceToCode(this.primaryWorkspace)
    let updateScreenCode = {
      cid: this.props.current_screen,
      code: code,
    }
    this.props.onUpdateCode(updateScreenCode)
  }

  saveXml = () => {
    var xml = Blockly.Xml.workspaceToDom(this.primaryWorkspace)
    var xml_text = Blockly.Xml.domToText(xml)
    let updateScreenXml = {
      cid: this.props.current_screen,
      xml: xml_text,
    }
    this.props.onUpdateXml(updateScreenXml)
  }

  get workspace() {
    return this.primaryWorkspace
  }

  setXml(xml) {
    Blockly.Xml.domToWorkspace(
      Blockly.Xml.textToDom(xml),
      this.primaryWorkspace
    )
  }

  render() {
    // console.log('rendering blockly component', this.props, this.state)
    return (
      <Fragment>
        <p>Blockly component</p>
        <div
          ref={(e) => (this.blocklyDiv = e)}
          className="h-[70%] w-[85%] min-w-[850px] absolute"
        />
        <xml
          xmlns="https://developers.google.com/blockly/xml"
          is="blockly"
          ref={(toolbox) => {
            this.toolbox = toolbox
          }}
        >
          {this.state.initialToolbar}
        </xml>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  components: state.component.components,
})

export default connect(mapStateToProps, null)(BlocklyComponent)
